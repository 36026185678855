/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-lines */
export enum Symbols {
    /** Cardano */
    'ADA' = 'ADA',
    /** Snek */
    'SNEK' = 'SNEK',
    /** IAGON */
    'IAG' = 'IAG',
    /** Minswap */
    'MIN' = 'MIN',
    /** Indigo Protocol */
    'INDY' = 'INDY',
    /** Hosky Token */
    'HOSKY' = 'HOSKY',
    /** Djed */
    'DJED' = 'DJED',
    /** KubeCoin */
    'KUBE' = 'KUBE',
    /** AdaSwap */
    'ASW' = 'ASW',
    /** Book.io */
    'BOOK' = 'BOOK',
    /** Indigo Protocol - iUSD */
    'IUSD' = 'IUSD',
    /** NEWM */
    'NEWM' = 'NEWM',
    /** Liqwid Finance */
    'LQ' = 'LQ',
    /** Revuto */
    'REVU' = 'REVU',
    /** WingRiders Governance Token */
    'WRT' = 'WRT',
    /** FREN */
    'FREN' = 'FREN',
    /** Axo */
    'AXO' = 'AXO',
    /** Lenfi */
    'LENFI' = 'LENFI',
    /** Indigo Protocol - iBTC */
    'IBTC' = 'IBTC',
    /** Genius Yield */
    'GENS' = 'GENS',
    /** Pavia */
    'PAVIA' = 'PAVIA',
    /** Ardana */
    'DANA' = 'DANA',
    /** Orcfax */
    'FACT' = 'FACT',
    /** Charli3 */
    'C3' = 'C3',
    /** Shen */
    'SHEN' = 'SHEN',
    /** ENCOINS */
    'ENCS' = 'ENCS',
    /** AnetaBTC */
    'cNETA' = 'cNETA',
    /** Stablecoin */
    'STABLE' = 'STABLE',
    /** Ray Network */
    'XRAY' = 'XRAY',
    /** Venom */
    'VNM' = 'VNM',
    /** MuesliSwap MILK */
    'MILK' = 'MILK',
    /** VyFinance */
    'VYFI' = 'VYFI',
    /** Empowa */
    'EMP' = 'EMP',
    /** BankerCoinAda */
    'BANK' = 'BANK',
    /** Clay Nation */
    'CLAY' = 'CLAY',
    /** ADAX */
    'ADAX' = 'ADAX',
    /** Chains of War */
    'MIRA' = 'MIRA',
    /** CamelCoin */
    'CML' = 'CML',
    /** Yummi Universe */
    'YUMMI' = 'YUMMI',
    /** Tangent */
    'TANG' = 'TANG',
    /** Ibilecoin */
    'BLC' = 'BLC',
    /** Vix Finance */
    'CPIGGY' = 'CPIGGY',
    /** Tether USDt */
    'USDT' = 'USDT',
    /** Chainlink */
    'LINK' = 'LINK',
    /** SingularityNET */
    'AGIX' = 'AGIX',
    /** USDC */
    'USDC' = 'USDC',
    /** Wrapped Bitcoin */
    'WBTC' = 'WBTC',
    /** Wrapped BNB */
    'WBNB' = 'WBNB',
    /** World Mobile Token */
    'WMT' = 'WMT',
    /** Cornucopias */
    'COPI' = 'COPI',
    /** NuNet */
    'NTX' = 'NTX',
    /** Paribus */
    'PBX' = 'PBX',
    /** WETH */
    'WETH' = 'WETH',
    /** Wrapped AVAX */
    'WAVAX' = 'WAVAX',
    /** Wrapped Fantom */
    'WFTM' = 'WFTM',
    /** Rejuve.AI */
    'RJV' = 'RJV',
    /** MELD */
    'MELD' = 'MELD',
    /** Cogito Finance */
    'CGV' = 'CGV',
    /** MAI */
    'MIMATIC' = 'MIMATIC',
    /** Blueshift */
    'BLUES' = 'BLUES',
    /** Wrapped Cardano */
    'WADA' = 'WADA',
    /** SundaeSwap */
    'SUNDAE' = 'SUNDAE',
    /** NMKR */
    'NMKR' = 'NMKR',
    /** Adadao */
    'ADAO' = 'ADAO',
    /** Algorand */
    'ALGO' = 'ALGO',
    /** Ethereum */
    'ETH' = 'ETH',
    /** Arbitrum */
    'ARB' = 'ARB',
    /** Treasure */
    'MAGIC' = 'MAGIC',
    /** Xai */
    'XAI' = 'XAI',
    /** ArbDoge AI */
    'AIDOGE' = 'AIDOGE',
    /** Vertex Protocol */
    'VRTX' = 'VRTX',
    /** ZTX */
    'ZTX' = 'ZTX',
    /** Wall Street Games */
    'WSG' = 'WSG',
    /** Hydranet */
    'HDN' = 'HDN',
    /** Reboot */
    'GG' = 'GG',
    /** Mozaic */
    'MOZ' = 'MOZ',
    /** Sperax USD */
    'USDs' = 'USDs',
    /** OpenWorld */
    'OPEN' = 'OPEN',
    /** Zyberswap */
    'ZYB' = 'ZYB',
    /** Neutra Finance */
    'NEU' = 'NEU',
    /** BlueSale Finance */
    'BLS' = 'BLS',
    /** POGAI */
    'pogai' = 'pogai',
    /** Fluidity */
    'FLY' = 'FLY',
    /** xPET tech */
    'XPET' = 'XPET',
    /** Camelot Token */
    'GRAIL' = 'GRAIL',
    /** Avive World */
    'AVIVE' = 'AVIVE',
    /** Grimace */
    'GRIMACE' = 'GRIMACE',
    /** GTA Token */
    'GTA' = 'GTA',
    /** Vela Exchange */
    'VELA' = 'VELA',
    /** Fluid USDC */
    'FUSDC' = 'FUSDC',
    /** Roseon */
    'ROSX' = 'ROSX',
    /** Edge Matrix Computing */
    'EMC' = 'EMC',
    /** AiShiba */
    'shibai' = 'shibai',
    /** A3S Protocol */
    'AA' = 'AA',
    /** AI CODE */
    'AICODE' = 'AICODE',
    /** Ramses Exchange */
    'RAM' = 'RAM',
    /** Unibit */
    'UIBT' = 'UIBT',
    /** Florence Finance Medici */
    'FFM' = 'FFM',
    /** ArbiPad */
    'ARBI' = 'ARBI',
    /** MyMetaTrader */
    'MMT' = 'MMT',
    /** HMX */
    'HMX' = 'HMX',
    /** PLEXUS */
    'PLX' = 'PLX',
    /** Lodestar */
    'LODE' = 'LODE',
    /** The Ennead */
    'NEADRAM' = 'NEADRAM',
    /** YachtingVerse */
    'YACHT' = 'YACHT',
    /** SIZE */
    'SIZE' = 'SIZE',
    /** Factor */
    'FCTR' = 'FCTR',
    /** Arbswap */
    'ARBS' = 'ARBS',
    /** Zen Panda Coin */
    'ZPC' = 'ZPC',
    /** Neptune Mutual */
    'NPM' = 'NPM',
    /** Umami Finance */
    'UMAMI' = 'UMAMI',
    /** Arbidoge */
    'ADOGE' = 'ADOGE',
    /** Jones USDC */
    'JUSDC' = 'JUSDC',
    /** ArbiDex Token */
    'ARX' = 'ARX',
    /** PlutusDAO */
    'PLS' = 'PLS',
    /** WORLD PEACE PROJECT */
    'TRUCE' = 'TRUCE',
    /** YieldFarming Index */
    'YFX' = 'YFX',
    /** Chronos */
    'CHR' = 'CHR',
    /** Cradles */
    'CRDS' = 'CRDS',
    /** ArbGPT */
    'GPT' = 'GPT',
    /** RWA Finance */
    'RWAS' = 'RWAS',
    /** Realm Anima */
    'ANIMA' = 'ANIMA',
    /** Tigris */
    'TIG' = 'TIG',
    /** LFGSwap Finance (Arbitrum) */
    'LFG' = 'LFG',
    /** Crystal */
    'CRYSTAL' = 'CRYSTAL',
    /** YURI */
    'YURI' = 'YURI',
    /** Hamachi Finance */
    'HAMI' = 'HAMI',
    /** Rodeo Finance */
    'RDO' = 'RDO',
    /** Mizar */
    'MZR' = 'MZR',
    /** GMD Protocol */
    'GMD' = 'GMD',
    /** Vesta Finance */
    'VSTA' = 'VSTA',
    /** Foxify */
    'FOX' = 'FOX',
    /** Buffer Finance */
    'BFR' = 'BFR',
    /** ELLERIUM (NEW) */
    'ELM' = 'ELM',
    /** AICORE */
    'AICORE' = 'AICORE',
    /** ARBSHIB */
    'AISHIB' = 'AISHIB',
    /** ETHforestAI */
    'ETHFAI' = 'ETHFAI',
    /** Alien */
    'ALIEN' = 'ALIEN',
    /** ArbiNYAN */
    'NYAN' = 'NYAN',
    /** OreoSwap */
    'OREO' = 'OREO',
    /** Honor World Token */
    'HWT' = 'HWT',
    /** AutoDCA */
    'DCA' = 'DCA',
    /** LSDoge */
    'LSDOGE' = 'LSDOGE',
    /** Archi Finance */
    'ARCHI' = 'ARCHI',
    /** LeverageInu */
    'LEVI' = 'LEVI',
    /** Exponential Capital */
    'EXPO' = 'EXPO',
    /** GBOT */
    'GBOT' = 'GBOT',
    /** Smart Aliens */
    'SAS' = 'SAS',
    /** Sharky Swap */
    'SHARKY' = 'SHARKY',
    /** Liquid Finance */
    'LIQD' = 'LIQD',
    /** Modular Wallet */
    'MOD' = 'MOD',
    /** 3xcalibur */
    'XCAL' = 'XCAL',
    /** Sector Finance */
    'SECT' = 'SECT',
    /** Ghast */
    'GHA' = 'GHA',
    /** Arcadeum */
    'ARC' = 'ARC',
    /** FrozenAi */
    'FROZE' = 'FROZE',
    /** Legends */
    'LG' = 'LG',
    /** AIENGLISH */
    'AIEN' = 'AIEN',
    /** ToxicGarden.finance SEED */
    'SEED' = 'SEED',
    /** Unlimited Network */
    'UWU' = 'UWU',
    /** Seneca */
    'SEN' = 'SEN',
    /** Gnosis */
    'GNO' = 'GNO',
    /** Dai */
    'DAI' = 'DAI',
    /** The Graph */
    'GRT' = 'GRT',
    /** Uniswap */
    'UNI' = 'UNI',
    /** Lido DAO */
    'LDO' = 'LDO',
    /** Pendle */
    'PENDLE' = 'PENDLE',
    /** Ankr */
    'ANKR' = 'ANKR',
    /** Curve DAO Token */
    'CRV' = 'CRV',
    /** Frax Share */
    'FXS' = 'FXS',
    /** PancakeSwap */
    'CAKE' = 'CAKE',
    /** WOO */
    'WOO' = 'WOO',
    /** Axelar */
    'AXL' = 'AXL',
    /** Balancer */
    'BAL' = 'BAL',
    /** yearn.finance */
    'YFI' = 'YFI',
    /** SushiSwap */
    'SUSHI' = 'SUSHI',
    /** Frax */
    'FRAX' = 'FRAX',
    /** Marlin */
    'POND' = 'POND',
    /** Seedify.fund */
    'SFUND' = 'SFUND',
    /** JOE */
    'JOE' = 'JOE',
    /** Synapse */
    'SYN' = 'SYN',
    /** Kujira */
    'KUJI' = 'KUJI',
    /** Ethena USDe */
    'USDe' = 'USDe',
    /** Orbs */
    'ORBS' = 'ORBS',
    /** Tellor */
    'TRB' = 'TRB',
    /** Cartesi */
    'CTSI' = 'CTSI',
    /** Gains Network */
    'GNS' = 'GNS',
    /** ApeX Protocol */
    'APEX' = 'APEX',
    /** SmarDex */
    'SDEX' = 'SDEX',
    /** crvUSD */
    'CRVUSD' = 'CRVUSD',
    /** DODO */
    'DODO' = 'DODO',
    /** Stella */
    'ALPHA' = 'ALPHA',
    /** Badger DAO */
    'BADGER' = 'BADGER',
    /** Chainge */
    'XCHNG' = 'XCHNG',
    /** MOBOX */
    'MBOX' = 'MBOX',
    /** Kyber Network Crystal v2 */
    'KNC' = 'KNC',
    /** Spell Token */
    'SPELL' = 'SPELL',
    /** Stargate Finance */
    'STG' = 'STG',
    /** Radiant Capital */
    'RDNT' = 'RDNT',
    /** Milady Meme Coin */
    'LADYS' = 'LADYS',
    /** Router Protocol */
    'ROUTE' = 'ROUTE',
    /** Opulous */
    'OPUL' = 'OPUL',
    /** Lumerin */
    'LMR' = 'LMR',
    /** Moonchain */
    'MXC' = 'MXC',
    /** Sperax */
    'SPA' = 'SPA',
    /** dForce */
    'DF' = 'DF',
    /** r/CryptoCurrency Moons */
    'MOON' = 'MOON',
    /** Xend Finance */
    'RWA' = 'RWA',
    /** Symbiosis */
    'SIS' = 'SIS',
    /** UBXS Token */
    'UBXS' = 'UBXS',
    /** Bitcoin Unlimited */
    'BTCU' = 'BTCU',
    /** Ferrum Network */
    'FRM' = 'FRM',
    /** Bridge Oracle */
    'BRG' = 'BRG',
    /** Tarot */
    'TAROT' = 'TAROT',
    /** OpenLeverage */
    'OLE' = 'OLE',
    /** Bitcoin XT */
    'BTCXT' = 'BTCXT',
    /** MahaDAO */
    'MAHA' = 'MAHA',
    /** RigoBlock */
    'GRG' = 'GRG',
    /** OpenOcean */
    'OOE' = 'OOE',
    /** Rubic */
    'RBC' = 'RBC',
    /** X World Games */
    'XWG' = 'XWG',
    /** Wicrypt */
    'WNT' = 'WNT',
    /** ROOBEE */
    'ROOBEE' = 'ROOBEE',
    /** DSLA Protocol */
    'DSLA' = 'DSLA',
    /** Xaya */
    'CHI' = 'CHI',
    /** Kommunitas */
    'KOM' = 'KOM',
    /** CheckDot */
    'CDT' = 'CDT',
    /** Edgeware */
    'EDG' = 'EDG',
    /** Kromatika */
    'KROM' = 'KROM',
    /** Donut */
    'DONUT' = 'DONUT',
    /** O3 Swap */
    'O3' = 'O3',
    /** Hord */
    'HORD' = 'HORD',
    /** SafeBlast */
    'BLAST' = 'BLAST',
    /** Football World Community */
    'FWC' = 'FWC',
    /** BullBear AI */
    'AIBB' = 'AIBB',
    /** Goons of Balatroon */
    'GOB' = 'GOB',
    /** BlackHat */
    'BLKC' = 'BLKC',
    /** ApeSwap */
    'BANANA' = 'BANANA',
    /** April */
    'APRIL' = 'APRIL',
    /** Project TXA */
    'TXA' = 'TXA',
    /** OpenBlox */
    'OBX' = 'OBX',
    /** Plasma Finance */
    'PPAY' = 'PPAY',
    /** FantomStarter */
    'FS' = 'FS',
    /** ERC20 */
    'ERC20' = 'ERC20',
    /** Aluna.Social */
    'ALN' = 'ALN',
    /** Flashstake */
    'FLASH' = 'FLASH',
    /** Lido wstETH */
    'WSTETH' = 'WSTETH',
    /** USD Coin Bridged */
    'USDC.e' = 'USDC.e',
    /** USD+ */
    'USD+' = 'USD+',
    /** Axelar Wrapped USDC */
    'axlUSDC' = 'axlUSDC',
    /** Renzo Restaked ETH */
    'EZETH' = 'EZETH',
    /** BVM */
    'BVM' = 'BVM',
    /** The Doge NFT */
    'DOG' = 'DOG',
    /** Frax Finance - Frax Ether */
    'FRXETH' = 'FRXETH',
    /** tBTC */
    'TBTC' = 'TBTC',
    /** Ice Network */
    'ICE' = 'ICE',
    /** OmniCat */
    'OMNI' = 'OMNI',
    /** Port3 Network */
    'PORT3' = 'PORT3',
    /** Ethena Staked USDe */
    'sUSDe' = 'sUSDe',
    /** GAM3S.GG */
    'G3' = 'G3',
    /** MetaDOS */
    'SECOND' = 'SECOND',
    /** Frax Staked Ether */
    'SFRXETH' = 'SFRXETH',
    /** Overnight */
    'OVN' = 'OVN',
    /** DOLA */
    'DOLA' = 'DOLA',
    /** Rabbit */
    'RAB' = 'RAB',
    /** Lybra Finance */
    'LBR' = 'LBR',
    /** Osaka Protocol */
    'OSAK' = 'OSAK',
    /** Nutcoin */
    'NUT' = 'NUT',
    /** Any Inu */
    'AI' = 'AI',
    /** OX Coin */
    'OX' = 'OX',
    /** Hello Pets */
    'PET' = 'PET',
    /** Dopex */
    'DPX' = 'DPX',
    /** Sanko GameCorp */
    'DMT' = 'DMT',
    /** StarHeroes */
    'STAR' = 'STAR',
    /** Magic Internet Money */
    'MIM' = 'MIM',
    /** KAP Games */
    'KAP' = 'KAP',
    /** Magpie */
    'MGP' = 'MGP',
    /** DRAM */
    'DRAM' = 'DRAM',
    /** Starfish Finance */
    'SEAN' = 'SEAN',
    /** Wrapped USDR */
    'WUSDR' = 'WUSDR',
    /** THORWallet */
    'TGT' = 'TGT',
    /** ENO */
    'ENO' = 'ENO',
    /** SHARBI */
    'SHARBI' = 'SHARBI',
    /** AI Meta Club */
    'AMC' = 'AMC',
    /** Governance OHM */
    'GOHM' = 'GOHM',
    /** Arbitrove Protocol */
    'TROVE' = 'TROVE',
    /** WINR Protocol */
    'WINR' = 'WINR',
    /** Equilibria Finance */
    'EQB' = 'EQB',
    /** Wagmi */
    'WAGMI' = 'WAGMI',
    /** Bumper */
    'BUMP' = 'BUMP',
    /** GenomesDao */
    '$GENE' = '$GENE',
    /** Perpy */
    'PRY' = 'PRY',
    /** FORE Protocol */
    'FORE' = 'FORE',
    /** CrazyRabbit */
    'CRAYRABBIT' = 'CRAYRABBIT',
    /** Jones DAO */
    'JONES' = 'JONES',
    /** IPOR */
    'IPOR' = 'IPOR',
    /** Alongside Crypto Market Index */
    'AMKT' = 'AMKT',
    /** Kenshi */
    'KNS' = 'KNS',
    /** BonusBlock */
    'BONUS' = 'BONUS',
    /** Elk Finance */
    'ELK' = 'ELK',
    /** Yieldification */
    'YDF' = 'YDF',
    /** XDAO */
    'XDAO' = 'XDAO',
    /** Astra DAO */
    'ASTRADAO' = 'ASTRADAO',
    /** EUROe Stablecoin */
    'EUROE' = 'EUROE',
    /** DegenReborn */
    'DEGEN' = 'DEGEN',
    /** Zoomer Coin */
    'ZOOMER' = 'ZOOMER',
    /** Equation */
    'EQU' = 'EQU',
    /** Eclipse Fi */
    'ECLIP' = 'ECLIP',
    /** Smolcoin */
    'SMOL' = 'SMOL',
    /** AXM token */
    'AXM' = 'AXM',
    /** Lumi Finance */
    'LUA' = 'LUA',
    /** Silo Finance */
    'SILO' = 'SILO',
    /** Spool */
    'SPOOL' = 'SPOOL',
    /** SteakHut Finance */
    'STEAK' = 'STEAK',
    /** Rekt */
    'REKT' = 'REKT',
    /** SEX Odyssey */
    'SEX' = 'SEX',
    /** GnomeLand */
    'GNOME' = 'GNOME',
    /** TridentDAO */
    'PSI' = 'PSI',
    /** Hepton */
    'HTE' = 'HTE',
    /** AiAkita */
    'AIAKITA' = 'AIAKITA',
    /** Volta Club */
    'VOLTA' = 'VOLTA',
    /** Thales */
    'THALES' = 'THALES',
    /** dForce USD */
    'USX' = 'USX',
    /** BattleFly */
    'GFLY' = 'GFLY',
    /** Frax Price Index Share */
    'FPIS' = 'FPIS',
    /** Superpower Squad */
    'SQUAD' = 'SQUAD',
    /** Mummy Finance */
    'MMY' = 'MMY',
    /** Kabosu Family */
    'KABOSU' = 'KABOSU',
    /** TaoBank */
    'TBANK' = 'TBANK',
    /** Yield Yak */
    'YAK' = 'YAK',
    /** unshETH Ether */
    'UNSHETH' = 'UNSHETH',
    /** xPET tech BPET */
    'BPET' = 'BPET',
    /** DFX Finance */
    'DFX' = 'DFX',
    /** Spherium */
    'SPHRI' = 'SPHRI',
    /** handleFOREX */
    'FOREX' = 'FOREX',
    /** Real USD */
    'USDR' = 'USDR',
    /** Davos.xyz USD */
    'DUSD' = 'DUSD',
    /** Plutus ARB */
    'PLSARB' = 'PLSARB',
    /** MemePad */
    'MEPAD' = 'MEPAD',
    /** Overnight DAI+ */
    'DAI+' = 'DAI+',
    /** Quick Intel */
    'QKNTL' = 'QKNTL',
    /** Collab.Land */
    'COLLAB' = 'COLLAB',
    /** LiquidDriver */
    'LQDR' = 'LQDR',
    /** unshETHing_Token */
    'USH' = 'USH',
    /** Nola */
    'NOLA' = 'NOLA',
    /** Blocjerk */
    'BJ' = 'BJ',
    /** Molten */
    'MOLTEN' = 'MOLTEN',
    /** BetSwirl */
    'BETS' = 'BETS',
    /** Artichoke Protocol */
    'CHOKE' = 'CHOKE',
    /** FOOM */
    'FOOM' = 'FOOM',
    /** RadioShack */
    'RADIO' = 'RADIO',
    /** HOLD VIP */
    'HOLD' = 'HOLD',
    /** Guardian */
    'GUARD' = 'GUARD',
    /** Dopex Rebate Token */
    'RDPX' = 'RDPX',
    /** Interport Token */
    'ITP' = 'ITP',
    /** Poison Finance */
    'POI$ON' = 'POI$ON',
    /** peg-eUSD */
    'PEUSD' = 'PEUSD',
    /** WardenSwap */
    'WAD' = 'WAD',
    /** Savvy */
    'SVY' = 'SVY',
    /** Arken Finance */
    'ARKEN' = 'ARKEN',
    /** Frax Price Index */
    'FPI' = 'FPI',
    /** OtterHome */
    'HOME' = 'HOME',
    /** Kunji Finance */
    'KNJ' = 'KNJ',
    /** Oath */
    'OATH' = 'OATH',
    /** Wombex Finance */
    'WMX' = 'WMX',
    /** SolidLizard */
    'SLIZ' = 'SLIZ',
    /** Premia */
    'PREMIA' = 'PREMIA',
    /** Onchain Trade */
    'OT' = 'OT',
    /** Global Token */
    'GBL' = 'GBL',
    /** Minato */
    'MNTO' = 'MNTO',
    /** MMFinance (Arbitrum) */
    'MMF' = 'MMF',
    /** Hyperbolic Protocol */
    'HYPE' = 'HYPE',
    /** Uranium3o8 */
    'U' = 'U',
    /** WePiggy Coin */
    'WPC' = 'WPC',
    /** MAGIKAL.ai */
    'MGKL' = 'MGKL',
    /** Cap */
    'CAP' = 'CAP',
    /** Wonderful Memories */
    'WMEMO' = 'WMEMO',
    /** LakeViewMeta */
    'LVM' = 'LVM',
    /** Layer2DAO */
    'L2DAO' = 'L2DAO',
    /** Y2K */
    'Y2K' = 'Y2K',
    /** Future AI */
    'FUTURE-AI' = 'FUTURE-AI',
    /** LEXER Markets */
    'LEX' = 'LEX',
    /** Digitex */
    'DGTX' = 'DGTX',
    /** BitcoinBR */
    'BTCBR' = 'BTCBR',
    /** ROVI */
    'ROVI' = 'ROVI',
    /** xDollar Stablecoin */
    'XUSD' = 'XUSD',
    /** Kortana */
    'KORA' = 'KORA',
    /** Hundred Finance */
    'HND' = 'HND',
    /** Altitude */
    'ALTD' = 'ALTD',
    /** Quoll Finance */
    'QUO' = 'QUO',
    /** NFTEarthOFT */
    'NFTE' = 'NFTE',
    /** Flux Protocol */
    'FLUX' = 'FLUX',
    /** StereoAI */
    'STAI' = 'STAI',
    /** DEI */
    'DEI' = 'DEI',
    /** pTokens BTC */
    'pBTC' = 'pBTC',
    /** Bolide */
    'BLID' = 'BLID',
    /** Bitcoin Classic */
    'BXC' = 'BXC',
    /** EthereumMax */
    'EMAX' = 'EMAX',
    /** Okcash */
    'OK' = 'OK',
    /** Ether Wars */
    'WAR' = 'WAR',
    /** ACryptoS */
    'ACS' = 'ACS',
    /** Unlock Protocol */
    'UDT' = 'UDT',
    /** Sarcophagus */
    'SARCO' = 'SARCO',
    /** Swapr */
    'SWPR' = 'SWPR',
    /** Popcorn */
    'POP' = 'POP',
    /** Doubloon */
    'DBL' = 'DBL',
    /** Twoge Inu */
    'TWOGE' = 'TWOGE',
    /** Falcon */
    'FLN' = 'FLN',
    /** Compounded Marinated UMAMI */
    'CMUMAMI' = 'CMUMAMI',
    /** ArbInu */
    'ARBINU' = 'ARBINU',
    /** Jones GLP */
    'JGLP' = 'JGLP',
    /** AsgardX */
    'ODIN' = 'ODIN',
    /** Gridex */
    'GDX' = 'GDX',
    /** Zenland */
    'ZENF' = 'ZENF',
    /** LionDEX */
    'LION' = 'LION',
    /** GND Protocol */
    'GND' = 'GND',
    /** TAIKULA COIN */
    'TAIKULA' = 'TAIKULA',
    /** The White Lotus */
    'LOTUS' = 'LOTUS',
    /** TraderDAO */
    'POT' = 'POT',
    /** Monopoly Finance Layer 3 */
    'POLY' = 'POLY',
    /** Magicverse */
    'ORB' = 'ORB',
    /** GMBL Computer */
    'GMBL' = 'GMBL',
    /** Yak DAO */
    'YAKS' = 'YAKS',
    /** Bitcoin Cash */
    'BCH' = 'BCH',
    /** Green Ben */
    'EBEN' = 'EBEN',
    /** Wrapped Bitcoin Cash */
    'WBCH' = 'WBCH',
    /** Bitcoin */
    'BTC' = 'BTC',
    /** Majo */
    'MAJO' = 'MAJO',
    /** BNB */
    'BNB' = 'BNB',
    /** FTX Token */
    'FTT' = 'FTT',
    /** Bitcoin BEP2 */
    'BTCB' = 'BTCB',
    /** WazirX */
    'WRX' = 'WRX',
    /** BUSD */
    'BUSD' = 'BUSD',
    /** Contentos */
    'COS' = 'COS',
    /** TROY */
    'TROY' = 'TROY',
    /** BIDR */
    'BIDR' = 'BIDR',
    /** ShareToken */
    'SHR' = 'SHR',
    /** Mithril */
    'MITH' = 'MITH',
    /** Atlas Protocol */
    'ATP' = 'ATP',
    /** GoWithMi */
    'GMAT' = 'GMAT',
    /** Ormeus Ecosystem */
    'ECO' = 'ECO',
    /** Aeron */
    'ARNX' = 'ARNX',
    /** Newton */
    'NEW' = 'NEW',
    /** Raven Protocol */
    'RAVEN' = 'RAVEN',
    /** Lition */
    'LIT' = 'LIT',
    /** CanYaCoin */
    'CAN' = 'CAN',
    /** CPChain */
    'CPC' = 'CPC',
    /** Aditus */
    'ADI' = 'ADI',
    /** Honest */
    'HNST' = 'HNST',
    /** Mcashchain */
    'MCASH' = 'MCASH',
    /** Fantom */
    'FTM' = 'FTM',
    /** Chiliz */
    'CHZ' = 'CHZ',
    /** TrueUSD */
    'TUSD' = 'TUSD',
    /** Nexo */
    'NEXO' = 'NEXO',
    /** Kava */
    'KAVA' = 'KAVA',
    /** Trust Wallet Token */
    'TWT' = 'TWT',
    /** COTI */
    'COTI' = 'COTI',
    /** Dusk */
    'DUSK' = 'DUSK',
    /** LTO Network */
    'LTO' = 'LTO',
    /** Viction */
    'VIC' = 'VIC',
    /** Aergo */
    'AERGO' = 'AERGO',
    /** Rupiah Token */
    'IDRT' = 'IDRT',
    /** Oxen */
    'OXEN' = 'OXEN',
    /** ChangeNOW Token */
    'NOW' = 'NOW',
    /** Cashaa */
    'CAS' = 'CAS',
    /** MultiVAC */
    'MTV' = 'MTV',
    /** Wagerr */
    'WGR' = 'WGR',
    /** TrueChain */
    'TRUE' = 'TRUE',
    /** SafePal */
    'SFP' = 'SFP',
    /** BinaryX */
    'BNX' = 'BNX',
    /** Cheelee */
    'CHEEL' = 'CHEEL',
    /** Venus BNB */
    'vBNB' = 'vBNB',
    /** Venus ETH */
    'vETH' = 'vETH',
    /** Ontology Gas */
    'ONG' = 'ONG',
    /** Open Campus */
    'EDU' = 'EDU',
    /** MetFi */
    'METFI' = 'METFI',
    /** H2O DAO */
    'H2O' = 'H2O',
    /** Venus */
    'XVS' = 'XVS',
    /** Metars Genesis */
    'MRS' = 'MRS',
    /** Hooked Protocol */
    'HOOK' = 'HOOK',
    /** Fusionist */
    'ACE' = 'ACE',
    /** Solidus Ai Tech */
    'AITECH' = 'AITECH',
    /** ChainGPT */
    'CGPT' = 'CGPT',
    /** L7 */
    'LSD' = 'LSD',
    /** NFPrompt */
    'NFP' = 'NFP',
    /** TokenFi */
    'TOKEN' = 'TOKEN',
    /** Velo */
    'VELO' = 'VELO',
    /** GameFi.org */
    'GAFI' = 'GAFI',
    /** MEMETOON */
    'MEME' = 'MEME',
    /** Toko Token */
    'TKO' = 'TKO',
    /** BakeryToken */
    'BAKE' = 'BAKE',
    /** StrikeX */
    'STRX' = 'STRX',
    /** Clash of Lilliput */
    'COL' = 'COL',
    /** Gaimin */
    'GMRX' = 'GMRX',
    /** Alitas */
    'ALT' = 'ALT',
    /** Games for a Living */
    'GFAL' = 'GFAL',
    /** Sologenic */
    'SOLO' = 'SOLO',
    /** RichQUACK.com */
    'QUACK' = 'QUACK',
    /** APX */
    'APX' = 'APX',
    /** Tenset */
    '10SET' = '10SET',
    /** Santos FC Fan Token */
    'SANTOS' = 'SANTOS',
    /** Metacraft */
    'MCT' = 'MCT',
    /** Biswap */
    'BSW' = 'BSW',
    /** Altura */
    'ALU' = 'ALU',
    /** iMe Lab */
    'LIME' = 'LIME',
    /** Openfabric AI */
    'OFN' = 'OFN',
    /** Decubate */
    'DCB' = 'DCB',
    /** Pitbull */
    'PIT' = 'PIT',
    /** Tranchess */
    'CHESS' = 'CHESS',
    /** Connex */
    'CONX' = 'CONX',
    /** Unifi Protocol DAO */
    'UNFI' = 'UNFI',
    /** Scallop */
    'SCLP' = 'SCLP',
    /** BurgerCities */
    'BURGER' = 'BURGER',
    /** Alpaca Finance */
    'ALPACA' = 'ALPACA',
    /** Creo Engine */
    'CREO' = 'CREO',
    /** Bloktopia */
    'BLOK' = 'BLOK',
    /** S.S. Lazio Fan Token */
    'LAZIO' = 'LAZIO',
    /** Poolz Finance */
    'POOLX' = 'POOLX',
    /** Alpine F1 Team Fan Token */
    'ALPINE' = 'ALPINE',
    /** Petals */
    'PTS' = 'PTS',
    /** Metahero */
    'HERO' = 'HERO',
    /** Catgirl */
    'CATGIRL' = 'CATGIRL',
    /** TokenPocket */
    'TPT' = 'TPT',
    /** YooShi */
    'YOOSHI' = 'YOOSHI',
    /** FC Porto Fan Token */
    'PORTO' = 'PORTO',
    /** Ellipsis */
    'EPS' = 'EPS',
    /** SquidGrow */
    'SQUIDGROW' = 'SQUIDGROW',
    /** MangoMan Intelligent */
    'MMIT' = 'MMIT',
    /** TABOO TOKEN */
    'TABOO' = 'TABOO',
    /** SpaceN */
    'SN' = 'SN',
    /** Defigram */
    'DFG' = 'DFG',
    /** Magic Square */
    'SQR' = 'SQR',
    /** Gifto */
    'GFT' = 'GFT',
    /** Splintershards */
    'SPS' = 'SPS',
    /** GT Protocol */
    'GTAI' = 'GTAI',
    /** UNS TOKEN */
    'UNS' = 'UNS',
    /** Diamond Launch */
    'DLC' = 'DLC',
    /** Wall Street Memes */
    'WSM' = 'WSM',
    /** Ellipsis */
    'EPX' = 'EPX',
    /** Landshare */
    'LAND' = 'LAND',
    /** ritestream */
    'RITE' = 'RITE',
    /** Genopets */
    'GENE' = 'GENE',
    /** Oxbull.tech */
    'OXB' = 'OXB',
    /** Baby Bonk */
    'BABYBONK' = 'BABYBONK',
    /** BSCPAD */
    'BSCPAD' = 'BSCPAD',
    /** MarsDAO */
    'MDAO' = 'MDAO',
    /** BRN Metaverse */
    'BRN' = 'BRN',
    /** PlayZap */
    'PZP' = 'PZP',
    /** CumRocket */
    'CUMMIES' = 'CUMMIES',
    /** Artyfact */
    'ARTY' = 'ARTY',
    /** WeWay */
    'WWY' = 'WWY',
    /** Pomerium */
    'PMG' = 'PMG',
    /** MagicCraft */
    'MCRT' = 'MCRT',
    /** Torum */
    'XTM' = 'XTM',
    /** Thetan World */
    'THG' = 'THG',
    /** Dechat */
    'DECHAT' = 'DECHAT',
    /** Everdome */
    'DOME' = 'DOME',
    /** Script Network */
    'SCPT' = 'SCPT',
    /** Pallapay */
    'PALLA' = 'PALLA',
    /** REV3AL */
    'REV3L' = 'REV3L',
    /** Blink Galaxy */
    'GQ' = 'GQ',
    /** Sakai Vault */
    'SAKAI' = 'SAKAI',
    /** Thena */
    'THE' = 'THE',
    /** UnityMeta */
    'UMT' = 'UMT',
    /** LitLab Games */
    'LITT' = 'LITT',
    /** QUINT */
    'QUINT' = 'QUINT',
    /** ACA Token */
    'ACA' = 'ACA',
    /** Blocto Token */
    'BLT' = 'BLT',
    /** DogeBonk */
    'DOBO' = 'DOBO',
    /** Gamestarter */
    'GAME' = 'GAME',
    /** BabySwap */
    'BABY' = 'BABY',
    /** Dark Frontiers */
    'DARK' = 'DARK',
    /** Vai */
    'VAI' = 'VAI',
    /** Mint Club */
    'MINT' = 'MINT',
    /** Sinverse */
    'SIN' = 'SIN',
    /** Hamster */
    'HAM' = 'HAM',
    /** Horizon Protocol */
    'HZN' = 'HZN',
    /** Bitcoin Standard Hashrate Token */
    'BTCST' = 'BTCST',
    /** Green Shiba Inu [New] */
    'GINUX' = 'GINUX',
    /** ETHAX */
    'ETHAX' = 'ETHAX',
    /** HeroesTD */
    'HTD' = 'HTD',
    /** Meta Apes */
    'PEEL' = 'PEEL',
    /** EGO */
    'EGO' = 'EGO',
    /** milestoneBased */
    'MILE' = 'MILE',
    /** WeSendit */
    'WSI' = 'WSI',
    /** LUCA */
    'LUCA' = 'LUCA',
    /** Operation Phoenix */
    '$OPHX' = '$OPHX',
    /** VinuChain */
    'VC' = 'VC',
    /** VIMworld */
    'VEED' = 'VEED',
    /** Revomon */
    'REVO' = 'REVO',
    /** myDID */
    'SYL' = 'SYL',
    /** Renewable Energy */
    'RET' = 'RET',
    /** Venus Reward Token */
    'VRT' = 'VRT',
    /** KickToken */
    'KICK' = 'KICK',
    /** Okratech Token */
    'ORT' = 'ORT',
    /** Fabwelt */
    'WELT' = 'WELT',
    /** PornRocket */
    'PORNROCKET' = 'PORNROCKET',
    /** Satoshi Island */
    'STC' = 'STC',
    /** VisionGame */
    'VISION' = 'VISION',
    /** Green Satoshi Token (BSC) */
    'GST' = 'GST',
    /** SHILL Token */
    'SHILL' = 'SHILL',
    /** TopGoal */
    'GOAL' = 'GOAL',
    /** Bitcoin Bam */
    'BTCBAM' = 'BTCBAM',
    /** BSCS */
    'BSCS' = 'BSCS',
    /** Ertha */
    'ERTHA' = 'ERTHA',
    /** BullPerks */
    'BLP' = 'BLP',
    /** NFTb */
    'NFTB' = 'NFTB',
    /** Sonar */
    'PING' = 'PING',
    /** Dreams Quest */
    'DREAMS' = 'DREAMS',
    /** RealFevr */
    'FEVR' = 'FEVR',
    /** ThreeFold */
    'TFT' = 'TFT',
    /** UNICE */
    'UNICE' = 'UNICE',
    /** Minu */
    'MINU' = 'MINU',
    /** My DeFi Pet */
    'DPET' = 'DPET',
    /** Monsta Infinite */
    'MONI' = 'MONI',
    /** JulSwap */
    'JULD' = 'JULD',
    /** Infinity PAD */
    'IPAD' = 'IPAD',
    /** Inflation Hedging Coin */
    'IHC' = 'IHC',
    /** Seascape Crowns */
    'CWS' = 'CWS',
    /** NuriTopia */
    'NBLU' = 'NBLU',
    /** Scotty Beam */
    'SCOTTY' = 'SCOTTY',
    /** Light */
    'LIGHT' = 'LIGHT',
    /** GoCrypto Token */
    'GOC' = 'GOC',
    /** Portuma */
    'POR' = 'POR',
    /** Kryptomon */
    'KMON' = 'KMON',
    /** HoDooi.com */
    'HOD' = 'HOD',
    /** DRIFE */
    'DRF' = 'DRF',
    /** MOVEZ */
    'MOVEZ' = 'MOVEZ',
    /** Spores Network */
    'SPO' = 'SPO',
    /** AstroSwap */
    'ASTRO' = 'ASTRO',
    /** TiFi Token */
    'TIFI' = 'TIFI',
    /** Laqira Protocol */
    'LQR' = 'LQR',
    /** Play Token */
    'PLAY' = 'PLAY',
    /** Baby Trump (BSC) */
    'BABYTRUMP' = 'BABYTRUMP',
    /** DOGGY */
    'DOGGY' = 'DOGGY',
    /** Monsterra (MSTR) */
    'MSTR' = 'MSTR',
    /** LABS Group */
    'LABS' = 'LABS',
    /** TRAVA.FINANCE */
    'TRAVA' = 'TRAVA',
    /** Eldarune */
    'ELDA' = 'ELDA',
    /** Revolve Games */
    'RPG' = 'RPG',
    /** SOUNI */
    'SON' = 'SON',
    /** NFT Art Finance */
    'NFTART' = 'NFTART',
    /** Auto */
    'AUTO' = 'AUTO',
    /** King Shiba */
    'KINGSHIB' = 'KINGSHIB',
    /** BHO Network */
    'BHO' = 'BHO',
    /** aiRight */
    'AIRI' = 'AIRI',
    /** ElvishMagic */
    'EMAGIC' = 'EMAGIC',
    /** IguVerse */
    'IGU' = 'IGU',
    /** City Tycoon Games */
    'CTG' = 'CTG',
    /** KALM */
    'KALM' = 'KALM',
    /** Position Exchange */
    'POSI' = 'POSI',
    /** ZAIBOT */
    'ZAI' = 'ZAI',
    /** Spore */
    'SPORE' = 'SPORE',
    /** Nemesis Downfall */
    'ND' = 'ND',
    /** MetaFighter */
    'MF' = 'MF',
    /** Streamity */
    'STM' = 'STM',
    /** IBStoken */
    'IBS' = 'IBS',
    /** The Dons */
    'DONS' = 'DONS',
    /** GamesPad */
    'GMPD' = 'GMPD',
    /** Belt Finance */
    'BELT' = 'BELT',
    /** AgeOfGods */
    'AOG' = 'AOG',
    /** PearDAO */
    'PEX' = 'PEX',
    /** Spartan Protocol */
    'SPARTA' = 'SPARTA',
    /** Metaverse Face */
    'MEFA' = 'MEFA',
    /** Qrkita Token */
    'QRT' = 'QRT',
    /** Elemon */
    'ELMON' = 'ELMON',
    /** SELF Crypto */
    'SELF' = 'SELF',
    /** Metaverser */
    'MTVT' = 'MTVT',
    /** Carbon */
    'CARBON' = 'CARBON',
    /** Plant Vs Undead */
    'PVU' = 'PVU',
    /** AiMalls */
    'AIT' = 'AIT',
    /** Nafter */
    'NAFT' = 'NAFT',
    /** CryptoZoon */
    'ZOON' = 'ZOON',
    /** Oggy Inu (BSC) */
    'OGGY' = 'OGGY',
    /** REGENT COIN */
    'REGENT' = 'REGENT',
    /** Wizardia */
    'WZRD' = 'WZRD',
    /** Tethereum */
    'T99' = 'T99',
    /** HyperChainX */
    'HYPER' = 'HYPER',
    /** STARSHIP */
    'STARSHIP' = 'STARSHIP',
    /** Agatech */
    'AGATA' = 'AGATA',
    /** CryptoBlades */
    'SKILL' = 'SKILL',
    /** FaraLand */
    'FARA' = 'FARA',
    /** Binamon */
    'BMON' = 'BMON',
    /** Cyclone Protocol */
    'CYC' = 'CYC',
    /** AcknoLedger */
    'ACK' = 'ACK',
    /** Chain of Legends */
    'CLEG' = 'CLEG',
    /** Azbit */
    'AZ' = 'AZ',
    /** MetaQ */
    'METAQ' = 'METAQ',
    /** XcelPay */
    'XLD' = 'XLD',
    /** Terran Coin */
    'TRR' = 'TRR',
    /** SONM (BEP-20) */
    'SNM' = 'SNM',
    /** Mist */
    'MIST' = 'MIST',
    /** Wonderman Nation */
    'WNDR' = 'WNDR',
    /** Kaby Arena */
    'KABY' = 'KABY',
    /** KCCPAD */
    'KCCPAD' = 'KCCPAD',
    /** Little Rabbit v2 */
    'LTRBT' = 'LTRBT',
    /** Binemon */
    'BIN' = 'BIN',
    /** XP NETWORK */
    'XPNET' = 'XPNET',
    /** Dexsport */
    'DESU' = 'DESU',
    /** Centric Swap */
    'CNS' = 'CNS',
    /** Lunar */
    'LNR' = 'LNR',
    /** Metagalaxy Land */
    'MEGALAND' = 'MEGALAND',
    /** yieldwatch */
    'WATCH' = 'WATCH',
    /** Silva Token */
    'SILVA' = 'SILVA',
    /** BLink */
    'BLINK' = 'BLINK',
    /** Pacoca */
    'PACOCA' = 'PACOCA',
    /** Ninneko */
    'NINO' = 'NINO',
    /** Moonft */
    'MTC' = 'MTC',
    /** CryptEx */
    'CRX' = 'CRX',
    /** My Master War */
    'MAT' = 'MAT',
    /** Propel */
    'PEL' = 'PEL',
    /** Linked Finance World */
    'LFW' = 'LFW',
    /** Amazy */
    'AZY' = 'AZY',
    /** KISSAN */
    'KSN' = 'KSN',
    /** CryptoTycoon */
    'CTT' = 'CTT',
    /** Helmet.insure */
    'HELMET' = 'HELMET',
    /** Lanceria */
    'LANC' = 'LANC',
    /** CryptoTanks */
    'TANK' = 'TANK',
    /** Step® */
    'STEP' = 'STEP',
    /** KingdomStarter */
    'KDG' = 'KDG',
    /** Refinable */
    'FINE' = 'FINE',
    /** CZ THE GOAT */
    'CZGOAT' = 'CZGOAT',
    /** PolkaWar */
    'PWAR' = 'PWAR',
    /** Defina Finance */
    'FINA' = 'FINA',
    /** ASPO World */
    'ASPO' = 'ASPO',
    /** Rainbow Token */
    'RAINBOW' = 'RAINBOW',
    /** HashBX */
    'HBX' = 'HBX',
    /** Neurashi */
    'NEI' = 'NEI',
    /** MetaShooter */
    'MHUNT' = 'MHUNT',
    /** Dynamix */
    'DYNA' = 'DYNA',
    /** ShredN */
    'SHRED' = 'SHRED',
    /** Nerve Finance */
    'NRV' = 'NRV',
    /** MEME MINT */
    'MEMEMINT' = 'MEMEMINT',
    /** Dragon Kart */
    'KART' = 'KART',
    /** Battle Hero */
    'BATH' = 'BATH',
    /** Wolf Safe Poor People */
    'WSPP' = 'WSPP',
    /** Metaverse VR v2 */
    'MEVRV2' = 'MEVRV2',
    /** Hero Blaze: Three Kingdoms */
    'MUDOL2' = 'MUDOL2',
    /** Mytheria */
    'MYRA' = 'MYRA',
    /** Hunter Token / Digital Arms */
    'HNTR' = 'HNTR',
    /** Cryptopolis */
    'CPO' = 'CPO',
    /** Infinity Games */
    'ING' = 'ING',
    /** SeedOn */
    'SEON' = 'SEON',
    /** Argon */
    'ARGON' = 'ARGON',
    /** COGI */
    'COGI' = 'COGI',
    /** Realvirm */
    'RVM' = 'RVM',
    /** Gunstar Metaverse */
    'GSTS' = 'GSTS',
    /** DONASWAP */
    'DONA' = 'DONA',
    /** TripCandy */
    'CANDY' = 'CANDY',
    /** WOWswap */
    'WOW' = 'WOW',
    /** Elpis Battle */
    'EBA' = 'EBA',
    /** Zuki Moba */
    'ZUKI' = 'ZUKI',
    /** ShopNEXT */
    'NEXT' = 'NEXT',
    /** Shield Protocol */
    'SHIELD' = 'SHIELD',
    /** Sable Finance */
    'SABLE' = 'SABLE',
    /** Bunicorn */
    'BUNI' = 'BUNI',
    /** The Corgi of PolkaBridge */
    'CORGIB' = 'CORGIB',
    /** Rune */
    'RUNE' = 'RUNE',
    /** Jade Currency */
    'JADE' = 'JADE',
    /** AirNFTs */
    'AIRT' = 'AIRT',
    /** Pancake Bunny */
    'BUNNY' = 'BUNNY',
    /** MoneySwap */
    'MSWAP' = 'MSWAP',
    /** BeNFT Solutions */
    'BeAI' = 'BeAI',
    /** Berry Data */
    'BRY' = 'BRY',
    /** SugarBounce */
    'TIP' = 'TIP',
    /** JOOPS */
    'JOOPS' = 'JOOPS',
    /** TheForce Trade */
    'FOC' = 'FOC',
    /** OpenSwap */
    'OSWAP' = 'OSWAP',
    /** Bitteam token */
    'BTT' = 'BTT',
    /** MetaverseX */
    'METAX' = 'METAX',
    /** Shackleford */
    'SHACK' = 'SHACK',
    /** MMOCoin */
    'MMO' = 'MMO',
    /** Citadel.one */
    'XCT' = 'XCT',
    /** Billion Happiness */
    'BHC' = 'BHC',
    /** Beer Money */
    'BEER' = 'BEER',
    /** Kawaii Islands */
    'KWT' = 'KWT',
    /** SparkPoint Fuel */
    'SFUEL' = 'SFUEL',
    /** ClassicDoge */
    'XDOGE' = 'XDOGE',
    /** OptionRoom */
    'ROOM' = 'ROOM',
    /** AFEN Blockchain Network */
    'AFEN' = 'AFEN',
    /** Goose Finance */
    'EGG' = 'EGG',
    /** Smoothy */
    'SMTY' = 'SMTY',
    /** Era Token (Era7) */
    'ERA' = 'ERA',
    /** VEKTOR */
    'VEKTOR' = 'VEKTOR',
    /** BEMIL Coin */
    'BEM' = 'BEM',
    /** BetU */
    'BETU' = 'BETU',
    /** KIWIGO */
    'KGO' = 'KGO',
    /** Twinci */
    'TWIN' = 'TWIN',
    /** Ancient Kingdom */
    'DOM' = 'DOM',
    /** ByteNext */
    'BNU' = 'BNU',
    /** Hertz Network */
    'HTZ' = 'HTZ',
    /** Lucretius */
    'LUC' = 'LUC',
    /** Warena */
    'RENA' = 'RENA',
    /** beFITTER */
    'FIU' = 'FIU',
    /** SPRINT */
    'SWP' = 'SWP',
    /** Moniwar */
    'MOWA' = 'MOWA',
    /** Luna Rush */
    'LUS' = 'LUS',
    /** Pixer Eternity */
    'PXT' = 'PXT',
    /** PrivaCoin */
    'PRVC' = 'PRVC',
    /** Lympo Market Token */
    'LMT' = 'LMT',
    /** Avatly */
    'AVA' = 'AVA',
    /** Knight War - The Holy Trio */
    'KWS' = 'KWS',
    /** Paybswap */
    'PAYB' = 'PAYB',
    /** Swirge */
    'SWG' = 'SWG',
    /** BSClaunch */
    'BSL' = 'BSL',
    /** ETNA Network */
    'ETNA' = 'ETNA',
    /** Mate */
    'MATE' = 'MATE',
    /** FOTA - Fight Of The Ages */
    'FOTA' = 'FOTA',
    /** Cubiex Power */
    'CBIX-P' = 'CBIX-P',
    /** PIAS */
    'PIAS' = 'PIAS',
    /** CryptoPlanes */
    'CPAN' = 'CPAN',
    /** YFFII Finance */
    'YFFII' = 'YFFII',
    /** PepePAD */
    'PEPE' = 'PEPE',
    /** Serenity Shield */
    'SERSH' = 'SERSH',
    /** HyperGPT */
    'HGPT' = 'HGPT',
    /** Crypto Hunters Coin */
    'CRH' = 'CRH',
    /** Lista Staked BNB */
    'slisBNB' = 'slisBNB',
    /** 2024PUMP */
    'PUMP' = 'PUMP',
    /** BlackCardCoin */
    'BCCOIN' = 'BCCOIN',
    /** Biceps */
    'BICS' = 'BICS',
    /** INTOverse */
    'TOX' = 'TOX',
    /** Sizlux */
    'SIZ' = 'SIZ',
    /** Beacon ETH */
    'BETH' = 'BETH',
    /** SuperCells Token */
    'SCT' = 'SCT',
    /** OrangeDX */
    'O4DX' = 'O4DX',
    /** King of Legends */
    'KOL' = 'KOL',
    /** Grok Cat */
    'GROKCAT' = 'GROKCAT',
    /** Octavia AI */
    'VIA' = 'VIA',
    /** HeFi */
    'HEFI' = 'HEFI',
    /** Biconomy Exchange Token */
    'BIT' = 'BIT',
    /** Chappyz */
    'CHAPZ' = 'CHAPZ',
    /** SLEX Token */
    'SLEX' = 'SLEX',
    /** BSCM */
    'BSCM' = 'BSCM',
    /** Friend3 */
    'F3' = 'F3',
    /** Ark Rivals */
    'ARKN' = 'ARKN',
    /** Baby Grok */
    'BABYGROK' = 'BABYGROK',
    /** Pundi X PURSE */
    'PURSE' = 'PURSE',
    /** GOMDori */
    'GOMD' = 'GOMD',
    /** lisUSD */
    'lisUSD' = 'lisUSD',
    /** Diment Dollar */
    'DD' = 'DD',
    /** EGGY */
    'EGGY' = 'EGGY',
    /** Axioma Token */
    'AXT' = 'AXT',
    /** Libra Incentix */
    'LIXX' = 'LIXX',
    /** BULL BTC CLUB */
    'BBC' = 'BBC',
    /** CashBackPro */
    'CBP' = 'CBP',
    /** IL CAPO OF CRYPTO */
    'CAPO' = 'CAPO',
    /** DexNet */
    'DEXNET' = 'DEXNET',
    /** Ctomorrow Platform */
    'CTP' = 'CTP',
    /** Trillioner */
    'TLC' = 'TLC',
    /** Milo Inu */
    'MILO' = 'MILO',
    /** Infinity Rocket Token */
    'IRT' = 'IRT',
    /** AASToken */
    'AAST' = 'AAST',
    /** Starly */
    '$STARLY' = '$STARLY',
    /** RGAMES */
    'RGAME' = 'RGAME',
    /** Vyvo Coin */
    'VSC' = 'VSC',
    /** Bombcrypto */
    'BCOIN' = 'BCOIN',
    /** Burnedfi */
    'BURN' = 'BURN',
    /** WebMind Network */
    'WMN' = 'WMN',
    /** Heaven Token */
    'HEAVEN' = 'HEAVEN',
    /** Mar3 AI */
    'MAR3' = 'MAR3',
    /** Ailey */
    'ALE' = 'ALE',
    /** DegenDuckRace */
    '$QUACK' = '$QUACK',
    /** Ozone Metaverse */
    'OZONE' = 'OZONE',
    /** AGII */
    'AGII' = 'AGII',
    /** DarkShield Games Studio */
    'DKS' = 'DKS',
    /** Edu3Labs */
    'NFE' = 'NFE',
    /** GAMI World */
    'GAMI' = 'GAMI',
    /** Ankr Staked BNB */
    'ankrBNB' = 'ankrBNB',
    /** FLOKITA */
    'FLOKITA' = 'FLOKITA',
    /** Payslink */
    'PAYS' = 'PAYS',
    /** Kyberdyne */
    'KBD' = 'KBD',
    /** Sonorus */
    'SNS' = 'SNS',
    /** ivendPay */
    'IVPAY' = 'IVPAY',
    /** marumaruNFT */
    'MARU' = 'MARU',
    /** KicksPad */
    'KICKS' = 'KICKS',
    /** Platform of meme coins */
    'PAYU' = 'PAYU',
    /** Elephant Money TRUNK */
    'TRUNK' = 'TRUNK',
    /** Cakepie */
    'CKP' = 'CKP',
    /** Disney */
    'DIS' = 'DIS',
    /** Gym Network */
    'GYMNET' = 'GYMNET',
    /** Arker */
    'ARKER' = 'ARKER',
    /** Forward Protocol */
    'FORWARD' = 'FORWARD',
    /** Fandomdao */
    'FAND' = 'FAND',
    /** LumiShare */
    'LUMI' = 'LUMI',
    /** HALO NFT OFFICIAL */
    'HALO' = 'HALO',
    /** Bowled.io */
    'BWLD' = 'BWLD',
    /** Baby Elon */
    'BABYELON' = 'BABYELON',
    /** VaporFund */
    'VPR' = 'VPR',
    /** Botopia.Finance */
    'BTOP' = 'BTOP',
    /** Tether USD Bridged ZED20 (USDT.z) */
    'USDT.Z' = 'USDT.Z',
    /** Fame MMA */
    'FAME' = 'FAME',
    /** Squid Game */
    'SQUID' = 'SQUID',
    /** Pop Social */
    'PPT' = 'PPT',
    /** DOGEMOB */
    'DOGEMOB' = 'DOGEMOB',
    /** Blockasset */
    'BLOCK' = 'BLOCK',
    /** Shoki */
    'SHOKI' = 'SHOKI',
    /** Ordify */
    'ORFY' = 'ORFY',
    /** Grow Token */
    'GROW' = 'GROW',
    /** Black Whale */
    'xXx' = 'xXx',
    /** APEcoin.dev */
    'APE' = 'APE',
    /** Xpense */
    'XPE' = 'XPE',
    /** ULTRON */
    'ULX' = 'ULX',
    /** Devomon */
    'EVO' = 'EVO',
    /** Meta Merge */
    'MMM' = 'MMM',
    /** The Debt Box */
    'DEBT' = 'DEBT',
    /** Hello Puppy */
    'BOB' = 'BOB',
    /** Good Games Guild */
    'GGG' = 'GGG',
    /** SHIBAAI */
    'SHIBAAI' = 'SHIBAAI',
    /** Planet */
    'GAMMA' = 'GAMMA',
    /** FUNCH */
    'FUNCH' = 'FUNCH',
    /** AET */
    'AET' = 'AET',
    /** SAFE(AnWang) */
    'SAFE' = 'SAFE',
    /** Nelore Coin */
    'NLC' = 'NLC',
    /** Smooth Network Solutions Token */
    'SNST' = 'SNST',
    /** Digiverse */
    'DIGI' = 'DIGI',
    /** Ecoin Finance */
    'ECOIN' = 'ECOIN',
    /** RoboFi */
    'VICS' = 'VICS',
    /** Sweeptoken */
    'SWEEP' = 'SWEEP',
    /** Absolute Sync */
    'AST' = 'AST',
    /** Centcex */
    'CENX' = 'CENX',
    /** Gulf Coin */
    'GULF' = 'GULF',
    /** Poollotto.finance */
    'PLT' = 'PLT',
    /** Joltify */
    'JOLT' = 'JOLT',
    /** BITCOIN FUTURE */
    'BTCF' = 'BTCF',
    /** TAP FANTASY */
    'TAP' = 'TAP',
    /** Gode Chain */
    'GODE' = 'GODE',
    /** MRHB DeFi Network */
    'MRHB' = 'MRHB',
    /** Thunder Brawl */
    'THB' = 'THB',
    /** JEN COIN */
    'JEN' = 'JEN',
    /** ELON’S CAT */
    'CATME' = 'CATME',
    /** PRIVATEUM GLOBAL */
    'PRI' = 'PRI',
    /** Love Earn Enjoy */
    'LEE' = 'LEE',
    /** Redluna */
    'REDLUNA' = 'REDLUNA',
    /** KittyCake */
    'KCAKE' = 'KCAKE',
    /** WAM */
    'WAM' = 'WAM',
    /** VLaunch */
    'VPAD' = 'VPAD',
    /** Stader BNBx */
    'BNBX' = 'BNBX',
    /** Plxyer */
    'PLXY' = 'PLXY',
    /** Keeps Coin */
    'KVERSE' = 'KVERSE',
    /** MikeToken */
    'MKT' = 'MKT',
    /** TendaCoin */
    'TND' = 'TND',
    /** TARALITY */
    'TARAL' = 'TARAL',
    /** Frutti Dino */
    'FDT' = 'FDT',
    /** MetaX */
    'ATEM' = 'ATEM',
    /** MEME AI */
    'MEMEAI' = 'MEMEAI',
    /** Grok */
    'GROK' = 'GROK',
    /** Chumbi Valley */
    'CHMB' = 'CHMB',
    /** All Crypto Mechanics */
    'ACM' = 'ACM',
    /** FRGX Finance */
    'FRGX' = 'FRGX',
    /** TypeIt */
    'TYPE' = 'TYPE',
    /** BabyRWA */
    'BABYRWA' = 'BABYRWA',
    /** Capitalrock */
    'CR' = 'CR',
    /** BIBI */
    'BIBI' = 'BIBI',
    /** Euro Shiba Inu */
    'EShib' = 'EShib',
    /** Magic Crystal */
    'MC' = 'MC',
    /** Coloniume Network */
    'CLNX' = 'CLNX',
    /** MetaMecha */
    'MM' = 'MM',
    /** Wizarre Scroll */
    'SCRL' = 'SCRL',
    /** Virtual Tourist */
    'VT' = 'VT',
    /** Rabi */
    'RABI' = 'RABI',
    /** Baby Doge CEO */
    'BABYCEO' = 'BABYCEO',
    /** Virtual X */
    'VRL' = 'VRL',
    /** WECOIN */
    'WECO' = 'WECO',
    /** Storepay */
    'SPC' = 'SPC',
    /** CeBioLabs */
    'CBSL' = 'CBSL',
    /** PAC Project */
    'PAC' = 'PAC',
    /** Finceptor */
    'FINC' = 'FINC',
    /** Windfall Token */
    'WFT' = 'WFT',
    /** Metafluence */
    'METO' = 'METO',
    /** MuratiAI */
    'MURATIAI' = 'MURATIAI',
    /** Thing */
    'THING' = 'THING',
    /** BNB DOG INU */
    'BNBDOG' = 'BNBDOG',
    /** BilliCat */
    'BCAT' = 'BCAT',
    /** KingdomX */
    'KT' = 'KT',
    /** OwlDAO */
    'OWL' = 'OWL',
    /** DaoVerse */
    'DVRS' = 'DVRS',
    /** Meta Games Coin */
    'MGC' = 'MGC',
    /** KARASOU */
    'INTELLIQUE' = 'INTELLIQUE',
    /** Inery */
    '$INR' = '$INR',
    /** Sportium */
    'SPRT' = 'SPRT',
    /** Let's Go Brandon Token */
    'LGBT' = 'LGBT',
    /** CEO */
    'CEO' = 'CEO',
    /** D3D Social */
    'D3D' = 'D3D',
    /** bitFloki */
    'BFLOKI' = 'BFLOKI',
    /** Evulus Token */
    'EVU' = 'EVU',
    /** GAMER */
    'GMR' = 'GMR',
    /** Lendr Network */
    'LNDRR' = 'LNDRR',
    /** FERMA SOSEDI */
    'FERMA' = 'FERMA',
    /** Ginoa */
    'GINOA' = 'GINOA',
    /** Global Virtual Coin */
    'GVC' = 'GVC',
    /** Utility Nexusmind */
    'UNMD' = 'UNMD',
    /** Felix */
    'FLX' = 'FLX',
    /** Optimus AI */
    'OPTIMUS AI' = 'OPTIMUS AI',
    /** Infiblue World */
    'MONIE' = 'MONIE',
    /** GrapeCoin */
    'GRAPE' = 'GRAPE',
    /** Spix */
    'SPX' = 'SPX',
    /** MTH Network */
    'MTHN' = 'MTHN',
    /** FitBurn */
    'CAL' = 'CAL',
    /** Janex */
    'JNX' = 'JNX',
    /** Fishkoin */
    'KOIN' = 'KOIN',
    /** Legion Network */
    'LGX' = 'LGX',
    /** CODAI */
    'CODAI' = 'CODAI',
    /** Youclout */
    'YCT' = 'YCT',
    /** Kryptonite */
    'SEILOR' = 'SEILOR',
    /** Soroosh Smart Ecosystem */
    'SSE' = 'SSE',
    /** CodeXchain */
    'CDX' = 'CDX',
    /** Planq */
    'PLQ' = 'PLQ',
    /** Satoshi Panda */
    'SAP' = 'SAP',
    /** Cavada */
    'CAVADA' = 'CAVADA',
    /** Trading GPT */
    'TGPT' = 'TGPT',
    /** MMPRO Token */
    'MMPRO' = 'MMPRO',
    /** Custodiy */
    'CTY' = 'CTY',
    /** ONINO */
    'ONI' = 'ONI',
    /** Fantaverse */
    'UT' = 'UT',
    /** Minto */
    'BTCMT' = 'BTCMT',
    /** GLI TOKEN */
    'GLI' = 'GLI',
    /** ALITA */
    'ALITA' = 'ALITA',
    /** READ2N */
    'RCM' = 'RCM',
    /** Play Kingdom */
    'PKT' = 'PKT',
    /** InpulseX */
    'IPX' = 'IPX',
    /** YAY Network */
    'YAY' = 'YAY',
    /** Miner Arena */
    'MINAR' = 'MINAR',
    /** MANE */
    'MANE' = 'MANE',
    /** FaceDAO */
    'FACEDAO' = 'FACEDAO',
    /** Canadian Inuit Dog [New] */
    'CADINU' = 'CADINU',
    /** Dollarmoon */
    'DMOON' = 'DMOON',
    /** Hebeto */
    'HBT' = 'HBT',
    /** BitBook */
    'BBT' = 'BBT',
    /** STEMX */
    'STEMX' = 'STEMX',
    /** MEFLEX */
    'MEF' = 'MEF',
    /** Winners Coin */
    'TW' = 'TW',
    /** Pig Finance */
    'PIG' = 'PIG',
    /** Planet Sandbox */
    'PSB' = 'PSB',
    /** FxBox */
    'FXB' = 'FXB',
    /** Ents */
    'ENTS' = 'ENTS',
    /** Alium Finance */
    'ALM' = 'ALM',
    /** Dega */
    'DEGA' = 'DEGA',
    /** EXIT Designer Token */
    'EXIT' = 'EXIT',
    /** Acet */
    'ACT' = 'ACT',
    /** CHICA */
    'CHICA' = 'CHICA',
    /** Animal Farm Dogs */
    'AFD' = 'AFD',
    /** Black Token */
    'BLACK' = 'BLACK',
    /** DOGO */
    'DOGO' = 'DOGO',
    /** BLUEART TOKEN */
    'BLA' = 'BLA',
    /** SEOR Network */
    'SEOR' = 'SEOR',
    /** Football At AlphaVerse */
    'FAV' = 'FAV',
    /** Marvelous NFTs (Bad Days) */
    'MNFT' = 'MNFT',
    /** Holdex Finance */
    'HOLDEX' = 'HOLDEX',
    /** Cyber Arena */
    'CAT' = 'CAT',
    /** KStarNFT */
    'KNFT' = 'KNFT',
    /** The Moon Metaverse */
    '2MOON' = '2MOON',
    /** Protocon */
    'PEN' = 'PEN',
    /** Sociapol */
    'SPOL' = 'SPOL',
    /** Metastrike */
    'MTS' = 'MTS',
    /** PRIMAL */
    'PRIMAL' = 'PRIMAL',
    /** Byepix */
    'EPIX' = 'EPIX',
    /** CelebrityCoinV2 */
    'CCV2' = 'CCV2',
    /** Humanize */
    '$HMT' = '$HMT',
    /** BattleVerse */
    'BVC' = 'BVC',
    /** Game Coin */
    'GMEX' = 'GMEX',
    /** LOFI */
    'LOFI' = 'LOFI',
    /** Going To The Moon */
    'GTTM' = 'GTTM',
    /** CoinNavigator */
    'CNG' = 'CNG',
    /** Level Finance */
    'LVL' = 'LVL',
    /** Palmswap */
    'PALM' = 'PALM',
    /** ScarQuest */
    'SCAR' = 'SCAR',
    /** Perezoso */
    'PRZS' = 'PRZS',
    /** ScapesMania */
    'MANIA' = 'MANIA',
    /** ITEMVERSE */
    'ITEM' = 'ITEM',
    /** C+Charge */
    'CCHG' = 'CCHG',
    /** Race Kingdom */
    'ATOZ' = 'ATOZ',
    /** KWAI */
    'KWAI' = 'KWAI',
    /** Hype Meme Token */
    'HMTT' = 'HMTT',
    /** YouCoin Metaverse (new) */
    'UCON' = 'UCON',
    /** Nexusmind */
    'NMD' = 'NMD',
    /** Tradetomato */
    'TTM' = 'TTM',
    /** Oceanland */
    'OLAND' = 'OLAND',
    /** UvToken */
    'UVT' = 'UVT',
    /** KENKA METAVERSE */
    'KENKA' = 'KENKA',
    /** IGUP (IguVerse) */
    'IGUP' = 'IGUP',
    /** Mind Matrix */
    'AIMX' = 'AIMX',
    /** WalletNow */
    'WNOW' = 'WNOW',
    /** Baby Pepe */
    'BABYPEPE' = 'BABYPEPE',
    /** Web3Shot */
    'W3S' = 'W3S',
    /** HowInu */
    'HOW' = 'HOW',
    /** Etherconnect */
    'ECC' = 'ECC',
    /** DeFi Warrior (FIWA) */
    'FIWA' = 'FIWA',
    /** Boss Token */
    'BOSS' = 'BOSS',
    /** Bitlocus */
    'BTL' = 'BTL',
    /** Fistbump */
    'FIST' = 'FIST',
    /** Agro Global */
    'AGRO' = 'AGRO',
    /** Dramatic Chipmunk */
    'MUNK' = 'MUNK',
    /** Buff Doge Coin */
    'DOGECOIN' = 'DOGECOIN',
    /** Vira-lata Finance */
    'REAU' = 'REAU',
    /** Burnsdefi */
    'BURNS' = 'BURNS',
    /** BitShiba */
    'SHIBA' = 'SHIBA',
    /** Smart Wallet Token */
    'SWT' = 'SWT',
    /** MoonStarter */
    'MNST' = 'MNST',
    /** Wiki Cat */
    'WKC' = 'WKC',
    /** Mecha Morphing */
    'MAPE' = 'MAPE',
    /** Christmas Pump */
    'CHRISPUMP' = 'CHRISPUMP',
    /** EverGrow */
    'EGC' = 'EGC',
    /** Smart Block Chain City */
    'SBCC' = 'SBCC',
    /** BillionAir */
    'AIRB' = 'AIRB',
    /** MCNCOIN */
    'MCN' = 'MCN',
    /** BOBO */
    'BOBO' = 'BOBO',
    /** DfiStarter */
    'DFI' = 'DFI',
    /** SavePlanetEarth */
    'SPE' = 'SPE',
    /** Limoverse */
    'LIMO' = 'LIMO',
    /** GAIA Everworld */
    'GAIA' = 'GAIA',
    /** A.I Genesis */
    'AIG' = 'AIG',
    /** Minebase */
    'MBASE' = 'MBASE',
    /** Stripto */
    'STRIP' = 'STRIP',
    /** Heroes & Empires */
    'HE' = 'HE',
    /** FLIGHTCLUPCOIN */
    'FLIGHT' = 'FLIGHT',
    /** Coinzix */
    'ZIX' = 'ZIX',
    /** Intelly */
    'INTL' = 'INTL',
    /** Shockwaves */
    'NEUROS' = 'NEUROS',
    /** LimoCoin Swap */
    'LMCSWAP' = 'LMCSWAP',
    /** The X Protocol */
    'TXP' = 'TXP',
    /** Matthew Box 404 */
    'MB4' = 'MB4',
    /** SQUIBONK */
    'SQUIBONK' = 'SQUIBONK',
    /** Utility Cjournal */
    'UCJL' = 'UCJL',
    /** Gay Pepe */
    'GAYPEPE' = 'GAYPEPE',
    /** CVIP */
    'CVIP' = 'CVIP',
    /** Ivy Live */
    'IVY' = 'IVY',
    /** KickPad */
    'KPAD' = 'KPAD',
    /** FLOKI CEO */
    'FLOKICEO' = 'FLOKICEO',
    /** REBorn(New) */
    'RB' = 'RB',
    /** ATM */
    'ATM' = 'ATM',
    /** Web3War */
    'FPS' = 'FPS',
    /** CC */
    'CC' = 'CC',
    /** Space Token */
    'SPACE' = 'SPACE',
    /** KAIF Platform */
    'KAF' = 'KAF',
    /** BFG Token */
    'BFG' = 'BFG',
    /** FalconsInu */
    'FALCONS' = 'FALCONS',
    /** HODL */
    'HODL' = 'HODL',
    /** POKOMON */
    'POKO' = 'POKO',
    /** EG Token */
    'EG' = 'EG',
    /** Mobipad */
    'MBP' = 'MBP',
    /** YFIONE */
    'YFO' = 'YFO',
    /** MASHIDA */
    'MSHD' = 'MSHD',
    /** Shelling */
    'SHL' = 'SHL',
    /** DeHealth */
    'DHLT' = 'DHLT',
    /** A.I.Earn */
    'AIE' = 'AIE',
    /** SpaceXCoin */
    'SPXC' = 'SPXC',
    /** BNB48 Club Token */
    'KOGE' = 'KOGE',
    /** Googly Cat */
    'GOOGLY' = 'GOOGLY',
    /** Daily Finance */
    'DLY' = 'DLY',
    /** Grizzly Honey */
    'GHNY' = 'GHNY',
    /** T23 */
    'T23' = 'T23',
    /** Voice Street */
    'VST' = 'VST',
    /** Broovs Projects */
    'BRS' = 'BRS',
    /** Sugar Kingdom Odyssey */
    'SKO' = 'SKO',
    /** FART COIN */
    'FRTC' = 'FRTC',
    /** BeFaster Holder Token */
    'BFHT' = 'BFHT',
    /** Mad Viking Games */
    'MVG' = 'MVG',
    /** Babydoge 2.0 */
    'BABYDOGE2.0' = 'BABYDOGE2.0',
    /** TrustFi Network */
    'TFI' = 'TFI',
    /** GROK CEO */
    'GROKCEO' = 'GROKCEO',
    /** KAKA NFT World */
    'KAKA' = 'KAKA',
    /** Nitroken */
    'NITO' = 'NITO',
    /** Gaming Stars */
    'GAMES' = 'GAMES',
    /** NFTBomb */
    'NBP' = 'NBP',
    /** Wrapped Zedxion */
    'WZEDX' = 'WZEDX',
    /** SpaceY */
    'SPAY' = 'SPAY',
    /** TRUST AI */
    'TRT' = 'TRT',
    /** Price Gap */
    'PGT' = 'PGT',
    /** Pi INU */
    'PINU' = 'PINU',
    /** HUGHUG Coin */
    'HGHG' = 'HGHG',
    /** Elephant Money */
    'ELEPHANT' = 'ELEPHANT',
    /** Titan Hunters */
    'TITA' = 'TITA',
    /** Seek Tiger */
    'STI' = 'STI',
    /** DOGITA */
    'DOGA' = 'DOGA',
    /** BSC TOOLS */
    'TOOLS' = 'TOOLS',
    /** MetaWear */
    'WEAR' = 'WEAR',
    /** Arcas */
    'ARCAS' = 'ARCAS',
    /** Trustpad (New) */
    'TPAD' = 'TPAD',
    /** Fanadise */
    'FAN' = 'FAN',
    /** Spintop */
    'SPIN' = 'SPIN',
    /** MacaronSwap */
    'MCRN' = 'MCRN',
    /** Seedify NFT Space */
    'SNFTS' = 'SNFTS',
    /** Edufex */
    'EDUX' = 'EDUX',
    /** Hummingbird Finance (New) */
    'HMNG' = 'HMNG',
    /** Babylons */
    'BABI' = 'BABI',
    /** BNBTiger */
    'BNBTIGER' = 'BNBTIGER',
    /** OciCat */
    'OCICAT' = 'OCICAT',
    /** Hollywood Capital Group WARRIOR */
    'WOR' = 'WOR',
    /** BunnyPark */
    'BP' = 'BP',
    /** Menzy */
    'MNZ' = 'MNZ',
    /** Australian Safe Shepherd */
    'ASS' = 'ASS',
    /** Trivians */
    'TRIVIA' = 'TRIVIA',
    /** CatElonMars */
    'CATELON' = 'CATELON',
    /** Zodium */
    'ZODI' = 'ZODI',
    /** SUPE */
    'SUPE' = 'SUPE',
    /** Alaska Gold Rush */
    'CARAT' = 'CARAT',
    /** Layerium */
    'LYUM' = 'LYUM',
    /** CyberDragon Gold */
    'GOLD' = 'GOLD',
    /** NINE DOGS */
    '9DOGS' = '9DOGS',
    /** Shibaqua */
    'SHIB' = 'SHIB',
    /** Crypto-AI-Robo.com */
    'CAIR' = 'CAIR',
    /** Aussie Digital */
    'AUD' = 'AUD',
    /** FX Stock Token */
    'FXST' = 'FXST',
    /** Great Bounty Dealer */
    'GBD' = 'GBD',
    /** Tip Inu */
    'TIPINU' = 'TIPINU',
    /** DNAxCAT Token */
    'DXCT' = 'DXCT',
    /** Reign of Terror */
    'REIGN' = 'REIGN',
    /** Vietnam Smarthub Logistics */
    'VSL' = 'VSL',
    /** Thoreum V3 */
    'THOREUM' = 'THOREUM',
    /** Safemars */
    'SAFEMARS' = 'SAFEMARS',
    /** Local Traders */
    'LCT' = 'LCT',
    /** PlayPad */
    'PPAD' = 'PPAD',
    /** BOUNTYKINDS */
    'YU' = 'YU',
    /** Cherry Network */
    'CHER' = 'CHER',
    /** cyberdoge */
    'CDOGE' = 'CDOGE',
    /** League of Ancients */
    'LOA' = 'LOA',
    /** MonbaseCoin */
    'MBC' = 'MBC',
    /** The Killbox */
    'KBOX' = 'KBOX',
    /** DAOLaunch */
    'DAL' = 'DAL',
    /** Nomad Exiles */
    'PRIDE' = 'PRIDE',
    /** SPECIEX */
    'SPEX' = 'SPEX',
    /** Orenium Protocol */
    'ORE' = 'ORE',
    /** PokerFi */
    'POKERFI' = 'POKERFI',
    /** Hero Arena */
    'HERA' = 'HERA',
    /** PolyPad */
    'POLYPAD' = 'POLYPAD',
    /** NFTCloud */
    'CLOUD' = 'CLOUD',
    /** Hydraverse */
    'HDV' = 'HDV',
    /** Baby Dragon */
    'BABYDRAGON' = 'BABYDRAGON',
    /** OptionPanda */
    'OPA' = 'OPA',
    /** Chonk The Cat */
    'CHONK' = 'CHONK',
    /** Starpad */
    'SRP' = 'SRP',
    /** ASYAGRO */
    'ASY' = 'ASY',
    /** MELI */
    'MELI' = 'MELI',
    /** WaultSwap */
    'WEX' = 'WEX',
    /** Hashtagger.com */
    'MOOO' = 'MOOO',
    /** Green Beli */
    'GRBE' = 'GRBE',
    /** Cloudname */
    'CNAME' = 'CNAME',
    /** Teddy Doge */
    'Teddy V2' = 'Teddy V2',
    /** Octopus Protocol */
    'OPS' = 'OPS',
    /** LUNCARMY */
    'LUNCARMY' = 'LUNCARMY',
    /** Bob LION Inu */
    'BNBLION' = 'BNBLION',
    /** PlaceWar */
    'PLACE' = 'PLACE',
    /** The Big Five Token */
    'BFT' = 'BFT',
    /** GoldMiner */
    'GM' = 'GM',
    /** MIR Token */
    'MIR' = 'MIR',
    /** BABYLONG */
    'BABYLONG' = 'BABYLONG',
    /** Operon Origins */
    'ORO' = 'ORO',
    /** Biblecoin */
    'BIBL' = 'BIBL',
    /** iinjaz (new) */
    'IJZ' = 'IJZ',
    /** Baby Floki (BSC) */
    'BABYFLOKI' = 'BABYFLOKI',
    /** CryptoMines */
    'ETERNAL' = 'ETERNAL',
    /** Irena Coin Apps */
    'IRENA' = 'IRENA',
    /** Dragon Mainland Shards */
    'DMS' = 'DMS',
    /** Streakk */
    'STKK' = 'STKK',
    /** Ftribe Fighters (F2 NFT) */
    'F2C' = 'F2C',
    /** SpacePi */
    'SPACEPI' = 'SPACEPI',
    /** Getaverse */
    'GETA' = 'GETA',
    /** Stella Fantasy */
    'SFTY' = 'SFTY',
    /** Kermit */
    'CRICKETS' = 'CRICKETS',
    /** EXATECH PoAI Blockchain */
    'EXT' = 'EXT',
    /** MetaWorth */
    'MTW' = 'MTW',
    /** Sonic Inu */
    'SONIC' = 'SONIC',
    /** Metan Evolutions */
    'METAN' = 'METAN',
    /** Demole */
    'DMLG' = 'DMLG',
    /** DeHeroGame Amazing Token */
    'AMG' = 'AMG',
    /** Embr */
    'EMBR' = 'EMBR',
    /** TRUMP */
    'MAGA' = 'MAGA',
    /** RYI Unity */
    'RYIU' = 'RYIU',
    /** Camly Coin */
    'CAMLY' = 'CAMLY',
    /** Altbase */
    'ALTB' = 'ALTB',
    /** Real Realm */
    'REAL' = 'REAL',
    /** GigaChadGPT */
    'GIGA' = 'GIGA',
    /** Street Runner NFT */
    'SRG' = 'SRG',
    /** STA */
    'STA' = 'STA',
    /** AgoDefi */
    'AGO' = 'AGO',
    /** IMO */
    'IMO' = 'IMO',
    /** SINSO */
    'SINSO' = 'SINSO',
    /** Zambesigold */
    'ZGD' = 'ZGD',
    /** PEPECASH */
    'PEPECASH' = 'PEPECASH',
    /** JP */
    'JP' = 'JP',
    /** Project X */
    'XIL' = 'XIL',
    /** Fidance */
    'FDC' = 'FDC',
    /** Planet */
    'AQUA' = 'AQUA',
    /** Galactic Arena: The NFTverse */
    'GAN' = 'GAN',
    /** Simpsons AI */
    'SAI' = 'SAI',
    /** Avocado Blockchain Group */
    'AVO' = 'AVO',
    /** KnightSwap */
    'KNIGHT' = 'KNIGHT',
    /** Liquid Crypto */
    'LQDX' = 'LQDX',
    /** StarSharks (SSS) */
    'SSS' = 'SSS',
    /** LinkFi */
    'LINKFI' = 'LINKFI',
    /** Nemesis PRO */
    'NMSP' = 'NMSP',
    /** when */
    'WHEN' = 'WHEN',
    /** Meme Lordz */
    '$LORDZ' = '$LORDZ',
    /** Flozo Game Token */
    'FGT' = 'FGT',
    /** Lenda */
    'LENDA' = 'LENDA',
    /** MultiFunctional Environmental Token */
    'MFET' = 'MFET',
    /** Web3Camp */
    '3P' = '3P',
    /** Ritz.Game */
    'RITZ' = 'RITZ',
    /** Vemate */
    'VMT' = 'VMT',
    /** Betterment Digital */
    'BEMD' = 'BEMD',
    /** CATEX */
    'CATEX' = 'CATEX',
    /** CryptoUnity */
    'CUT' = 'CUT',
    /** Defi For You */
    'DFY' = 'DFY',
    /** Murasaki */
    'MURA' = 'MURA',
    /** Germany Rabbit Token */
    'GERMANY' = 'GERMANY',
    /** WEB4 AI */
    'WEB4' = 'WEB4',
    /** The Three Kingdoms */
    'TTK' = 'TTK',
    /** Idle Ninja Online */
    'NINKY' = 'NINKY',
    /** Baby WOJ */
    'BWJ' = 'BWJ',
    /** SafeLaunch */
    'SFEX' = 'SFEX',
    /** Pin Token */
    'PIN' = 'PIN',
    /** United */
    'UTED' = 'UTED',
    /** Monkecoin */
    'MONKE' = 'MONKE',
    /** Krypton DAO */
    'KRD' = 'KRD',
    /** JOJO */
    'JOJO' = 'JOJO',
    /** Grok Bank */
    'GROKBANK' = 'GROKBANK',
    /** The Recharge */
    'RCG' = 'RCG',
    /** CheersLand */
    'CHEERS' = 'CHEERS',
    /** BitMinerX */
    'BMX' = 'BMX',
    /** ShibaKeanu */
    'SHIBK' = 'SHIBK',
    /** Metaland Shares */
    'MLS' = 'MLS',
    /** X AI */
    'X' = 'X',
    /** Pikaster */
    'RBP' = 'RBP',
    /** Metagame Arena */
    'MGA' = 'MGA',
    /** Rabbit Finance */
    'RABBIT' = 'RABBIT',
    /** NewGold */
    'NEWG' = 'NEWG',
    /** NewB.Farm */
    'NEWB' = 'NEWB',
    /** Open Source Network */
    'OPN' = 'OPN',
    /** ViteX Coin */
    'VX' = 'VX',
    /** Bitcoin City Coin */
    'BCITY' = 'BCITY',
    /** Nominex */
    'NMX' = 'NMX',
    /** Continuum Finance */
    'CTN' = 'CTN',
    /** Doge CEO */
    'DOGECEO' = 'DOGECEO',
    /** BabyBNBTiger */
    'BABYBNBTIGER' = 'BABYBNBTIGER',
    /** iVipCoin */
    'IVIP' = 'IVIP',
    /** Dot Dot Finance */
    'DDD' = 'DDD',
    /** Copycat Finance */
    'COPYCAT' = 'COPYCAT',
    /** ThinkwareAI */
    'THINKWAREAI' = 'THINKWAREAI',
    /** Morse */
    'MORSE' = 'MORSE',
    /** xWIN Finance */
    'XWIN' = 'XWIN',
    /** Crypto Delivery */
    'DCOIN' = 'DCOIN',
    /** NFTGamingStars */
    'GS1' = 'GS1',
    /** Green Blue Coin */
    'GBC' = 'GBC',
    /** Ruby Play Network */
    'RUBY' = 'RUBY',
    /** Seba */
    'SEBA' = 'SEBA',
    /** Moonlight Token */
    'MOONLIGHT' = 'MOONLIGHT',
    /** JOKER */
    'JOKER' = 'JOKER',
    /** Baby Sora */
    'BABYSORA' = 'BABYSORA',
    /** BinStarter */
    'BSR' = 'BSR',
    /** Cairo Finance */
    'CBANK' = 'CBANK',
    /** Pandora Finance */
    'PNDR' = 'PNDR',
    /** Husky.AI */
    'HUS' = 'HUS',
    /** Outter Finance */
    'OUT' = 'OUT',
    /** GoWrap */
    'GWGW' = 'GWGW',
    /** Ari10 */
    'Ari10' = 'Ari10',
    /** Shihtzu Exchange */
    'STZU' = 'STZU',
    /** IVIRSE */
    'IVI' = 'IVI',
    /** MetaShipping */
    'MSHIP' = 'MSHIP',
    /** CryptoXpress */
    'XPRESS' = 'XPRESS',
    /** Tesla AI */
    'TESLAI' = 'TESLAI',
    /** MEGAHERO */
    'MEGAHERO' = 'MEGAHERO',
    /** Animal Farm Pigs */
    'AFP' = 'AFP',
    /** Baby Troll */
    'BABYTROLL' = 'BABYTROLL',
    /** Alita */
    'ALME' = 'ALME',
    /** NIX */
    'NIX' = 'NIX',
    /** Mars Ecosystem Token */
    'XMS' = 'XMS',
    /** MINO INU */
    'MINO' = 'MINO',
    /** ReadFi */
    '$RDF' = '$RDF',
    /** Matrix Gpt Ai */
    'MAI' = 'MAI',
    /** Trossard */
    'TROSS' = 'TROSS',
    /** Dogecoin 2.0 */
    'DOGE2' = 'DOGE2',
    /** VIP Token */
    'VIP' = 'VIP',
    /** BTAF token */
    'BTAF' = 'BTAF',
    /** SpritzMoon Crypto Token */
    'spritzmoon' = 'spritzmoon',
    /** Ookeenga */
    'OKG' = 'OKG',
    /** Jonny Five */
    'JFIVE' = 'JFIVE',
    /** Mobster */
    'MOB' = 'MOB',
    /** A2DAO */
    'ATD' = 'ATD',
    /** Paragen */
    'RGEN' = 'RGEN',
    /** Heroes 3 Foundation */
    'GORGONZOLA' = 'GORGONZOLA',
    /** Vibing */
    'VBG' = 'VBG',
    /** Smarty Pay */
    'SPY' = 'SPY',
    /** T-mac DAO */
    'TMG' = 'TMG',
    /** Solidray (new) */
    'SRT' = 'SRT',
    /** RakeIn */
    'RAKE' = 'RAKE',
    /** TBCC */
    'TBCC' = 'TBCC',
    /** Junkie Cats */
    'JUNKIE' = 'JUNKIE',
    /** PiBridge */
    'PIB' = 'PIB',
    /** Cake Monster */
    'MONSTA' = 'MONSTA',
    /** ZILLION AAKAR XO */
    'ZILLIONXO' = 'ZILLIONXO',
    /** Monopoly Millionaire Control */
    'MMC' = 'MMC',
    /** Revault Network */
    'REVA' = 'REVA',
    /** GBANK APY */
    'GBK' = 'GBK',
    /** Metarix */
    'MTRX' = 'MTRX',
    /** ZeLoop Eco Reward */
    'ERW' = 'ERW',
    /** Pepe Wif Hat */
    'PIF' = 'PIF',
    /** KTX.Finance */
    'KTC' = 'KTC',
    /** PEPI */
    'PEPI' = 'PEPI',
    /** Block Buster Tech Inc */
    'BBTF' = 'BBTF',
    /** Ninneko (Mata) */
    'MATA' = 'MATA',
    /** MetaGods */
    'MGOD' = 'MGOD',
    /** Victory Gem */
    'VTG' = 'VTG',
    /** BABYOKX */
    'BABYOKX' = 'BABYOKX',
    /** MEME MUSK */
    'MEMEMUSK' = 'MEMEMUSK',
    /** GCCOIN */
    'GCC' = 'GCC',
    /** Baby Cat Coin */
    'BABYCAT' = 'BABYCAT',
    /** Coinbuck */
    'BUCK' = 'BUCK',
    /** Neuralink */
    'NLINK' = 'NLINK',
    /** Child Support */
    'CS' = 'CS',
    /** Echo Of The Horizon */
    'EOTH' = 'EOTH',
    /** DogeKing */
    'DOGEKING' = 'DOGEKING',
    /** MagnetGold */
    'MTG' = 'MTG',
    /** MuziKoin */
    'MZK' = 'MZK',
    /** GBURN */
    'GBURN' = 'GBURN',
    /** Avrora Metaverse */
    'AVR' = 'AVR',
    /** XSpace */
    'XSP' = 'XSP',
    /** Growth DeFi */
    'xGRO' = 'xGRO',
    /** TetherTV */
    'USDTV' = 'USDTV',
    /** Vulture Peak */
    'VPK' = 'VPK',
    /** Revenue Coin */
    'RVC' = 'RVC',
    /** Banus.Finance */
    'BANUS' = 'BANUS',
    /** Bedrock */
    'ROCK' = 'ROCK',
    /** Pikaster */
    'SRBP' = 'SRBP',
    /** LoopSwap */
    'LSWAP' = 'LSWAP',
    /** MoveApp */
    'MOVE' = 'MOVE',
    /** AutoCrypto */
    'AU' = 'AU',
    /** PECland */
    'PECL' = 'PECL',
    /** Gyro */
    'GYRO' = 'GYRO',
    /** Mother of Memes */
    'MOM' = 'MOM',
    /** Lucro */
    'LCR' = 'LCR',
    /** popo */
    'POPO' = 'POPO',
    /** TipsyCoin */
    '$TIPSY' = '$TIPSY',
    /** Black Phoenix */
    'BPX' = 'BPX',
    /** BLU */
    'BLU' = 'BLU',
    /** PooCoin */
    'POOCOIN' = 'POOCOIN',
    /** Baby Cat Coin */
    'BABYCATS' = 'BABYCATS',
    /** CoinClaim */
    'CLM' = 'CLM',
    /** Aarma */
    'ARMA' = 'ARMA',
    /** Grok Girl */
    'GROKGIRL' = 'GROKGIRL',
    /** BMW */
    'BMW' = 'BMW',
    /** Baby Musk */
    'BABYMUSK' = 'BABYMUSK',
    /** Pesabase */
    'PESA' = 'PESA',
    /** Grok X */
    'GROK X' = 'GROK X',
    /** Space Misfits */
    'SMCW' = 'SMCW',
    /** ETH Fan Token Ecosystem */
    'EFT' = 'EFT',
    /** CAT PEPE */
    'CATPEPE' = 'CATPEPE',
    /** Dogewhale */
    'DOGEWHALE' = 'DOGEWHALE',
    /** Book of Baby Memes */
    'BABYBOME' = 'BABYBOME',
    /** Golden Doge */
    'GDOGE' = 'GDOGE',
    /** BlocX */
    'BLX' = 'BLX',
    /** ELON MARS */
    'ELONMARS' = 'ELONMARS',
    /** Gagarin */
    'GGR' = 'GGR',
    /** Konstellation Network */
    'DARC' = 'DARC',
    /** Bit Hotel */
    'BTH' = 'BTH',
    /** BR34P */
    'BR34P' = 'BR34P',
    /** Dibs Share */
    'DSHARE' = 'DSHARE',
    /** TEN */
    'TENFI' = 'TENFI',
    /** Expert Infra */
    'EIM' = 'EIM',
    /** Darik */
    'DARIK' = 'DARIK',
    /** Hudi */
    'HUDI' = 'HUDI',
    /** Gege */
    'GEGE' = 'GEGE',
    /** Metarun */
    'MRUN' = 'MRUN',
    /** AITK */
    'AITK' = 'AITK',
    /** CryptoHunterTrading */
    'CHT' = 'CHT',
    /** PARMA Fan Token */
    'PARMA' = 'PARMA',
    /** Ratscoin */
    'RATS' = 'RATS',
    /** BabyChita */
    'BCT' = 'BCT',
    /** MOMOJI */
    'EMOJI' = 'EMOJI',
    /** LunaOne */
    'XLN' = 'XLN',
    /** Affinity */
    'AFNTY' = 'AFNTY',
    /** BlackRock */
    'BLACKROCK' = 'BLACKROCK',
    /** MAGA */
    'TRUMP' = 'TRUMP',
    /** OXO Network */
    'OXO' = 'OXO',
    /** CZUSD */
    'CZUSD' = 'CZUSD',
    /** LULU */
    'LULU' = 'LULU',
    /** Dao Space */
    'DAOP' = 'DAOP',
    /** The Kingdom Coin */
    'TKC' = 'TKC',
    /** Midway AI */
    'MIDAI' = 'MIDAI',
    /** Blin Metaverse */
    'BLIN' = 'BLIN',
    /** Vitra Studios */
    'VITRA' = 'VITRA',
    /** Arabian Dragon */
    'AGON' = 'AGON',
    /** Stabledoc */
    'SDT' = 'SDT',
    /** SpartaCats */
    'PURR' = 'PURR',
    /** elonwifcoin */
    'EWIF' = 'EWIF',
    /** ElonDoge */
    'EDOGE' = 'EDOGE',
    /** DKEY BANK */
    'DKEY' = 'DKEY',
    /** Wojak Finance */
    'WOJ' = 'WOJ',
    /** ZakumiFi */
    'ZAFI' = 'ZAFI',
    /** CrossWallet */
    'CWT' = 'CWT',
    /** Sora Doge */
    'SORADOGE' = 'SORADOGE',
    /** NexMillionaires */
    'NEXM' = 'NEXM',
    /** Triathon */
    'GEON' = 'GEON',
    /** Viblos */
    'VIBLO' = 'VIBLO',
    /** Moonscape */
    'MSCP' = 'MSCP',
    /** Medamon */
    'MON' = 'MON',
    /** Meta Ricaro */
    'CARO' = 'CARO',
    /** MoMo KEY */
    'KEY' = 'KEY',
    /** Optimus */
    'OPTCM' = 'OPTCM',
    /** Telo Meme Coin */
    'TELO' = 'TELO',
    /** Onschain */
    'ONS' = 'ONS',
    /** Hectic Turkey */
    'HECT' = 'HECT',
    /** Aquari */
    'AQUARI' = 'AQUARI',
    /** OSK */
    'OSK' = 'OSK',
    /** Wednesday Inu */
    'WED' = 'WED',
    /** AISwap */
    'AIS' = 'AIS',
    /** Curry */
    'CURRY' = 'CURRY',
    /** EGOLD */
    'EGOLD' = 'EGOLD',
    /** BOCOIN */
    'BOC' = 'BOC',
    /** Monkey */
    'MONKEY' = 'MONKEY',
    /** Crogecoin */
    'CROGE' = 'CROGE',
    /** NerveFlux */
    'NERVE' = 'NERVE',
    /** Meta Apes */
    'SHELL' = 'SHELL',
    /** Steakd */
    'SDX' = 'SDX',
    /** ArithFi */
    'ATF' = 'ATF',
    /** Project Quantum */
    'QBIT' = 'QBIT',
    /** Liquidus Foundation */
    'LIQ' = 'LIQ',
    /** Defi Tiger */
    'DTG' = 'DTG',
    /** Pi Network DeFi */
    'PI NETWORK DEFI' = 'PI NETWORK DEFI',
    /** Baby Bali */
    'BB' = 'BB',
    /** LADA */
    'LADA' = 'LADA',
    /** Crazy Bunny */
    'CRAZYBUNNY' = 'CRAZYBUNNY',
    /** WallStreetBets DApp */
    'WSB' = 'WSB',
    /** Day Of Defeat 2.0 */
    'DOD' = 'DOD',
    /** BIB Token */
    'BIB' = 'BIB',
    /** Omni Consumer Protocols */
    'OCP' = 'OCP',
    /** Digimon Rabbit */
    'DRB' = 'DRB',
    /** SaveYourAssets */
    'SYA' = 'SYA',
    /** NetZero */
    'NZERO' = 'NZERO',
    /** Gaming-T-Bot */
    'GTBOT' = 'GTBOT',
    /** Goatcoin */
    'GOAT' = 'GOAT',
    /** CorgiCoin */
    'CORGI' = 'CORGI',
    /** GreenTrust */
    'GNT' = 'GNT',
    /** BIT GAME VERSE TOKEN */
    'BGVT' = 'BGVT',
    /** ZedDex */
    'ZED' = 'ZED',
    /** OneMillionDollars */
    'OMD' = 'OMD',
    /** New Cat */
    'NCT' = 'NCT',
    /** SHELTERZ */
    'TERZ' = 'TERZ',
    /** BNbitcoin */
    'BNBTC' = 'BNBTC',
    /** Wanaka Farm */
    'WANA' = 'WANA',
    /** Web3Met */
    'W3M' = 'W3M',
    /** AddMeFast */
    'AMF' = 'AMF',
    /** Quidax Token */
    'QDX' = 'QDX',
    /** Battle Infinity */
    'IBAT' = 'IBAT',
    /** STACKS PAY */
    'STACKS' = 'STACKS',
    /** Iris Ecosystem */
    'IRISTOKEN' = 'IRISTOKEN',
    /** FLORK BNB */
    'FLORK' = 'FLORK',
    /** Drip Network */
    'DRIP' = 'DRIP',
    /** RamenSwap */
    'RAMEN' = 'RAMEN',
    /** BLOKPAD */
    'BPAD' = 'BPAD',
    /** Level Finance */
    'LGO' = 'LGO',
    /** Wrapped PKT */
    'WPKT' = 'WPKT',
    /** XTblock */
    'XTT-B20' = 'XTT-B20',
    /** Socaverse */
    'SOCA' = 'SOCA',
    /** Manga Token */
    '$MANGA' = '$MANGA',
    /** TokoQrt */
    'TQRT' = 'TQRT',
    /** TemplarDAO */
    'TEM' = 'TEM',
    /** IMOV */
    'IMT' = 'IMT',
    /** MetaWars */
    'WARS' = 'WARS',
    /** Class Coin */
    'CLASS' = 'CLASS',
    /** Sports Future Exchange Token */
    'SPOX' = 'SPOX',
    /** Pepe AI */
    'PEPEAI' = 'PEPEAI',
    /** GINZA NETWORK */
    'GINZA' = 'GINZA',
    /** Verve */
    'VERVE' = 'VERVE',
    /** ZMINE */
    'ZMN' = 'ZMN',
    /** AiONE */
    'AIONE' = 'AIONE',
    /** Distributed Autonomous Organization */
    'DAO' = 'DAO',
    /** DeathRoad */
    'DRACE' = 'DRACE',
    /** Momentum */
    'MASS' = 'MASS',
    /** Coinracer Reloaded */
    'CRACER' = 'CRACER',
    /** MARSUPILAMI INU */
    'MARSUPILAMI' = 'MARSUPILAMI',
    /** Ponke BNB */
    'PONKE BNB' = 'PONKE BNB',
    /** DEXART */
    'DXA' = 'DXA',
    /** Volley token */
    'VOY' = 'VOY',
    /** TriipMiles */
    'TIIM' = 'TIIM',
    /** MetaGear */
    'GEAR' = 'GEAR',
    /** Alita Finance */
    'ALI' = 'ALI',
    /** Cosmic FOMO */
    'COSMIC' = 'COSMIC',
    /** Biokript */
    'BKPT' = 'BKPT',
    /** MicroPets */
    'PETS' = 'PETS',
    /** Tag Protocol */
    'TAG' = 'TAG',
    /** Valas Finance */
    'VALAS' = 'VALAS',
    /** FelixCoin */
    'FELIX' = 'FELIX',
    /** TRUMP2024 */
    'TRUMP2024' = 'TRUMP2024',
    /** NUDES */
    'NUDES' = 'NUDES',
    /** Pepa Inu */
    'PEPA' = 'PEPA',
    /** Gmining */
    'GMNT' = 'GMNT',
    /** Crypto Birds */
    'XCB' = 'XCB',
    /** Magic Beasties */
    'BSTS' = 'BSTS',
    /** Zetos */
    'ZES' = 'ZES',
    /** Supernova Shards */
    'LFC' = 'LFC',
    /** King Cat */
    'KINGCAT' = 'KINGCAT',
    /** Titi Financial */
    'TITI' = 'TITI',
    /** SKY FRONTIER */
    'GSKY' = 'GSKY',
    /** Baby Tomcat */
    'BABYTOMCAT' = 'BABYTOMCAT',
    /** Froggies Token */
    'FRGST' = 'FRGST',
    /** BODA Token */
    'BODAV2' = 'BODAV2',
    /** DeFiHorse */
    'DFH' = 'DFH',
    /** ELON DOGE */
    'ELONDOGE' = 'ELONDOGE',
    /** Green Life Energy */
    'GLE' = 'GLE',
    /** Ethereal */
    'ETRL' = 'ETRL',
    /** Baby Toshi */
    'BABYTOSHI' = 'BABYTOSHI',
    /** Leve Invest */
    'LEVE' = 'LEVE',
    /** Baby Jerry */
    'BABYJERRY' = 'BABYJERRY',
    /** TOKEN 2049 */
    '#2049' = '2049',
    /** Moonpot */
    'POTS' = 'POTS',
    /** Jerry Inu */
    'JERRY' = 'JERRY',
    /** Shinjiru Inu V2 */
    'SHINJI' = 'SHINJI',
    /** SafeMars */
    'SMARS' = 'SMARS',
    /** ENEFTIVERSE */
    'EVR' = 'EVR',
    /** WORLD ID */
    'WOID' = 'WOID',
    /** RocketFi */
    'ROCKETFI' = 'ROCKETFI',
    /** TrumpCoin */
    'DTC' = 'DTC',
    /** Pepe Original Version */
    'POV' = 'POV',
    /** Asva */
    'ASVA' = 'ASVA',
    /** Dogai */
    'DOGAI' = 'DOGAI',
    /** The Everlasting Parachain */
    'ELP' = 'ELP',
    /** Jaiho Crypto */
    'JAIHO' = 'JAIHO',
    /** Infinite Launch */
    'ILA' = 'ILA',
    /** MsgSender */
    'MSG' = 'MSG',
    /** Baby Pepe Floki */
    'BPEPEF' = 'BPEPEF',
    /** Baby Shiba Inu */
    'BABYSHIBAINU' = 'BABYSHIBAINU',
    /** LaserEyes */
    'LSR' = 'LSR',
    /** Alaaddin.ai */
    'ALDIN' = 'ALDIN',
    /** BOOK OF DOGE MEMES */
    'BOMEDOGE' = 'BOMEDOGE',
    /** TrustNFT */
    'TRUSTNFT' = 'TRUSTNFT',
    /** ElonXAIDogeMessi69PepeInu */
    'BITCOIN' = 'BITCOIN',
    /** Reflecto */
    'RTO' = 'RTO',
    /** PAM */
    'PAM' = 'PAM',
    /** Freedom God Dao */
    'FGD' = 'FGD',
    /** Jesus Coin (BSC) */
    'JESUS' = 'JESUS',
    /** HongKongDAO */
    'HKD' = 'HKD',
    /** Librium Tech */
    'LIB' = 'LIB',
    /** Flokimooni */
    'FLOKIM' = 'FLOKIM',
    /** Pomerium Utility Token */
    'PMR' = 'PMR',
    /** Profit Blue */
    'BLUE' = 'BLUE',
    /** BasketCoin */
    'BSKT' = 'BSKT',
    /** Moonarch.app */
    'MOONARCH' = 'MOONARCH',
    /** Coinary Token */
    'CYT' = 'CYT',
    /** LungDefi */
    'LUNG' = 'LUNG',
    /** Shitcoin */
    'SHIT' = 'SHIT',
    /** TangYuan */
    'TANGYUAN' = 'TANGYUAN',
    /** Elon Cat */
    'ELONCAT' = 'ELONCAT',
    /** Musk Metaverse */
    'METAMUSK' = 'METAMUSK',
    /** LONG */
    'LONG' = 'LONG',
    /** FoxGirl */
    'FOXGIRL' = 'FOXGIRL',
    /** PVC Meta */
    'PVC' = 'PVC',
    /** TWELVE ZODIAC */
    'TWELVE' = 'TWELVE',
    /** RXCGames */
    'RXCG' = 'RXCG',
    /** Bomb Money */
    'BOMB' = 'BOMB',
    /** ENTS2.0 */
    'ENTS2.0' = 'ENTS2.0',
    /** MICROCOSM */
    'MIC' = 'MIC',
    /** Raichu Inu */
    'RINU' = 'RINU',
    /** Birb */
    'BIRB' = 'BIRB',
    /** EVAI */
    'EV' = 'EV',
    /** CleanCarbon */
    'CARBO' = 'CARBO',
    /** CLIMB TOKEN FINANCE */
    'CLIMB' = 'CLIMB',
    /** KyotoSwap */
    'KSWAP' = 'KSWAP',
    /** World of Defish */
    'WOD' = 'WOD',
    /** Baby Pepe Fork */
    'BABYPORK' = 'BABYPORK',
    /** Bitbull */
    'BITBULL' = 'BITBULL',
    /** XPROJECT */
    'XPRO' = 'XPRO',
    /** MicroCreditToken */
    '1MCT' = '1MCT',
    /** Decentralized Community Investment Protocol */
    'DCIP' = 'DCIP',
    /** DogeZilla */
    'ZILLA' = 'ZILLA',
    /** Floki SafeMoon */
    'FSM' = 'FSM',
    /** Papa Bear */
    'PAPA' = 'PAPA',
    /** REX */
    'XRX' = 'XRX',
    /** HerityNetwork */
    'HER' = 'HER',
    /** Swapz */
    'SWAPZ' = 'SWAPZ',
    /** DMEX (Decentralized Mining Exchange) */
    'DMC' = 'DMC',
    /** Vulkania */
    'VLK' = 'VLK',
    /** ForthBox */
    'FBX' = 'FBX',
    /** Gays Inu */
    'LGBTQ' = 'LGBTQ',
    /** CYBERTRUCK */
    'CYBERTRUCK' = 'CYBERTRUCK',
    /** DragonKing */
    'DRAGONKING' = 'DRAGONKING',
    /** MetaVerse-M */
    'M' = 'M',
    /** BONK KONG */
    'BONKKONG' = 'BONKKONG',
    /** Clear Water */
    '$CLEAR' = '$CLEAR',
    /** JERRYINU */
    'JERRYINU' = 'JERRYINU',
    /** MyBricks */
    '$BRICKS' = '$BRICKS',
    /** PhoenixCo Token */
    'XPHX' = 'XPHX',
    /** Nusa */
    'NUSA' = 'NUSA',
    /** NanoMeter Bitcoin */
    'NMBTC' = 'NMBTC',
    /** Flag Network */
    'FLAG' = 'FLAG',
    /** Bitpaid Token */
    'BTP' = 'BTP',
    /** BurnifyAI */
    'BURNIFYAI' = 'BURNIFYAI',
    /** DYOR Token */
    'DYOR' = 'DYOR',
    /** Thetan Coin */
    'THC' = 'THC',
    /** TurboDEX */
    'TURBO' = 'TURBO',
    /** Vegasino */
    'VEGAS' = 'VEGAS',
    /** Octavus Prime */
    'OCTAVUS' = 'OCTAVUS',
    /** BULL */
    'BULL' = 'BULL',
    /** Kingdom Raids */
    'KRS' = 'KRS',
    /** FUTURECOIN */
    'FUTURE' = 'FUTURE',
    /** Sir */
    'SIR' = 'SIR',
    /** Hare Token */
    'HARE' = 'HARE',
    /** Baby Myro */
    'BABYMYRO' = 'BABYMYRO',
    /** Satellite Doge-1 */
    'DOGE-1' = 'DOGE-1',
    /** Pico Coin */
    'PICO' = 'PICO',
    /** Trans Pepe */
    'TRANS' = 'TRANS',
    /** Galaxy Finance */
    'GLF' = 'GLF',
    /** For Meta World */
    '4MW' = '4MW',
    /** Kephi Gallery */
    'KPHI' = 'KPHI',
    /** MOON DOGE */
    'DOGE' = 'DOGE',
    /** PIKACHU INU */
    'PIKACHU' = 'PIKACHU',
    /** Duckie Land */
    'MMETA' = 'MMETA',
    /** ZEDXION */
    'ZEDX' = 'ZEDX',
    /** Arena Token */
    'ARENA' = 'ARENA',
    /** DogePome */
    'PME' = 'PME',
    /** WEDEX TOKEN V2 */
    'DEX' = 'DEX',
    /** Bitcoin Pay */
    'BTCPAY' = 'BTCPAY',
    /** RocketXRP Official */
    'RXO' = 'RXO',
    /** GOKUSWAP */
    'GOKU' = 'GOKU',
    /** Streakk Chain */
    'STKC' = 'STKC',
    /** ZCore Finance */
    'ZEFI' = 'ZEFI',
    /** Uniwhale */
    'UNW' = 'UNW',
    /** Baby Rats */
    'BABYRATS' = 'BABYRATS',
    /** BSC FARM */
    'BSC' = 'BSC',
    /** BNB SNAKE */
    'BNBSNAKE' = 'BNBSNAKE',
    /** Cryptostone */
    'CPS' = 'CPS',
    /** Shiba Floki Inu */
    'FLOKI' = 'FLOKI',
    /** Kingdom Karnage */
    'KKT' = 'KKT',
    /** LOL coin */
    'LOL' = 'LOL',
    /** KaraStar UMY */
    'UMY' = 'UMY',
    /** MEOW MEOW */
    '$MEOW' = '$MEOW',
    /** WOLF INU */
    'WOLF INU' = 'WOLF INU',
    /** Scrooge */
    'SCROOGE' = 'SCROOGE',
    /** dexSHARE */
    'DEXSHARE' = 'DEXSHARE',
    /** THE WORD TOKEN */
    'TWD' = 'TWD',
    /** Trump Inu */
    'TRUMPINU' = 'TRUMPINU',
    /** MonoMoney */
    'MONO' = 'MONO',
    /** Rage On Wheels */
    'ROW' = 'ROW',
    /** Satozhi */
    'SATOZ' = 'SATOZ',
    /** AI Bob */
    'AIB' = 'AIB',
    /** Optimus X */
    'OPX' = 'OPX',
    /** Kepple */
    'KPL' = 'KPL',
    /** FLOKI X */
    'FLOKIX' = 'FLOKIX',
    /** Waifu */
    'WAIFU' = 'WAIFU',
    /** FUFU */
    'FUFU' = 'FUFU',
    /** KINGPEPE */
    'KINGPEPE' = 'KINGPEPE',
    /** Hololoot */
    'HOL' = 'HOL',
    /** Perry The BNB V2 */
    'PERRY' = 'PERRY',
    /** FINK */
    'FINK' = 'FINK',
    /** Disabled Pepe */
    'DISPEPE' = 'DISPEPE',
    /** Pepe Potato */
    '$MRPEPE' = '$MRPEPE',
    /** Jetset */
    'JTS' = 'JTS',
    /** Troll Face */
    'TROLL' = 'TROLL',
    /** Cub Finance */
    'CUB' = 'CUB',
    /** Zone of Avoidance */
    'ZOA' = 'ZOA',
    /** Carmin */
    'CARMIN' = 'CARMIN',
    /** HongKong Doge */
    'HKDOGE' = 'HKDOGE',
    /** Olive Cash */
    'OLIVE' = 'OLIVE',
    /** CAW CEO */
    'CAWCEO' = 'CAWCEO',
    /** RevolutionGames */
    'RVLNG' = 'RVLNG',
    /** BabyKitty */
    'BABYKITTY' = 'BABYKITTY',
    /** Onno Vault */
    'ONNO' = 'ONNO',
    /** JK Coin */
    'JK' = 'JK',
    /** Travel Coin */
    'TCOIN' = 'TCOIN',
    /** USD Open Dollar */
    'USDO' = 'USDO',
    /** Fayda */
    'FAYD' = 'FAYD',
    /** SafeMoo */
    'SAFEMOO' = 'SAFEMOO',
    /** Furio */
    '$FUR' = '$FUR',
    /** Lucky7 */
    '#7' = '7',
    /** NGA Tiger */
    'NGA' = 'NGA',
    /** Chinese NY Dragon */
    'CNYD' = 'CNYD',
    /** OBRok Token */
    'OBROK' = 'OBROK',
    /** CRAZY CAT */
    'CRAZYCAT' = 'CRAZYCAT',
    /** EverETH Reflect */
    'EVERETH' = 'EVERETH',
    /** Superalgos */
    'SA' = 'SA',
    /** HotMoon Token */
    'HOTMOON' = 'HOTMOON',
    /** UFC WIN */
    'UFC' = 'UFC',
    /** INTERSTELLAR DOMAIN ORDER */
    'IDO' = 'IDO',
    /** Model Labs */
    'MODEL' = 'MODEL',
    /** Print Cash BNB */
    'CASH' = 'CASH',
    /** SaltSwap Finance */
    'SALT' = 'SALT',
    /** Niob Finance */
    'NIOB' = 'NIOB',
    /** Gafa */
    'GAFA' = 'GAFA',
    /** The SIMPSONS BNB */
    'SIMPSONS' = 'SIMPSONS',
    /** WifeDoge */
    'WIFEDOGE' = 'WIFEDOGE',
    /** Duck Off Coin */
    'DUCK' = 'DUCK',
    /** JEFF */
    'JEFF' = 'JEFF',
    /** FlokiBurn */
    'FLOKIBURN' = 'FLOKIBURN',
    /** Baby Squid Game */
    'BSG' = 'BSG',
    /** CRAZY TIGER */
    'CRAZYTIGER' = 'CRAZYTIGER',
    /** Liquid Collectibles */
    'LICO' = 'LICO',
    /** BNBdoge */
    'BNBDOGE' = 'BNBDOGE',
    /** Poodl Token */
    'POODL' = 'POODL',
    /** Coin Of Champions */
    'COC' = 'COC',
    /** vBSWAP */
    'VBSWAP' = 'VBSWAP',
    /** JeromeAndGary */
    'JARY' = 'JARY',
    /** Secured MoonRat Token */
    'SMRAT' = 'SMRAT',
    /** AMATERAS */
    'AMT' = 'AMT',
    /** Ainu Token */
    'AINU' = 'AINU',
    /** WHITE DRAGON */
    'DRAGON' = 'DRAGON',
    /** MARIO CEO */
    'MARIO' = 'MARIO',
    /** FLOKI INU */
    'FLINU' = 'FLINU',
    /** Xrius */
    'XRS' = 'XRS',
    /** XMAS */
    'XMAS' = 'XMAS',
    /** gotEM */
    'GOTEM' = 'GOTEM',
    /** BNB BONK */
    'BNBBONK' = 'BNBBONK',
    /** GCWine */
    'GCW' = 'GCW',
    /** Golden Ball */
    'GLB' = 'GLB',
    /** TRONPAD */
    'TRONPAD' = 'TRONPAD',
    /** XQUOK */
    'XQUOK' = 'XQUOK',
    /** Jswap.Finance */
    'JF' = 'JF',
    /** FIDELIS */
    'FDLS' = 'FDLS',
    /** ASIX+ */
    'ASIX+' = 'ASIX+',
    /** BitX Exchange */
    'BITX' = 'BITX',
    /** CRAZY MUSK */
    'CRAZYMUSK' = 'CRAZYMUSK',
    /** AI DogeX */
    'AIDOGEX' = 'AIDOGEX',
    /** LEOPARD */
    'LEOPARD' = 'LEOPARD',
    /** Hongkong */
    'HK' = 'HK',
    /** hong kong floki */
    'HKFLOKI' = 'HKFLOKI',
    /** ZOO Crypto World */
    'ZOO' = 'ZOO',
    /** Dogs Rock */
    'DOGSROCK' = 'DOGSROCK',
    /** Neutrinos */
    'NEUTR' = 'NEUTR',
    /** NOOT */
    '$NOOT' = '$NOOT',
    /** MetaDoge */
    'METADOGE' = 'METADOGE',
    /** Daw Currency */
    'DAW' = 'DAW',
    /** CRAZY DRAGON */
    'CRAZYDRAGON' = 'CRAZYDRAGON',
    /** NEMO */
    'NEMO' = 'NEMO',
    /** Sora */
    'SORA' = 'SORA',
    /** WHITE TIGER MOON */
    '$WHTETGRMOON' = '$WHTETGRMOON',
    /** CRAZY DOGE */
    'CRAZYDOGE' = 'CRAZYDOGE',
    /** BNBsongoku */
    'BNBSONGOKU' = 'BNBSONGOKU',
    /** HALLOWEEN X */
    'HALLOWEEN' = 'HALLOWEEN',
    /** CRAZY BONK */
    'CRAZYBONK' = 'CRAZYBONK',
    /** OLYMPIC GAMES DOGE */
    'OGD' = 'OGD',
    /** DOLLAR SHIBA INU */
    'DSHIB' = 'DSHIB',
    /** SPIDERMAN BITCOIN */
    'SPIDERMAN' = 'SPIDERMAN',
    /** PAW PATROL INU */
    'PAW' = 'PAW',
    /** Speedex */
    'SPDX' = 'SPDX',
    /** MILO DOG */
    'MILO DOG' = 'MILO DOG',
    /** ChinaZilla */
    'CHINAZILLA' = 'CHINAZILLA',
    /** PEPE DAO */
    'PEPEDAO' = 'PEPEDAO',
    /** Stamen Tellus Token */
    'STT' = 'STT',
    /** MiniPepe */
    'MINIPEPE' = 'MINIPEPE',
    /** Spoody Man */
    'SPOODY' = 'SPOODY',
    /** CALO INDOOR */
    'IFIT' = 'IFIT',
    /** TWEETY */
    'TWEETY' = 'TWEETY',
    /** Cloak */
    'CLOAK' = 'CLOAK',
    /** Vetter Token */
    'VETTER' = 'VETTER',
    /** Optimism Doge */
    'ODOGE' = 'ODOGE',
    /** ShibaCorgi */
    'SHICO' = 'SHICO',
    /** Starship Doge */
    'STARSHIPDOGE' = 'STARSHIPDOGE',
    /** RUN TOGETHER */
    'RUN' = 'RUN',
    /** Cheese (V2) */
    'CHEESE' = 'CHEESE',
    /** Lil Floki */
    'LILFLOKI' = 'LILFLOKI',
    /** Grok Coin */
    'GROKCOIN' = 'GROKCOIN',
    /** Baby Binance */
    'BABYBINANCE' = 'BABYBINANCE',
    /** One Basis */
    'OBS' = 'OBS',
    /** Tiny Coin */
    'TINC' = 'TINC',
    /** Dex on Crypto */
    'DOCSWAP' = 'DOCSWAP',
    /** Iotec */
    'IOT' = 'IOT',
    /** PEPE inu */
    'PEPEINU' = 'PEPEINU',
    /** Wrapped Pi */
    'WPI' = 'WPI',
    /** Baby Pepe */
    'BABY PEPE' = 'BABY PEPE',
    /** Shambala */
    'BALA' = 'BALA',
    /** ELON 2024(BSC) */
    'ELON2024' = 'ELON2024',
    /** QuantumXC */
    'QXC' = 'QXC',
    /** Metaverse Miner */
    'META' = 'META',
    /** Grok Elo */
    'GELO' = 'GELO',
    /** ChitCAT */
    'CHITCAT' = 'CHITCAT',
    /** Justus */
    'JTT' = 'JTT',
    /** Eggman Inu */
    'EGGMAN' = 'EGGMAN',
    /** DogeFather */
    'FATHER' = 'FATHER',
    /** Cakebot */
    'CAKEBOT' = 'CAKEBOT',
    /** Monsterra (MAG) */
    'MAG' = 'MAG',
    /** Supreme Finance */
    'HYPES' = 'HYPES',
    /** Realital Metaverse */
    'RETA' = 'RETA',
    /** Floki Cash */
    'FLOKICASH' = 'FLOKICASH',
    /** King Sora */
    'KINGSORA' = 'KINGSORA',
    /** DOGE LEGION */
    'DOGE LEGION' = 'DOGE LEGION',
    /** Safuu */
    'SAFUU' = 'SAFUU',
    /** TosaInu BSC */
    'TOSA' = 'TOSA',
    /** Candylad */
    'CANDYLAD' = 'CANDYLAD',
    /** Baby Meme Coin */
    'BABYMEME' = 'BABYMEME',
    /** SwirlToken */
    'SWIRLX' = 'SWIRLX',
    /** Anypad */
    'APAD' = 'APAD',
    /** Dx Spot */
    'DXS' = 'DXS',
    /** Memes vs Undead */
    'MVU' = 'MVU',
    /** SafeMoonCash */
    'SAFEMOONCASH' = 'SAFEMOONCASH',
    /** EXTOCIUM */
    'XTO' = 'XTO',
    /** Chibi Dinos */
    'HOOP' = 'HOOP',
    /** Kalata */
    'KALA' = 'KALA',
    /** Nero Token */
    'NERO' = 'NERO',
    /** RIMAUNANGIS */
    'RXT' = 'RXT',
    /** MINA */
    'MINA' = 'MINA',
    /** JELLY */
    'JELLY' = 'JELLY',
    /** SUPER BONK */
    'BONK' = 'BONK',
    /** MongBNB */
    'MONGBNB' = 'MONGBNB',
    /** VELOREX */
    'VEX' = 'VEX',
    /** Block Commerce Protocol */
    'BCP' = 'BCP',
    /** PIPI */
    'PIPI' = 'PIPI',
    /** Reflecto USD */
    'RUSD' = 'RUSD',
    /** DoragonLand */
    'DOR' = 'DOR',
    /** BNBcat */
    'BNBCAT' = 'BNBCAT',
    /** PepeFloki */
    'PEPEFLOKI' = 'PEPEFLOKI',
    /** CoinMarketPrime */
    'CMP' = 'CMP',
    /** Square Token */
    'SQUA' = 'SQUA',
    /** BeatGen NFT */
    'BGN' = 'BGN',
    /** Werecoin EV Charging */
    'WRC' = 'WRC',
    /** MELEGA */
    'MARCO' = 'MARCO',
    /** Bitcoin Legend */
    'BCL' = 'BCL',
    /** SafeBull */
    'SAFEBULL' = 'SAFEBULL',
    /** Minifootball */
    'MINIFOOTBALL' = 'MINIFOOTBALL',
    /** TRUSTxGAMING */
    'TXG' = 'TXG',
    /** Elon Pepe Robot */
    'ELONPEPE' = 'ELONPEPE',
    /** LUCKY CATS */
    'KATZ' = 'KATZ',
    /** Bob AI */
    'BOBAI' = 'BOBAI',
    /** Online Cold Wallet */
    'OCW' = 'OCW',
    /** PANDAINU */
    'PWT' = 'PWT',
    /** Dinosaureggs */
    'DSG' = 'DSG',
    /** MilkshakeSwap */
    'Milk' = 'Milk',
    /** Monsters Clan */
    'MONS' = 'MONS',
    /** GameGuru */
    'GGT' = 'GGT',
    /** Inuko Finance */
    'INUKO' = 'INUKO',
    /** COXSWAP V2 */
    'COX' = 'COX',
    /** GPT Guru */
    'GPTG' = 'GPTG',
    /** Digihealth */
    'DGH' = 'DGH',
    /** Soccer Crypto */
    'SOT' = 'SOT',
    /** Super Floki */
    'SLOKI' = 'SLOKI',
    /** Coinscope */
    'COINSCOPE' = 'COINSCOPE',
    /** CubeBase */
    'CUBEB' = 'CUBEB',
    /** BNB FLOKI */
    'BNBFLOKI' = 'BNBFLOKI',
    /** Copiosa Coin */
    'COP' = 'COP',
    /** POTTER */
    'POTTER' = 'POTTER',
    /** BNBPot */
    'BNBP' = 'BNBP',
    /** BHAX Token */
    'BHAX' = 'BHAX',
    /** CTBNETWORK */
    'CTB/WBNB' = 'CTB/WBNB',
    /** Hungarian Vizsla Inu */
    'HVI' = 'HVI',
    /** Lucky Block v1 */
    'LBLOCK' = 'LBLOCK',
    /** JINDO INU */
    'JIND' = 'JIND',
    /** Web3 Inu */
    'WEB3' = 'WEB3',
    /** God Of Wealth */
    'GOW39' = 'GOW39',
    /** DNA(RXC) */
    'DNA' = 'DNA',
    /** UNIVERSE ISLAND */
    'UIM' = 'UIM',
    /** Digital Bank of Africa */
    'DBA' = 'DBA',
    /** Joey Inu */
    'JOEY' = 'JOEY',
    /** SHAUN INU */
    'SHAUN' = 'SHAUN',
    /** LiveGreen Coin */
    'LGC' = 'LGC',
    /** Dhabi Coin */
    'DBC' = 'DBC',
    /** Eifi FInance */
    'EIFI' = 'EIFI',
    /** GrokKing */
    'GROKKING' = 'GROKKING',
    /** BABY SAFEMOON */
    'BSFM' = 'BSFM',
    /** BFK Warzone */
    'BFK' = 'BFK',
    /** MarsRise */
    'MARSRISE' = 'MARSRISE',
    /** Revolotto */
    'RVL' = 'RVL',
    /** TryHards */
    'TRYHARDS' = 'TRYHARDS',
    /** Wanda Exchange */
    'WE' = 'WE',
    /** Google AI */
    'GAI' = 'GAI',
    /** Crypto Inu */
    'ABCD' = 'ABCD',
    /** Drover Inu */
    'DROVERS' = 'DROVERS',
    /** El Dorado Exchange */
    'EDE' = 'EDE',
    /** AirCoin */
    'AIR' = 'AIR',
    /** Taroverse */
    'TARO' = 'TARO',
    /** Sonic Space Cat */
    'SCAT' = 'SCAT',
    /** ChainCade */
    'CHAINCADE' = 'CHAINCADE',
    /** Cats Coin */
    'CTS' = 'CTS',
    /** Baby Luffy */
    'BLF' = 'BLF',
    /** Shib Army */
    'SHIBARMY' = 'SHIBARMY',
    /** CryptHub */
    'CRHT' = 'CRHT',
    /** Feeder.finance */
    'FEED' = 'FEED',
    /** JNY */
    'JNY' = 'JNY',
    /** Mech Master */
    'MECH' = 'MECH',
    /** Onlinebase */
    'ONLINE' = 'ONLINE',
    /** The Simpsons Inu */
    'SIMPSONSINU' = 'SIMPSONSINU',
    /** Ryoshi Token */
    'RYOSHI' = 'RYOSHI',
    /** Lesbian Inu */
    'LESBIAN' = 'LESBIAN',
    /** AEROTYME */
    'IND' = 'IND',
    /** SafeMoneyUP */
    'SMU' = 'SMU',
    /** AI DogeMini */
    'AIDOGEMINI' = 'AIDOGEMINI',
    /** Blizzard.money */
    'xBLZD' = 'xBLZD',
    /** Grok Inu */
    'GROKINU' = 'GROKINU',
    /** SwinCoin */
    'SWIN' = 'SWIN',
    /** KoinBülteni Token */
    'KOINB' = 'KOINB',
    /** Gemini */
    'GEMINI' = 'GEMINI',
    /** Sheesha Finance [BEP20] */
    'SHEESHA' = 'SHEESHA',
    /** Zamzam Token */
    'ZAMZAM' = 'ZAMZAM',
    /** Orbitau Taureum */
    'TAUM' = 'TAUM',
    /** BNB Tiger AI */
    'AITIGER' = 'AITIGER',
    /** FIT Token */
    'FIT' = 'FIT',
    /** Eternal World */
    'ETL' = 'ETL',
    /** BABYBTC */
    'BABYBTC' = 'BABYBTC',
    /** Bonfire */
    'BONFIRE' = 'BONFIRE',
    /** ShibKiller */
    'SHIBKILLER' = 'SHIBKILLER',
    /** Glory Finance */
    'GLR' = 'GLR',
    /** Baby Maga */
    'BMAGA' = 'BMAGA',
    /** Cancer */
    'CANCER' = 'CANCER',
    /** GAMINGDOGE */
    'GAMINGDOGE' = 'GAMINGDOGE',
    /** Dobby */
    'DOBBY' = 'DOBBY',
    /** PandAI */
    'PANDAI' = 'PANDAI',
    /** Monetas */
    'MNTG' = 'MNTG',
    /** SHIBU INU */
    'SHIBU' = 'SHIBU',
    /** xMooney */
    'XM' = 'XM',
    /** BigTycoon */
    'BTYC' = 'BTYC',
    /** PepeMo */
    'PEPEMO' = 'PEPEMO',
    /** AutoShark */
    'JAWS' = 'JAWS',
    /** Macro Protocol */
    'MTP' = 'MTP',
    /** VicMove */
    'VIM' = 'VIM',
    /** BonkFork */
    'BONKFORK' = 'BONKFORK',
    /** ShibaFameV2 */
    'SFV2' = 'SFV2',
    /** Revolution */
    'REV' = 'REV',
    /** Daddy Doge */
    'DADDYDOGE' = 'DADDYDOGE',
    /** Kitten Coin */
    'KITTENS' = 'KITTENS',
    /** Hatchyverse */
    'HATCHY' = 'HATCHY',
    /** SANGKARA MISA */
    'MISA' = 'MISA',
    /** Wirtual */
    'WIRTUAL' = 'WIRTUAL',
    /** TigerMoon */
    'TIGERMOON' = 'TIGERMOON',
    /** Zkzone */
    'ZKZ' = 'ZKZ',
    /** LIFEBIRD */
    'LIFEBIRD' = 'LIFEBIRD',
    /** Shira Cat */
    'CATSHIRA' = 'CATSHIRA',
    /** CharityDAO */
    'CHD' = 'CHD',
    /** SmartFi */
    'SMTF' = 'SMTF',
    /** Moby Dick V2 */
    'MOBY' = 'MOBY',
    /** Safe Energy */
    'EnergyX' = 'EnergyX',
    /** Last Survivor */
    'LSC' = 'LSC',
    /** ELON BUYS TWITTER */
    'EBT' = 'EBT',
    /** PUDEL */
    'PD' = 'PD',
    /** Age of Tanks */
    'A.O.T' = 'A.O.T',
    /** BorderCollieBSC */
    'BDCL BSC' = 'BDCL BSC',
    /** BOLICAI */
    'BOAI' = 'BOAI',
    /** H2O Securities */
    'H2ON' = 'H2ON',
    /** Predictcoin */
    'PRED' = 'PRED',
    /** 1NFT */
    '1NFT' = '1NFT',
    /** BOHR */
    'BR' = 'BR',
    /** Pepe The Frog */
    'PEPEBNB' = 'PEPEBNB',
    /** 3air */
    '3AIR' = '3AIR',
    /** PEPE COIN BSC */
    'PPC' = 'PPC',
    /** π */
    'π' = 'π',
    /** Calo */
    'CALO' = 'CALO',
    /** Chief Troll Officer(BSC) */
    'CTO' = 'CTO',
    /** Bart Simpson Coin */
    'BART' = 'BART',
    /** BabyHkTiger */
    'BABYHKTIGER' = 'BABYHKTIGER',
    /** Sensi */
    'SENSI' = 'SENSI',
    /** BNBEE */
    'BEE' = 'BEE',
    /** DragonGROK */
    'DRAGONGROK' = 'DRAGONGROK',
    /** Nether NFT */
    'NTR' = 'NTR',
    /** GamerFI */
    'GAMERFI' = 'GAMERFI',
    /** Bull inu */
    'BULLINU' = 'BULLINU',
    /** Howl City */
    'HWL' = 'HWL',
    /** BABY DOGE INU */
    '$BABYDOGEINU' = '$BABYDOGEINU',
    /** Strite */
    'STRI' = 'STRI',
    /** Grok Queen */
    'GROKQUEEN' = 'GROKQUEEN',
    /** Blaze */
    'BLAZE' = 'BLAZE',
    /** BALI TOKEN */
    'BLI' = 'BLI',
    /** ShibCEO */
    'SHIBCEO' = 'SHIBCEO',
    /** Shib Generating */
    'SHG' = 'SHG',
    /** Dancing Lizard Coin */
    'LIZD' = 'LIZD',
    /** Elon404 */
    'ELON404' = 'ELON404',
    /** KRYZA Network */
    'KRN' = 'KRN',
    /** Rising Sun */
    'SUN' = 'SUN',
    /** Melo Token */
    'MELO' = 'MELO',
    /** Red Pepe */
    'REDPEPE' = 'REDPEPE',
    /** bHIVE */
    'BHIVE' = 'BHIVE',
    /** GreenGold */
    'GNG' = 'GNG',
    /** GeoLeaf (new) */
    'GLT' = 'GLT',
    /** Newsolution2.0 */
    'NSTE' = 'NSTE',
    /** Bomb Money */
    'BSHARE' = 'BSHARE',
    /** DFS MAFIA (V2) */
    'DFSM' = 'DFSM',
    /** Shiny Ore */
    'SO' = 'SO',
    /** Tom Coin */
    'TMC' = 'TMC',
    /** WEB5 Inu */
    'WEB5' = 'WEB5',
    /** MetaBomb */
    'MTB' = 'MTB',
    /** MetaUFO */
    'METAUFO' = 'METAUFO',
    /** KINGU */
    'KINGU' = 'KINGU',
    /** AmpleSwap (new) */
    'AMPLE' = 'AMPLE',
    /** Suteku */
    'SUTEKU' = 'SUTEKU',
    /** Adroverse */
    'ADR' = 'ADR',
    /** BOOK OF DOGS */
    'BODO' = 'BODO',
    /** PEPEPi */
    'PEPEPI' = 'PEPEPI',
    /** POTENT */
    'PTT' = 'PTT',
    /** Yearn Classic Finance */
    'EARN' = 'EARN',
    /** Topshelf Finance */
    'LIQR' = 'LIQR',
    /** PEPEFLOKI */
    'PEPEF' = 'PEPEF',
    /** GamingShiba */
    'GAMINGSHIBA' = 'GAMINGSHIBA',
    /** GIANT */
    'GTAN' = 'GTAN',
    /** King WIF */
    'KINGWIF' = 'KINGWIF',
    /** Dominant Kong */
    'DK' = 'DK',
    /** BNBFROG */
    'BNBFROG' = 'BNBFROG',
    /** DELOT.IO */
    'DELOT' = 'DELOT',
    /** CMC Coin */
    'CMCC' = 'CMCC',
    /** HONK */
    'HONK' = 'HONK',
    /** Wanaka Farm WAIRERE Token */
    'WAI' = 'WAI',
    /** iAssets */
    'ASSET' = 'ASSET',
    /** Metakings */
    'MTK' = 'MTK',
    /** NOSHIT */
    'NSH' = 'NSH',
    /** VENOM */
    'VENOMAI' = 'VENOMAI',
    /** BURNZ */
    'BURNZ' = 'BURNZ',
    /** 2024 */
    '#2024' = '2024',
    /** Cage Match */
    'MUSKVSZUCK' = 'MUSKVSZUCK',
    /** AI Floki */
    'AIFLOKI' = 'AIFLOKI',
    /** PumpETH */
    'PETH' = 'PETH',
    /** MoonStar */
    'MOONSTAR' = 'MOONSTAR',
    /** Safereum (BSC) */
    'SAFEREUM' = 'SAFEREUM',
    /** Subawu Token */
    'SUBAWU' = 'SUBAWU',
    /** BlockAura */
    'TBAC' = 'TBAC',
    /** Catena X */
    'CEX' = 'CEX',
    /** BearAI */
    'BAI' = 'BAI',
    /** Solar Energy */
    'SEG' = 'SEG',
    /** Pocoland */
    'POCO' = 'POCO',
    /** SDAO */
    'SDAO' = 'SDAO',
    /** LuckyChip */
    'LC' = 'LC',
    /** PeePee */
    'PP' = 'PP',
    /** YUMMY */
    'YUMMY' = 'YUMMY',
    /** Vention */
    'VENTION' = 'VENTION',
    /** CuanSwap.com */
    'CUAN' = 'CUAN',
    /** samoydecoin (BSC) */
    'SAMO' = 'SAMO',
    /** Surviving Soldiers */
    'SSG' = 'SSG',
    /** BAO */
    'BAO' = 'BAO',
    /** Tao Te Ching */
    'TTC' = 'TTC',
    /** StickMan */
    'STIC' = 'STIC',
    /** DINGO TOKEN (old) */
    'DINGO' = 'DINGO',
    /** BabyFlokiZilla */
    'BABYFLOKIZILLA' = 'BABYFLOKIZILLA',
    /** 1Move */
    '1MT' = '1MT',
    /** ALL BEST ICO SATOSHI */
    'SATSALL' = 'SATSALL',
    /** Pepe 2.0 */
    'PEPE2.0' = 'PEPE2.0',
    /** DogeZilla V2 */
    'DOGEZILLA' = 'DOGEZILLA',
    /** pSTAKE Staked BNB */
    'STKBNB' = 'STKBNB',
    /** PinkSale */
    'PINKSALE' = 'PINKSALE',
    /** Immutable */
    'DARA' = 'DARA',
    /** HyperVerse */
    'HVT' = 'HVT',
    /** CakeWSwap */
    'CAKEW' = 'CAKEW',
    /** DecentralFree */
    'FREELA' = 'FREELA',
    /** Lovelace World */
    'LACE' = 'LACE',
    /** Shiba Inu Pay */
    'SHIBAY' = 'SHIBAY',
    /** FirstHare */
    'FirstHare' = 'FirstHare',
    /** CluCoin */
    'CLU' = 'CLU',
    /** Philcoin */
    'PHL' = 'PHL',
    /** SpaceGrime */
    'GRIMEX' = 'GRIMEX',
    /** Yellow Pepe */
    'YEPE' = 'YEPE',
    /** OMarket Global LLC */
    'OPV' = 'OPV',
    /** BitNautic Token */
    'BTNTV2' = 'BTNTV2',
    /** MUSK MEME */
    'MUSKMEME' = 'MUSKMEME',
    /** Crypto Ai */
    'CAI' = 'CAI',
    /** Dubai Cat */
    'DUBAICAT' = 'DUBAICAT',
    /** Moomonster */
    'MOO' = 'MOO',
    /** bHBD */
    'BHBD' = 'BHBD',
    /** Peachy */
    'PEACHY' = 'PEACHY',
    /** Tribalisland game Token */
    'TIC' = 'TIC',
    /** Doge Floki Coin */
    'DOFI' = 'DOFI',
    /** Safegem */
    'GEMS' = 'GEMS',
    /** MiniBNBTiger */
    'MINIBNBTIGER' = 'MINIBNBTIGER',
    /** Moox Protocol */
    'MOOX' = 'MOOX',
    /** BNBDragon */
    'BNBDRGN' = 'BNBDRGN',
    /** Yuse Token */
    'YUSE' = 'YUSE',
    /** Hugo Inu */
    'HUGO' = 'HUGO',
    /** PEPE DAO */
    'PEPED' = 'PEPED',
    /** Dinosaur Inu */
    'DINO' = 'DINO',
    /** Multiverse */
    'MVS' = 'MVS',
    /** OSK DAO */
    'OSK-DAO' = 'OSK-DAO',
    /** King Trump */
    'KINGTRUMP' = 'KINGTRUMP',
    /** Happy Pepe BNB */
    'HPYPEPE' = 'HPYPEPE',
    /** HUNNY FINANCE */
    'HUNNY' = 'HUNNY',
    /** OSL AI */
    'OSL' = 'OSL',
    /** NFTL */
    'NFTL' = 'NFTL',
    /** Qubit */
    'QBT' = 'QBT',
    /** Navis */
    'NVS' = 'NVS',
    /** Useless (V3) */
    'USE' = 'USE',
    /** Wally */
    'WALLY' = 'WALLY',
    /** Green Climate World */
    'WGC' = 'WGC',
    /** Frog Bsc */
    'FROG' = 'FROG',
    /** MetaFame */
    'BMF' = 'BMF',
    /** BLOCK GALAXY NETWORK */
    'GLAX' = 'GLAX',
    /** Snap Token */
    'SNAP' = 'SNAP',
    /** Atlantis */
    'ATLAS' = 'ATLAS',
    /** Meblox Protocol */
    'MEB' = 'MEB',
    /** BlockSAFU */
    'BSAFU' = 'BSAFU',
    /** Galaxy War */
    'GWT' = 'GWT',
    /** LeisurePay */
    'LPY' = 'LPY',
    /** Meme ETF */
    'MEMEETF' = 'MEMEETF',
    /** Whale Exploder */
    'WHEX' = 'WHEX',
    /** AlphaDEX */
    'ROAR' = 'ROAR',
    /** Pepe 2.0 */
    'PEPE 2.0' = 'PEPE 2.0',
    /** Bogged */
    'BOG' = 'BOG',
    /** Bored Coin */
    'BRD' = 'BRD',
    /** Pink BNB */
    'PNB' = 'PNB',
    /** PEPEMOON */
    'PEPEMOON' = 'PEPEMOON',
    /** CoinRadr */
    'RADR' = 'RADR',
    /** Burnny Inu */
    'BURNNY' = 'BURNNY',
    /** Xmas Grok */
    'XMAS GROK' = 'XMAS GROK',
    /** PEPEARMY */
    'PEPEARMY' = 'PEPEARMY',
    /** BenTen Inu */
    'BENT' = 'BENT',
    /** Surf Live */
    'SURF' = 'SURF',
    /** MiniDOGE */
    'MINIDOGE' = 'MINIDOGE',
    /** Pepe Classic */
    'PEPC' = 'PEPC',
    /** Catvills Coin */
    'CATVILLS' = 'CATVILLS',
    /** La Peseta */
    'PTAS' = 'PTAS',
    /** PepVerse */
    'PEPVERS' = 'PEPVERS',
    /** Little Angry Bunny v2 */
    'LAB v2' = 'LAB v2',
    /** Kaby Gaming Token */
    'KGT' = 'KGT',
    /** GrokBoy */
    'GROKBOY' = 'GROKBOY',
    /** Insurabler */
    'INSR' = 'INSR',
    /** BlueBenx */
    'BENX' = 'BENX',
    /** Xrpcashone */
    'XCE' = 'XCE',
    /** CZodiac Farming Token */
    'CZF' = 'CZF',
    /** MoonLift Capital */
    'MLTPX' = 'MLTPX',
    /** OXM Protocol */
    'OXM' = 'OXM',
    /** Phoenix Dragon */
    'PDRAGON' = 'PDRAGON',
    /** Minions INU */
    'MINION' = 'MINION',
    /** Pyramid */
    'PYRAMID' = 'PYRAMID',
    /** Golden Paws */
    'GPAWS' = 'GPAWS',
    /** Evodefi */
    'GENX' = 'GENX',
    /** USD mars */
    'USDm' = 'USDm',
    /** Zeppelin DAO */
    'ZEP' = 'ZEP',
    /** Sandwich Network */
    'SANDWICH' = 'SANDWICH',
    /** GROK heroes */
    'GROKHEROES' = 'GROKHEROES',
    /** Binance Smart Chain Girl */
    'BSCGIRL' = 'BSCGIRL',
    /** SOLCash */
    'SOLCASH' = 'SOLCASH',
    /** HELPER COIN */
    'HLPR' = 'HLPR',
    /** Agricoin */
    'AGX' = 'AGX',
    /** PepeFork INU */
    'PORKINU' = 'PORKINU',
    /** Joe-Yo Coin */
    'JYC' = 'JYC',
    /** GenomicDao G-Stroke */
    'PCSP' = 'PCSP',
    /** CZshares */
    'CZSHARES' = 'CZSHARES',
    /** All In AI */
    'AIAI' = 'AIAI',
    /** Girlfriend */
    '$GF' = '$GF',
    /** Walter Inu */
    '$WINU' = '$WINU',
    /** The Parallel */
    'PRL' = 'PRL',
    /** Vegas Inu Token */
    'VIT' = 'VIT',
    /** Sishi Finance */
    'SISHI' = 'SISHI',
    /** WHITEX */
    'WHX' = 'WHX',
    /** Gem Guardian */
    'GEMG' = 'GEMG',
    /** Hokkaido Inu */
    'HOKA' = 'HOKA',
    /** CRED COIN PAY */
    'CRED' = 'CRED',
    /** Bogey */
    'BOGEY' = 'BOGEY',
    /** Dives Defi */
    'DDF' = 'DDF',
    /** HorizonDollar */
    'HZD' = 'HZD',
    /** BabySweep */
    'BABYSWEEP' = 'BABYSWEEP',
    /** CATCEO */
    'CATCEO' = 'CATCEO',
    /** Shibking Inu */
    'SHIBKING' = 'SHIBKING',
    /** Lego Pepe Vision */
    '$LPV' = '$LPV',
    /** Add Finance */
    'ADD' = 'ADD',
    /** UNITED EMIRATE DECENTRALIZED COIN. */
    'UEDC' = 'UEDC',
    /** Pepa Inu 2.0 */
    'PEPA 2.0' = 'PEPA 2.0',
    /** ElonTech */
    'ETCH' = 'ETCH',
    /** Rabbit INU */
    'RBIT' = 'RBIT',
    /** ORDI 2.0 */
    'ORDI2' = 'ORDI2',
    /** Pink Panther Lovers */
    'PPL' = 'PPL',
    /** SHIBCAT */
    'SHIBCAT' = 'SHIBCAT',
    /** Equilibrium */
    'EDX' = 'EDX',
    /** API INU */
    'API' = 'API',
    /** Txbit Token */
    'TXBIT' = 'TXBIT',
    /** ADACash */
    'ADACASH' = 'ADACASH',
    /** Green Block Token */
    'GBT' = 'GBT',
    /** PulseAI */
    'PULSE' = 'PULSE',
    /** GroKKy */
    'GROKKY' = 'GROKKY',
    /** King Grok */
    'KINGGROK' = 'KINGGROK',
    /** FLOKI SHIBA PEPE CEO */
    '3CEO' = '3CEO',
    /** 2G CARBON COIN */
    '2GCC' = '2GCC',
    /** Vancat */
    'VANCAT' = 'VANCAT',
    /** SnowCrash Token */
    'NORA' = 'NORA',
    /** Kamaleont */
    'KLT' = 'KLT',
    /** Grok Moon */
    'GROKMOON' = 'GROKMOON',
    /** PayRue (Propel) */
    'PROPEL' = 'PROPEL',
    /** DsunDAO */
    'DSUN' = 'DSUN',
    /** PEPEKING */
    'PEPEKING' = 'PEPEKING',
    /** AutoShark DEX */
    'FINS' = 'FINS',
    /** Traders coin */
    'TRDC' = 'TRDC',
    /** Royal Gold */
    'RGOLD' = 'RGOLD',
    /** Posschain */
    'POSS' = 'POSS',
    /** AquaGoat.Finance */
    'AQUAGOAT' = 'AQUAGOAT',
    /** GG Token */
    'GGTKN' = 'GGTKN',
    /** SMP Finance */
    'SMPF' = 'SMPF',
    /** EZZY GAME */
    'EZY' = 'EZY',
    /** EZZY GAME GEZY */
    'GEZY' = 'GEZY',
    /** CryptoBlades Kingdoms */
    'KING' = 'KING',
    /** PinHub */
    'PUB' = 'PUB',
    /** Vagabond */
    'VGO' = 'VGO',
    /** BitOrbit */
    'BITORB' = 'BITORB',
    /** Snaps */
    'SNPS' = 'SNPS',
    /** TRDGtoken (BSC) */
    'TRDG' = 'TRDG',
    /** FRLONGTOKEN */
    'FRLONG' = 'FRLONG',
    /** Story */
    'STORY' = 'STORY',
    /** Swinca */
    'SWI' = 'SWI',
    /** SeamlessSwap */
    'SEAMLESS' = 'SEAMLESS',
    /** Wolf Town Wool */
    'WTWOOL' = 'WTWOOL',
    /** BabyDoge CEO */
    'BCEO' = 'BCEO',
    /** MMSC PLATFORM */
    'MMSC' = 'MMSC',
    /** SMILE */
    'SMILE' = 'SMILE',
    /** GLOBALTRUSTFUND TOKEN */
    'GTF' = 'GTF',
    /** Endless Board Game */
    'ENG' = 'ENG',
    /** FolgoryUSD */
    'USDF' = 'USDF',
    /** Nyantereum International */
    'NYANTE' = 'NYANTE',
    /** Kiwi Finance */
    'KIWI' = 'KIWI',
    /** Jetfuel Finance */
    'FUEL' = 'FUEL',
    /** Daily */
    'DAILY' = 'DAILY',
    /** BerrySwap */
    'BERRY' = 'BERRY',
    /** Realfinance Network */
    'REFI' = 'REFI',
    /** Swampy */
    'SWAMP' = 'SWAMP',
    /** Porta */
    'KIAN' = 'KIAN',
    /** Donkey */
    'DONK' = 'DONK',
    /** CherrySwap */
    'CHE' = 'CHE',
    /** CroxSwap */
    'CROX' = 'CROX',
    /** ZionLabs Token */
    'RASTA' = 'RASTA',
    /** Fortress Lending */
    'FTS' = 'FTS',
    /** Dot Finance */
    'PINK' = 'PINK',
    /** Greenheart CBD */
    'CBD' = 'CBD',
    /** Vanity */
    'VNY' = 'VNY',
    /** RFOX Finance */
    'VFOX' = 'VFOX',
    /** Black Eye Galaxy */
    'BYG' = 'BYG',
    /** wShiba */
    'WSHIB' = 'WSHIB',
    /** Koji */
    'KOJI' = 'KOJI',
    /** CoinSwap Space */
    'CSS' = 'CSS',
    /** Jetswap.finance */
    'WINGS' = 'WINGS',
    /** NEFTiPEDiA */
    'NFT' = 'NFT',
    /** Yield Parrot */
    'LORY' = 'LORY',
    /** BabyBitcoin */
    'BABYBITC' = 'BABYBITC',
    /** RedFEG */
    'REDFEG' = 'REDFEG',
    /** Nobility */
    'NBL' = 'NBL',
    /** Nuketoken */
    'NUKE' = 'NUKE',
    /** Moonie NFT */
    'MNY' = 'MNY',
    /** Kaiken Shiba */
    'KSHIB' = 'KSHIB',
    /** Minimals */
    'MMS' = 'MMS',
    /** Starmon Metaverse */
    'SMON' = 'SMON',
    /** Sona Network */
    'SONA' = 'SONA',
    /** YoCoin */
    'YOCO' = 'YOCO',
    /** Hummingbird Egg */
    'HEGG' = 'HEGG',
    /** RugZombie */
    'ZMBE' = 'ZMBE',
    /** Portify */
    'PFY' = 'PFY',
    /** XTRA Token */
    'XTRA' = 'XTRA',
    /** Baby Floki Billionaire */
    'BabyFB' = 'BabyFB',
    /** StarSharks SEA */
    'SEA' = 'SEA',
    /** Karus Starter */
    'KST' = 'KST',
    /** Ideanet Token */
    'INET' = 'INET',
    /** Piggy Finance */
    'PIGGY' = 'PIGGY',
    /** Red Floki */
    'REDFLOKI' = 'REDFLOKI',
    /** Treat DAO [new] */
    'TREAT' = 'TREAT',
    /** Graphene */
    'GFN' = 'GFN',
    /** PAPPAY */
    'PAPPAY' = 'PAPPAY',
    /** Cpos Cloud Payment */
    'CPOS' = 'CPOS',
    /** FastSwap (BSC) */
    'FAST' = 'FAST',
    /** SquidGameToken */
    'SGT' = 'SGT',
    /** CashCow */
    'COW' = 'COW',
    /** BabyDogeZilla */
    'BABYDOGEZILLA' = 'BABYDOGEZILLA',
    /** ShibaZilla2.0 (old) */
    'SBZ2.0' = 'SBZ2.0',
    /** Afrostar */
    'AFRO' = 'AFRO',
    /** Cockapoo */
    'CPOO' = 'CPOO',
    /** Bunscake */
    'BSCAKE' = 'BSCAKE',
    /** Santa Coin */
    'SANTA' = 'SANTA',
    /** RIFI United */
    'RU' = 'RU',
    /** ZUNA */
    'ZUNA' = 'ZUNA',
    /** nSights DeFi Trader */
    'NSI' = 'NSI',
    /** MetaDoge V2 */
    'METADOGEV2' = 'METADOGEV2',
    /** AVNRich Token */
    'AVN' = 'AVN',
    /** FIA Protocol */
    'FIA' = 'FIA',
    /** Diamond DND */
    'DND' = 'DND',
    /** ADToken */
    'AD' = 'AD',
    /** Shiba Interstellar */
    'SHINT' = 'SHINT',
    /** QuizDrop */
    'QDROP' = 'QDROP',
    /** Shiba Inu Empire */
    'SHIBEMP' = 'SHIBEMP',
    /** Dali */
    'DALI' = 'DALI',
    /** HappyLand */
    'HPL' = 'HPL',
    /** HashLand Coin */
    'HC' = 'HC',
    /** SappChat */
    'APP' = 'APP',
    /** Microverse */
    'MVP' = 'MVP',
    /** Respan */
    'RSPN' = 'RSPN',
    /** Koda Cryptocurrency */
    'KODA' = 'KODA',
    /** CatBonk */
    'CABO' = 'CABO',
    /** RPS LEAGUE */
    'RPS' = 'RPS',
    /** Autobusd */
    'ABS' = 'ABS',
    /** BunnyPark Game */
    'BG' = 'BG',
    /** Metavice */
    'SERVE' = 'SERVE',
    /** Baby Lovely Inu */
    'BLOVELY' = 'BLOVELY',
    /** CollectCoin */
    'CLCT' = 'CLCT',
    /** LocalTrade */
    'LTT' = 'LTT',
    /** Multiverse Capital */
    'MVC' = 'MVC',
    /** Peoplez */
    'LEZ' = 'LEZ',
    /** Defi Coin */
    'DEFC' = 'DEFC',
    /** WoopMoney */
    'WMW' = 'WMW',
    /** World of Legends */
    'WOL' = 'WOL',
    /** BIZVERSE */
    'BIVE' = 'BIVE',
    /** GFORCE */
    'GFCE' = 'GFCE',
    /** Cryptotem */
    'TOTEM' = 'TOTEM',
    /** FILIPCOIN */
    'FCP' = 'FCP',
    /** TATA Coin */
    'TATA' = 'TATA',
    /** The Wasted Lands */
    'WAL' = 'WAL',
    /** Crypto Fight Club */
    'FIGHT' = 'FIGHT',
    /** Rise of Defenders */
    'RDR' = 'RDR',
    /** Power Cash */
    'PRCH' = 'PRCH',
    /** KING FOREVER */
    'KFR' = 'KFR',
    /** TravGoPV */
    'TPV' = 'TPV',
    /** Bot Planet */
    'BOT' = 'BOT',
    /** CakeSwap */
    'CAKESWAP' = 'CAKESWAP',
    /** Conjee */
    'CONJ' = 'CONJ',
    /** Exobots */
    'EXOS' = 'EXOS',
    /** FADO Go */
    'FADO' = 'FADO',
    /** ZAT Project */
    'ZPRO' = 'ZPRO',
    /** OUSE Token */
    'OUSE' = 'OUSE',
    /** BAHA */
    'BA' = 'BA',
    /** France REV Finance */
    'FRF' = 'FRF',
    /** SHIBIC */
    'SHIBIC' = 'SHIBIC',
    /** TopManager */
    'TMT' = 'TMT',
    /** GamiFi.GG */
    'GMI' = 'GMI',
    /** Puli */
    'PULI' = 'PULI',
    /** Eagle Token */
    'EAGLE' = 'EAGLE',
    /** King Dog Inu */
    'KINGDOG' = 'KINGDOG',
    /** Gunstar Metaverse Currency */
    'GSC' = 'GSC',
    /** Dogecolony */
    'DOGECO' = 'DOGECO',
    /** Galatic Kitty Fighters */
    'GKF' = 'GKF',
    /** Value Finance */
    'VFT' = 'VFT',
    /** EarthByt */
    'EBYT' = 'EBYT',
    /** Fenomy */
    'FENOMY' = 'FENOMY',
    /** Robot Warriors */
    'METABOT' = 'METABOT',
    /** TOURIST SHIBA INU */
    'TOURISTS' = 'TOURISTS',
    /** AppleSwap */
    'APPLE' = 'APPLE',
    /** Forus */
    'FORS' = 'FORS',
    /** NFT11 */
    'NFT11' = 'NFT11',
    /** PlatinX */
    'PTX' = 'PTX',
    /** MetaSwap */
    'MSC' = 'MSC',
    /** Teneo */
    'TEN' = 'TEN',
    /** MetaRim */
    'RIM' = 'RIM',
    /** Santa Floki v2.0 */
    'HOHOHO V2.0' = 'HOHOHO V2.0',
    /** FantomPAD */
    'FPAD' = 'FPAD',
    /** Covid Cutter */
    'CVC' = 'CVC',
    /** Hare Plus */
    'HARE PLUS' = 'HARE PLUS',
    /** Nekocoin */
    'NEKOS' = 'NEKOS',
    /** Zoro Inu */
    'ZORO' = 'ZORO',
    /** Food Bank */
    'FOOD' = 'FOOD',
    /** WOLFCOIN */
    'WOLF' = 'WOLF',
    /** ARize */
    'ARZ' = 'ARZ',
    /** Dpad Finance */
    'DPAD' = 'DPAD',
    /** Frz Solar System */
    'FRZSS' = 'FRZSS',
    /** MetaTariffv3 */
    'RIF3' = 'RIF3',
    /** Space Corsair Key */
    'SCK' = 'SCK',
    /** Mooner */
    'MNR' = 'MNR',
    /** GoldeFy */
    'GOD' = 'GOD',
    /** Calvex */
    'CLVX' = 'CLVX',
    /** Samurai Legends */
    'SMG' = 'SMG',
    /** CryptoMines Reborn */
    'CRUX' = 'CRUX',
    /** Cylum Finance */
    'CYM' = 'CYM',
    /** UpDeFi */
    'UP' = 'UP',
    /** Two Monkey Juice Bar */
    'TMON' = 'TMON',
    /** MLOKY */
    'MLOKY' = 'MLOKY',
    /** TiraVerse */
    'TVRS' = 'TVRS',
    /** Vulcano */
    'VULC' = 'VULC',
    /** CATpay */
    'CATPAY' = 'CATPAY',
    /** Vitteey */
    'VITY' = 'VITY',
    /** MetaToll */
    'TAX' = 'TAX',
    /** MetaContinental */
    'CON' = 'CON',
    /** Zirve Coin */
    'ZIRVE' = 'ZIRVE',
    /** Apple */
    'AMB' = 'AMB',
    /** Sanji Inu */
    'SANJI' = 'SANJI',
    /** Zenc Coin */
    'ZENC' = 'ZENC',
    /** World of Masters */
    'WOFM' = 'WOFM',
    /** Galaxy Heroes */
    'GHC' = 'GHC',
    /** Firework Games */
    'FIRE' = 'FIRE',
    /** panKUKU */
    'KUKU' = 'KUKU',
    /** Numitor */
    'NUMI' = 'NUMI',
    /** BelecX Protocol */
    'BEX' = 'BEX',
    /** Million Dollar Baby */
    'MDB' = 'MDB',
    /** StepG */
    'STEPG' = 'STEPG',
    /** Ezillion */
    'EZI' = 'EZI',
    /** X-Metaverse */
    'XMETA' = 'XMETA',
    /** ShinobiVerse */
    'SHINO' = 'SHINO',
    /** Walk To Earn */
    'W2E' = 'W2E',
    /** Sports 2K75 */
    'S2K' = 'S2K',
    /** Coin Gabbar Token */
    'CGT' = 'CGT',
    /** VizslaSwap */
    'VIZSLASWAP' = 'VIZSLASWAP',
    /** HyperOne */
    'HOT' = 'HOT',
    /** OLOID */
    'OLOID' = 'OLOID',
    /** Metagamble */
    'GAMBL' = 'GAMBL',
    /** Yoda Coin Swap */
    'JEDALS' = 'JEDALS',
    /** Plant Empires */
    'PEFI' = 'PEFI',
    /** Source Token */
    'SRCX' = 'SRCX',
    /** LooksCoin */
    'LOOK' = 'LOOK',
    /** Mudra MDR */
    'MDR' = 'MDR',
    /** Artificial Intelligence Technology Network */
    'AITN' = 'AITN',
    /** Wateenswap */
    'WTN' = 'WTN',
    /** Optimus */
    'OPT' = 'OPT',
    /** NBOX */
    'NBOX' = 'NBOX',
    /** SOULS OF META */
    'SOM' = 'SOM',
    /** Lunatics */
    'LUNAT' = 'LUNAT',
    /** Lulu Market */
    'LUCK' = 'LUCK',
    /** EcoSmart */
    'ECG' = 'ECG',
    /** Metahamster */
    'MHAM' = 'MHAM',
    /** Chihuahua Token */
    'CHH' = 'CHH',
    /** Future Of Fintech */
    'FOF' = 'FOF',
    /** Poochain */
    'POOP' = 'POOP',
    /** Lucky Coin */
    'LKC' = 'LKC',
    /** Telegram Inu */
    'TINU' = 'TINU',
    /** Genesis Universe */
    'GUT' = 'GUT',
    /** Decode Coin */
    'DECODE' = 'DECODE',
    /** LOGOS */
    'LOG' = 'LOG',
    /** Shanum */
    'SHAN' = 'SHAN',
    /** BabyApeFunClub */
    'BAFC' = 'BAFC',
    /** NEWTOWNGAMING */
    'NTG' = 'NTG',
    /** Stylike Governance */
    'STYL' = 'STYL',
    /** NSKSwap */
    'NSK' = 'NSK',
    /** Smart World Union */
    'SWU' = 'SWU',
    /** Moonions */
    'MOONION' = 'MOONION',
    /** NXD Next */
    'NXDT' = 'NXDT',
    /** Presale.World */
    'PRESALE' = 'PRESALE',
    /** Karbun */
    'KBC' = 'KBC',
    /** Fame Reward Plus */
    'FRP' = 'FRP',
    /** Global Innovative Solutions */
    'GSI' = 'GSI',
    /** SoccerInu */
    'SOCCER' = 'SOCCER',
    /** ECLAT */
    'ELT' = 'ELT',
    /** Maximus Coin */
    'MXZ' = 'MXZ',
    /** BlockRock */
    'BRO$' = 'BRO$',
    /** Hayya Qatar */
    'HQR' = 'HQR',
    /** Winterdog */
    'WDOG' = 'WDOG',
    /** NUTGAIN */
    'NUTGV2' = 'NUTGV2',
    /** Lucky Roo */
    'ROO' = 'ROO',
    /** JackpotDoge */
    'JPD' = 'JPD',
    /** MetaSetGO */
    'MSGO' = 'MSGO',
    /** Upload Token */
    'UPLOAD' = 'UPLOAD',
    /** ZFMCOIN */
    'ZFM' = 'ZFM',
    /** Forever Burn */
    'FBURN' = 'FBURN',
    /** Punk Panda Messenger */
    'PPM' = 'PPM',
    /** Bitratoken */
    'BITRA' = 'BITRA',
    /** Setter Protocol */
    'SET' = 'SET',
    /** MxmBoxcEus Token */
    'MBE' = 'MBE',
    /** Homie Wars */
    'HOMIECOIN' = 'HOMIECOIN',
    /** ETXInfinity */
    'ETX' = 'ETX',
    /** DYZilla */
    'DYZILLA' = 'DYZILLA',
    /** ShopNEXT */
    'STE' = 'STE',
    /** Bitcointry Token */
    'BTTY' = 'BTTY',
    /** Jimizz */
    'JMZ' = 'JMZ',
    /** Defi Connect (new) */
    'DFC' = 'DFC',
    /** Control2XY */
    'CTRL2XY' = 'CTRL2XY',
    /** Vege Token */
    'VEGE' = 'VEGE',
    /** Meta Game City */
    'MECI' = 'MECI',
    /** Cryptegrity DAO */
    'ESCROW' = 'ESCROW',
    /** BSC PAYMENTS */
    'BSCPAY' = 'BSCPAY',
    /** FLOSHIDO INU */
    'FLOSHIDO' = 'FLOSHIDO',
    /** GlieseCoin */
    'GLC' = 'GLC',
    /** iFortune */
    'IFC' = 'IFC',
    /** Metapioneers */
    'MPI' = 'MPI',
    /** Belifex */
    'BEFX' = 'BEFX',
    /** Optimus Inu */
    'OPINU' = 'OPINU',
    /** Doge Ipa */
    'DIPA' = 'DIPA',
    /** ALIF COIN */
    'ALIF' = 'ALIF',
    /** DogeArmy */
    'DOGRMY' = 'DOGRMY',
    /** Utility Web3Shot */
    'UW3S' = 'UW3S',
    /** InteractWith */
    'INTER' = 'INTER',
    /** Frog Ceo */
    'FROGCEO' = 'FROGCEO',
    /** Generative GPT */
    'GGPT' = 'GGPT',
    /** NeoCortexAI */
    'nCORAI' = 'nCORAI',
    /** SincroniX */
    'SNX' = 'SNX',
    /** CCGDS */
    'CCGDS' = 'CCGDS',
    /** Viddli */
    'MTY' = 'MTY',
    /** StaFi Staked BNB */
    'RBNB' = 'RBNB',
    /** Yorkie Doge */
    'YDOGE' = 'YDOGE',
    /** Kang3n */
    'KANG3N' = 'KANG3N',
    /** REAL PEPE CEO */
    'PEPECEO' = 'PEPECEO',
    /** Fathom */
    'FATHOM' = 'FATHOM',
    /** Sell Token */
    'SELLC' = 'SELLC',
    /** Cremation Coin */
    'CREMAT' = 'CREMAT',
    /** CEO DOGE */
    'CEODOGE' = 'CEODOGE',
    /** Wrapped BESC */
    'WBESC' = 'WBESC',
    /** BIDZ Coin */
    'BIDZ' = 'BIDZ',
    /** Meta FPS */
    'MFPS' = 'MFPS',
    /** TruthGPT (BSC) */
    'TRUTH' = 'TRUTH',
    /** BlockChainGames */
    'BCG' = 'BCG',
    /** Layer Network */
    'LAYER' = 'LAYER',
    /** Red Floki CEO */
    'RedFlokiCEO' = 'RedFlokiCEO',
    /** Have Fun */
    'HF' = 'HF',
    /** SONGOKU */
    'SONGOKU' = 'SONGOKU',
    /** Pooh Inu */
    'POOH' = 'POOH',
    /** Bearium */
    'BRIUM' = 'BRIUM',
    /** FlokiPepe */
    'FLOKIPEPE' = 'FLOKIPEPE',
    /** CRAZY PEPE */
    'CRAZYPEPE' = 'CRAZYPEPE',
    /** PEPE Chain */
    'PEPECHAIN' = 'PEPECHAIN',
    /** PEPEZilla */
    'PEPEZILLA' = 'PEPEZILLA',
    /** Pepelon */
    'PEPELON' = 'PEPELON',
    /** MigMig Swap */
    'MIGMIG' = 'MIGMIG',
    /** MYCOWRIE */
    'COWRIE' = 'COWRIE',
    /** Wombex WOM */
    'WMXWOM' = 'WMXWOM',
    /** Frozy Inu */
    'FRZ' = 'FRZ',
    /** Hotel of Secrets */
    'HOS' = 'HOS',
    /** Stuart Inu */
    'STUART' = 'STUART',
    /** Automated Income Machine */
    'AIM' = 'AIM',
    /** Lunasphere */
    'LSPHERE' = 'LSPHERE',
    /** RhinoMars */
    'RhinoMars' = 'RhinoMars',
    /** The Wrong Token */
    'WRONG' = 'WRONG',
    /** LindaYacc Ceo */
    'LINDACEO' = 'LINDACEO',
    /** SpaceXDoge */
    'SDOGE' = 'SDOGE',
    /** Diamond Are Forever */
    'DAF' = 'DAF',
    /** Metafury */
    'FURYX' = 'FURYX',
    /** UTB.ai */
    'UTBAI' = 'UTBAI',
    /** Refund */
    'RFD' = 'RFD',
    /** Odesis */
    '$ODS' = '$ODS',
    /** RollerSwap */
    'ROLS' = 'ROLS',
    /** SoIGPT */
    'SGP' = 'SGP',
    /** Puppets Coin */
    'PUPPETS' = 'PUPPETS',
    /** HajiIni */
    'HINU' = 'HINU',
    /** Jasan Wellness */
    'JW' = 'JW',
    /** UXOS */
    'UXOS' = 'UXOS',
    /** Quadrillion Coin */
    'QDC' = 'QDC',
    /** pepeX */
    'PEPEX' = 'PEPEX',
    /** Pepe Grow */
    '$PG' = '$PG',
    /** PEPPA */
    'PEPPA' = 'PEPPA',
    /** 4REALZA COIN */
    '4RZ' = '4RZ',
    /** Pepeburn */
    'PEPEBURN' = 'PEPEBURN',
    /** ONE Token */
    'ONE' = 'ONE',
    /** HUNDRED */
    'HUNDRED' = 'HUNDRED',
    /** Pepe Grinch */
    'PEPEGRINCH' = 'PEPEGRINCH',
    /** Bananace */
    'NANA' = 'NANA',
    /** CryptoMoonShot */
    '$CMOON' = '$CMOON',
    /** EtherPOS */
    'ETPOS' = 'ETPOS',
    /** Archean */
    'ARCH' = 'ARCH',
    /** Finance Token */
    'FT TOKEN' = 'FT TOKEN',
    /** Soma Network */
    'SMA' = 'SMA',
    /** MR BOB COIN */
    'MRBOB' = 'MRBOB',
    /** RefundToken */
    'RFT' = 'RFT',
    /** Dinowars */
    'DINW' = 'DINW',
    /** DiveWallet Token */
    'DWT' = 'DWT',
    /** Pepe GEM AI */
    'PEPEGA' = 'PEPEGA',
    /** HongKong BTC bank */
    'HKB' = 'HKB',
    /** Snowmantastic */
    'SNOWMANTASTIC' = 'SNOWMANTASTIC',
    /** ROB */
    'ROB' = 'ROB',
    /** Ferzan */
    'FERZAN' = 'FERZAN',
    /** Garuda Coin */
    'GARU' = 'GARU',
    /** White Tiger Pixel */
    'WHTGRPXL' = 'WHTGRPXL',
    /** BabyAMA */
    'BAMA' = 'BAMA',
    /** BABYLTC */
    'BLTC' = 'BLTC',
    /** Cyrus Exchange */
    'CYRUS' = 'CYRUS',
    /** GOGU Coin */
    'GOGU' = 'GOGU',
    /** Tribalisland Token */
    'TIT' = 'TIT',
    /** DZD */
    'DZD' = 'DZD',
    /** Jesus-Raptor */
    'RAPTOR' = 'RAPTOR',
    /** Web3 Whales */
    'W3W' = 'W3W',
    /** X Project */
    'X-TOKEN' = 'X-TOKEN',
    /** Luigi Inu */
    'LUIGI' = 'LUIGI',
    /** BossBaby */
    'BOSSBABY' = 'BOSSBABY',
    /** Spider Man */
    'Spider' = 'Spider',
    /** Incorgnito */
    'INCORGNITO' = 'INCORGNITO',
    /** Safari Crush */
    'SFRC' = 'SFRC',
    /** WARIO COIN */
    'WCOIN' = 'WCOIN',
    /** Nekoin */
    'NEKOIN' = 'NEKOIN',
    /** Bullpepe */
    'BULLPEPE' = 'BULLPEPE',
    /** Pepe 3.0 */
    'PEPE3.0' = 'PEPE3.0',
    /** BSCBURN */
    'BSCBURN' = 'BSCBURN',
    /** Beagle Inu */
    'BEA' = 'BEA',
    /** TradeX AI */
    'TRADEX' = 'TRADEX',
    /** NexBox */
    'NEXBOX' = 'NEXBOX',
    /** Sorcery */
    'SOR' = 'SOR',
    /** Real Smurf Cat (BSC) */
    'ШАЙЛУШАЙ' = 'ШАЙЛУШАЙ',
    /** GreenEnvCoalition */
    'GEC' = 'GEC',
    /** Crypto News Flash AI */
    'CNF' = 'CNF',
    /** Happy Train */
    'HTR' = 'HTR',
    /** Froggy */
    'FROGGY' = 'FROGGY',
    /** NBA BSC */
    'NBABSC' = 'NBABSC',
    /** Weavers Token */
    'VRS' = 'VRS',
    /** DOGE-1SATELLITE */
    'DOGE-1SAT' = 'DOGE-1SAT',
    /** Minelab */
    'MELB' = 'MELB',
    /** ArtDraw */
    'ARTDRAW' = 'ARTDRAW',
    /** Rencom Network */
    'RNT' = 'RNT',
    /** BNB Whales */
    'BNB WHALES' = 'BNB WHALES',
    /** PawStars */
    'PAWS' = 'PAWS',
    /** GROK 2.0 */
    'GROK2' = 'GROK2',
    /** Gojo Coin */
    'GOJOCOIN' = 'GOJOCOIN',
    /** Diamond Token */
    'DNT' = 'DNT',
    /** Froge */
    'FROGE' = 'FROGE',
    /** Grok X Ai */
    'GROK X AI' = 'GROK X AI',
    /** Droggy */
    'DROGGY' = 'DROGGY',
    /** Santa Grok */
    'SANTAGROK' = 'SANTAGROK',
    /** GTA VI */
    'GTA6' = 'GTA6',
    /** SafeGrok */
    'SAFEGROK' = 'SAFEGROK',
    /** SmartMEME */
    'SMARTMEME' = 'SMARTMEME',
    /** Day of Defeat Mini 100x */
    'DOD100' = 'DOD100',
    /** NAZAR PROTOCOL */
    'NAZAR' = 'NAZAR',
    /** Baby Bob */
    'BABYBOB' = 'BABYBOB',
    /** Bonkinu */
    'BONKINU' = 'BONKINU',
    /** GROKolaus */
    'GROKOLAUS' = 'GROKOLAUS',
    /** JOJO */
    '$JOJO' = '$JOJO',
    /** GPT-1 ALLBESTICO */
    'GPT-1' = 'GPT-1',
    /** Bonk Grok */
    'BONKGROK' = 'BONKGROK',
    /** PokeGROK */
    'POKEGROK' = 'POKEGROK',
    /** King Bonk */
    'KINGBONK' = 'KINGBONK',
    /** Trollheim */
    'TROLLHEIM' = 'TROLLHEIM',
    /** TYO GHOUL */
    'TYO GHOUL' = 'TYO GHOUL',
    /** MYROWIF */
    'MYROWIF' = 'MYROWIF',
    /** Mini Myro */
    'MINI MYRO' = 'MINI MYRO',
    /** JohnLennonC0IN */
    'BEATLES' = 'BEATLES',
    /** Payvertise */
    'PVT' = 'PVT',
    /** MYRO DRAGON */
    'MYRODRAGON' = 'MYRODRAGON',
    /** DADA */
    'DADA' = 'DADA',
    /** Baby Mickey */
    'BMICKEY' = 'BMICKEY',
    /** BabyBNB */
    'BABYBNB' = 'BABYBNB',
    /** Baby Honk */
    'BABYHONK' = 'BABYHONK',
    /** LONGFU */
    'LONGFU' = 'LONGFU',
    /** PONKE FORK */
    'PORKE' = 'PORKE',
    /** Xover */
    'XVR' = 'XVR',
    /** DogeFork */
    'DOGEFORK' = 'DOGEFORK',
    /** Cassie Dragon */
    'Cassie&#128009' = 'Cassie&#128009',
    /** BNB AI ALLBESTICO */
    'BNB-AI' = 'BNB-AI',
    /** Spidey Token */
    'SPIDEY' = 'SPIDEY',
    /** DUG */
    'DUG' = 'DUG',
    /** Honorarium */
    'HRM' = 'HRM',
    /** Saitama Inu */
    'SAITAMA' = 'SAITAMA',
    /** BNB VEGETA */
    'BNBVEGETA' = 'BNBVEGETA',
    /** TROLL MODE */
    'TROLLMODE' = 'TROLLMODE',
    /** SORA CEO */
    'SORACEO' = 'SORACEO',
    /** PEPE ETF */
    'PETF' = 'PETF',
    /** GROKSORAX */
    'GROKSORAX' = 'GROKSORAX',
    /** flokiwifhat */
    'FIF' = 'FIF',
    /** FLOKIMOON */
    'FLOKIMOON' = 'FLOKIMOON',
    /** Sugar Daddy */
    'SD' = 'SD',
    /** CRUDE OIL BRENT (Zedcex) */
    'OIL' = 'OIL',
    /** Litecoin */
    'LTC' = 'LTC',
    /** XRP */
    'XRP' = 'XRP',
    /** Ethereum Classic */
    'ETC' = 'ETC',
    /** Maker */
    'MKR' = 'MKR',
    /** EOS */
    'EOS' = 'EOS',
    /** TRON */
    'TRX' = 'TRX',
    /** Tezos */
    'XTZ' = 'XTZ',
    /** Filecoin */
    'FIL' = 'FIL',
    /** Fetch.ai */
    'FET' = 'FET',
    /** Cosmos */
    'ATOM' = 'ATOM',
    /** Polygon */
    'MATIC' = 'MATIC',
    /** Avalanche */
    'AVAX' = 'AVAX',
    /** Polkadot */
    'DOT' = 'DOT',
    /** Axie Infinity */
    'AXS' = 'AXS',
    /** Injective */
    'INJ' = 'INJ',
    /** Aave */
    'AAVE' = 'AAVE',
    /** AIOZ Network */
    'AIOZ' = 'AIOZ',
    /** Toncoin */
    'TON' = 'TON',
    /** First Digital USD */
    'FDUSD' = 'FDUSD',
    /** Beam */
    'BEAM' = 'BEAM',
    /** Zcash */
    'ZEC' = 'ZEC',
    /** Basic Attention Token */
    'BAT' = 'BAT',
    /** aelf */
    'ELF' = 'ELF',
    /** Zilliqa */
    'ZIL' = 'ZIL',
    /** Ontology */
    'ONT' = 'ONT',
    /** IoTeX */
    'IOTX' = 'IOTX',
    /** Terra Classic */
    'LUNC' = 'LUNC',
    /** PAX Gold */
    'PAXG' = 'PAXG',
    /** Compound */
    'COMP' = 'COMP',
    /** MANTRA */
    'OM' = 'OM',
    /** DeXe */
    'DEXE' = 'DEXE',
    /** Oasis Network */
    'ROSE' = 'ROSE',
    /** 1inch Network */
    '1INCH' = '1INCH',
    /** Raydium */
    'RAY' = 'RAY',
    /** Mask Network */
    'MASK' = 'MASK',
    /** Galxe */
    'GAL' = 'GAL',
    /** GMT */
    'GMT' = 'GMT',
    /** USDD */
    'USDD' = 'USDD',
    /** SPACE ID */
    'ID' = 'ID',
    /** Celer Network */
    'CELR' = 'CELR',
    /** Beldex */
    'BDX' = 'BDX',
    /** Prom */
    'PROM' = 'PROM',
    /** Band Protocol */
    'BAND' = 'BAND',
    /** Alchemy Pay */
    'ACH' = 'ACH',
    /** Baby Doge Coin */
    'BabyDoge' = 'BabyDoge',
    /** Coin98 */
    'C98' = 'C98',
    /** Delysium */
    'AGI' = 'AGI',
    /** Wrapped Beacon ETH */
    'WBETH' = 'WBETH',
    /** Zeebu */
    'ZBU' = 'ZBU',
    /** Bluzelle */
    'BLZ' = 'BLZ',
    /** MVL */
    'MVL' = 'MVL',
    /** Pax Dollar */
    'USDP' = 'USDP',
    /** WINkLink */
    'WIN' = 'WIN',
    /** inSure DeFi */
    'SURE' = 'SURE',
    /** Smooth Love Potion */
    'SLP' = 'SLP',
    /** TerraClassicUSD */
    'USTC' = 'USTC',
    /** Oraichain */
    'ORAI' = 'ORAI',
    /** Zignaly */
    'ZIG' = 'ZIG',
    /** Hashflow */
    'HFT' = 'HFT',
    /** Nexera */
    'NXRA' = 'NXRA',
    /** CyberConnect */
    'CYBER' = 'CYBER',
    /** KWD */
    'KWD' = 'KWD',
    /** Loom Network */
    'LOOM' = 'LOOM',
    /** ARPA */
    'ARPA' = 'ARPA',
    /** Telos */
    'TLOS' = 'TLOS',
    /** Shentu */
    'CTK' = 'CTK',
    /** MAP Protocol */
    'MAPO' = 'MAPO',
    /** Cream Finance */
    'CREAM' = 'CREAM',
    /** GamerCoin */
    'GHX' = 'GHX',
    /** Polkastarter */
    'POLS' = 'POLS',
    /** Everscale */
    'EVER' = 'EVER',
    /** Ankr Staked ETH */
    'ankrETH' = 'ankrETH',
    /** Bounce Token */
    'AUCTION' = 'AUCTION',
    /** MyNeighborAlice */
    'ALICE' = 'ALICE',
    /** Trias Token (New) */
    'TRIAS' = 'TRIAS',
    /** Dogelon Mars */
    'ELON' = 'ELON',
    /** Highstreet */
    'HIGH' = 'HIGH',
    /** RACA */
    'RACA' = 'RACA',
    /** Mines of Dalarnia */
    'DAR' = 'DAR',
    /** Phoenix */
    'PHB' = 'PHB',
    /** SIDUS */
    'SIDUS' = 'SIDUS',
    /** Maverick Protocol */
    'MAV' = 'MAV',
    /** MARBLEX */
    'MBX' = 'MBX',
    /** Komodo */
    'KMD' = 'KMD',
    /** Bitcoin Palladium */
    'BTCP' = 'BTCP',
    /** NULS */
    'NULS' = 'NULS',
    /** Streamr */
    'DATA' = 'DATA',
    /** Neom */
    'NEOM' = 'NEOM',
    /** MATH */
    'MATH' = 'MATH',
    /** Orion */
    'ORN' = 'ORN',
    /** DEXTools */
    'DEXT' = 'DEXT',
    /** Frontier */
    'FRONT' = 'FRONT',
    /** Meter Governance */
    'MTRG' = 'MTRG',
    /** DIA */
    'DIA' = 'DIA',
    /** Bella Protocol */
    'BEL' = 'BEL',
    /** CLV */
    'CLV' = 'CLV',
    /** Alien Worlds */
    'TLM' = 'TLM',
    /** Automata Network */
    'ATA' = 'ATA',
    /** Polytrade */
    'TRADE' = 'TRADE',
    /** Impossible Finance Launchpad */
    'IDIA' = 'IDIA',
    /** Bitgert */
    'BRISE' = 'BRISE',
    /** Beta Finance */
    'BETA' = 'BETA',
    /** Numbers Protocol */
    'NUM' = 'NUM',
    /** Onyxcoin */
    'XCN' = 'XCN',
    /** LimeWire */
    'LMWR' = 'LMWR',
    /** Anchored Coins AEUR */
    'AEUR' = 'AEUR',
    /** Circuits of Value */
    'COVAL' = 'COVAL',
    /** Bitcoin Dominica */
    'BTCD' = 'BTCD',
    /** Measurable Data Token */
    'MDT' = 'MDT',
    /** CEEK VR */
    'CEEK' = 'CEEK',
    /** ThunderCore */
    'TT' = 'TT',
    /** COMBO */
    'COMBO' = 'COMBO',
    /** ELYSIA */
    'EL' = 'EL',
    /** PARSIQ */
    'PRQ' = 'PRQ',
    /** DEAPcoin */
    'DEP' = 'DEP',
    /** Hacken Token */
    'HAI' = 'HAI',
    /** Aleph.im */
    'ALEPH' = 'ALEPH',
    /** MUX Protocol */
    'MCB' = 'MCB',
    /** Reef */
    'REEF' = 'REEF',
    /** Dego Finance */
    'DEGO' = 'DEGO',
    /** Linear Finance */
    'LINA' = 'LINA',
    /** Alpha Quark Token */
    'AQT' = 'AQT',
    /** RAMP */
    'RAMP' = 'RAMP',
    /** PAID Network */
    'PAID' = 'PAID',
    /** Mdex */
    'MDX' = 'MDX',
    /** XCAD Network */
    'XCAD' = 'XCAD',
    /** Cratos */
    'CRTS' = 'CRTS',
    /** Decimal */
    'DEL' = 'DEL',
    /** SLG.GAMES */
    'SLG' = 'SLG',
    /** PLANET */
    'PLANET' = 'PLANET',
    /** Shrapnel */
    'SHRAP' = 'SHRAP',
    /** AdEx */
    'ADX' = 'ADX',
    /** VITE */
    'VITE' = 'VITE',
    /** Velas */
    'VLX' = 'VLX',
    /** Zynecoin */
    'ZYN' = 'ZYN',
    /** XPR Network */
    'XPR' = 'XPR',
    /** Suku */
    'SUKU' = 'SUKU',
    /** Wing Finance */
    'WING' = 'WING',
    /** UniLend */
    'UFT' = 'UFT',
    /** RocketX exchange */
    'RVF' = 'RVF',
    /** DeRace */
    'DERC' = 'DERC',
    /** Nine Chronicles */
    'WNCG' = 'WNCG',
    /** Katana Inu */
    'KATA' = 'KATA',
    /** pSTAKE Finance */
    'PSTAKE' = 'PSTAKE',
    /** QORPO WORLD */
    'QORPO' = 'QORPO',
    /** HELLO Labs */
    'HELLO' = 'HELLO',
    /** VIDT DAO */
    'VIDT' = 'VIDT',
    /** Firo */
    'FIRO' = 'FIRO',
    /** Chrono.tech */
    'TIME' = 'TIME',
    /** SwftCoin */
    'SWFTC' = 'SWFTC',
    /** ABBC Coin */
    'ABBC' = 'ABBC',
    /** Newscrypto */
    'NWC' = 'NWC',
    /** KardiaChain */
    'KAI' = 'KAI',
    /** renBTC */
    'RENBTC' = 'RENBTC',
    /** TrustSwap */
    'SWAP' = 'SWAP',
    /** DFI.Money */
    'YFII' = 'YFII',
    /** Stobox Token */
    'STBU' = 'STBU',
    /** Dvision Network */
    'DVI' = 'DVI',
    /** OVR */
    'OVR' = 'OVR',
    /** Deeper Network */
    'DPR' = 'DPR',
    /** Cellframe */
    'CELL' = 'CELL',
    /** Ternoa */
    'CAPS' = 'CAPS',
    /** MILC Platform */
    'MLT' = 'MLT',
    /** Credefi */
    'CREDI' = 'CREDI',
    /** Gamium */
    'GMM' = 'GMM',
    /** TRVL */
    'TRVL' = 'TRVL',
    /** ParaSwap */
    'PSP' = 'PSP',
    /** Galeon */
    'GALEON' = 'GALEON',
    /** Volt Inu */
    'VOLT' = 'VOLT',
    /** DMAIL Network */
    'DMAIL' = 'DMAIL',
    /** DexCheck AI */
    'DCK' = 'DCK',
    /** Carbon browser */
    'CSIX' = 'CSIX',
    /** Inspect */
    'INSP' = 'INSP',
    /** GameGPT */
    'DUEL' = 'DUEL',
    /** Adshares */
    'ADS' = 'ADS',
    /** Fusion */
    'FSN' = 'FSN',
    /** Phantasma */
    'SOUL' = 'SOUL',
    /** ForTube */
    'FOR' = 'FOR',
    /** MixMarvel */
    'MIX' = 'MIX',
    /** Fuse */
    'FUSE' = 'FUSE',
    /** KIRA */
    'KEX' = 'KEX',
    /** REVV */
    'REVV' = 'REVV',
    /** UNCX Network */
    'UNCX' = 'UNCX',
    /** Electra Protocol */
    'XEP' = 'XEP',
    /** HAPI Protocol */
    'HAPI' = 'HAPI',
    /** Saito */
    'SAITO' = 'SAITO',
    /** EPIK Prime */
    'EPIK' = 'EPIK',
    /** GameZone */
    'GZONE' = 'GZONE',
    /** RMRK */
    'RMRK' = 'RMRK',
    /** Swash */
    'SWASH' = 'SWASH',
    /** Vita Inu */
    'VINU' = 'VINU',
    /** NvirWorld */
    'NVIR' = 'NVIR',
    /** Brickken */
    'BKN' = 'BKN',
    /** Oho */
    'OHO' = 'OHO',
    /** Verified USD */
    'USDV' = 'USDV',
    /** Byte */
    'BYTE' = 'BYTE',
    /** Carbon Protocol */
    'SWTH' = 'SWTH',
    /** TOKPIE */
    'TKP' = 'TKP',
    /** Banano */
    'BAN' = 'BAN',
    /** NEST Protocol */
    'NEST' = 'NEST',
    /** Serum */
    'SRM' = 'SRM',
    /** Tornado Cash */
    'TORN' = 'TORN',
    /** Prosper */
    'PROS' = 'PROS',
    /** PolkaBridge */
    'PBR' = 'PBR',
    /** TOWER */
    'TOWER' = 'TOWER',
    /** GAMEE */
    'GMEE' = 'GMEE',
    /** PureFi Protocol */
    'UFI' = 'UFI',
    /** Izumi Finance */
    'IZI' = 'IZI',
    /** Multichain */
    'MULTI' = 'MULTI',
    /** AIPAD */
    'AIPAD' = 'AIPAD',
    /** Minati Coin */
    'MNTC' = 'MNTC',
    /** Obyte */
    'GBYTE' = 'GBYTE',
    /** ColossusXT */
    'COLX' = 'COLX',
    /** QuadrantProtocol */
    'EQUAD' = 'EQUAD',
    /** apM Coin */
    'APM' = 'APM',
    /** Umbrella Network */
    'UMB' = 'UMB',
    /** UnMarshal */
    'MARSH' = 'MARSH',
    /** Gains */
    'GAINS' = 'GAINS',
    /** Ispolink */
    'ISP' = 'ISP',
    /** Buying.com */
    'BUY' = 'BUY',
    /** Lovely Inu Finance */
    'LOVELY' = 'LOVELY',
    /** LABEL Foundation */
    'LBL' = 'LBL',
    /** DappRadar */
    'RADAR' = 'RADAR',
    /** Hillstone Finance */
    'HSF' = 'HSF',
    /** Kunci Coin */
    'KUNCI' = 'KUNCI',
    /** Chirpley */
    'CHRP' = 'CHRP',
    /** Orbofi AI */
    'OBI' = 'OBI',
    /** Penpie */
    'PNP' = 'PNP',
    /** Dypius [New] */
    'DYP' = 'DYP',
    /** Effect Network */
    'EFX' = 'EFX',
    /** NewYork Exchange */
    'NYE' = 'NYE',
    /** RFOX */
    'RFOX' = 'RFOX',
    /** ClinTex CTi */
    'CTI' = 'CTI',
    /** DeHub */
    'DHB' = 'DHB',
    /** DOSE */
    'DOSE' = 'DOSE',
    /** EverRise */
    'RISE' = 'RISE',
    /** Grove Coin */
    'GRV' = 'GRV',
    /** Mysterium */
    'MYST' = 'MYST',
    /** NerveNetwork */
    'NVT' = 'NVT',
    /** ASTA */
    'ASTA' = 'ASTA',
    /** Mettalex */
    'MTLX' = 'MTLX',
    /** Unbound */
    'UNB' = 'UNB',
    /** Forj (Bondly) */
    'BONDLY' = 'BONDLY',
    /** Exeedme */
    'XED' = 'XED',
    /** Uno Re */
    'UNO' = 'UNO',
    /** Etherland */
    'ELAND' = 'ELAND',
    /** Dotmoovs */
    'MOOV' = 'MOOV',
    /** Witch Token */
    'WITCH' = 'WITCH',
    /** MARS4 */
    'MARS4' = 'MARS4',
    /** StreamCoin */
    'STRM' = 'STRM',
    /** BBS Network */
    'BBS' = 'BBS',
    /** ApeBond */
    'ABOND' = 'ABOND',
    /** DeFi */
    'DEFI' = 'DEFI',
    /** Minter Network */
    'BIP' = 'BIP',
    /** PEAKDEFI */
    'PEAK' = 'PEAK',
    /** Juggernaut */
    'JGN' = 'JGN',
    /** Polkacity */
    'POLC' = 'POLC',
    /** Aurox */
    'URUS' = 'URUS',
    /** ChainGuardians */
    'CGG' = 'CGG',
    /** Mogul Productions */
    'STARS' = 'STARS',
    /** Rainicorn */
    'RAINI' = 'RAINI',
    /** HI */
    'HI' = 'HI',
    /** DEEPSPACE */
    'DPS' = 'DPS',
    /** Ariva */
    'ARV' = 'ARV',
    /** Pawthereum */
    'PAWTH' = 'PAWTH',
    /** Walken */
    'WLKN' = 'WLKN',
    /** FREEdom Coin */
    'FREE' = 'FREE',
    /** SparkPoint */
    'SRK' = 'SRK',
    /** Aryacoin */
    'AYA' = 'AYA',
    /** TenUp */
    'TUP' = 'TUP',
    /** Neutrino Index */
    'XTN' = 'XTN',
    /** Idena */
    'IDNA' = 'IDNA',
    /** Bird.Money */
    'BIRD' = 'BIRD',
    /** InsurAce */
    'INSUR' = 'INSUR',
    /** Polychain Monsters */
    'PMON' = 'PMON',
    /** Nabox */
    'NABOX' = 'NABOX',
    /** Virtue Poker */
    'VPP' = 'VPP',
    /** Pleasure Coin */
    'NSFW' = 'NSFW',
    /** FEAR */
    'FEAR' = 'FEAR',
    /** XCarnival */
    'XCV' = 'XCV',
    /** Cirus Foundation */
    'CIRUS' = 'CIRUS',
    /** Arix */
    'ARIX' = 'ARIX',
    /** JMPT Rewards */
    'JMPT' = 'JMPT',
    /** NanoByte Token */
    'NBT' = 'NBT',
    /** Wombat Exchange */
    'WOM' = 'WOM',
    /** Virtual Versions */
    'VV' = 'VV',
    /** BitCore */
    'BTX' = 'BTX',
    /** Zap */
    'ZAP' = 'ZAP',
    /** suterusu */
    'SUTER' = 'SUTER',
    /** ArdCoin */
    'ARDX' = 'ARDX',
    /** Bitgesell */
    'BGL' = 'BGL',
    /** pNetwork */
    'PNT' = 'PNT',
    /** Offshift [Old] */
    'XFT' = 'XFT',
    /** Hakka.Finance */
    'HAKKA' = 'HAKKA',
    /** OIN Finance */
    'OIN' = 'OIN',
    /** ZeroSwap */
    'ZEE' = 'ZEE',
    /** Oddz */
    'ODDZ' = 'ODDZ',
    /** Pussy Financial */
    'PUSSY' = 'PUSSY',
    /** Hot Cross */
    'HOTCROSS' = 'HOTCROSS',
    /** Bistroo */
    'BIST' = 'BIST',
    /** VEMP */
    'VEMP' = 'VEMP',
    /** VelasPad */
    'VLXPAD' = 'VLXPAD',
    /** Blockchain Monster Hunt */
    'BCMC' = 'BCMC',
    /** Rainmaker Games */
    'RAIN' = 'RAIN',
    /** XY Finance */
    'XY' = 'XY',
    /** Kaizen Finance */
    'KZEN' = 'KZEN',
    /** Cheems Inu (new) */
    'CINU' = 'CINU',
    /** Bismuth */
    'BIS' = 'BIS',
    /** Garlicoin */
    'GRLC' = 'GRLC',
    /** Atomic Wallet Coin */
    'AWC' = 'AWC',
    /** Safe Haven */
    'SHA' = 'SHA',
    /** RioDeFi */
    'RFUEL' = 'RFUEL',
    /** Hyve */
    'HYVE' = 'HYVE',
    /** UBIX.Network */
    'UBX' = 'UBX',
    /** Glitch */
    'GLCH' = 'GLCH',
    /** Chronicle */
    'XNL' = 'XNL',
    /** Woonkly Power */
    'WOOP' = 'WOOP',
    /** Kattana */
    'KTN' = 'KTN',
    /** PERI Finance */
    'PERI' = 'PERI',
    /** blockbank */
    'BBANK' = 'BBANK',
    /** Omchain */
    'OMC' = 'OMC',
    /** ETHPad */
    'ETHPAD' = 'ETHPAD',
    /** Brokoli Network */
    'BRKL' = 'BRKL',
    /** ADAPad */
    'ADAPAD' = 'ADAPAD',
    /** Internet of Energy Network */
    'IOEN' = 'IOEN',
    /** OneArt */
    '1ART' = '1ART',
    /** Avocado DAO Token */
    'AVG' = 'AVG',
    /** Wrapped Alvey Chain */
    'WALV' = 'WALV',
    /** Metrix Coin */
    'MRX' = 'MRX',
    /** BitcoinZ */
    'BTCZ' = 'BTCZ',
    /** Callisto Network */
    'CLO' = 'CLO',
    /** Etho Protocol */
    'ETHO' = 'ETHO',
    /** S4FE */
    'S4F' = 'S4F',
    /** PERL.eco */
    'PERL' = 'PERL',
    /** Tokoin */
    'TOKO' = 'TOKO',
    /** Arcona */
    'ARCONA' = 'ARCONA',
    /** Gather */
    'GTH' = 'GTH',
    /** Freeway Token */
    'FWT' = 'FWT',
    /** CyberFi Token */
    'CFi' = 'CFi',
    /** Unido EP */
    'UDO' = 'UDO',
    /** Don-key */
    'DON' = 'DON',
    /** Loser Coin */
    'LOWB' = 'LOWB',
    /** Tiger King Coin */
    'TKING' = 'TKING',
    /** Corite */
    'CO' = 'CO',
    /** EVRYNET */
    'EVRY' = 'EVRY',
    /** PulsePad */
    'PLSPAD' = 'PLSPAD',
    /** NFTY Token */
    'NFTY' = 'NFTY',
    /** CoTrader */
    'COT' = 'COT',
    /** AtromG8 */
    'AG8' = 'AG8',
    /** Zelwin */
    'ZLW' = 'ZLW',
    /** Autobahn Network */
    'TXL' = 'TXL',
    /** SaTT */
    'SATT' = 'SATT',
    /** Coreto */
    'COR' = 'COR',
    /** Kangal */
    'KANGAL' = 'KANGAL',
    /** Equalizer */
    'EQZ' = 'EQZ',
    /** Signata */
    'SATA' = 'SATA',
    /** Coldstack */
    'CLS' = 'CLS',
    /** IOI Token */
    'IOI' = 'IOI',
    /** The HUSL */
    'HUSL' = 'HUSL',
    /** Life Crypto */
    'LIFE' = 'LIFE',
    /** Shirtum */
    'SHI' = 'SHI',
    /** NFTrade */
    'NFTD' = 'NFTD',
    /** Jetcoin */
    'JET' = 'JET',
    /** HOPE */
    'HOPE' = 'HOPE',
    /** DAPS Coin */
    'DAPS' = 'DAPS',
    /** DuckDaoDime */
    'DDIM' = 'DDIM',
    /** Lead Wallet */
    'LEAD' = 'LEAD',
    /** SakeToken */
    'SAKE' = 'SAKE',
    /** Peanut */
    'NUX' = 'NUX',
    /** KeyFi */
    'KEYFI' = 'KEYFI',
    /** Playcent */
    'PCNT' = 'PCNT',
    /** disBalancer */
    'DDOS' = 'DDOS',
    /** 8PAY */
    '8PAY' = '8PAY',
    /** Raze Network */
    'RAZE' = 'RAZE',
    /** Impossible Finance */
    'IF' = 'IF',
    /** Triall */
    'TRL' = 'TRL',
    /** SPORT */
    'SPORT' = 'SPORT',
    /** Polis */
    'POLIS' = 'POLIS',
    /** Golff */
    'GOF' = 'GOF',
    /** EasyFi */
    'EZ' = 'EZ',
    /** ACoconut */
    'AC' = 'AC',
    /** Curate */
    'XCUR' = 'XCUR',
    /** Finxflo */
    'FXF' = 'FXF',
    /** APYSwap */
    'APYS' = 'APYS',
    /** Shadows */
    'DOWS' = 'DOWS',
    /** Ares Protocol */
    'ARES' = 'ARES',
    /** DeHive */
    'DHV' = 'DHV',
    /** Genesis Shards */
    'GS' = 'GS',
    /** Mochi Market */
    'MOMA' = 'MOMA',
    /** DinoX */
    'DNXC' = 'DNXC',
    /** KamPay */
    'KAMPAY' = 'KAMPAY',
    /** WagyuSwap */
    'WAG' = 'WAG',
    /** Ekta */
    'EKTA' = 'EKTA',
    /** ChronoBase */
    'TIK' = 'TIK',
    /** Orion Money */
    'ORION' = 'ORION',
    /** IDRX */
    'IDRX' = 'IDRX',
    /** NevaCoin */
    'NEVA' = 'NEVA',
    /** Shard */
    'SHARD' = 'SHARD',
    /** ZUM TOKEN */
    'ZUM' = 'ZUM',
    /** ALL BEST ICO */
    'ALLBI' = 'ALLBI',
    /** PhoenixDAO */
    'PHNX' = 'PHNX',
    /** Spaceswap MILK2 */
    'MILK2' = 'MILK2',
    /** YVS.Finance */
    'YVS' = 'YVS',
    /** PRivaCY Coin */
    'PRCY' = 'PRCY',
    /** TotemFi */
    'TOTM' = 'TOTM',
    /** Rigel Protocol */
    'RGP' = 'RGP',
    /** Formation Fi */
    'FORM' = 'FORM',
    /** ShoeFy */
    'SHOE' = 'SHOE',
    /** Mrweb Finance (new) */
    'AMA' = 'AMA',
    /** SeChain */
    'SNN' = 'SNN',
    /** extraDNA */
    'XDNA' = 'XDNA',
    /** Gem Exchange and Trading */
    'GXT' = 'GXT',
    /** BonFi */
    'BNF' = 'BNF',
    /** CorionX */
    'CORX' = 'CORX',
    /** Gourmet Galaxy */
    'GUM' = 'GUM',
    /** Rage Fan */
    'RAGE' = 'RAGE',
    /** WorkQuest Token */
    'WQT' = 'WQT',
    /** UniFarm */
    'UFARM' = 'UFARM',
    /** 1MillionNFTs */
    '1MIL' = '1MIL',
    /** Knit Finance */
    'KFT' = 'KFT',
    /** Kwikswap Protocol */
    'KWIK' = 'KWIK',
    /** ZCore (old) */
    'ZCR' = 'ZCR',
    /** ECOSC */
    'ECU' = 'ECU',
    /** Wrapped Matic */
    'WMATIC' = 'WMATIC',
    /** Wrapped Solana */
    'SOL' = 'SOL',
    /** LayerAI */
    'LAI' = 'LAI',
    /** HTX */
    'HTX' = 'HTX',
    /** Masa */
    'MASA' = 'MASA',
    /** Polyhedra Network */
    'ZK' = 'ZK',
    /** VNDC */
    'VNDC' = 'VNDC',
    /** Chintai */
    'CHEX' = 'CHEX',
    /** GOLCOIN */
    'GOLC' = 'GOLC',
    /** XEN Crypto */
    'XEN' = 'XEN',
    /** NetMind Token */
    'NMT' = 'NMT',
    /** Wrapped EGLD */
    'WEGLD' = 'WEGLD',
    /** Digital Financial Exchange */
    'DIFX' = 'DIFX',
    /** XANA */
    'XETA' = 'XETA',
    /** Parex */
    'PRX' = 'PRX',
    /** YOUR AI */
    'YOURAI' = 'YOURAI',
    /** LIF3 */
    'LIF3' = 'LIF3',
    /** Hippo Wallet Token (HPO) */
    'HPO' = 'HPO',
    /** Work X */
    'WORK' = 'WORK',
    /** Catboy */
    'CATBOY' = 'CATBOY',
    /** Childrens Aid Foundation */
    'CAF' = 'CAF',
    /** Trex20 */
    'TX20' = 'TX20',
    /** QANplatform */
    'QANX' = 'QANX',
    /** EscoinToken */
    'ELG' = 'ELG',
    /** BoringDAO */
    'BORING' = 'BORING',
    /** Animal Concerts */
    'ANML' = 'ANML',
    /** Zedxion */
    'USDZ' = 'USDZ',
    /** Rebase GG */
    'IRL' = 'IRL',
    /** Degen Zoo */
    'DZOO' = 'DZOO',
    /** csas (Ordinals) */
    'CSAS' = 'CSAS',
    /** Brazilian Digital Token */
    'BRZ' = 'BRZ',
    /** Bitcoiva */
    'BCA' = 'BCA',
    /** Zedxion */
    'ZEDXION' = 'ZEDXION',
    /** MetaGaming Guild */
    'MGG' = 'MGG',
    /** Bit.Store */
    'STORE' = 'STORE',
    /** Patex */
    'PATEX' = 'PATEX',
    /** Fideum */
    'FI' = 'FI',
    /** SIX */
    'SIX' = 'SIX',
    /** Oobit */
    'OBT' = 'OBT',
    /** FEG Token [NEW] */
    'FEG' = 'FEG',
    /** Derp */
    'DERP' = 'DERP',
    /** XRGB */
    'XRGB' = 'XRGB',
    /** WadzPay Token */
    'WTK' = 'WTK',
    /** Privapp Network */
    'bPRIVA' = 'bPRIVA',
    /** Enjinstarter */
    'EJS' = 'EJS',
    /** HyperCycle */
    'HYPC' = 'HYPC',
    /** BEFE */
    'BEFE' = 'BEFE',
    /** TribeOne */
    'HAKA' = 'HAKA',
    /** MContent */
    'MCONTENT' = 'MCONTENT',
    /** Melos Studio */
    'MELOS' = 'MELOS',
    /** SaitaRealty */
    'SRLTY' = 'SRLTY',
    /** Acria.AI */
    'ACRIA' = 'ACRIA',
    /** Luffy */
    'LUFFY' = 'LUFFY',
    /** SophiaVerse */
    'SOPH' = 'SOPH',
    /** CateCoin */
    'CATE' = 'CATE',
    /** EQIFI */
    'EQX' = 'EQX',
    /** AlienForm */
    'A4M' = 'A4M',
    /** Vera */
    'VERA' = 'VERA',
    /** Domi Online */
    'DOMI' = 'DOMI',
    /** XOX Labs */
    'XOX' = 'XOX',
    /** Launchpool */
    'LPOOL' = 'LPOOL',
    /** Freela */
    'FREL' = 'FREL',
    /** Gold Fever */
    'NGL' = 'NGL',
    /** bloXmove Token */
    'BLXM' = 'BLXM',
    /** Mean DAO */
    'MEAN' = 'MEAN',
    /** ClearDAO */
    'CLH' = 'CLH',
    /** Betswap.gg */
    'BSGG' = 'BSGG',
    /** Loop Network */
    'LOOP' = 'LOOP',
    /** HistoryDAO */
    'HAO' = 'HAO',
    /** Zeniq */
    'ZENIQ' = 'ZENIQ',
    /** Zamio */
    'ZAM' = 'ZAM',
    /** Pancake Games */
    'GCAKE' = 'GCAKE',
    /** Chihuahua */
    'HUAHUA' = 'HUAHUA',
    /** Spellfire */
    'SPELLFIRE' = 'SPELLFIRE',
    /** Golden Inu */
    'GOLDEN' = 'GOLDEN',
    /** Wrapped Kaspa */
    'WKAS' = 'WKAS',
    /** Obortech */
    'OBOT' = 'OBOT',
    /** Talken */
    'TALK' = 'TALK',
    /** Realm */
    'REALM' = 'REALM',
    /** Electric Vehicle Direct Currency */
    'EVDC' = 'EVDC',
    /** Zombie Inu(New) */
    'ZINU' = 'ZINU',
    /** TruePNL */
    'PNL' = 'PNL',
    /** Jax.Network */
    'WJXN' = 'WJXN',
    /** CATCOIN */
    'CATS' = 'CATS',
    /** Dynamite */
    'DYNMT' = 'DYNMT',
    /** Nexalt */
    'XLT' = 'XLT',
    /** Occam.Fi */
    'OCC' = 'OCC',
    /** WELD */
    'WELD' = 'WELD',
    /** LunarCrush */
    'LUNR' = 'LUNR',
    /** ONUS */
    'ONUS' = 'ONUS',
    /** SafeMoon V2 */
    'SFM' = 'SFM',
    /** iDypius */
    'IDYP' = 'IDYP',
    /** Wrapped Rose */
    'wROSE' = 'wROSE',
    /** Xodex */
    'XODEX' = 'XODEX',
    /** Evanesco Network */
    'EVA' = 'EVA',
    /** Catena */
    'CMCX' = 'CMCX',
    /** Kiba Inu */
    'KIBA' = 'KIBA',
    /** Trace Network Labs */
    'TRACE' = 'TRACE',
    /** QMALL TOKEN */
    'QMALL' = 'QMALL',
    /** Dark Energy Crystals */
    'DEC' = 'DEC',
    /** NAOS Finance */
    'NAOS' = 'NAOS',
    /** Sirius Chain */
    'XPX' = 'XPX',
    /** Moon Rabbit */
    'AAA' = 'AAA',
    /** Phantom Protocol */
    'PHM' = 'PHM',
    /** Poken */
    'PKN' = 'PKN',
    /** BlockchainSpace */
    'GUILD' = 'GUILD',
    /** Frontrow */
    'FRR' = 'FRR',
    /** The Winkyverse */
    'WNK' = 'WNK',
    /** Navcoin */
    'NAV' = 'NAV',
    /** Blind Boxes */
    'BLES' = 'BLES',
    /** HalfPizza */
    'PIZA' = 'PIZA',
    /** Celestial */
    'CELT' = 'CELT',
    /** Sphynx Labs */
    'SPHYNX' = 'SPHYNX',
    /** Reunit Wallet */
    'REUNI' = 'REUNI',
    /** Archethic */
    'UCO' = 'UCO',
    /** BOY X HIGHSPEED */
    'BXH' = 'BXH',
    /** Envoy */
    'ENV' = 'ENV',
    /** Dinger Token */
    'DINGER' = 'DINGER',
    /** SpaceMine */
    'MINE' = 'MINE',
    /** Doge KaKi */
    'KAKI' = 'KAKI',
    /** Chain Games */
    'CHAIN' = 'CHAIN',
    /** Paralink Network */
    'PARA' = 'PARA',
    /** Wrapped Harmony */
    'WONE' = 'WONE',
    /** Asia Coin */
    'ASIA' = 'ASIA',
    /** NASDEX */
    'NSDX' = 'NSDX',
    /** Uplift */
    'LIFT' = 'LIFT',
    /** DEUS Finance */
    'DEUS' = 'DEUS',
    /** Mimir Token */
    'MIMIR' = 'MIMIR',
    /** Vent Finance */
    'VENT' = 'VENT',
    /** ChainPort */
    'PORTX' = 'PORTX',
    /** XENO Governance */
    'GXE' = 'GXE',
    /** MaziMatic */
    'MAZI' = 'MAZI',
    /** DataHighway */
    'DHX' = 'DHX',
    /** DeSpace Protocol */
    'DES' = 'DES',
    /** PixelVerse */
    'PIXEL' = 'PIXEL',
    /** Ridotto */
    'RDT' = 'RDT',
    /** AmazingTeamDAO */
    'AMAZINGTEAM' = 'AMAZINGTEAM',
    /** Rentible */
    'RNB' = 'RNB',
    /** TCGCoin 2.0 */
    'TCG2' = 'TCG2',
    /** 99Starz */
    'STZ' = 'STZ',
    /** Brewlabs */
    'BREWLABS' = 'BREWLABS',
    /** Okuru */
    'XOT' = 'XOT',
    /** DeFinity */
    'DEFX' = 'DEFX',
    /** Flurry Finance */
    'FLURRY' = 'FLURRY',
    /** Allbridge */
    'ABR' = 'ABR',
    /** FODL Finance */
    'FODL' = 'FODL',
    /** GovWorld */
    'GOV' = 'GOV',
    /** Pontoon */
    'TOON' = 'TOON',
    /** BYTZ */
    'BYTZ' = 'BYTZ',
    /** TNC Coin */
    'TNC' = 'TNC',
    /** BambooDeFi */
    'BAMBOO' = 'BAMBOO',
    /** SportsIcon */
    'ICONS' = 'ICONS',
    /** ReSource Protocol */
    'SOURCE' = 'SOURCE',
    /** BUILD */
    'BUILD' = 'BUILD',
    /** Genius */
    'GENI' = 'GENI',
    /** Wistaverse */
    'WISTA' = 'WISTA',
    /** Cat of Meme */
    'COME' = 'COME',
    /** Jupiter */
    'JUP' = 'JUP',
    /** BitTorrent */
    'BTTOLD' = 'BTTOLD',
    /** PirateCash */
    'PIRATE' = 'PIRATE',
    /** BiLira */
    'TRYB' = 'TRYB',
    /** DBX */
    'DBX' = 'DBX',
    /** MetaVPad */
    'METAV' = 'METAV',
    /** ℓUSD */
    'LUSD' = 'LUSD',
    /** OSEAN */
    'OSEAN' = 'OSEAN',
    /** teleBTC */
    'TELEBTC' = 'TELEBTC',
    /** SINERGIA */
    'SNG' = 'SNG',
    /** Creator Platform */
    'CTR' = 'CTR',
    /** PlayNity */
    'PLY' = 'PLY',
    /** Besa Gaming */
    'BESA' = 'BESA',
    /** Cloudbric */
    'CLBK' = 'CLBK',
    /** Lever Token */
    'LEV' = 'LEV',
    /** NEXTYPE */
    'NT' = 'NT',
    /** Jarvis Synthetic Euro */
    'JEUR' = 'JEUR',
    /** Shirushi Coin */
    'SISC' = 'SISC',
    /** BBC Gold Coin */
    'BBCG' = 'BBCG',
    /** Tidex Token */
    'TDX' = 'TDX',
    /** Zugacoin */
    'SZCB' = 'SZCB',
    /** MetalSwap */
    'XMT' = 'XMT',
    /** Froyo Games */
    'FROYO' = 'FROYO',
    /** FRZ Solar System Coin */
    'FRZSSCOIN' = 'FRZSSCOIN',
    /** MN Bridge */
    'MNB' = 'MNB',
    /** Anyswap */
    'ANY' = 'ANY',
    /** Luxurious Pro Network Token */
    'LPNT' = 'LPNT',
    /** Quidd */
    'QUIDD' = 'QUIDD',
    /** Anime Token */
    'ANI' = 'ANI',
    /** Shih Tzu */
    'SHIH' = 'SHIH',
    /** Betero */
    'BTE' = 'BTE',
    /** Gyrowin */
    'GW' = 'GW',
    /** CrowdSwap */
    'CROWD' = 'CROWD',
    /** 8Bit Chain */
    'W8BIT' = 'W8BIT',
    /** Baby Golden Coin */
    'BABYGOLDEN' = 'BABYGOLDEN',
    /** XIDO FINANCE */
    'XIDO' = 'XIDO',
    /** TokenClub */
    'TCT' = 'TCT',
    /** 4P FOUR */
    'FOUR' = 'FOUR',
    /** Ethereum Meta */
    'ETHM' = 'ETHM',
    /** Barter */
    'BRTR' = 'BRTR',
    /** JEFE TOKEN */
    'JEFE' = 'JEFE',
    /** FQSwap V2 */
    'FQS' = 'FQS',
    /** Ignore Fud */
    '4TOKEN' = '4TOKEN',
    /** Popecoin */
    'POPE' = 'POPE',
    /** ZilPepe */
    'ZILPEPE' = 'ZILPEPE',
    /** SwiftCash */
    'SWIFT' = 'SWIFT',
    /** BSCStarter */
    'START' = 'START',
    /** TTcoin */
    'TC' = 'TC',
    /** Hanu Yokia */
    'HANU' = 'HANU',
    /** Yoshi.exchange */
    'YOSHI' = 'YOSHI',
    /** Centaurify */
    'CENT' = 'CENT',
    /** Pige */
    'PIGE' = 'PIGE',
    /** Talent Token */
    'TTX' = 'TTX',
    /** BlackCoin */
    'BLK' = 'BLK',
    /** TFS Token */
    'TFS' = 'TFS',
    /** bobcoin */
    'BOBC' = 'BOBC',
    /** EnreachDAO */
    'NRCH' = 'NRCH',
    /** HALO network */
    'HO' = 'HO',
    /** Bit2Me */
    'B2M' = 'B2M',
    /** Wakanda Inu */
    'WKD' = 'WKD',
    /** PayBolt */
    'PAY' = 'PAY',
    /** MAIN */
    'MAIN' = 'MAIN',
    /** Ankr Staked MATIC */
    'ankrMATIC' = 'ankrMATIC',
    /** UpBots Token */
    'UBXN' = 'UBXN',
    /** GogolCoin */
    'GOL' = 'GOL',
    /** Pirate Coin Games */
    'PirateCoin☠' = 'PirateCoin☠',
    /** Safle */
    'SAFLE' = 'SAFLE',
    /** StrongHands Finance */
    'ISHND' = 'ISHND',
    /** Nunu Spirits */
    'NNT' = 'NNT',
    /** CoinsPaid */
    'CPD' = 'CPD',
    /** Crypto Global United */
    'CGU' = 'CGU',
    /** Hector Network */
    'HEC' = 'HEC',
    /** Primate */
    'PRIMATE' = 'PRIMATE',
    /** StaFi Staked MATIC */
    'RMATIC' = 'RMATIC',
    /** American Shiba */
    'USHIBA' = 'USHIBA',
    /** YEL.Finance */
    'YEL' = 'YEL',
    /** 42-coin */
    '#42' = '42',
    /** Mainstream For The Underground */
    'MFTU' = 'MFTU',
    /** PayAccept */
    'PAYT' = 'PAYT',
    /** TOZEX */
    'TOZ' = 'TOZ',
    /** Minter HUB */
    'HUB' = 'HUB',
    /** HOKK Finance */
    'HOKK' = 'HOKK',
    /** SokuSwap */
    'SOKU' = 'SOKU',
    /** Omnisea */
    'OSEA' = 'OSEA',
    /** XV */
    'XV' = 'XV',
    /** Novacoin */
    'NVC' = 'NVC',
    /** Kambria */
    'KAT' = 'KAT',
    /** Switch */
    'ESH' = 'ESH',
    /** ITAM Games */
    'ITAM' = 'ITAM',
    /** DexKit */
    'KIT' = 'KIT',
    /** Vidiachange */
    'VIDA' = 'VIDA',
    /** Dohrnii */
    'DHN' = 'DHN',
    /** Voltz */
    'VOLTZ' = 'VOLTZ',
    /** Giftedhands */
    'GHD' = 'GHD',
    /** Zipmex */
    'ZMT' = 'ZMT',
    /** Zoo Token */
    'ZOOT' = 'ZOOT',
    /** KingDeFi */
    'KRW' = 'KRW',
    /** Matrix Labs */
    'MATRIX' = 'MATRIX',
    /** Zenith Coin */
    'ZENITH' = 'ZENITH',
    /** TOR */
    'TOR' = 'TOR',
    /** Gemlink */
    'GLINK' = 'GLINK',
    /** Exohood */
    'EXO' = 'EXO',
    /** Shibaken Finance */
    'SHIBAKEN' = 'SHIBAKEN',
    /** DigitalNote */
    'XDN' = 'XDN',
    /** NFT STARS */
    'NFTS' = 'NFTS',
    /** Cogecoin */
    'COGE' = 'COGE',
    /** LunaChow */
    'LUCHOW' = 'LUCHOW',
    /** ShibElon */
    'SHIBELON' = 'SHIBELON',
    /** Colizeum */
    'ZEUM' = 'ZEUM',
    /** Travel Care */
    'TRAVEL' = 'TRAVEL',
    /** Lacostoken */
    'LCSN' = 'LCSN',
    /** Duet Protocol */
    'DUET' = 'DUET',
    /** Saudi Shiba Inu */
    'SAUDISHIB' = 'SAUDISHIB',
    /** AppleSwap AI */
    'AP' = 'AP',
    /** BlazeX */
    'BLAZEX' = 'BLAZEX',
    /** Zeedex */
    'ZDEX' = 'ZDEX',
    /** Reflex */
    'RFX' = 'RFX',
    /** Gera Coin */
    'GERA' = 'GERA',
    /** Goldex Token */
    'GLDX' = 'GLDX',
    /** HappyFans */
    'HAPPY' = 'HAPPY',
    /** Lightcoin */
    'LHC' = 'LHC',
    /** INT */
    'INT' = 'INT',
    /** CREDIT */
    'CREDIT' = 'CREDIT',
    /** Homeros */
    'HMR' = 'HMR',
    /** IjasCoin */
    'IJC' = 'IJC',
    /** CRYSTAL STONES */
    'CRYSTAL STONES' = 'CRYSTAL STONES',
    /** Iclick inu */
    'ICLICK' = 'ICLICK',
    /** Crown */
    'CRW' = 'CRW',
    /** Scaleswap */
    'SCA' = 'SCA',
    /** Raptor Finance */
    'RPTR' = 'RPTR',
    /** Relay Token */
    'RELAY' = 'RELAY',
    /** delta.theta */
    'DLTA' = 'DLTA',
    /** Cross-Chain Bridge Token */
    'BRIDGE' = 'BRIDGE',
    /** Shido [Old] */
    'SHIDO' = 'SHIDO',
    /** Micromines */
    'MICRO' = 'MICRO',
    /** DeFiato */
    'DFIAT' = 'DFIAT',
    /** AMMYI Coin */
    'AMI' = 'AMI',
    /** TORG */
    'TORG' = 'TORG',
    /** Xave Coin */
    'XVC' = 'XVC',
    /** L3USD */
    'L3USD' = 'L3USD',
    /** Ormeus Coin */
    'ORMEUS' = 'ORMEUS',
    /** Merge */
    'MERGE' = 'MERGE',
    /** Centurion */
    'CNT' = 'CNT',
    /** Lamden */
    'TAU' = 'TAU',
    /** FantasyGold */
    'FGC' = 'FGC',
    /** Kalkulus */
    'KLKS' = 'KLKS',
    /** Noku */
    'NOKU' = 'NOKU',
    /** YoloCash */
    'YLC' = 'YLC',
    /** Beetlecoin */
    'BEET' = 'BEET',
    /** Escroco Emerald */
    'ESCE' = 'ESCE',
    /** USDe */
    'USDE' = 'USDE',
    /** xRhodium */
    'XRC' = 'XRC',
    /** Asian Fintech */
    'AFIN' = 'AFIN',
    /** Levolution */
    'LEVL' = 'LEVL',
    /** Aircoins */
    'AIRX' = 'AIRX',
    /** Cofinex */
    'CNX' = 'CNX',
    /** Banque Universal */
    'CBU' = 'CBU',
    /** JBOX */
    'JBX' = 'JBX',
    /** Medicalveda */
    'MVEDA' = 'MVEDA',
    /** Action Coin */
    'ACTN' = 'ACTN',
    /** ebox */
    'EBOX' = 'EBOX',
    /** UMI */
    'UMI' = 'UMI',
    /** Upfire */
    'UPR' = 'UPR',
    /** Sensitrust */
    'SETS' = 'SETS',
    /** PalGold */
    'PALG' = 'PALG',
    /** TeraBlock */
    'TBC' = 'TBC',
    /** Identity */
    'IDTT' = 'IDTT',
    /** Hibiki Finance */
    'HIBIKI' = 'HIBIKI',
    /** PolkaFantasy */
    'XP' = 'XP',
    /** Crosschain IOTX */
    'CIOTX' = 'CIOTX',
    /** NFTmall */
    'GEM' = 'GEM',
    /** DAO Invest */
    'VEST' = 'VEST',
    /** Quarashi */
    'QUA' = 'QUA',
    /** Starchi */
    'ELIXIR' = 'ELIXIR',
    /** RBX */
    'RBX' = 'RBX',
    /** Adonis */
    'ADON' = 'ADON',
    /** Rice Wallet */
    'RICE' = 'RICE',
    /** Studyum */
    'STUD' = 'STUD',
    /** HUH Token */
    'HUH' = 'HUH',
    /** Multi-Chain Capital (new) */
    'MCC' = 'MCC',
    /** A4 Finance */
    'A4' = 'A4',
    /** VCGamers */
    'VCG' = 'VCG',
    /** CUBE */
    'ITAMCUBE' = 'ITAMCUBE',
    /** DigiMetaverse */
    'DGMV' = 'DGMV',
    /** ELYFI */
    'ELFI' = 'ELFI',
    /** GoCryptoMe */
    'GCME' = 'GCME',
    /** Salmonation */
    'SUI' = 'SUI',
    /** POLYSPORTS */
    'PS1' = 'PS1',
    /** Kyuubi */
    '$KYUB' = '$KYUB',
    /** Universal Store of Value */
    'USV' = 'USV',
    /** Echelon */
    'ECH' = 'ECH',
    /** SpiceUSD */
    'USDS' = 'USDS',
    /** LSHARE */
    'LSHARE' = 'LSHARE',
    /** DRAC Network */
    'DRAC' = 'DRAC',
    /** Coin98 Dollar */
    'CUSD' = 'CUSD',
    /** Shibnobi(New) */
    'SHINJA' = 'SHINJA',
    /** Kudoe */
    'KDOE' = 'KDOE',
    /** MusicAI */
    'MUSICAI' = 'MUSICAI',
    /** Aves */
    'AVS' = 'AVS',
    /** Kylacoin */
    'KCN' = 'KCN',
    /** Entropy */
    'ENT' = 'ENT',
    /** OGzClub */
    'OGz' = 'OGz',
    /** YOUWHO */
    'YOU' = 'YOU',
    /** Celo */
    'CELO' = 'CELO',
    /** Celo Euro */
    'CEUR' = 'CEUR',
    /** Plastiks */
    'PLASTIK' = 'PLASTIK',
    /** Moola Celo USD */
    'mCUSD' = 'mCUSD',
    /** Moola Celo EUR */
    'mCEUR' = 'mCEUR',
    /** impactMarket */
    'PACT' = 'PACT',
    /** Celo Brazilian Real */
    'CREAL' = 'CREAL',
    /** Moola Celo */
    'mCELO' = 'mCELO',
    /** ImmortalDAO Finance */
    'IMMO' = 'IMMO',
    /** Mobius Money */
    'MOBI' = 'MOBI',
    /** Ari Swap */
    'ARI' = 'ARI',
    /** Poof.cash */
    'POOF' = 'POOF',
    /** Beefy */
    'BIFI' = 'BIFI',
    /** TrueFeedBack */
    'TFBX' = 'TFBX',
    /** USD Coin (Wormhole) */
    'USDC(WormHole)' = 'USDC(WormHole)',
    /** Glo Dollar */
    'USDGLO' = 'USDGLO',
    /** Moss Carbon Credit */
    'MCO2' = 'MCO2',
    /** KnoxDAO */
    'KNOX' = 'KNOX',
    /** EthicHub */
    'ETHIX' = 'ETHIX',
    /** GoodDollar */
    'G$' = 'G$',
    /** Symmetric */
    'SYMM' = 'SYMM',
    /** Doge Eat Doge */
    'OMNOM' = 'OMNOM',
    /** Wrapped WDOGE */
    'WWDOGE' = 'WWDOGE',
    /** YodeSwap */
    'YODE' = 'YODE',
    /** DogeShiba */
    'DOSHIB' = 'DOSHIB',
    /** Poo Doge */
    'POO DOGE' = 'POO DOGE',
    /** Dogechain */
    'DC' = 'DC',
    /** Dogira */
    'DOGIRA' = 'DOGIRA',
    /** Duckereum */
    'DUCKER' = 'DUCKER',
    /** MultiversX */
    'EGLD' = 'EGLD',
    /** Hatom */
    'HTM' = 'HTM',
    /** Cantina Royale */
    'CRT' = 'CRT',
    /** ZoidPay */
    'ZPAY' = 'ZPAY',
    /** Emorya Finance */
    'EMR' = 'EMR',
    /** Proteo DeFi */
    'PROTEO' = 'PROTEO',
    /** Ta-da */
    'TADA' = 'TADA',
    /** BH Network */
    'BHAT' = 'BHAT',
    /** Itheum */
    'ITHEUM' = 'ITHEUM',
    /** xExchange */
    'MEX' = 'MEX',
    /** AshSwap */
    'ASH' = 'ASH',
    /** Sense4FIT */
    'SFIT' = 'SFIT',
    /** QoWatt */
    'QWT' = 'QWT',
    /** Moove Protocol */
    'MOOVE' = 'MOOVE',
    /** PeerMe */
    'SUPER' = 'SUPER',
    /** Aerovek Aviation */
    'AERO' = 'AERO',
    /** JEXchange */
    'JEX' = 'JEX',
    /** CheckerChain */
    'CHECKR' = 'CHECKR',
    /** GoCharge Tech */
    'CHARGED' = 'CHARGED',
    /** Plata Network */
    'PLATA' = 'PLATA',
    /** Effort Economy */
    'EFFORT' = 'EFFORT',
    /** Ofero */
    'OFE' = 'OFE',
    /** Krogan */
    'KRO' = 'KRO',
    /** Vital Network */
    'VITAL' = 'VITAL',
    /** Beskar */
    'BSK-BAA025' = 'BSK-BAA025',
    /** xMoney */
    'UTK' = 'UTK',
    /** holoride */
    'RIDE' = 'RIDE',
    /** UNUS SED LEO */
    'LEO' = 'LEO',
    /** Mantle */
    'MNT' = 'MNT',
    /** OKB */
    'OKB' = 'OKB',
    /** Render */
    'RNDR' = 'RNDR',
    /** Immutable */
    'IMX' = 'IMX',
    /** Bitget Token */
    'BGB' = 'BGB',
    /** Ethena */
    'ENA' = 'ENA',
    /** Gala */
    'GALA' = 'GALA',
    /** Quant */
    'QNT' = 'QNT',
    /** Ondo */
    'ONDO' = 'ONDO',
    /** The Sandbox */
    'SAND' = 'SAND',
    /** Starknet */
    'STRK' = 'STRK',
    /** Worldcoin */
    'WLD' = 'WLD',
    /** JasmyCoin */
    'JASMY' = 'JASMY',
    /** Decentraland */
    'MANA' = 'MANA',
    /** KuCoin Token */
    'KCS' = 'KCS',
    /** Echelon Prime */
    'PRIME' = 'PRIME',
    /** GateToken */
    'GT' = 'GT',
    /** Ribbon Finance */
    'RBN' = 'RBN',
    /** Blur */
    'BLUR' = 'BLUR',
    /** dYdX (ethDYDX) */
    'ETHDYDX' = 'ETHDYDX',
    /** Tether Gold */
    'XAUt' = 'XAUt',
    /** MX TOKEN */
    'MX' = 'MX',
    /** Ocean Protocol */
    'OCEAN' = 'OCEAN',
    /** SKALE */
    'SKL' = 'SKL',
    /** ether.fi */
    'ETHFI' = 'ETHFI',
    /** Rocket Pool */
    'RPL' = 'RPL',
    /** Ethereum Name Service */
    'ENS' = 'ENS',
    /** 0x Protocol */
    'ZRX' = 'ZRX',
    /** ssv.network */
    'SSV' = 'SSV',
    /** Livepeer */
    'LPT' = 'LPT',
    /** OriginTrail */
    'TRAC' = 'TRAC',
    /** Biconomy */
    'BICO' = 'BICO',
    /** Golem */
    'GLM' = 'GLM',
    /** Aragon */
    'ANT' = 'ANT',
    /** Illuvium */
    'ILV' = 'ILV',
    /** Loopring */
    'LRC' = 'LRC',
    /** Amp */
    'AMP' = 'AMP',
    /** Metis */
    'METIS' = 'METIS',
    /** Threshold */
    'T' = 'T',
    /** Arkham */
    'ARKM' = 'ARKM',
    /** ZetaChain */
    'ZETA' = 'ZETA',
    /** Yield Guild Games */
    'YGG' = 'YGG',
    /** Reserve Rights */
    'RSR' = 'RSR',
    /** Lido Staked ETH */
    'stETH' = 'stETH',
    /** Wrapped eETH */
    'weETH' = 'weETH',
    /** Rocket Pool ETH */
    'RETH' = 'RETH',
    /** Marinade Staked SOL */
    'MSOL' = 'MSOL',
    /** Coinbase Wrapped Staked ETH */
    'cbETH' = 'cbETH',
    /** Orbler */
    'ORBR' = 'ORBR',
    /** Rollbit Coin */
    'RLB' = 'RLB',
    /** PAAL AI */
    'PAAL' = 'PAAL',
    /** HEX */
    'HEX' = 'HEX',
    /** Propy */
    'PRO' = 'PRO',
    /** Telcoin */
    'TEL' = 'TEL',
    /** Tribe */
    'TRIBE' = 'TRIBE',
    /** 0x0.ai */
    '#0x0' = '0x0',
    /** Convex Finance */
    'CVX' = 'CVX',
    /** Wilder World */
    'WILD' = 'WILD',
    /** Huobi BTC */
    'HBTC' = 'HBTC',
    /** ATOR Protocol */
    'ATOR' = 'ATOR',
    /** Storj */
    'STORJ' = 'STORJ',
    /** Audius */
    'AUDIO' = 'AUDIO',
    /** LCX */
    'LCX' = 'LCX',
    /** UMA */
    'UMA' = 'UMA',
    /** Arcblock */
    'ABT' = 'ABT',
    /** MOG Coin */
    'MOG' = 'MOG',
    /** PayPal USD */
    'PYUSD' = 'PYUSD',
    /** SwissBorg */
    'BORG' = 'BORG',
    /** API3 */
    'API3' = 'API3',
    /** RSS3 */
    'RSS3' = 'RSS3',
    /** GuildFi */
    'GF' = 'GF',
    /** VVS Finance */
    'VVS' = 'VVS',
    /** Vanar Chain */
    'VANRY' = 'VANRY',
    /** Braintrust */
    'BTRST' = 'BTRST',
    /** Aevo */
    'AEVO' = 'AEVO',
    /** FLEX */
    'FLEX' = 'FLEX',
    /** iExec RLC */
    'RLC' = 'RLC',
    /** Syntropy */
    'NOIA' = 'NOIA',
    /** Portal */
    'PORTAL' = 'PORTAL',
    /** Guild of Guardians */
    'GOG' = 'GOG',
    /** Unizen */
    'ZCX' = 'ZCX',
    /** Covalent */
    'CQT' = 'CQT',
    /** IQ */
    'IQ' = 'IQ',
    /** Keep Network */
    'KEEP' = 'KEEP',
    /** Numeraire */
    'NMR' = 'NMR',
    /** Big Time */
    'BIGTIME' = 'BIGTIME',
    /** Powerledger */
    'POWR' = 'POWR',
    /** Pundi X (New) */
    'PUNDIX' = 'PUNDIX',
    /** Status */
    'SNT' = 'SNT',
    /** Multibit */
    'MUBI' = 'MUBI',
    /** Node AI */
    'GPU' = 'GPU',
    /** Bone ShibaSwap */
    'BONE' = 'BONE',
    /** Vulcan Forged (PYR) */
    'PYR' = 'PYR',
    /** TrueFi */
    'TRU' = 'TRU',
    /** STASIS EURO */
    'EURS' = 'EURS',
    /** Dent */
    'DENT' = 'DENT',
    /** Dione Protocol */
    'DIONE' = 'DIONE',
    /** NYM */
    'NYM' = 'NYM',
    /** Sovryn */
    'SOV' = 'SOV',
    /** Request */
    'REQ' = 'REQ',
    /** Victoria VR */
    'VR' = 'VR',
    /** dKargo */
    'DKA' = 'DKA',
    /** ConstitutionDAO */
    'PEOPLE' = 'PEOPLE',
    /** Gemini Dollar */
    'GUSD' = 'GUSD',
    /** Aurora */
    'AURORA' = 'AURORA',
    /** Myria */
    'MYRIA' = 'MYRIA',
    /** Goldfinch */
    'GFI' = 'GFI',
    /** XYO */
    'XYO' = 'XYO',
    /** LooksRare */
    'LOOKS' = 'LOOKS',
    /** Polygon Ecosystem Token */
    'POL' = 'POL',
    /** STP */
    'STPT' = 'STPT',
    /** Orchid */
    'OXT' = 'OXT',
    /** Bifrost */
    'BFC' = 'BFC',
    /** Tectum */
    'TET' = 'TET',
    /** CUDOS */
    'CUDOS' = 'CUDOS',
    /** Huobi Token */
    'HT' = 'HT',
    /** Tokenlon Network Token */
    'LON' = 'LON',
    /** Hifi Finance */
    'HIFI' = 'HIFI',
    /** Metal DAO */
    'MTL' = 'MTL',
    /** Ethernity */
    'ERN' = 'ERN',
    /** RARI */
    'RARI' = 'RARI',
    /** Liquity */
    'LQTY' = 'LQTY',
    /** Bancor */
    'BNT' = 'BNT',
    /** Origin Protocol */
    'OGN' = 'OGN',
    /** Aavegotchi */
    'GHST' = 'GHST',
    /** OMG Network */
    'OMG' = 'OMG',
    /** Heroes of Mavia */
    'MAVIA' = 'MAVIA',
    /** Bazaars */
    'BZR' = 'BZR',
    /** Adventure Gold */
    'AGLD' = 'AGLD',
    /** QuarkChain */
    'QKC' = 'QKC',
    /** SuperRare */
    'RARE' = 'RARE',
    /** Wrapped NXM */
    'WNXM' = 'WNXM',
    /** Clearpool */
    'CPOOL' = 'CPOOL',
    /** Gitcoin */
    'GTC' = 'GTC',
    /** Dimitra */
    'DMTR' = 'DMTR',
    /** Maple */
    'MPL' = 'MPL',
    /** PepeFork */
    'PORK' = 'PORK',
    /** StormX */
    'STMX' = 'STMX',
    /** Agoras: Currency of Tau */
    'AGRS' = 'AGRS',
    /** LeverFi */
    'LEVER' = 'LEVER',
    /** Perpetual Protocol */
    'PERP' = 'PERP',
    /** Dora Factory */
    'DORA' = 'DORA',
    /** Boson Protocol */
    'BOSON' = 'BOSON',
    /** Rally */
    'RLY' = 'RLY',
    /** Cobak Token */
    'CBK' = 'CBK',
    /** tomiNet */
    'TOMI' = 'TOMI',
    /** Function X */
    'FX' = 'FX',
    /** Ultra */
    'UOS' = 'UOS',
    /** Ampleforth Governance Token */
    'FORTH' = 'FORTH',
    /** Uquid Coin */
    'UQC' = 'UQC',
    /** Gods Unchained */
    'GODS' = 'GODS',
    /** Ren */
    'REN' = 'REN',
    /** NEOPIN */
    'NPT' = 'NPT',
    /** FUNToken */
    'FUN' = 'FUN',
    /** Pandora */
    'PANDORA' = 'PANDORA',
    /** Carry */
    'CRE' = 'CRE',
    /** Sweat Economy */
    'SWEAT' = 'SWEAT',
    /** Propchain */
    'PROPC' = 'PROPC',
    /** Alchemix */
    'ALCX' = 'ALCX',
    /** DeFi Pulse Index */
    'DPI' = 'DPI',
    /** Enzyme */
    'MLN' = 'MLN',
    /** The Root Network */
    'ROOT' = 'ROOT',
    /** Lyra */
    'LYRA' = 'LYRA',
    /** Zero1 Labs */
    'DEAI' = 'DEAI',
    /** Hunt Town */
    'HUNT' = 'HUNT',
    /** Verasity */
    'VRA' = 'VRA',
    /** 5ire */
    '5IRE' = '5IRE',
    /** Moon Tropica */
    'CAH' = 'CAH',
    /** FirmaChain */
    'FCT' = 'FCT',
    /** PlayDapp */
    'PDA' = 'PDA',
    /** Niza Global */
    'NIZA' = 'NIZA',
    /** sUSD */
    'SUSD' = 'SUSD',
    /** Morpheus.Network */
    'MNW' = 'MNW',
    /** Reserve */
    'RSV' = 'RSV',
    /** Whiteheart */
    'WHITE' = 'WHITE',
    /** IDEX */
    'IDEX' = 'IDEX',
    /** EURC */
    'EURC' = 'EURC',
    /** Assemble Protocol */
    'ASM' = 'ASM',
    /** cheqd */
    'CHEQ' = 'CHEQ',
    /** Cere Network */
    'CERE' = 'CERE',
    /** Doge Killer */
    'LEASH' = 'LEASH',
    /** League of Kingdoms Arena */
    'LOKA' = 'LOKA',
    /** Isiklar Coin */
    'ISIKC' = 'ISIKC',
    /** Kishu Inu */
    'KISHU' = 'KISHU',
    /** Sentinel Protocol */
    'UPP' = 'UPP',
    /** Moss Coin */
    'MOC' = 'MOC',
    /** HOPR */
    'HOPR' = 'HOPR',
    /** Botto */
    'BOTTO' = 'BOTTO',
    /** Vectorspace AI */
    'VXV' = 'VXV',
    /** e-Radix */
    'EXRD' = 'EXRD',
    /** ParagonsDAO */
    'PDT' = 'PDT',
    /** Vega Protocol */
    'VEGA' = 'VEGA',
    /** Tether EURt */
    'EURt' = 'EURt',
    /** Gelato */
    'GEL' = 'GEL',
    /** XSGD */
    'XSGD' = 'XSGD',
    /** Celsius */
    'CEL' = 'CEL',
    /** Syncus */
    'SYNC' = 'SYNC',
    /** Stratos */
    'STOS' = 'STOS',
    /** AVINOC */
    'AVINOC' = 'AVINOC',
    /** ASD */
    'ASD' = 'ASD',
    /** Stronghold Token */
    'SHX' = 'SHX',
    /** ONBUFF */
    'ONIT' = 'ONIT',
    /** NFT Worlds */
    'WRLD' = 'WRLD',
    /** Smart Layer Network */
    'SLN' = 'SLN',
    /** Keep3rV1 */
    'KP3R' = 'KP3R',
    /** ALTAVA */
    'TAVA' = 'TAVA',
    /** StaFi */
    'FIS' = 'FIS',
    /** Aura Finance */
    'AURA' = 'AURA',
    /** Across Protocol */
    'ACX' = 'ACX',
    /** Crypto Asset Governance Alliance */
    'CAGA' = 'CAGA',
    /** QuickSwap [Old] */
    'QUICK' = 'QUICK',
    /** PointPay */
    'PXP' = 'PXP',
    /** Nuco.cloud */
    'NCDT' = 'NCDT',
    /** Swarm Markets */
    'SMT' = 'SMT',
    /** ISKRA Token */
    'ISK' = 'ISK',
    /** Kryll */
    'KRL' = 'KRL',
    /** Dejitaru Tsuka */
    'TSUKA' = 'TSUKA',
    /** Blocksquare Token */
    'BST' = 'BST',
    /** Akropolis */
    'AKRO' = 'AKRO',
    /** GET Protocol */
    'GET' = 'GET',
    /** Ampleforth */
    'AMPL' = 'AMPL',
    /** Rakon */
    'RKN' = 'RKN',
    /** Kava Lend */
    'HARD' = 'HARD',
    /** Inverse Finance */
    'INV' = 'INV',
    /** USDK */
    'USDK' = 'USDK',
    /** WAGMI Games */
    'WAGMIGAMES' = 'WAGMIGAMES',
    /** UFO Gaming */
    'UFO' = 'UFO',
    /** Shiba Predator */
    'QOM' = 'QOM',
    /** Cryptex Finance */
    'CTX' = 'CTX',
    /** Swarm */
    'BZZ' = 'BZZ',
    /** Optimus AI */
    'OPTI' = 'OPTI',
    /** BarnBridge */
    'BOND' = 'BOND',
    /** Starlink */
    'STARL' = 'STARL',
    /** Kleros */
    'PNK' = 'PNK',
    /** zKML */
    'ZKML' = 'ZKML',
    /** Yield App */
    'YLD' = 'YLD',
    /** GameSwift */
    'GSWIFT' = 'GSWIFT',
    /** Castello Coin */
    'CAST' = 'CAST',
    /** Asterix Labs */
    'ASTX' = 'ASTX',
    /** ICHI */
    'ICHI' = 'ICHI',
    /** Locus Chain */
    'LOCUS' = 'LOCUS',
    /** Arianee */
    'ARIA20' = 'ARIA20',
    /** Aventus */
    'AVT' = 'AVT',
    /** Hermez Network */
    'HEZ' = 'HEZ',
    /** SHPING */
    'SHPING' = 'SHPING',
    /** ZUSD */
    'ZUSD' = 'ZUSD',
    /** BioPassport Token */
    'BIOT' = 'BIOT',
    /** Dragonchain */
    'DRGN' = 'DRGN',
    /** Viberate */
    'VIB' = 'VIB',
    /** Bubblefong */
    'BBF' = 'BBF',
    /** Polaris Share */
    'POLA' = 'POLA',
    /** VoluMint */
    'VMINT' = 'VMINT',
    /** Prisma mkUSD */
    'MKUSD' = 'MKUSD',
    /** Coinweb */
    'CWEB' = 'CWEB',
    /** Quiztok */
    'QTCON' = 'QTCON',
    /** Metacade */
    'MCADE' = 'MCADE',
    /** SPECTRE AI */
    'SPECTRE' = 'SPECTRE',
    /** OAX */
    'OAX' = 'OAX',
    /** Voyager Token */
    'VGX' = 'VGX',
    /** Bad Idea AI */
    'BAD' = 'BAD',
    /** Cult DAO */
    'CULT' = 'CULT',
    /** Crust Network */
    'CRU' = 'CRU',
    /** SpookySwap */
    'BOO' = 'BOO',
    /** Thrupenny */
    'TPY' = 'TPY',
    /** Fei USD */
    'FEI' = 'FEI',
    /** Atlas Navi */
    'NAVI' = 'NAVI',
    /** rhino.fi */
    'DVF' = 'DVF',
    /** SOLVE */
    'SOLVE' = 'SOLVE',
    /** LeisureMeta */
    'LM' = 'LM',
    /** Image Generation AI */
    'IMGNAI' = 'IMGNAI',
    /** Bitrock */
    'BROCK' = 'BROCK',
    /** Ferro */
    'FER' = 'FER',
    /** GYEN */
    'GYEN' = 'GYEN',
    /** Hegic */
    'HEGIC' = 'HEGIC',
    /** Push Protocol */
    'PUSH' = 'PUSH',
    /** UniBot */
    'UNIBOT' = 'UNIBOT',
    /** LATOKEN */
    'LA' = 'LA',
    /** PowerPool */
    'CVP' = 'CVP',
    /** Prometheum Prodigy */
    'PMPY' = 'PMPY',
    /** BeFi Labs */
    'BEFI' = 'BEFI',
    /** Akita Inu */
    'AKITA' = 'AKITA',
    /** Unicly CryptoPunks Collection */
    'UPUNK' = 'UPUNK',
    /** Somnium Space Cubes */
    'CUBE' = 'CUBE',
    /** SENATE */
    'SENATE' = 'SENATE',
    /** Unibright */
    'UBT' = 'UBT',
    /** Realio Network */
    'RIO' = 'RIO',
    /** Robonomics.network */
    'XRT' = 'XRT',
    /** Big Data Protocol */
    'BDP' = 'BDP',
    /** LandX Finance */
    'LNDX' = 'LNDX',
    /** Winnerz */
    'WNZ' = 'WNZ',
    /** AI Analysis Token */
    'AIAT' = 'AIAT',
    /** Ooki Protocol */
    'OOKI' = 'OOKI',
    /** Konnect */
    'KCT' = 'KCT',
    /** Choise.com */
    'CHO' = 'CHO',
    /** Bepro */
    'BEPRO' = 'BEPRO',
    /** Lambda */
    'LAMB' = 'LAMB',
    /** HUSD */
    'HUSD' = 'HUSD',
    /** Lossless */
    'LSS' = 'LSS',
    /** Angola */
    'AGLA' = 'AGLA',
    /** Origin DeFi Governance */
    'OGV' = 'OGV',
    /** RAI Finance */
    'SOFI' = 'SOFI',
    /** ArkiTech */
    'ARKI' = 'ARKI',
    /** Props Token */
    'PROPS' = 'PROPS',
    /** NFTX */
    'NFTX' = 'NFTX',
    /** Project WITH */
    'WIKEN' = 'WIKEN',
    /** EML Protocol */
    'EML' = 'EML',
    /** Decentral Games */
    'DG' = 'DG',
    /** Augur */
    'REP' = 'REP',
    /** Hoge Finance */
    'HOGE' = 'HOGE',
    /** BABB */
    'BAX' = 'BAX',
    /** ZEON */
    'ZEON' = 'ZEON',
    /** Valor Token */
    'VALOR' = 'VALOR',
    /** Dacxi */
    'DACXI' = 'DACXI',
    /** Red Kite */
    'PKF' = 'PKF',
    /** STFX */
    'STFX' = 'STFX',
    /** Linker Coin */
    'LNC' = 'LNC',
    /** XDEFI Wallet */
    'XDEFI' = 'XDEFI',
    /** Gameswap */
    'GSWAP' = 'GSWAP',
    /** BitKan */
    'KAN' = 'KAN',
    /** Origin Dollar */
    'OUSD' = 'OUSD',
    /** MongCoin */
    '$MONG' = '$MONG',
    /** Efinity Token */
    'EFI' = 'EFI',
    /** Minted */
    'MTD' = 'MTD',
    /** Pluton */
    'PLU' = 'PLU',
    /** Lithium */
    'LITH' = 'LITH',
    /** Geeq */
    'GEEQ' = 'GEEQ',
    /** BitForex Token */
    'BF' = 'BF',
    /** Era Swap */
    'ES' = 'ES',
    /** Mancium */
    'MANC' = 'MANC',
    /** Wombat Web 3 Gaming Platform */
    'WOMBAT' = 'WOMBAT',
    /** Lush AI */
    'LUSH' = 'LUSH',
    /** HAVAH */
    'HVH' = 'HVH',
    /** MASQ */
    'MASQ' = 'MASQ',
    /** Receive Access Ecosystem */
    'RAE' = 'RAE',
    /** Muse */
    'MUSE' = 'MUSE',
    /** Unisocks */
    'SOCKS' = 'SOCKS',
    /** VIXCO */
    'VIX' = 'VIX',
    /** Hourglass */
    'WAIT' = 'WAIT',
    /** GensoKishi Metaverse */
    'MV' = 'MV',
    /** Blockchain Brawlers */
    'BRWL' = 'BRWL',
    /** beoble */
    'BBL' = 'BBL',
    /** Skey Network */
    'SKEY' = 'SKEY',
    /** Pintu Token */
    'PTU' = 'PTU',
    /** BreederDAO */
    'BREED' = 'BREED',
    /** Adappter Token */
    'ADP' = 'ADP',
    /** dHedge DAO */
    'DHT' = 'DHT',
    /** King DAG */
    'KDAG' = 'KDAG',
    /** Veloce */
    'VEXT' = 'VEXT',
    /** SHOPX */
    'SHOPX' = 'SHOPX',
    /** Netvrk */
    'NETVR' = 'NETVR',
    /** Presearch */
    'PRE' = 'PRE',
    /** WHALE */
    'WHALE' = 'WHALE',
    /** dAppstore */
    'DAPPX' = 'DAPPX',
    /** Tamadoge */
    'TAMA' = 'TAMA',
    /** Darwinia Network */
    'RING' = 'RING',
    /** LootBot */
    'LOOT' = 'LOOT',
    /** Aether Games */
    'AEG' = 'AEG',
    /** ZKBase */
    'ZKB' = 'ZKB',
    /** Emirex Token */
    'EMRX' = 'EMRX',
    /** Bitrue Coin */
    'BTR' = 'BTR',
    /** Real Smurf Cat (ETH) */
    'SMURFCAT' = 'SMURFCAT',
    /** Bag */
    'BAG' = 'BAG',
    /** XELS */
    'XELS' = 'XELS',
    /** DAD */
    'DAD' = 'DAD',
    /** Pikaboss */
    'PIKA' = 'PIKA',
    /** TomTomCoin */
    'TOMS' = 'TOMS',
    /** Orbit Chain */
    'ORC' = 'ORC',
    /** Etherisc DIP Token */
    'DIP' = 'DIP',
    /** Sylo */
    'SYLO' = 'SYLO',
    /** Gracy */
    'GRACY' = 'GRACY',
    /** Govi */
    'GOVI' = 'GOVI',
    /** Coupon Assets */
    'CA' = 'CA',
    /** STAT */
    'STAT' = 'STAT',
    /** Rari Governance Token */
    'RGT' = 'RGT',
    /** Chainbing */
    'CBG' = 'CBG',
    /** Blocery */
    'BLY' = 'BLY',
    /** Polkadex */
    'PDEX' = 'PDEX',
    /** Perion */
    'PERC' = 'PERC',
    /** Prime Numbers Labs Ecosystem */
    'PRNT' = 'PRNT',
    /** IMPT */
    'IMPT' = 'IMPT',
    /** Onooks */
    'OOKS' = 'OOKS',
    /** SENSO */
    'SENSO' = 'SENSO',
    /** B-cube.ai */
    'BCUBE' = 'BCUBE',
    /** Rai Reflex Index */
    'RAI' = 'RAI',
    /** Dust Protocol */
    'DUST' = 'DUST',
    /** PlayFuel */
    'PLF' = 'PLF',
    /** Shiryo */
    'SHIRYO' = 'SHIRYO',
    /** Bware */
    'INFRA' = 'INFRA',
    /** SunContract */
    'SNC' = 'SNC',
    /** WeFi */
    'WEFI' = 'WEFI',
    /** Super Trump */
    'STRUMP' = 'STRUMP',
    /** Verse */
    'VERSE' = 'VERSE',
    /** Unification */
    'FUND' = 'FUND',
    /** Strips Finance */
    'STRP' = 'STRP',
    /** AmonD */
    'AMON' = 'AMON',
    /** GNY */
    'GNY' = 'GNY',
    /** Spheroid Universe */
    'SPH' = 'SPH',
    /** Vesper */
    'VSP' = 'VSP',
    /** Crypterium */
    'CRPT' = 'CRPT',
    /** Vabble */
    'VAB' = 'VAB',
    /** AXIS Token */
    'AXIS' = 'AXIS',
    /** OpenDAO */
    'SOS' = 'SOS',
    /** KiboShib */
    'KIBSHI' = 'KIBSHI',
    /** Electric Vehicle Zone */
    'EVZ' = 'EVZ',
    /** Morpheus Labs */
    'MITX' = 'MITX',
    /** Sheboshis */
    'SHEB' = 'SHEB',
    /** Neighbourhoods */
    'NHT' = 'NHT',
    /** MindAI */
    'MDAI' = 'MDAI',
    /** Efforce */
    'WOZX' = 'WOZX',
    /** BendDAO */
    'BEND' = 'BEND',
    /** Unix Gaming */
    'UNIX' = 'UNIX',
    /** Dfyn Network */
    'DFYN' = 'DFYN',
    /** BaaSid */
    'BAAS' = 'BAAS',
    /** KINE */
    'KINE' = 'KINE',
    /** Gorilla */
    'GORILLA' = 'GORILLA',
    /** Lattice Token */
    'LTX' = 'LTX',
    /** TriumphX */
    'TRIX' = 'TRIX',
    /** DAFI Protocol */
    'DAFI' = 'DAFI',
    /** DxChain Token */
    'DX' = 'DX',
    /** Kylin */
    'KYL' = 'KYL',
    /** EXNT */
    'EXNT' = 'EXNT',
    /** Wecan Group */
    'WECAN' = 'WECAN',
    /** DeFrogs */
    'DEFROGS' = 'DEFROGS',
    /** yAxis */
    'YAXIS' = 'YAXIS',
    /** Pickle Finance */
    'PICKLE' = 'PICKLE',
    /** saffron.finance */
    'SFI' = 'SFI',
    /** CropBytes */
    'CBX' = 'CBX',
    /** BOLT */
    'BOLT' = 'BOLT',
    /** Polkamarkets */
    'POLK' = 'POLK',
    /** Lympo */
    'LYM' = 'LYM',
    /** Standard */
    'STND' = 'STND',
    /** Jobchain */
    'JOB' = 'JOB',
    /** Niftyx Protocol */
    'SHROOM' = 'SHROOM',
    /** BTSE Token */
    'BTSE' = 'BTSE',
    /** mStable Governance Token: Meta (MTA) */
    'MTA' = 'MTA',
    /** UPCX */
    'UPC' = 'UPC',
    /** Bitune */
    'TUNE' = 'TUNE',
    /** Vidya */
    'VIDYA' = 'VIDYA',
    /** Castle of Blackwater */
    'COBE' = 'COBE',
    /** Civilization */
    'CIV' = 'CIV',
    /** LGCY Network */
    'LGCY' = 'LGCY',
    /** Baanx */
    'BXX' = 'BXX',
    /** VNX Euro */
    'VEUR' = 'VEUR',
    /** Drep [new] */
    'DREP' = 'DREP',
    /** DEVAI */
    '0XDEV' = '0XDEV',
    /** Idle */
    'IDLE' = 'IDLE',
    /** Launchblock.com */
    'LBP' = 'LBP',
    /** Ziktalk */
    'ZIK' = 'ZIK',
    /** AurusX */
    'AX' = 'AX',
    /** Razor Network */
    'RAZOR' = 'RAZOR',
    /** My Crypto Heroes */
    'MCHC' = 'MCHC',
    /** Damex Token */
    'DAMEX' = 'DAMEX',
    /** ZELIX */
    'ZELIX' = 'ZELIX',
    /** K21 */
    'K21' = 'K21',
    /** Tap */
    'XTP' = 'XTP',
    /** MAX Exchange Token */
    'MAX' = 'MAX',
    /** Grape */
    'GRP' = 'GRP',
    /** DerivaDAO */
    'DDX' = 'DDX',
    /** ALLY */
    'ALY' = 'ALY',
    /** IQ Protocol */
    'IQT' = 'IQT',
    /** VNX Swiss Franc */
    'VCHF' = 'VCHF',
    /** Fenerbahçe Token */
    'FB' = 'FB',
    /** KEK */
    'KEKE' = 'KEKE',
    /** Attila */
    'ATT' = 'ATT',
    /** Skeb Coin */
    'SKEB' = 'SKEB',
    /** Alvara Protocol */
    'ALVA' = 'ALVA',
    /** Konomi Network */
    'KONO' = 'KONO',
    /** YAM V3 */
    'YAM' = 'YAM',
    /** Integral */
    'ITGR' = 'ITGR',
    /** Monavale */
    'MONA' = 'MONA',
    /** ELMOERC */
    'ELMO' = 'ELMO',
    /** Crust Shadow */
    'CSM' = 'CSM',
    /** Humaniq */
    'HMQ' = 'HMQ',
    /** STAKE */
    'STAKE' = 'STAKE',
    /** Convergence */
    'CONV' = 'CONV',
    /** TOP */
    'TOP' = 'TOP',
    /** hiPENGUINS */
    'HIPENGUINS' = 'HIPENGUINS',
    /** BLOX */
    'BLOX' = 'BLOX',
    /** Base Protocol */
    'BASE' = 'BASE',
    /** PUMLx */
    'PUMLX' = 'PUMLX',
    /** Thorstarter */
    'XRUNE' = 'XRUNE',
    /** Neutrino Token */
    'NSBT' = 'NSBT',
    /** Breezecoin */
    'BRZE' = 'BRZE',
    /** Leverj Gluon */
    'L2' = 'L2',
    /** Pillar */
    'PLR' = 'PLR',
    /** Hypr Network */
    'HYPR' = 'HYPR',
    /** Eden */
    'EDEN' = 'EDEN',
    /** Idavoll DAO */
    'IDV' = 'IDV',
    /** Unistake */
    'UNISTAKE' = 'UNISTAKE',
    /** XMON */
    'XMON' = 'XMON',
    /** Fringe Finance */
    'FRIN' = 'FRIN',
    /** CONTRACOIN */
    'CTCN' = 'CTCN',
    /** Geojam Token */
    'JAM' = 'JAM',
    /** Nvidia Inu */
    'NINU' = 'NINU',
    /** All In */
    'ALLIN' = 'ALLIN',
    /** Eminer */
    'EM' = 'EM',
    /** Nord Finance */
    'NORD' = 'NORD',
    /** CEREAL */
    'CEP' = 'CEP',
    /** SafeMoon Inu */
    'SMI' = 'SMI',
    /** Rook */
    'ROOK' = 'ROOK',
    /** Shiba Saga */
    'SHIA' = 'SHIA',
    /** 3space Art */
    'PACE' = 'PACE',
    /** Waltonchain */
    'WTC' = 'WTC',
    /** Elitium */
    'EUM' = 'EUM',
    /** MAPS */
    'MAPS' = 'MAPS',
    /** EXMO Coin */
    'EXM' = 'EXM',
    /** Throne */
    'THN' = 'THN',
    /** FidexToken */
    'FEX' = 'FEX',
    /** Dentacoin */
    'DCN' = 'DCN',
    /** Monetha */
    'MTH' = 'MTH',
    /** 0xBitcoin */
    '0xBTC' = '0xBTC',
    /** hiDOODLES */
    'HIDOODLES' = 'HIDOODLES',
    /** Dragonball Z Tribute */
    'DBZ' = 'DBZ',
    /** Abyss */
    'ABYSS' = 'ABYSS',
    /** Residual Token */
    'eRSDL' = 'eRSDL',
    /** VNX Gold */
    'VNXAU' = 'VNXAU',
    /** LOCGame */
    'LOCG' = 'LOCG',
    /** Alphabet */
    'ALPHABET' = 'ALPHABET',
    /** Tranche Finance */
    'SLICE' = 'SLICE',
    /** Meme Alliance */
    'MMA' = 'MMA',
    /** TE-FOOD */
    'TONE' = 'TONE',
    /** Acquire.Fi */
    'ACQ' = 'ACQ',
    /** CRYPTO20 */
    'C20' = 'C20',
    /** Salad */
    'SALD' = 'SALD',
    /** Avatly (New) */
    'AVATLY' = 'AVATLY',
    /** DEXGame */
    'DXGM' = 'DXGM',
    /** Databroker */
    'DTX' = 'DTX',
    /** Strong */
    'STRONG' = 'STRONG',
    /** KlubCoin */
    'KLUB' = 'KLUB',
    /** Aurora */
    'AOA' = 'AOA',
    /** Ojamu */
    'OJA' = 'OJA',
    /** AGA Token */
    'AGA' = 'AGA',
    /** Falconswap */
    'FSW' = 'FSW',
    /** Genaro Network */
    'GNX' = 'GNX',
    /** Dawn Protocol */
    'DAWN' = 'DAWN',
    /** Olyverse */
    'OLY' = 'OLY',
    /** MetamonkeyAi */
    'MMAI' = 'MMAI',
    /** hiMFERS */
    'HIMFERS' = 'HIMFERS',
    /** SORA */
    'XOR' = 'XOR',
    /** PlotX */
    'PLOT' = 'PLOT',
    /** ReapChain */
    'REAP' = 'REAP',
    /** Opium */
    'OPIUM' = 'OPIUM',
    /** BlockWallet */
    'BLANK' = 'BLANK',
    /** TrustVerse */
    'TRV' = 'TRV',
    /** Refereum */
    'RFR' = 'RFR',
    /** hiENS3 */
    'HIENS3' = 'HIENS3',
    /** KOK */
    'KOK' = 'KOK',
    /** Handy */
    'HANDY' = 'HANDY',
    /** Oxygen */
    'OXY' = 'OXY',
    /** Voxel X Network */
    'VXL' = 'VXL',
    /** Bridge Mutual */
    'BMI' = 'BMI',
    /** LINKA */
    'LINKA' = 'LINKA',
    /** WaifuAI */
    'WFAI' = 'WFAI',
    /** DIGG */
    'DIGG' = 'DIGG',
    /** 404Aliens */
    '404A' = '404A',
    /** Alpha Token */
    'A' = 'A',
    /** Parachute */
    'PAR' = 'PAR',
    /** HollyGold */
    'HGOLD' = 'HGOLD',
    /** HanChain */
    'HAN' = 'HAN',
    /** UBD Network */
    'UBDN' = 'UBDN',
    /** DomRaider */
    'DRT' = 'DRT',
    /** MurAll */
    'PAINT' = 'PAINT',
    /** Rapidz */
    'RPZX' = 'RPZX',
    /** Papi */
    'PAPI' = 'PAPI',
    /** hiFIDENZA */
    'HIFIDENZA' = 'HIFIDENZA',
    /** Swerve */
    'SWRV' = 'SWRV',
    /** Inex Project */
    'INEX' = 'INEX',
    /** OctoFi */
    'OCTO' = 'OCTO',
    /** hiPUNKS */
    'HIPUNKS' = 'HIPUNKS',
    /** TitanSwap */
    'TITAN' = 'TITAN',
    /** DEXA COIN */
    'DEXA' = 'DEXA',
    /** Ruff */
    'RUFF' = 'RUFF',
    /** CyberVein */
    'CVT' = 'CVT',
    /** DeFine */
    'DFA' = 'DFA',
    /** bAlpha */
    'BALPHA' = 'BALPHA',
    /** Cook Finance */
    'COOK' = 'COOK',
    /** FXDX */
    'FXDX' = 'FXDX',
    /** The QWAN */
    'QWAN' = 'QWAN',
    /** hiFLUF */
    'HIFLUF' = 'HIFLUF',
    /** Swingby */
    'SWINGBY' = 'SWINGBY',
    /** Duckies, the canary network for Yellow */
    'DUCKIES' = 'DUCKIES',
    /** Zoracles */
    'ZORA' = 'ZORA',
    /** hiSQUIGGLE */
    'HISQUIGGLE' = 'HISQUIGGLE',
    /** OnGO */
    'FTG' = 'FTG',
    /** Nerd AI */
    'NERD' = 'NERD',
    /** hiAZUKI */
    'HIAZUKI' = 'HIAZUKI',
    /** 88mph */
    'MPH' = 'MPH',
    /** Xeno Token */
    'XNO' = 'XNO',
    /** Digital Reserve Currency */
    'DRC' = 'DRC',
    /** hiBAYC */
    'HIBAYC' = 'HIBAYC',
    /** Ubex */
    'UBEX' = 'UBEX',
    /** Empty Set Dollar */
    'ESD' = 'ESD',
    /** PowerTrade Fuel */
    'PTF' = 'PTF',
    /** Shopping.io */
    'SHOP' = 'SHOP',
    /** Lokr */
    'LKR' = 'LKR',
    /** hiMAYC */
    'HIMAYC' = 'HIMAYC',
    /** hiCOOLCATS */
    'HICOOLCATS' = 'HICOOLCATS',
    /** Darwinia Commitment Token */
    'KTON' = 'KTON',
    /** Basis Cash */
    'BAC' = 'BAC',
    /** Tidal Finance */
    'TIDAL' = 'TIDAL',
    /** Internxt */
    'INXT' = 'INXT',
    /** Traxx */
    'TRAXX' = 'TRAXX',
    /** Blockzero Labs */
    'XIO' = 'XIO',
    /** Seigniorage Shares */
    'SHARE' = 'SHARE',
    /** SOHOTRN */
    'SOHOT' = 'SOHOT',
    /** ROCKI */
    'ROCKI' = 'ROCKI',
    /** IG Gold */
    'IGG' = 'IGG',
    /** hiMEEBITS */
    'HIMEEBITS' = 'HIMEEBITS',
    /** hiGAZERS */
    'HIGAZERS' = 'HIGAZERS',
    /** SORA Validator Token */
    'VAL' = 'VAL',
    /** Fractal ID */
    'FCL' = 'FCL',
    /** SnowSwap */
    'SNOW' = 'SNOW',
    /** Jarvis Network */
    'JRT' = 'JRT',
    /** BitBall */
    'BTB' = 'BTB',
    /** Hedget */
    'HGET' = 'HGET',
    /** e-Money */
    'NGM' = 'NGM',
    /** APY.Finance */
    'APY' = 'APY',
    /** YFDAI.FINANCE */
    'YF-DAI' = 'YF-DAI',
    /** hiFRIENDS */
    'HIFRIENDS' = 'HIFRIENDS',
    /** hiUNDEAD */
    'HIUNDEAD' = 'HIUNDEAD',
    /** Cyclub */
    'CYCLUB' = 'CYCLUB',
    /** hiRENGA */
    'HIRENGA' = 'HIRENGA',
    /** DeFiner */
    'FIN' = 'FIN',
    /** Governor DAO */
    'GDAO' = 'GDAO',
    /** EarthFund */
    '1EARTH' = '1EARTH',
    /** Pawtocol */
    'UPI' = 'UPI',
    /** RUSH COIN */
    'RUSH' = 'RUSH',
    /** Busy DAO */
    'BUSY' = 'BUSY',
    /** Vodra */
    'VDR' = 'VDR',
    /** Celeum */
    'CLX' = 'CLX',
    /** hiBEANZ */
    'HIBEANZ' = 'HIBEANZ',
    /** TokenBot */
    'TKB' = 'TKB',
    /** hiSAND33 */
    'HISAND33' = 'HISAND33',
    /** ORAO Network */
    'ORAO' = 'ORAO',
    /** Polinate */
    'POLI' = 'POLI',
    /** Hashgard */
    'GARD' = 'GARD',
    /** hiCLONEX */
    'HICLONEX' = 'HICLONEX',
    /** Sphere */
    'SXS' = 'SXS',
    /** hiVALHALLA */
    'HIVALHALLA' = 'HIVALHALLA',
    /** PymeDAO */
    'PYME' = 'PYME',
    /** hiSEALS */
    'HISEALS' = 'HISEALS',
    /** XcelToken Plus */
    'XLAB' = 'XLAB',
    /** Misbloc */
    'MSB' = 'MSB',
    /** GeoDB */
    'GEO' = 'GEO',
    /** Drops Ownership Power */
    'DOP' = 'DOP',
    /** Dogeswap */
    'DOGES' = 'DOGES',
    /** Phoenix Token */
    'PHX' = 'PHX',
    /** Creaticles */
    'CRE8' = 'CRE8',
    /** hiMOONBIRDS */
    'HIMOONBIRDS' = 'HIMOONBIRDS',
    /** hiENS4 */
    'HIENS4' = 'HIENS4',
    /** Bitball Treasure */
    'BTRS' = 'BTRS',
    /** ETHA Lend */
    'ETHA' = 'ETHA',
    /** Coinsbit Token */
    'CNB' = 'CNB',
    /** Sentinel Chain */
    'SENC' = 'SENC',
    /** Blockchain Cuties Universe Governance */
    'BCUG' = 'BCUG',
    /** hiOD */
    'HIOD' = 'HIOD',
    /** hiODBS */
    'HIODBS' = 'HIODBS',
    /** EveryCoin  */
    'EVY' = 'EVY',
    /** HairyPlotterFTX */
    'FTX' = 'FTX',
    /** Rublix */
    'RBLX' = 'RBLX',
    /** Yield Protocol */
    'YIELD' = 'YIELD',
    /** LiquidApps */
    'DAPP' = 'DAPP',
    /** Opacity */
    'OPCT' = 'OPCT',
    /** Flixxo */
    'FLIXX' = 'FLIXX',
    /** Bitspawn */
    'SPWN' = 'SPWN',
    /** NBX */
    'BYN' = 'BYN',
    /** Tokenbox */
    'TBX' = 'TBX',
    /** SynchroBitcoin */
    'SNB' = 'SNB',
    /** Spume Protocol */
    'SPUME' = 'SPUME',
    /** NuriFootBall */
    'NRFB' = 'NRFB',
    /** POLKARARE */
    'PRARE' = 'PRARE',
    /** RED TOKEN */
    'RED' = 'RED',
    /** Jigstack */
    'STAK' = 'STAK',
    /** BoutsPro */
    'BOUTS' = 'BOUTS',
    /** Nsure.Network */
    'NSURE' = 'NSURE',
    /** Uptrennd */
    '1UP' = '1UP',
    /** PYRO Network */
    'PYRO' = 'PYRO',
    /** Azuki */
    'AZUKI' = 'AZUKI',
    /** Alphr finance */
    'ALPHR' = 'ALPHR',
    /** MELX */
    'MEL' = 'MEL',
    /** hiBAKC */
    'HIBAKC' = 'HIBAKC',
    /** Skyrim Finance */
    'SKYRIM' = 'SKYRIM',
    /** Innovative Bioresearch Coin */
    'INNBC' = 'INNBC',
    /** Chimpion */
    'BNANA' = 'BNANA',
    /** Niftify */
    'NIFT' = 'NIFT',
    /** Guider */
    'GDR' = 'GDR',
    /** Rewardiqa */
    'REW' = 'REW',
    /** Indexed Finance */
    'NDX' = 'NDX',
    /** Connectome */
    'CNTM' = 'CNTM',
    /** FUZE Token */
    'FUZE' = 'FUZE',
    /** Kcash */
    'KCASH' = 'KCASH',
    /** HXAcoin */
    'HXA' = 'HXA',
    /** CareCoin */
    'CARE' = 'CARE',
    /** Kuverit */
    'KUV' = 'KUV',
    /** Elementeum */
    'ELET' = 'ELET',
    /** CRDT */
    'CRDT' = 'CRDT',
    /** Mesefa */
    'SEFA' = 'SEFA',
    /** Crystal Token */
    'CYL' = 'CYL',
    /** ASKO */
    'ASKO' = 'ASKO',
    /** PREMA */
    'PRMX' = 'PRMX',
    /** S.Finance */
    'SFG' = 'SFG',
    /** HaloDAO */
    'RNBW' = 'RNBW',
    /** BlackPearl Token */
    'BPLC' = 'BPLC',
    /** WhiteBIT Coin */
    'WBT' = 'WBT',
    /** Kelp DAO Restaked ETH */
    'RSETH' = 'RSETH',
    /** Bitcoin Cats */
    '1CAT' = '1CAT',
    /** Airbloc */
    'ABL' = 'ABL',
    /** ANDY */
    'ANDY' = 'ANDY',
    /** HugeWin */
    'HUGE' = 'HUGE',
    /** Apeiron */
    'APRS' = 'APRS',
    /** Ordiswap */
    'ORDS' = 'ORDS',
    /** pufETH */
    'PUFETH' = 'PUFETH',
    /** dogwifsaudihat */
    'WIFSA' = 'WIFSA',
    /** Truflation */
    'TRUF' = 'TRUF',
    /** Normie */
    'NORMIE' = 'NORMIE',
    /** SMILEY */
    'SMILEY' = 'SMILEY',
    /** Altered State Token */
    'ASTO' = 'ASTO',
    /** IPVERSE */
    'IPV' = 'IPV',
    /** Gala Music */
    'MUSIC' = 'MUSIC',
    /** Mantle Staked Ether */
    'METH' = 'METH',
    /** Savings Dai */
    'SDAI' = 'SDAI',
    /** Zuzalu Inu */
    'ZUZALU' = 'ZUZALU',
    /** Apu Apustaja */
    'APU' = 'APU',
    /** Joseon Mun */
    'JSM' = 'JSM',
    /** PepeCoin */
    'PEPECOIN' = 'PEPECOIN',
    /** Edelcoin */
    'EDLC' = 'EDLC',
    /** ENTERBUTTON */
    'ENTC' = 'ENTC',
    /** Prisma Finance */
    'PRISMA' = 'PRISMA',
    /** Restaked Swell Ethereum */
    'RSWETH' = 'RSWETH',
    /** Angle Protocol */
    'AGEUR' = 'AGEUR',
    /** Eesee */
    'ESE' = 'ESE',
    /** Vitalik Smart Gas */
    'VSG' = 'VSG',
    /** SatoshiVM */
    'SAVM' = 'SAVM',
    /** TurtSat */
    'TURT' = 'TURT',
    /** Virtual Protocol */
    'VIRTUAL' = 'VIRTUAL',
    /** Fluence */
    'FLT' = 'FLT',
    /** BitStable */
    'BSSB' = 'BSSB',
    /** Next Gem AI */
    'GEMAI' = 'GEMAI',
    /** Arcana Network */
    'XAR' = 'XAR',
    /** BasedAI */
    'BASEDAI' = 'BASEDAI',
    /** P2P Solutions foundation */
    'P2PS' = 'P2PS',
    /** LBK */
    'LBK' = 'LBK',
    /** Blendr Network */
    'BLENDR' = 'BLENDR',
    /** Autonolas */
    'OLAS' = 'OLAS',
    /** PDX Coin */
    'PDX' = 'PDX',
    /** DGI Game */
    'DGI' = 'DGI',
    /** Chainflip */
    'FLIP' = 'FLIP',
    /** Everyworld */
    'EVERY' = 'EVERY',
    /** RepubliK */
    'RPK' = 'RPK',
    /** Railgun */
    'RAIL' = 'RAIL',
    /** Reality Metaverse */
    'RMV' = 'RMV',
    /** Wrapped TAO */
    'WTAO' = 'WTAO',
    /** Structure finance */
    'STF' = 'STF',
    /** Love Hate Inu */
    'LHINU' = 'LHINU',
    /** XT.com Token */
    'XT' = 'XT',
    /** Planet Mojo */
    'MOJO' = 'MOJO',
    /** Brett */
    'BRETT' = 'BRETT',
    /** Taproot Exchange */
    'TAPROOT' = 'TAPROOT',
    /** Floor Protocol */
    'FLC' = 'FLC',
    /** ChainSwap */
    'CSWAP' = 'CSWAP',
    /** Phemex Token */
    'PT' = 'PT',
    /** HashAI */
    'HASHAI' = 'HASHAI',
    /** Spectra Chain */
    'SPCT' = 'SPCT',
    /** OpSec */
    'OPSEC' = 'OPSEC',
    /** Nettensor */
    'NAO' = 'NAO',
    /** Destra Network */
    'DSYNC' = 'DSYNC',
    /** Wojak */
    'WOJAK' = 'WOJAK',
    /** Fellaz */
    'FLZ' = 'FLZ',
    /** SIGN */
    'SIGN' = 'SIGN',
    /** Ÿ */
    'YAI' = 'YAI',
    /** StakeWise Staked ETH */
    'osETH' = 'osETH',
    /** AVAV */
    'AVAV' = 'AVAV',
    /** fanC */
    'FANC' = 'FANC',
    /** Curve.fi FRAX/USDC */
    'CRVFRAX' = 'CRVFRAX',
    /** Australian Dollar Token */
    'AUDT' = 'AUDT',
    /** VPS AI */
    'VPS' = 'VPS',
    /** CAW(A Hunters Dream) */
    'CAW' = 'CAW',
    /** SubQuery Network */
    'SQT' = 'SQT',
    /** Convex CRV */
    'CVXCRV' = 'CVXCRV',
    /** I love puppies */
    'PUPPIES' = 'PUPPIES',
    /** Shuffle */
    'SHFL' = 'SHFL',
    /** APF coin */
    'APFC' = 'APFC',
    /** RivusDAO */
    'RIVUS' = 'RIVUS',
    /** Euler */
    'EUL' = 'EUL',
    /** ROGin AI */
    'ROG' = 'ROG',
    /** Perro Dinero */
    'JOTCHUA' = 'JOTCHUA',
    /** AIgentX */
    'AIX' = 'AIX',
    /** LinqAI */
    'LNQ' = 'LNQ',
    /** USK */
    'USK' = 'USK',
    /** Stader ETHx */
    'ETHX' = 'ETHX',
    /** RAYN */
    'AKTIO' = 'AKTIO',
    /** Velar */
    'VELAR' = 'VELAR',
    /** Inscribe */
    'INS' = 'INS',
    /** Houdini Swap */
    'LOCK' = 'LOCK',
    /** GHO */
    'GHO' = 'GHO',
    /** QToken */
    'QTO' = 'QTO',
    /** PlasticHero */
    'PTH' = 'PTH',
    /** Peapods Finance */
    'PEAS' = 'PEAS',
    /** Switch */
    'SWITCH' = 'SWITCH',
    /** 1ex Trading Board */
    '1EX' = '1EX',
    /** Arbius */
    'AIUS' = 'AIUS',
    /** Vector */
    'VEC' = 'VEC',
    /** IX Swap */
    'IXS' = 'IXS',
    /** Gaga (Pepe) */
    'GAGA' = 'GAGA',
    /** LuckysLeprecoin */
    'LUCKYSLP' = 'LUCKYSLP',
    /** DAV Coin */
    'DAV' = 'DAV',
    /** EthXY */
    'SEXY' = 'SEXY',
    /** Shadow Node */
    'SVPN' = 'SVPN',
    /** Devve */
    'DEVVE' = 'DEVVE',
    /** Not Financial Advice */
    'NFAI' = 'NFAI',
    /** Nitefeeder */
    'NITEFEEDER' = 'NITEFEEDER',
    /** Amino */
    'AMO' = 'AMO',
    /** bitsCrunch */
    'BCUT' = 'BCUT',
    /** Charged Particles */
    'IONX' = 'IONX',
    /** FairERC20 */
    'ferc' = 'ferc',
    /** Ordibank */
    'ORBK' = 'ORBK',
    /** swETH */
    'SWETH' = 'SWETH',
    /** sudoswap */
    'SUDO' = 'SUDO',
    /** Sommelier */
    'SOMM' = 'SOMM',
    /** Scalia Infrastructure */
    'SCALE' = 'SCALE',
    /** Chickencoin */
    'CHKN' = 'CHKN',
    /** Humans.ai */
    'HEART' = 'HEART',
    /** FUTUREXCRYPTO */
    'FXC' = 'FXC',
    /** BobaCat */
    'PSPS' = 'PSPS',
    /** GG MetaGame */
    'GGMT' = 'GGMT',
    /** K9 Finance */
    'KNINE' = 'KNINE',
    /** Apes Go Bananas */
    'AGB' = 'AGB',
    /** GENIUS TOKEN & NFT COLLECTIONS */
    'GNUS' = 'GNUS',
    /** Cajutel */
    'CAJ' = 'CAJ',
    /** Kermit */
    'KERMIT' = 'KERMIT',
    /** Flappymoonbird */
    'FMB' = 'FMB',
    /** 00 Token */
    '#00' = '00',
    /** Nirvana */
    'VANA' = 'VANA',
    /** Ethlas */
    'ELS' = 'ELS',
    /** Stader MaticX */
    'MATICX' = 'MATICX',
    /** Origin Ether */
    'OETH' = 'OETH',
    /** ChainMiner */
    'CMINER' = 'CMINER',
    /** The Emerald Company */
    'EMRLD' = 'EMRLD',
    /** Octopus Network */
    'OCT' = 'OCT',
    /** Alkimi */
    '$ADS' = '$ADS',
    /** TG Casino */
    'TGC' = 'TGC',
    /** Gorilla In A Coupe */
    'GIAC' = 'GIAC',
    /** Medifakt */
    'FAKT' = 'FAKT',
    /** Worldwide USD */
    'WUSD' = 'WUSD',
    /** Teq Network */
    'TEQ' = 'TEQ',
    /** DORK LORD */
    'DORKL' = 'DORKL',
    /** Defactor */
    'FACTR' = 'FACTR',
    /** FansTime */
    'FTI' = 'FTI',
    /** BMAX */
    'BMAX' = 'BMAX',
    /** Musk Dao */
    'MUSK' = 'MUSK',
    /** Azure Wallet */
    'AZURE' = 'AZURE',
    /** dogwifhat */
    'DOGWIFHAT' = 'DOGWIFHAT',
    /** Pando */
    'PANDO' = 'PANDO',
    /** X2Y2 */
    'X2Y2' = 'X2Y2',
    /** zkLend */
    'ZEND' = 'ZEND',
    /** DeepFakeAI */
    'FAKEAI' = 'FAKEAI',
    /** Olympus v2 */
    'OHM' = 'OHM',
    /** Kelp Earned Points */
    'KEP' = 'KEP',
    /** lmeow */
    'LMEOW' = 'LMEOW',
    /** Wasder */
    'WAS' = 'WAS',
    /** Ness LAB */
    'NESS' = 'NESS',
    /** Orchai */
    'OCH' = 'OCH',
    /** Fileshare Platform */
    'FSC' = 'FSC',
    /** Oracle AI */
    'ORACLE' = 'ORACLE',
    /** Pond Coin */
    'PNDC' = 'PNDC',
    /** Aegis Ai */
    'AEGIS' = 'AEGIS',
    /** Wrapped Minima */
    'WMINIMA' = 'WMINIMA',
    /** Advanced Project */
    'AUC' = 'AUC',
    /** KAIJUNO8 */
    'KAIJU' = 'KAIJU',
    /** AxonDAO Governance Token */
    'AXGT' = 'AXGT',
    /** Inu Inu */
    'INUINU' = 'INUINU',
    /** LP 3pool Curve */
    '3CRV' = '3CRV',
    /** Juice */
    'JUICE' = 'JUICE',
    /** Mazze */
    'MAZZE' = 'MAZZE',
    /** UnleashClub */
    'UNLEASH' = 'UNLEASH',
    /** Wrapped Dogecoin */
    'WDOGE' = 'WDOGE',
    /** Forgotten Playland */
    'FP' = 'FP',
    /** VitaDAO */
    'VITA' = 'VITA',
    /** SnackboxAI */
    'SNACK' = 'SNACK',
    /** Vestate */
    'VES' = 'VES',
    /** Pastel */
    'PSL' = 'PSL',
    /** Kinka */
    'XNK' = 'XNK',
    /** Fofar */
    'FOFAR' = 'FOFAR',
    /** WoofWork.io */
    'WOOF' = 'WOOF',
    /** ECOMI */
    'OMI' = 'OMI',
    /** Vow */
    'VOW' = 'VOW',
    /** LANDWOLF */
    'LANDWOLF' = 'LANDWOLF',
    /** APED */
    'APED' = 'APED',
    /** SoonVerse */
    'SOON' = 'SOON',
    /** Taτsu */
    'TATSU' = 'TATSU',
    /** Soul Society */
    'HON' = 'HON',
    /** Verge (ETH) */
    'XVG' = 'XVG',
    /** JC Coin */
    'JCC' = 'JCC',
    /** ecoterra */
    'ECOTERRA' = 'ECOTERRA',
    /** Karate Combat */
    'KARATE' = 'KARATE',
    /** BRC App */
    'BRCT' = 'BRCT',
    /** enqAI */
    'enqAI' = 'enqAI',
    /** LENX Finance */
    'XD' = 'XD',
    /** MetaZero */
    'MZERO' = 'MZERO',
    /** ether.fi Staked ETH */
    'EETH' = 'EETH',
    /** HYTOPIA */
    'TOPIA' = 'TOPIA',
    /** Lends */
    'LENDS' = 'LENDS',
    /** Shib2.0 */
    'SHIB2.0' = 'SHIB2.0',
    /** DigiFinexToken */
    'DFT' = 'DFT',
    /** Wagie Bot */
    'WAGIEBOT' = 'WAGIEBOT',
    /** wrapped POKT */
    'WPOKT' = 'WPOKT',
    /** Gravita Protocol */
    'GRAI' = 'GRAI',
    /** ResearchCoin */
    'RSC' = 'RSC',
    /** DEFY */
    'DEFY' = 'DEFY',
    /** Instadapp */
    'INST' = 'INST',
    /** Viridis Network */
    'VRD' = 'VRD',
    /** SatoshiSync */
    'SSNC' = 'SSNC',
    /** Index Cooperative */
    'INDEX' = 'INDEX',
    /** Faith Tribe */
    'FTRB' = 'FTRB',
    /** Commune AI */
    'COMAI' = 'COMAI',
    /** Green Bitcoin */
    'GBTC' = 'GBTC',
    /** Data Lake */
    'LAKE' = 'LAKE',
    /** Decentralized Cloud Infrastructure */
    'DCI' = 'DCI',
    /** Mythos */
    'MYTH' = 'MYTH',
    /** Tao Ceτi */
    'CETI' = 'CETI',
    /** Angle */
    'ANGLE' = 'ANGLE',
    /** Chimpzee */
    'CHMPZ' = 'CHMPZ',
    /** Weave6 */
    'WX' = 'WX',
    /** Epiko */
    'EPIKO' = 'EPIKO',
    /** GPTPlus */
    'GPTPLUS' = 'GPTPLUS',
    /** UGOLD Inc. */
    'UGOLD' = 'UGOLD',
    /** Panther Protocol */
    'ZKP' = 'ZKP',
    /** EpiK Protocol */
    'AIEPK' = 'AIEPK',
    /** Revest Finance */
    'RVST' = 'RVST',
    /** Gekko HQ */
    'GEKKO' = 'GEKKO',
    /** Divergence */
    'DIVER' = 'DIVER',
    /** Electronic USD */
    'eUSD' = 'eUSD',
    /** ZkLock */
    'ZKLK' = 'ZKLK',
    /** EDUM */
    'EDUM' = 'EDUM',
    /** LEOX */
    'LEOX' = 'LEOX',
    /** Jinbi Token */
    'JNB' = 'JNB',
    /** Non-Playable Coin */
    'NPC' = 'NPC',
    /** AI Network */
    'AIN' = 'AIN',
    /** Lido Staked Matic */
    'stMATIC' = 'stMATIC',
    /** Doge 2.0 */
    'DOGE2.0' = 'DOGE2.0',
    /** Sensay */
    'SNSY' = 'SNSY',
    /** Devour */
    'DPAY' = 'DPAY',
    /** Human */
    'HMT' = 'HMT',
    /** Shiva Inu */
    'SHIV' = 'SHIV',
    /** ContentBox */
    'BOX' = 'BOX',
    /** Tidalflats */
    'TIDE' = 'TIDE',
    /** El Hippo */
    'HIPP' = 'HIPP',
    /** IMVU */
    'VCORE' = 'VCORE',
    /** Brainers */
    'BRAINERS' = 'BRAINERS',
    /** LORDS */
    'LORDS' = 'LORDS',
    /** Cramer Coin */
    'CRAMER' = 'CRAMER',
    /** OraiDEX */
    'ORAIX' = 'ORAIX',
    /** Fottie */
    'FOTTIE' = 'FOTTIE',
    /** Medicle */
    'MDI' = 'MDI',
    /** AngelBlock */
    'THOL' = 'THOL',
    /** Luna Inu */
    'LINU' = 'LINU',
    /** TitanX */
    'TITANX' = 'TITANX',
    /** Fame AI */
    'FMC' = 'FMC',
    /** ADDAMS AI */
    'ADDAMS' = 'ADDAMS',
    /** Bank AI */
    '$BANK' = '$BANK',
    /** Hemule */
    'HEMULE' = 'HEMULE',
    /** Wrapped Centrifuge */
    'WCFG' = 'WCFG',
    /** Storm Warfare */
    'JAN' = 'JAN',
    /** X Project */
    'XERS' = 'XERS',
    /** 4-CHAN */
    '4CHAN' = '4CHAN',
    /** Nova */
    'NOVA' = 'NOVA',
    /** HarryPotterObamaInu */
    'INU' = 'INU',
    /** TensorSpace */
    'TPU' = 'TPU',
    /** USP Token */
    'USP' = 'USP',
    /** APWine Finance */
    'APW' = 'APW',
    /** Icy */
    'IC' = 'IC',
    /** DeTensor */
    'DETENSOR' = 'DETENSOR',
    /** CryptoGPT */
    'CRGPT' = 'CRGPT',
    /** Ecoreal Estate */
    'ECOREAL' = 'ECOREAL',
    /** Wibx */
    'WBX' = 'WBX',
    /** go fu*k yourself. */
    'GFY' = 'GFY',
    /** Cappasity */
    'CAPP' = 'CAPP',
    /** Aviator */
    'AVI' = 'AVI',
    /** Renq Finance */
    'RENQ' = 'RENQ',
    /** Metal Blockchain */
    'METAL' = 'METAL',
    /** TAO INU */
    'TAONU' = 'TAONU',
    /** The Nemesis */
    'NEMS' = 'NEMS',
    /** Ben (v2) */
    'BEN' = 'BEN',
    /** VaultCraft */
    'VCX' = 'VCX',
    /** Financie Token */
    'FNCT' = 'FNCT',
    /** Mind */
    'MND' = 'MND',
    /** Ondo US Dollar Yield */
    'USDY' = 'USDY',
    /** Dawg Coin */
    'DAWG' = 'DAWG',
    /** Mute */
    'MUTE' = 'MUTE',
    /** Morpheus Infrastructure Node */
    'MIND' = 'MIND',
    /** Kondux */
    'KNDX' = 'KNDX',
    /** Redacted */
    'BTRFLY' = 'BTRFLY',
    /** Magical Blocks */
    'MBLK' = 'MBLK',
    /** FlokiFork */
    'FORK' = 'FORK',
    /** socrates */
    'SOC' = 'SOC',
    /** StorageChain */
    'WSTOR' = 'WSTOR',
    /** SPACE-iZ */
    'SPIZ' = 'SPIZ',
    /** NEXUS */
    'NEX' = 'NEX',
    /** ShibaDoge */
    'SHIBDOGE' = 'SHIBDOGE',
    /** Mumu */
    'MUMU' = 'MUMU',
    /** Peepo */
    'PEEPO' = 'PEEPO',
    /** SymVerse */
    'SYM' = 'SYM',
    /** GreenWorld */
    'GWD' = 'GWD',
    /** Metahorse Unity */
    'MUNITY' = 'MUNITY',
    /** Kusunoki Samurai */
    'KUSUNOKI' = 'KUSUNOKI',
    /** ScamFari token */
    'SCM' = 'SCM',
    /** JOE */
    'BIDEN' = 'BIDEN',
    /** HarryPotterObamaWallStreetBets10Inu */
    'STONKS' = 'STONKS',
    /** Crazy Frog */
    'CRAZY' = 'CRAZY',
    /** Hasaki */
    'HAHA' = 'HAHA',
    /** PlumpyDragons */
    'LOONG' = 'LOONG',
    /** Lets Go Brandon */
    'LETSGO' = 'LETSGO',
    /** Pepy */
    'PEPY' = 'PEPY',
    /** THORSwap */
    'THOR' = 'THOR',
    /** JFIN Coin */
    'JFIN' = 'JFIN',
    /** zkHive */
    'ZKHIVE' = 'ZKHIVE',
    /** TOTO */
    'TOTO' = 'TOTO',
    /** LinkEye */
    'LET' = 'LET',
    /** Defender Bot */
    'DFNDR' = 'DFNDR',
    /** Lillian Token */
    'LYF' = 'LYF',
    /** Manifold Finance */
    'FOLD' = 'FOLD',
    /** XFLOKI */
    'XFLOKI' = 'XFLOKI',
    /** spurdo */
    'SPURDO' = 'SPURDO',
    /** Changex */
    'CHANGE' = 'CHANGE',
    /** Gradient */
    'GDT' = 'GDT',
    /** C-cash */
    'CCASH' = 'CCASH',
    /** Harambe Coin */
    'HARAMBE' = 'HARAMBE',
    /** StarkMeta */
    'SMETA' = 'SMETA',
    /** MEOW */
    'MEOW' = 'MEOW',
    /** Love Power Coin */
    'LOVE' = 'LOVE',
    /** GGDApp */
    'GGTK' = 'GGTK',
    /** CRYN */
    'CRYN' = 'CRYN',
    /** Convex FXS */
    'CVXFXS' = 'CVXFXS',
    /** Aurix */
    'AUR' = 'AUR',
    /** ZigZag */
    'ZZ' = 'ZZ',
    /** CPUcoin */
    'CPU' = 'CPU',
    /** Cadence Protocol */
    'CAD' = 'CAD',
    /** NeuralAI */
    'NEURAL' = 'NEURAL',
    /** TOAD */
    'TOAD' = 'TOAD',
    /** LiquidLayer */
    'LILA' = 'LILA',
    /** Stake DAO CRV */
    'SDCRV' = 'SDCRV',
    /** Monerium EUR emoney */
    'EURe' = 'EURe',
    /** Dongo AI */
    'DONGO' = 'DONGO',
    /** AssetMantle */
    'MNTL' = 'MNTL',
    /** FractonX */
    'FT' = 'FT',
    /** REAKTOR */
    'RKR' = 'RKR',
    /** Super Best Friends */
    'SUBF' = 'SUBF',
    /** LynKey */
    'LYNK' = 'LYNK',
    /** Metanept */
    'NEPT' = 'NEPT',
    /** Mickey Meme */
    'MICKEY' = 'MICKEY',
    /** MFERS */
    'MFERS' = 'MFERS',
    /** Super Athletes Token */
    'SAT' = 'SAT',
    /** QuantixAI */
    'QAI' = 'QAI',
    /** Feels Good Man */
    'FGM' = 'FGM',
    /** Relation Labs */
    'REL' = 'REL',
    /** BigShortBets */
    'BIGSB' = 'BIGSB',
    /** Blockdefend AI */
    'DEFEND' = 'DEFEND',
    /** BEBE */
    'BEBE' = 'BEBE',
    /** THE BALKAN DWARF */
    'KEKEC' = 'KEKEC',
    /** EurocoinToken */
    'ECTE' = 'ECTE',
    /** Mars Battle */
    'SHOOT' = 'SHOOT',
    /** AQTIS */
    'AQTIS' = 'AQTIS',
    /** Exen Coin */
    'EXEN' = 'EXEN',
    /** Blockchain Bets */
    'BCB' = 'BCB',
    /** CODEX */
    'CODEX' = 'CODEX',
    /** Flovi inu */
    'FLOVI' = 'FLOVI',
    /** Neurahub */
    'NEURA' = 'NEURA',
    /** STIMA */
    'STIMA' = 'STIMA',
    /** Spot */
    'SPOT' = 'SPOT',
    /** Greelance */
    'GRL' = 'GRL',
    /** /Reach */
    'REACH' = 'REACH',
    /** WATTTON */
    'WATT' = 'WATT',
    /** Bazed Games */
    'BAZED' = 'BAZED',
    /** AGG */
    'AGG' = 'AGG',
    /** Zypto */
    'ZYPTO TOKEN' = 'ZYPTO TOKEN',
    /** BoxBet */
    'BXBT' = 'BXBT',
    /** Root Protocol */
    'ISME' = 'ISME',
    /** Purple AI */
    'PAI' = 'PAI',
    /** Montage Token */
    'MTGX' = 'MTGX',
    /** Hop Protocol */
    'HOP' = 'HOP',
    /** Choppy */
    'CHOPPY' = 'CHOPPY',
    /** TRESTLE */
    'TRESTLE' = 'TRESTLE',
    /** Metavisa Protocol */
    'MESA' = 'MESA',
    /** MEMEVENGERS */
    'MMVG' = 'MMVG',
    /** Smart Game Finance */
    'SMART' = 'SMART',
    /** Spatial Computing */
    'CMPT' = 'CMPT',
    /** CYBONK */
    'CYBONK' = 'CYBONK',
    /** Wrapped Ampleforth */
    'WAMPL' = 'WAMPL',
    /** Morra */
    'MORRA' = 'MORRA',
    /** ESG */
    'ESG' = 'ESG',
    /** Independence Token */
    'RFKJ' = 'RFKJ',
    /** Whirl */
    'WHIRL' = 'WHIRL',
    /** Silent Notary */
    'UBSN' = 'UBSN',
    /** Nahmii */
    'NII' = 'NII',
    /** DECENTRACARD */
    'DCARD' = 'DCARD',
    /** SNKRZ */
    'FRC' = 'FRC',
    /** DBXen */
    'DXN' = 'DXN',
    /** Sowa AI */
    'SOWA' = 'SOWA',
    /** XRootAI */
    'XROOTAI' = 'XROOTAI',
    /** UGAS */
    'UGAS' = 'UGAS',
    /** DOLZ */
    'DOLZ' = 'DOLZ',
    /** Reddit */
    'RDDT' = 'RDDT',
    /** Save America */
    'DJT' = 'DJT',
    /** Don't Buy Inu */
    'DBI' = 'DBI',
    /** UCX */
    'UCX' = 'UCX',
    /** NOBLEBLOCKS */
    'NOBL' = 'NOBL',
    /** Zeni */
    'ZENI' = 'ZENI',
    /** NightVerse Game */
    'NVG' = 'NVG',
    /** OrdBridge */
    'BRGE' = 'BRGE',
    /** Restake Finance */
    'RSTK' = 'RSTK',
    /** Giveth */
    'GIV' = 'GIV',
    /** Brillion */
    'DUA' = 'DUA',
    /** zkTAO */
    '$ZAO' = '$ZAO',
    /** Dash 2 Trade */
    'D2T' = 'D2T',
    /** KIZUNA */
    'KIZUNA' = 'KIZUNA',
    /** Dreamverse */
    'DV' = 'DV',
    /** dFund */
    'DFND' = 'DFND',
    /** Roko */
    'ROKO' = 'ROKO',
    /** ECOx */
    'ECOX' = 'ECOX',
    /** Day By Day */
    'DBD' = 'DBD',
    /** Exorde */
    'EXD' = 'EXD',
    /** Lanify */
    'LAN' = 'LAN',
    /** Artizen */
    'ATNT' = 'ATNT',
    /** Domani Protocol */
    'DEXTF' = 'DEXTF',
    /** Trillant */
    'TRI' = 'TRI',
    /** PaladinAI */
    'PALAI' = 'PALAI',
    /** Decentralized Vulnerability Platform */
    'DVP' = 'DVP',
    /** Metano */
    'METANO' = 'METANO',
    /** MindVerse */
    'MVERSE' = 'MVERSE',
    /** Invest Club Global */
    'ICG' = 'ICG',
    /** BREPE */
    'BREPE' = 'BREPE',
    /** Clipper */
    'SAIL' = 'SAIL',
    /** Mandox Token */
    'MANDOX' = 'MANDOX',
    /** Jungle Labz */
    'JNGL' = 'JNGL',
    /** TokenSight */
    'TKST' = 'TKST',
    /** HYPERCOMIC */
    'HYCO' = 'HYCO',
    /** 0xScans */
    'SCANS' = 'SCANS',
    /** Qlindo */
    'QLINDO' = 'QLINDO',
    /** MOBLAND */
    'SYNR' = 'SYNR',
    /** Modex */
    'MODEX' = 'MODEX',
    /** 0 Knowledge Network */
    '0KN' = '0KN',
    /** Buddha */
    'BUDDHA' = 'BUDDHA',
    /** Dubbz */
    'DUBBZ' = 'DUBBZ',
    /** INOVAI */
    'INOVAI' = 'INOVAI',
    /** Taxa Token */
    'TXT' = 'TXT',
    /** TPRO Network */
    'TPRO' = 'TPRO',
    /** CYBRIA */
    'CYBA' = 'CYBA',
    /** Arro Social */
    'ARRO' = 'ARRO',
    /** Aureus Nummus Gold */
    'ANG' = 'ANG',
    /** Autonio */
    'NIOX' = 'NIOX',
    /** Scarab Tools */
    'DUNG' = 'DUNG',
    /** Collateral Network */
    'COLT' = 'COLT',
    /** HotKeySwap */
    'HOTKEY' = 'HOTKEY',
    /** BitMEX Token */
    'BMEX' = 'BMEX',
    /** Punk Vault (NFTX) */
    'PUNK' = 'PUNK',
    /** 0xGpu.ai */
    '0XG' = '0XG',
    /** MEMEME */
    'MEMEME' = 'MEMEME',
    /** Cindrum */
    'CIND' = 'CIND',
    /** 0xVPN.org */
    'VPN' = 'VPN',
    /** The Paradox Metaverse */
    'PARADOX' = 'PARADOX',
    /** ETH 2x Flexible Leverage Index */
    'ETH2X-FLI' = 'ETH2X-FLI',
    /** CFX Quantum */
    'CFXQ' = 'CFXQ',
    /** AnonTech */
    'ATEC' = 'ATEC',
    /** The GameHub */
    'GHUB' = 'GHUB',
    /** Regen Network */
    'REGEN' = 'REGEN',
    /** Akita DAO */
    'HACHI' = 'HACHI',
    /** Neo Tokyo */
    'BYTES' = 'BYTES',
    /** LUKSO (Old) */
    'LYXe' = 'LYXe',
    /** Odyssey */
    'OCN' = 'OCN',
    /** BlueSparrow Token */
    'BlueSparrow' = 'BlueSparrow',
    /** Titanium22 */
    'TI' = 'TI',
    /** Cat-in-a-Box Ether */
    'BOXETH' = 'BOXETH',
    /** MaidSafeCoin */
    'EMAID' = 'EMAID',
    /** NAGA */
    'NGC' = 'NGC',
    /** Centurion Invest */
    'CIX' = 'CIX',
    /** Rent AI */
    'RENT' = 'RENT',
    /** MeshWave */
    'MWAVE' = 'MWAVE',
    /** CAT AI */
    'CATAI' = 'CATAI',
    /** Book of Pepe */
    'BOPE' = 'BOPE',
    /** Notional Finance */
    'NOTE' = 'NOTE',
    /** Voxto Amplify */
    'VXT' = 'VXT',
    /** NexusAI */
    'NEXUSAI' = 'NEXUSAI',
    /** Materium */
    'MTRM' = 'MTRM',
    /** Shezmu */
    'SHEZMU' = 'SHEZMU',
    /** Zus */
    'ZCN' = 'ZCN',
    /** Metronome */
    'MET' = 'MET',
    /** Aardvark */
    'VARK' = 'VARK',
    /** AimBot AI */
    'AIMBOT' = 'AIMBOT',
    /** MultiPlanetary Inus */
    'INUS' = 'INUS',
    /** L */
    '$L' = '$L',
    /** Clips */
    'CLIPS' = 'CLIPS',
    /** Doggensnout Skeptic */
    'DOGS' = 'DOGS',
    /** Crown Token */
    'CROWN' = 'CROWN',
    /** Chainback */
    'ARCHIVE' = 'ARCHIVE',
    /** The Habibiz */
    'HABIBI' = 'HABIBI',
    /** JPEG'd */
    'JPEG' = 'JPEG',
    /** Future1coin */
    'F1C' = 'F1C',
    /** Sturdy */
    'STRDY' = 'STRDY',
    /** Opyn Squeeth */
    'OSQTH' = 'OSQTH',
    /** Feisty Doge NFT */
    'NFD' = 'NFD',
    /** XMax */
    'XMX' = 'XMX',
    /** Conic Finance */
    'CNC' = 'CNC',
    /** 0x Leverage */
    '0XL' = '0XL',
    /** FuturesAI */
    'FAI' = 'FAI',
    /** Pacific */
    'PAF' = 'PAF',
    /** WASD Studios */
    'WASD' = 'WASD',
    /** EdgeSwap */
    'EGS' = 'EGS',
    /** VEROX */
    'VRX' = 'VRX',
    /** MemeDAO */
    'MEMD' = 'MEMD',
    /** Wicked Bet */
    'WIK' = 'WIK',
    /** SO-COL */
    'SIMP' = 'SIMP',
    /** MetaZooMee */
    'MZM' = 'MZM',
    /** poundtoken */
    'GBPT' = 'GBPT',
    /** Crafting Finance */
    'CRF' = 'CRF',
    /** Doge Payment */
    'DOGEPAY' = 'DOGEPAY',
    /** Ishi */
    'ISHI' = 'ISHI',
    /** EVERY GAME */
    'EGAME' = 'EGAME',
    /** Neos Credits */
    'NCR' = 'NCR',
    /** LunchDAO */
    'LUNCH' = 'LUNCH',
    /** Meme Kombat */
    'MK' = 'MK',
    /** Carbify */
    'CBY' = 'CBY',
    /** OTSea */
    'OTSEA' = 'OTSEA',
    /** AAG */
    'AAG' = 'AAG',
    /** SmartMoney */
    'SMRT' = 'SMRT',
    /** AssangeDAO */
    'JUSTICE' = 'JUSTICE',
    /** Sifu Vision */
    'SIFU' = 'SIFU',
    /** HNB Protocol */
    'HNB' = 'HNB',
    /** Veil */
    'VEIL' = 'VEIL',
    /** SOLAV TOKEN */
    'SOLAV' = 'SOLAV',
    /** Web-x-ai */
    'WEB' = 'WEB',
    /** Meta Masters Guild Games */
    'MEMAGX' = 'MEMAGX',
    /** Quantfury Token */
    'QTF' = 'QTF',
    /** MOE */
    'MOE' = 'MOE',
    /** CaliCoin */
    'CALI' = 'CALI',
    /** ZURRENCY */
    'ZURR' = 'ZURR',
    /** Empire Token */
    'EMPIRE' = 'EMPIRE',
    /** Toad Killer */
    '$TOAD' = '$TOAD',
    /** MixTrust */
    'MXT' = 'MXT',
    /** VeraOne */
    'VRO' = 'VRO',
    /** Casinu Inu */
    'CASINU' = 'CASINU',
    /** Kitty Inu */
    'kitty' = 'kitty',
    /** Paypolitan Token */
    'EPAN' = 'EPAN',
    /** Invitoken */
    'INVI' = 'INVI',
    /** Theopetra */
    'THEO' = 'THEO',
    /** Gas DAO */
    'GAS' = 'GAS',
    /** Moonsama */
    'SAMA' = 'SAMA',
    /** CHUCK */
    'CHUCK' = 'CHUCK',
    /** MoonDAO */
    'MOONEY' = 'MOONEY',
    /** Global Digital Content */
    'GDC' = 'GDC',
    /** XRUN */
    'XRUN' = 'XRUN',
    /** Bitcoin 2.0 */
    'BTC2.0' = 'BTC2.0',
    /** On-Chain Dynamics */
    'OCD' = 'OCD',
    /** TRUMPCAT */
    'TRUMPCAT' = 'TRUMPCAT',
    /** MOROS NET */
    'MOROS' = 'MOROS',
    /** Panda DAO */
    'PANDA' = 'PANDA',
    /** AstraAI */
    'ASTRA' = 'ASTRA',
    /** Cardiocoin */
    'CRDC' = 'CRDC',
    /** Tigra */
    'TIGRA' = 'TIGRA',
    /** PROPHET */
    'PROPHET' = 'PROPHET',
    /** Rake Finance */
    'RAK' = 'RAK',
    /** MMX */
    'MMX' = 'MMX',
    /** CodeGenie */
    '$CODEG' = '$CODEG',
    /** Port AI */
    'POAI' = 'POAI',
    /** CumInu */
    'CUMINU' = 'CUMINU',
    /** PL^Gnet */
    'PLUG' = 'PLUG',
    /** HitChain */
    'HIT' = 'HIT',
    /** Taylor Swift */
    'SWIFTIES' = 'SWIFTIES',
    /** HXRO */
    'HXRO' = 'HXRO',
    /** MediShares */
    'MDS' = 'MDS',
    /** MatrixETF */
    'MDF' = 'MDF',
    /** X Payments */
    'XPAY' = 'XPAY',
    /** Baby X */
    'BABYX' = 'BABYX',
    /** Biaocoin */
    'BIAO' = 'BIAO',
    /** Sashimi */
    'SASHIMI' = 'SASHIMI',
    /** Naka Bodhi Token */
    'NBOT' = 'NBOT',
    /** InfinityBit Token */
    'IBIT' = 'IBIT',
    /** OnX Finance */
    'ONX' = 'ONX',
    /** daCat */
    'DACAT' = 'DACAT',
    /** TurboPepe */
    'VROOM' = 'VROOM',
    /** CoinFi */
    'COFI' = 'COFI',
    /** DogeGF */
    'DOGEGF' = 'DOGEGF',
    /** Hoppy */
    'HOPPY' = 'HOPPY',
    /** Alpaca City */
    'ALPA' = 'ALPA',
    /** Shiba Girlfriend */
    'SHIBGF' = 'SHIBGF',
    /** OrdiGen */
    'ODGN' = 'ODGN',
    /** Lien */
    'LIEN' = 'LIEN',
    /** reBaked */
    'BAKED' = 'BAKED',
    /** Bincentive */
    'BCNT' = 'BCNT',
    /** Vader Protocol */
    'VADER' = 'VADER',
    /** FX1 Sports */
    'FXI' = 'FXI',
    /** 1Sol */
    '1SOL' = '1SOL',
    /** Essentia */
    'ESS' = 'ESS',
    /** CryptoArt.Ai */
    'CART' = 'CART',
    /** BEEP coin */
    'BEEP' = 'BEEP',
    /** Vault Tech */
    'VAULT' = 'VAULT',
    /** Dope Wars Paper */
    'PAPER' = 'PAPER',
    /** Bounty0x */
    'BNTY' = 'BNTY',
    /** Crypto Villains */
    'CV' = 'CV',
    /** XCeption */
    'XCEPT' = 'XCEPT',
    /** Fort Block Games */
    'FBG' = 'FBG',
    /** NPICK BLOCK */
    'NPICK' = 'NPICK',
    /** Wooooo! Coin */
    'WOOOOO!' = 'WOOOOO!',
    /** Nitro League */
    'NITRO' = 'NITRO',
    /** Capybara */
    'CAPY' = 'CAPY',
    /** BaconDAO */
    'BACON' = 'BACON',
    /** BlockCDN */
    'BCDN' = 'BCDN',
    /** MODA DAO */
    'MODA' = 'MODA',
    /** MyOwnItem */
    'MOI' = 'MOI',
    /** LemoChain */
    'LEMO' = 'LEMO',
    /** Hitmakr */
    'HMKR' = 'HMKR',
    /** Marvin Inu */
    'MARVIN' = 'MARVIN',
    /** VectorChat.ai */
    'CHAT' = 'CHAT',
    /** Delphy */
    'DPY' = 'DPY',
    /** TMNT */
    '$TMNT' = '$TMNT',
    /** NKCL Classic */
    'NKCLC' = 'NKCLC',
    /** Lemond */
    'LEMD' = 'LEMD',
    /** Friends With Benefits Pro */
    'FWB' = 'FWB',
    /** IHT Real Estate Protocol */
    'IHT' = 'IHT',
    /** Kuma Inu */
    'KUMA' = 'KUMA',
    /** 0xCalls */
    '#0XC' = '0XC',
    /** Jaypeggers */
    'JAY' = 'JAY',
    /** Website AI */
    'WEBAI' = 'WEBAI',
    /** Tempus */
    'TEMP' = 'TEMP',
    /** EVEAI */
    'EVEAI' = 'EVEAI',
    /** Wanna Bot */
    'WANNA' = 'WANNA',
    /** Bear Inu */
    'BEAR' = 'BEAR',
    /** Xtremeverse */
    'XTREME' = 'XTREME',
    /** EVE Token */
    'EVE' = 'EVE',
    /** Champz */
    'CHAMPZ' = 'CHAMPZ',
    /** UNIPOLY */
    'UNP' = 'UNP',
    /** Crypto Holding Frank Token */
    'CHFT' = 'CHFT',
    /** Hypersign Identity */
    'HID' = 'HID',
    /** Ajna Protocol */
    'AJNA' = 'AJNA',
    /** Blocksport */
    'BSPT' = 'BSPT',
    /** Solidly */
    'SOLID' = 'SOLID',
    /** EHash */
    'EHASH' = 'EHASH',
    /** Homer */
    'SIMPSON' = 'SIMPSON',
    /** 0xGasless */
    '0XGAS' = '0XGAS',
    /** KittenFinance */
    'KIF' = 'KIF',
    /** Foxe */
    'FOXE' = 'FOXE',
    /** EmiSwap */
    'ESW' = 'ESW',
    /** Akropolis Delphi */
    'ADEL' = 'ADEL',
    /** StakeWise */
    'SWISE' = 'SWISE',
    /** Skrumble Network */
    'SKM' = 'SKM',
    /** GSENetwork */
    'GSE' = 'GSE',
    /** One Cash */
    'ONC' = 'ONC',
    /** PanoVerse */
    'PANO' = 'PANO',
    /** Stox */
    'STX' = 'STX',
    /** R4RE Token */
    'R4RE' = 'R4RE',
    /** Vuzz AI */
    'VUZZ' = 'VUZZ',
    /** Cred */
    'LBA' = 'LBA',
    /** MerchDAO */
    'MRCH' = 'MRCH',
    /** AladdinDAO */
    'ALD' = 'ALD',
    /** JPY Coin */
    'JPYC' = 'JPYC',
    /** Energreen */
    'EGRN' = 'EGRN',
    /** Filecoin Standard Hashrate Token */
    'FILST' = 'FILST',
    /** VIDY */
    'VIDY' = 'VIDY',
    /** Cydotori */
    'DOTR' = 'DOTR',
    /** 0xNumber */
    'OXN' = 'OXN',
    /** Woozoo Music */
    'WZM' = 'WZM',
    /** VetMe */
    'VETME' = 'VETME',
    /** MiniSwap */
    'MINI' = 'MINI',
    /** Sienna (ERC20) */
    'wSIENNA' = 'wSIENNA',
    /** Onchain AI */
    'OCAI' = 'OCAI',
    /** Theos */
    'THEOS' = 'THEOS',
    /** DOS Network */
    'DOS' = 'DOS',
    /** DeFIL */
    'DFL' = 'DFL',
    /** Marnotaur */
    'TAUR' = 'TAUR',
    /** ProBit Token */
    'PROB' = 'PROB',
    /** HayCoin */
    'HAY' = 'HAY',
    /** Pear Swap */
    'PEAR' = 'PEAR',
    /** Sipher */
    'SIPHER' = 'SIPHER',
    /** TRUMP INU */
    'TRUMP INU' = 'TRUMP INU',
    /** Artem Coin */
    'ARTEM' = 'ARTEM',
    /** SOAI */
    'SOAI' = 'SOAI',
    /** Burp */
    'BURP' = 'BURP',
    /** WWF */
    'WWF' = 'WWF',
    /** Douglas Adams */
    'HHGTTG' = 'HHGTTG',
    /** UniDexAI */
    'UNIDEXAI' = 'UNIDEXAI',
    /** Mobius Finance */
    'MOT' = 'MOT',
    /** METANOA */
    'NOA' = 'NOA',
    /** Protectorate Protocol */
    'PRTC' = 'PRTC',
    /** TensorScan AI */
    'TSA' = 'TSA',
    /** Chicken */
    'KFC' = 'KFC',
    /** Pine */
    'PINE' = 'PINE',
    /** Hoichi */
    'HOICHI' = 'HOICHI',
    /** Krypton Galaxy Coin */
    'KGC' = 'KGC',
    /** SMARTSET TOKEN */
    'SST' = 'SST',
    /** Autumn */
    'AUTUMN' = 'AUTUMN',
    /** PowBlocks */
    'XPB' = 'XPB',
    /** OGSMINEM */
    'OGSM' = 'OGSM',
    /** Galvan */
    'IZE' = 'IZE',
    /** Yesports */
    'YESP' = 'YESP',
    /** MESSIER */
    'M87' = 'M87',
    /** Snetwork */
    'SNET' = 'SNET',
    /** Electrify.Asia */
    'ELEC' = 'ELEC',
    /** LNDRY */
    'LNDRY' = 'LNDRY',
    /** Neural Tensor Dynamics */
    'NTD' = 'NTD',
    /** The Dare */
    'DARE' = 'DARE',
    /** UNODEX */
    'UNDX' = 'UNDX',
    /** GuardAI */
    'GUARDAI' = 'GUARDAI',
    /** BitoPro Exchange Token */
    'BITO' = 'BITO',
    /** Satoshi Nakamoto */
    'SATOSHI' = 'SATOSHI',
    /** P Pizza */
    'PPIZZA' = 'PPIZZA',
    /** Calvaria: Duels of Eternity */
    'RIA' = 'RIA',
    /** Anima Spirit Gem */
    'ASG' = 'ASG',
    /** PoolTogether */
    'POOL' = 'POOL',
    /** FunFi */
    'FNF' = 'FNF',
    /** BlockGPT */
    'BGPT' = 'BGPT',
    /** CSR */
    'CSR' = 'CSR',
    /** Mimo Governance Token */
    'MIMO' = 'MIMO',
    /** Animalia */
    'ANIM' = 'ANIM',
    /** THE9 */
    'THE9' = 'THE9',
    /** Got Guaranteed */
    'GOTG' = 'GOTG',
    /** Wise Token */
    'WISE' = 'WISE',
    /** pBTC35A */
    'pBTC35A' = 'pBTC35A',
    /** Grok 1.5 */
    'GROK 1.5' = 'GROK 1.5',
    /** Ethermon */
    'EMON' = 'EMON',
    /** MINER (NEW) */
    'MINER' = 'MINER',
    /** HILO */
    'HILO' = 'HILO',
    /** Stats */
    'STATS' = 'STATS',
    /** Remme */
    'REM' = 'REM',
    /** PepInu */
    'PEPINU' = 'PEPINU',
    /** sETH */
    'SETH' = 'SETH',
    /** Qrolli */
    'QR' = 'QR',
    /** DiscoverFeed */
    'DMCC' = 'DMCC',
    /** Benji Bananas */
    'BENJI' = 'BENJI',
    /** Everex */
    'EVX' = 'EVX',
    /** Amaterasu Omikami */
    'OMIKAMI' = 'OMIKAMI',
    /** MiraQle */
    'MQL' = 'MQL',
    /** Laika */
    'LAIKA' = 'LAIKA',
    /** Sensor Protocol */
    'SENSOR' = 'SENSOR',
    /** TUCKER CARLSON */
    'TUCKER' = 'TUCKER',
    /** Ripio Credit Network */
    'RCN' = 'RCN',
    /** Nchart Token */
    'CHART' = 'CHART',
    /** Decentralized ETF */
    'DETF' = 'DETF',
    /** Neuralink */
    'NEURALINK' = 'NEURALINK',
    /** KleeKai */
    'KLEE' = 'KLEE',
    /** Vone */
    'VONE' = 'VONE',
    /** Raiden Network Token */
    'RDN' = 'RDN',
    /** Kanagawa Nami */
    'OKINAMI' = 'OKINAMI',
    /** SocialGood */
    'SG' = 'SG',
    /** Robo Inu Finance */
    'RBIF' = 'RBIF',
    /** STREETH */
    'STREETH' = 'STREETH',
    /** Yup */
    'YUP' = 'YUP',
    /** HOMMIES */
    'HOMMIES' = 'HOMMIES',
    /** VirtuSwap */
    'VRSW' = 'VRSW',
    /** KOMPETE */
    'KOMPETE' = 'KOMPETE',
    /** Medicalchain */
    'MTN' = 'MTN',
    /** Cyber Crowd Chain */
    'CCC' = 'CCC',
    /** OST */
    'OST' = 'OST',
    /** DumbMoney */
    'GME' = 'GME',
    /** CouponBay */
    'CUP' = 'CUP',
    /** GROK 2.0 */
    'GROK2.0' = 'GROK2.0',
    /** Sekuritance */
    'SKRT' = 'SKRT',
    /** eosDAC */
    'EOSDAC' = 'EOSDAC',
    /** Side.xyz */
    'SIDE' = 'SIDE',
    /** Artificial Idiot */
    'AII' = 'AII',
    /** Gambex */
    'GBE' = 'GBE',
    /** QASH */
    'QASH' = 'QASH',
    /** MobiFi */
    'MoFi' = 'MoFi',
    /** DOPE Coin */
    'DOPE' = 'DOPE',
    /** 0xS */
    '$0XS' = '$0XS',
    /** Voidz */
    'VDZ' = 'VDZ',
    /** 2DAI.io */
    '2DAI' = '2DAI',
    /** EverMoon */
    'EVERMOON' = 'EVERMOON',
    /** Ribbit */
    'RIBBIT' = 'RIBBIT',
    /** Dogeclub */
    'DOGC' = 'DOGC',
    /** Sanin Inu */
    'SANI' = 'SANI',
    /** MILEI Token */
    'MILEI' = 'MILEI',
    /** FOGNET */
    'FOG' = 'FOG',
    /** Dinerobet */
    'DINERO' = 'DINERO',
    /** SEC */
    'SEC' = 'SEC',
    /** SORA AI */
    'SORAI' = 'SORAI',
    /** ExenPay Token */
    'XNP' = 'XNP',
    /** Btour Chain */
    'MSOT' = 'MSOT',
    /** Saudi Bonk */
    'SAUDIBONK' = 'SAUDIBONK',
    /** EventChain */
    'EVC' = 'EVC',
    /** Big Eyes */
    'BIG' = 'BIG',
    /** YES */
    'YES' = 'YES',
    /** XRP20 */
    'XRP20' = 'XRP20',
    /** Zeusshield */
    'ZSC' = 'ZSC',
    /** Covenant */
    'COVN' = 'COVN',
    /** DEDPRZ */
    'USA' = 'USA',
    /** Escrowed Illuvium 2 */
    'SILV2' = 'SILV2',
    /** Shardus */
    'ULT' = 'ULT',
    /** Raft */
    'RAFT' = 'RAFT',
    /** K-Tune */
    'KTT' = 'KTT',
    /** Phuture */
    'PHTR' = 'PHTR',
    /** Tiamonds */
    'TIA' = 'TIA',
    /** SoPay */
    'SOP' = 'SOP',
    /** Sponge */
    '$SPONGE' = '$SPONGE',
    /** Mixin */
    'XIN' = 'XIN',
    /** Aidi Finance (new) */
    'AIDI' = 'AIDI',
    /** NodeSynapse */
    'NS' = 'NS',
    /** LINQ */
    'LINQ' = 'LINQ',
    /** USDEBT */
    'USDEBT' = 'USDEBT',
    /** NiiFi */
    'NIIFI' = 'NIIFI',
    /** COTI Governance Token */
    'gCOTI' = 'gCOTI',
    /** Cardstack */
    'CARD' = 'CARD',
    /** Bitcoin Inu */
    'BTCINU' = 'BTCINU',
    /** Sendpicks */
    'SEND' = 'SEND',
    /** WASSIE */
    'WASSIE' = 'WASSIE',
    /** MetaUnit */
    'MEU' = 'MEU',
    /** Winter */
    'WINTER' = 'WINTER',
    /** Millix */
    'WMLX' = 'WMLX',
    /** Veritaseum */
    'VERI' = 'VERI',
    /** Olea Token */
    'OLEA' = 'OLEA',
    /** Summer */
    'SUMMER' = 'SUMMER',
    /** AlphaKEK.AI */
    'AIKEK' = 'AIKEK',
    /** Value Liquidity */
    'VALUE' = 'VALUE',
    /** Lyve Finance */
    'LYVE' = 'LYVE',
    /** xSUSHI */
    'XSUSHI' = 'XSUSHI',
    /** Etherpets */
    'EPETS' = 'EPETS',
    /** HashCoin */
    'HSC' = 'HSC',
    /** Decentralized Eternal Virtual Traveller */
    'DEVT' = 'DEVT',
    /** dog in cats world */
    'BARK' = 'BARK',
    /** Neuroni AI */
    'NEURONI' = 'NEURONI',
    /** ZENEX */
    'ZNX' = 'ZNX',
    /** ordinex */
    'ORD' = 'ORD',
    /** BTC 2x Flexible Leverage Index */
    'BTC2X-FLI' = 'BTC2X-FLI',
    /** Host AI */
    'HOSTAI' = 'HOSTAI',
    /** Wolf Game Wool */
    'WOOL' = 'WOOL',
    /** SX Network */
    'SX' = 'SX',
    /** Bezoge Earth */
    'BEZOGE' = 'BEZOGE',
    /** Tate */
    'TATE' = 'TATE',
    /** DSQ Solutions */
    'DSQ' = 'DSQ',
    /** SafeStake */
    'DVT' = 'DVT',
    /** INOFI */
    'FON' = 'FON',
    /** Intellix */
    'ITX' = 'ITX',
    /** Spring */
    'SPRING' = 'SPRING',
    /** Futureswap */
    'FST' = 'FST',
    /** Childhoods End */
    'O' = 'O',
    /** I LOVE SNOOPY */
    'LOVESNOOPY' = 'LOVESNOOPY',
    /** sETH2 */
    'SETH2' = 'SETH2',
    /** Melania Trump */
    'MELANIA' = 'MELANIA',
    /** Dede */
    'DEDE' = 'DEDE',
    /** Stone */
    '0NE' = '0NE',
    /** bitcastle */
    'CASTLE' = 'CASTLE',
    /** Trump Shiba */
    'TRUMPSHIBA' = 'TRUMPSHIBA',
    /** Undead Blocks */
    'UNDEAD' = 'UNDEAD',
    /** Jerome Powell */
    'POWELL' = 'POWELL',
    /** ZARP Stablecoin */
    'ZARP' = 'ZARP',
    /** Elyssa AR */
    '$ELY' = '$ELY',
    /** DecentraMind */
    'DMIND' = 'DMIND',
    /** Bambi */
    'BAM' = 'BAM',
    /** QUARTZ */
    'QUARTZ' = 'QUARTZ',
    /** Mistral AI */
    'MISTRAL' = 'MISTRAL',
    /** Convergence */
    'CVG' = 'CVG',
    /** MetaRare */
    'MTRA' = 'MTRA',
    /** DPRating */
    'RATING' = 'RATING',
    /** Alpha Gardeners */
    'AG' = 'AG',
    /** Ethereum Express */
    'ETE' = 'ETE',
    /** MINESHIELD */
    'MNS' = 'MNS',
    /** Primas */
    'PST' = 'PST',
    /** Xi Token */
    'XI' = 'XI',
    /** HZM Coin */
    'HZM' = 'HZM',
    /** NeonAI */
    'NEONAI' = 'NEONAI',
    /** Kitty Token */
    'KITTY' = 'KITTY',
    /** Lunar */
    'LUNAR' = 'LUNAR',
    /** New World Order */
    'STATE' = 'STATE',
    /** Mithril Share */
    'MIS' = 'MIS',
    /** Edge */
    'EDGE' = 'EDGE',
    /** Die Protocol */
    'DIE' = 'DIE',
    /** TiUSD */
    'TIUSD' = 'TIUSD',
    /** Liza */
    'LIZA' = 'LIZA',
    /** LinkCoin Token */
    'LKN' = 'LKN',
    /** Shiba V Pepe */
    'SHEPE' = 'SHEPE',
    /** Xeno */
    'XENO' = 'XENO',
    /** BetaCarbon */
    'BCAU' = 'BCAU',
    /** ULTRA Prisma Finance */
    'ULTRA' = 'ULTRA',
    /** Hedron */
    'HDRN' = 'HDRN',
    /** greg */
    'GREG' = 'GREG',
    /** Daisy Launch Pad */
    'DAISY' = 'DAISY',
    /** Darüşşafaka Spor Kulübü Token */
    'DSK' = 'DSK',
    /** Merchant Token */
    'MTO' = 'MTO',
    /** NOTHING */
    'NOTHING' = 'NOTHING',
    /** NiHao */
    'NIHAO' = 'NIHAO',
    /** Open Proprietary Protocol */
    'OPP' = 'OPP',
    /** Bugs Bunny */
    'BUGS' = 'BUGS',
    /** arcadefi */
    'ARCADE' = 'ARCADE',
    /** AIBabyDoge */
    'AIBABYDOGE' = 'AIBABYDOGE',
    /** Mainframe */
    'MFT' = 'MFT',
    /** Tribal Finance */
    'TRIBL' = 'TRIBL',
    /** CHOW CHOW */
    'CHOW' = 'CHOW',
    /** Dexioprotocol */
    'DEXIO' = 'DEXIO',
    /** G Revolution */
    'G' = 'G',
    /** MetaBET */
    'MBET' = 'MBET',
    /** Ethereans */
    'OS' = 'OS',
    /** RebelSatoshi */
    'RBLZ' = 'RBLZ',
    /** BIDEN 2024 */
    'BIDEN2024' = 'BIDEN2024',
    /** humanDAO */
    'HDAO' = 'HDAO',
    /** Crypto Cat */
    'CCAT' = 'CCAT',
    /** Shanghai Inu */
    'SHANG' = 'SHANG',
    /** Osmo Bot */
    'OSMO' = 'OSMO',
    /** Cindicator */
    'CND' = 'CND',
    /** Behodler */
    'EYE' = 'EYE',
    /** PhotoChromic */
    'PHCR' = 'PHCR',
    /** Z-Cubed */
    'Z3' = 'Z3',
    /** Hobbes */
    'HOBBES' = 'HOBBES',
    /** Shiba 2.0 */
    'Shiba 2.0' = 'Shiba 2.0',
    /** Sparko */
    'SPARKO' = 'SPARKO',
    /** Etherscape */
    'SCAPE' = 'SCAPE',
    /** SnailBrook */
    'SNAIL' = 'SNAIL',
    /** SPYRO */
    'SPYRO' = 'SPYRO',
    /** Mt Pelerin Shares */
    'MPS' = 'MPS',
    /** Kirby CEO */
    'KIRBYCEO' = 'KIRBYCEO',
    /** Pokemon */
    'POKEMON' = 'POKEMON',
    /** Gambit */
    'GAMBIT' = 'GAMBIT',
    /** MuzzleToken */
    'MUZZ' = 'MUZZ',
    /** DORK */
    'DORK' = 'DORK',
    /** 4ART Coin */
    '4ART' = '4ART',
    /** SHIB2 */
    'SHIB2' = 'SHIB2',
    /** inheritance Art */
    'IAI' = 'IAI',
    /** Baby Shiba Inu */
    'BABYSHIB' = 'BABYSHIB',
    /** Mononoke Inu */
    'Mononoke-Inu' = 'Mononoke-Inu',
    /** Matching Game */
    'MATCH' = 'MATCH',
    /** Wrapped LUNA Classic */
    'WLUNC' = 'WLUNC',
    /** WoofOracle */
    'WFO' = 'WFO',
    /** Neton */
    'NTO' = 'NTO',
    /** Super Grok */
    'SGROK' = 'SGROK',
    /** CRYPTO */
    'CRYPTO' = 'CRYPTO',
    /** f(x) Protocol Leveraged ETH */
    'XETH' = 'XETH',
    /** tGOLD */
    'TXAU' = 'TXAU',
    /** Adana Demirspor Token */
    'DEMIR' = 'DEMIR',
    /** CoinBot */
    'COINBT' = 'COINBT',
    /** NovaCore */
    'NCOR' = 'NCOR',
    /** OpenAI ERC */
    'OPENAI ERC' = 'OPENAI ERC',
    /** Cosmo Baby */
    'CBABY' = 'CBABY',
    /** Pepe Girl */
    'PEPEG' = 'PEPEG',
    /** Tether MXNt */
    'MXNt' = 'MXNt',
    /** Kento */
    'KNTO' = 'KNTO',
    /** Capybara */
    'BARA' = 'BARA',
    /** Metaverse Index */
    'MVI' = 'MVI',
    /** BLOCKv */
    'VEE' = 'VEE',
    /** Liquid Staked ETH */
    'LSETH' = 'LSETH',
    /** Interns */
    'INTERN' = 'INTERN',
    /** Hola Token */
    '$HOLA' = '$HOLA',
    /** PepeFork2.0 */
    'PORK2.0' = 'PORK2.0',
    /** ATMA */
    'ATMA' = 'ATMA',
    /** EI Risitas */
    'KEK' = 'KEK',
    /** Shibarium Perpetuals */
    'SERP' = 'SERP',
    /** JACKBOT */
    'JBOT' = 'JBOT',
    /** TerraAI */
    'TAI' = 'TAI',
    /** Hamster Coin */
    '$HAMSTR' = '$HAMSTR',
    /** Monkeys */
    'MONKEYS' = 'MONKEYS',
    /** Newsly */
    'NEWS' = 'NEWS',
    /** Agnus Ai */
    'AGN' = 'AGN',
    /** Bananatok */
    'BNA' = 'BNA',
    /** tSILVER */
    'TXAG' = 'TXAG',
    /** Ben's Finale */
    'FINALE' = 'FINALE',
    /** MCFinance */
    'MCF' = 'MCF',
    /** Hikari Protocol */
    'HIKARI' = 'HIKARI',
    /** Dummy */
    'DUMMY' = 'DUMMY',
    /** NuCypher */
    'NU' = 'NU',
    /** Cuckadoodledoo */
    'CUCK' = 'CUCK',
    /** ETH 2.0 */
    'ETH 2.0' = 'ETH 2.0',
    /** Gigantix Wallet Token */
    'GTX' = 'GTX',
    /** MOMO v2 */
    'MOMO V2' = 'MOMO V2',
    /** Gains Farm */
    'GFARM2' = 'GFARM2',
    /** PREME Token */
    'PREME' = 'PREME',
    /** The Blu Arctic Water Company */
    'BARC' = 'BARC',
    /** CoinMerge OS */
    'CMOS' = 'CMOS',
    /** TCG Verse */
    'TCGC' = 'TCGC',
    /** ETH3.0 */
    'ETH 3.0' = 'ETH 3.0',
    /** Jim */
    'JIM' = 'JIM',
    /** MetaOctagon */
    'MOTG' = 'MOTG',
    /** VARC */
    'VARC' = 'VARC',
    /** Lotty */
    'LOTTY' = 'LOTTY',
    /** Diablo IV */
    'DIABLO' = 'DIABLO',
    /** Bidao */
    'BID' = 'BID',
    /** Tether CNHt */
    'CNHt' = 'CNHt',
    /** Fungify */
    'FUNG' = 'FUNG',
    /** DecentraNode */
    'DNODE' = 'DNODE',
    /** Warped Games */
    'WARPED' = 'WARPED',
    /** UNION Protocol Governance Token */
    'UNN' = 'UNN',
    /** Kounotori */
    'KTO' = 'KTO',
    /** MoonBot */
    'MBOT' = 'MBOT',
    /** KEVIN */
    'KEVIN' = 'KEVIN',
    /** Page */
    'PAGE' = 'PAGE',
    /** IYKYK */
    'IYKYK' = 'IYKYK',
    /** Toshi Tools */
    'TOSHI' = 'TOSHI',
    /** Earn Defi Coin */
    'EDC' = 'EDC',
    /** Minds */
    'MINDS' = 'MINDS',
    /** SpillWays */
    'SPILLWAYS' = 'SPILLWAYS',
    /** SHIKOKU */
    'SHIK' = 'SHIK',
    /** CHARTAI */
    'CX' = 'CX',
    /** Gooch */
    'GOOCH' = 'GOOCH',
    /** BuckHathCoin */
    'BHIG' = 'BHIG',
    /** Diyarbekirspor Token */
    'DIYAR' = 'DIYAR',
    /** StarShip */
    'SSHIP' = 'SSHIP',
    /** Tonka Finance */
    'TOKA' = 'TOKA',
    /** Liquid Protocol */
    'LP' = 'LP',
    /** 50x.com */
    '50X' = '50X',
    /** Fuck Pepe */
    'FKPEPE' = 'FKPEPE',
    /** Bitcoin ETF */
    'ETF' = 'ETF',
    /** BarbieCrashBandicootRFK888Inu */
    'SOLANA' = 'SOLANA',
    /** Cometh */
    'MUST' = 'MUST',
    /** BlackSwan AI */
    'BLACKSWAN' = 'BLACKSWAN',
    /** Element */
    'ELMT' = 'ELMT',
    /** Stronger */
    'STRNGR' = 'STRNGR',
    /** Azure */
    'AZR' = 'AZR',
    /** Linework Coin */
    'LWC' = 'LWC',
    /** Pax Unitas */
    'PAXU' = 'PAXU',
    /** D Community */
    'DILI' = 'DILI',
    /** Psyop */
    'PSYOP' = 'PSYOP',
    /** CryptoFranc */
    'XCHF' = 'XCHF',
    /** $LAMBO */
    'LAMBO' = 'LAMBO',
    /** SORA Synthetic USD */
    'XSTUSD' = 'XSTUSD',
    /** XGPT */
    'XGPT' = 'XGPT',
    /** Dogey */
    'DOGEY' = 'DOGEY',
    /** Blockster */
    'BXR' = 'BXR',
    /** Evil Pepe */
    'EVILPEPE' = 'EVILPEPE',
    /** Basis Share */
    'BAS' = 'BAS',
    /** VoldemortTrumpRobotnik-10Neko */
    'ETHEREUM' = 'ETHEREUM',
    /** Chad Coin */
    'CHAD' = 'CHAD',
    /** Maximus DECI */
    'DECI' = 'DECI',
    /** PLEBToken */
    'PLEB' = 'PLEB',
    /** KStarCoin */
    'KSC' = 'KSC',
    /** ArbiSmart */
    'RBIS' = 'RBIS',
    /** Fisco Coin */
    'FSCC' = 'FSCC',
    /** Oasis */
    'OASIS' = 'OASIS',
    /** Coinmetro Token */
    'XCM' = 'XCM',
    /** Honey Badger */
    'HOBA' = 'HOBA',
    /** Mechanium */
    'MECHA' = 'MECHA',
    /** DAM Finance */
    'D2O' = 'D2O',
    /** Sivasspor Token */
    'SIV' = 'SIV',
    /** RawBlock */
    'RWB' = 'RWB',
    /** Duzce Token */
    'DUZCE' = 'DUZCE',
    /** Good Gensler */
    'GENSLR' = 'GENSLR',
    /** MeetPle */
    'MPT' = 'MPT',
    /** RUG RADIO */
    'RUG' = 'RUG',
    /** WCTrades */
    'WCT' = 'WCT',
    /** Espresso Bot */
    'ESPR' = 'ESPR',
    /** ANIVERSE */
    'ANV' = 'ANV',
    /** Monarch */
    'MNRCH' = 'MNRCH',
    /** Bitpanda Ecosystem Token */
    'BEST' = 'BEST',
    /** Bancor Governance Token */
    'VBNT' = 'VBNT',
    /** Hatayspor Token */
    'HATAY' = 'HATAY',
    /** ProjectDojo */
    'DOJO' = 'DOJO',
    /** GMFAM */
    'GMFAM' = 'GMFAM',
    /** Bankera V2 */
    'BNK' = 'BNK',
    /** Vector Space Biosciences, Inc. */
    'SBIO' = 'SBIO',
    /** TRYC */
    'TRYC' = 'TRYC',
    /** zkSync Labs */
    'ZKLAB' = 'ZKLAB',
    /** Wexo */
    'WEXO' = 'WEXO',
    /** AMLT */
    'AMLT' = 'AMLT',
    /** SWIM - Spread Wisdom */
    'SWIM' = 'SWIM',
    /** MyBit */
    'MYB' = 'MYB',
    /** Sakaryaspor Token */
    'SKRY' = 'SKRY',
    /** Shita-kiri Suzume */
    'SUZUME' = 'SUZUME',
    /** MADworld */
    'UMAD' = 'UMAD',
    /** PORA AI */
    'PORA' = 'PORA',
    /** Baby Shiva */
    'BABYSHIV' = 'BABYSHIV',
    /** Print Mining */
    'PRINT' = 'PRINT',
    /** Tuzlaspor Token */
    'TUZLA' = 'TUZLA',
    /** 0xCoco */
    'COCO' = 'COCO',
    /** Kayserispor Token */
    'KYSR' = 'KYSR',
    /** OUCHI */
    'OUCHI' = 'OUCHI',
    /** Fable Of The Dragon */
    'TYRANT' = 'TYRANT',
    /** X8X Token */
    'X8X' = 'X8X',
    /** Transactra Finance */
    'TRSCT' = 'TRSCT',
    /** Icel Idman Yurdu Token */
    'MIY' = 'MIY',
    /** Floor Cheese Burger */
    'FLRBRG' = 'FLRBRG',
    /** Elon Vitalik Pigs */
    'PIGS' = 'PIGS',
    /** Chaintools */
    'CTLS' = 'CTLS',
    /** Pollchain */
    'POLL' = 'POLL',
    /** Vanspor Token */
    'VAN' = 'VAN',
    /** TideBit Token */
    'TBT' = 'TBT',
    /** XRPCHAIN */
    'RIPPLE CHAIN' = 'RIPPLE CHAIN',
    /** KoiPond */
    'KOI' = 'KOI',
    /** Trump Tech */
    'TRUMPTECH' = 'TRUMPTECH',
    /** Koyo */
    'KOY' = 'KOY',
    /** Yobit Token */
    'YO' = 'YO',
    /** Open Mind Network */
    'OPMND' = 'OPMND',
    /** Algory Project */
    'ALG' = 'ALG',
    /** Rizespor Token */
    'RIZE' = 'RIZE',
    /** Swot AI */
    'SWOT' = 'SWOT',
    /** Bok Choy */
    'CHOY' = 'CHOY',
    /** Blob */
    'BLOB' = 'BLOB',
    /** Decentral Games Governance (xDG) */
    'xDG' = 'xDG',
    /** Blacksmith Token */
    'BS' = 'BS',
    /** CrocBot */
    'CROC' = 'CROC',
    /** Happy Birthday Coin */
    'HBDC' = 'HBDC',
    /** Balıkesirspor Token */
    'BLKS' = 'BLKS',
    /** Search */
    '0XSEARCH' = '0XSEARCH',
    /** GROKX */
    'GROKX' = 'GROKX',
    /** Luna 2.0 */
    'LUNA2.0' = 'LUNA2.0',
    /** Somdejcoin */
    'SOMDEJ' = 'SOMDEJ',
    /** Generational Wealth */
    'GEN' = 'GEN',
    /** BLOCKS */
    'BLOCKS' = 'BLOCKS',
    /** Year of the Dragon */
    'YOD' = 'YOD',
    /** Boop */
    'BOOP' = 'BOOP',
    /** BuildUp */
    'BUP' = 'BUP',
    /** Idea Chain Coin */
    'ICH' = 'ICH',
    /** Neural Radiance Field */
    'NERF' = 'NERF',
    /** Mondo Community Coin */
    'MNDCC' = 'MNDCC',
    /** Mountain Protocol */
    'USDM' = 'USDM',
    /** iCommunity Labs */
    'ICOM' = 'ICOM',
    /** Rabbitgame */
    'RAIT' = 'RAIT',
    /** VINLINK */
    'VNLNK' = 'VNLNK',
    /** ArchAngel Token */
    'ARCHA' = 'ARCHA',
    /** Experty Wisdom Token */
    'WIS' = 'WIS',
    /** Mars Network */
    'MARS' = 'MARS',
    /** DeFi Franc */
    'DCHF' = 'DCHF',
    /** EYES Protocol */
    'EYES' = 'EYES',
    /** Cryptomeda */
    'TECH' = 'TECH',
    /** Shiba 3.0 */
    'SHIBA 3.0' = 'SHIBA 3.0',
    /** Da Pinchi */
    '$PINCHI' = '$PINCHI',
    /** KumaDex Token */
    'dKUMA' = 'dKUMA',
    /** CNNS */
    'CNNS' = 'CNNS',
    /** WEN Token */
    'WEN' = 'WEN',
    /** DubX */
    'DUB' = 'DUB',
    /** SALAMANDER */
    'SALLY' = 'SALLY',
    /** Big Floppa */
    'FLOP' = 'FLOP',
    /** WhaleRoom */
    'WHL' = 'WHL',
    /** Deepcave */
    'CAVE' = 'CAVE',
    /** XRP 2.0 */
    'XRP 2.0' = 'XRP 2.0',
    /** ZuckPepe */
    'ZUCKPEPE' = 'ZUCKPEPE',
    /** OmniBotX */
    'OMNIX' = 'OMNIX',
    /** Ethscan AI */
    'ESAI' = 'ESAI',
    /** VKENAF */
    'VKNF' = 'VKNF',
    /** Kryxivia */
    'KXA' = 'KXA',
    /** WOLVERINU (new) */
    'WOLVERINU' = 'WOLVERINU',
    /** GYSR */
    'GYSR' = 'GYSR',
    /** MEZZ Token */
    'MEZZ' = 'MEZZ',
    /** Riot Racers */
    'RIOT' = 'RIOT',
    /** Floki VS Pepe */
    'FLEPE' = 'FLEPE',
    /** Wrapped Accumulate */
    'WACME' = 'WACME',
    /** A2A */
    'A2A' = 'A2A',
    /** Open Trade Exchange */
    'OTX' = 'OTX',
    /** Antalyaspor Token */
    'AKREP' = 'AKREP',
    /** DongCoin */
    'DONG' = 'DONG',
    /** LinkPool */
    'LPL' = 'LPL',
    /** CASHCAB */
    'CAB' = 'CAB',
    /** Uhive */
    'HVE2' = 'HVE2',
    /** Cope */
    'COPE' = 'COPE',
    /** WAXE */
    'WAXE' = 'WAXE',
    /** Apollo FTW */
    'FTW' = 'FTW',
    /** LEZGI Token */
    'LEZGI' = 'LEZGI',
    /** Ccore */
    'CCO' = 'CCO',
    /** met a meta metameme */
    'METAMEME' = 'METAMEME',
    /** Reddit */
    'REDDIT' = 'REDDIT',
    /** TrumpJr */
    'TRUMPJR' = 'TRUMPJR',
    /** Giresunspor Token */
    'GRS' = 'GRS',
    /** Solareum */
    'SOLAR' = 'SOLAR',
    /** Tokenize Xchange */
    'TKX' = 'TKX',
    /** Bermuda */
    'BMDA' = 'BMDA',
    /** Hyper */
    'EON' = 'EON',
    /** DAYSTARTER */
    'DST' = 'DST',
    /** Memecoin 2.0 */
    'MEME 2.0' = 'MEME 2.0',
    /** ELIS */
    'XLS' = 'XLS',
    /** Moxy */
    'WMOXY' = 'WMOXY',
    /** Grom */
    'GR' = 'GR',
    /** DecentraWeb */
    'DWEB' = 'DWEB',
    /** Xrise */
    'XRISE' = 'XRISE',
    /** BeforeCoinMarketCap */
    'BCMC1' = 'BCMC1',
    /** The Rug Game */
    'TRG' = 'TRG',
    /** Potion 404 */
    'P404' = 'P404',
    /** SmartCredit Token */
    'SMARTCREDIT' = 'SMARTCREDIT',
    /** ECHO BOT */
    'ECHO' = 'ECHO',
    /** New Order */
    'NEWO' = 'NEWO',
    /** Urfaspor Token */
    'URFA' = 'URFA',
    /** Sanshu Inu */
    'SANSHU' = 'SANSHU',
    /** Ethereum 2.0 */
    'ETH2.0' = 'ETH2.0',
    /** VERSACE */
    'VERSACE' = 'VERSACE',
    /** OrdinalsFi */
    'ORDIFI' = 'ORDIFI',
    /** BEG */
    'BEG' = 'BEG',
    /** Erzurumspor Token */
    'ERZ' = 'ERZ',
    /** HARAM */
    'HARAM' = 'HARAM',
    /** Hummingbot */
    'HBOT' = 'HBOT',
    /** Freechat */
    'FCC' = 'FCC',
    /** renZEC */
    'RENZEC' = 'RENZEC',
    /** GTAVI */
    'GTAVI' = 'GTAVI',
    /** Modai */
    'MODAI' = 'MODAI',
    /** Huobi Pool Token */
    'HPT' = 'HPT',
    /** Kirby Inu */
    'KIRBYINU' = 'KIRBYINU',
    /** Athenas AI */
    'ATH' = 'ATH',
    /** SPINDLE */
    'SPD' = 'SPD',
    /** YAMA Inu */
    'YAMA' = 'YAMA',
    /** Satoshis Vision */
    'SATS' = 'SATS',
    /** Freight Trust & Clearing Network */
    'EDI' = 'EDI',
    /** Jenny Metaverse DAO Token */
    'UJENNY' = 'UJENNY',
    /** ETF Ethereum */
    'ETF-ETH' = 'ETF-ETH',
    /** Skillchain */
    'SKI' = 'SKI',
    /** Shila Inu */
    'SHIL' = 'SHIL',
    /** Aloha */
    'ALOHA' = 'ALOHA',
    /** Tronipay */
    'TRP' = 'TRP',
    /** Half Shiba Inu */
    'SHIB0.5' = 'SHIB0.5',
    /** Dextoken */
    'DEXG' = 'DEXG',
    /** MAGA Trump */
    'MAGATRUMP' = 'MAGATRUMP',
    /** PEPE 3.0 */
    'PEPE 3.0' = 'PEPE 3.0',
    /** Waste Digital Coin */
    'WACO' = 'WACO',
    /** Tr3zor */
    'TR3' = 'TR3',
    /** Yeni Malatyaspor Token */
    'YMS' = 'YMS',
    /** Demeter Chain */
    'DMTC' = 'DMTC',
    /** Global Gaming */
    'GMNG' = 'GMNG',
    /** FLAMA */
    'FMA' = 'FMA',
    /** Doge Protocol */
    'DOGEP' = 'DOGEP',
    /** Arbidex */
    'ABX' = 'ABX',
    /** DogPad Finance */
    'DOGPAD' = 'DOGPAD',
    /** Scanetchain */
    'SWC' = 'SWC',
    /** Axia Protocol */
    'AXIA' = 'AXIA',
    /** Tail */
    'TAIL' = 'TAIL',
    /** Memefi Toybox 404 */
    'TOYBOX' = 'TOYBOX',
    /** Continuum World */
    'UM' = 'UM',
    /** X-TECH */
    'XTECH' = 'XTECH',
    /** Elon Mars X */
    '$EMX' = '$EMX',
    /** NCOP */
    'NCOP' = 'NCOP',
    /** GUISE */
    'GUISE' = 'GUISE',
    /** Camp */
    'CAMP' = 'CAMP',
    /** Limestone Network */
    'LIMEX' = 'LIMEX',
    /** VINU Network */
    'VNN' = 'VNN',
    /** CitiOs */
    'R2R' = 'R2R',
    /** Bibox Token */
    'BIX' = 'BIX',
    /** DegenX */
    'DGNX' = 'DGNX',
    /** XSHIB */
    'XSHIB' = 'XSHIB',
    /** Moeda Loyalty Points */
    'MDA' = 'MDA',
    /** Befy Protocol */
    'BEFY' = 'BEFY',
    /** MDsquare */
    'TMED' = 'TMED',
    /** Mission Helios */
    'HELIOS' = 'HELIOS',
    /** Pac Man */
    'PACMAN' = 'PACMAN',
    /** Vendit */
    'VNDT' = 'VNDT',
    /** Qawalla */
    'QWLA' = 'QWLA',
    /** PABLO DEFI */
    'PABLO' = 'PABLO',
    /** UNIUM */
    'UNM' = 'UNM',
    /** MMAON */
    'MMAON' = 'MMAON',
    /** Libfx */
    'LIBFX' = 'LIBFX',
    /** TILWIKI */
    'TLW' = 'TLW',
    /** Buzzshow */
    'GLDY' = 'GLDY',
    /** Xank */
    'XANK' = 'XANK',
    /** GreenTek */
    'GTE' = 'GTE',
    /** Intexcoin */
    'INTX' = 'INTX',
    /** GoldeNugget */
    'GNTO' = 'GNTO',
    /** USD Bancor */
    'USDB' = 'USDB',
    /** Bestay */
    'BSY' = 'BSY',
    /** Pre-retogeum */
    'PRTG' = 'PRTG',
    /** Digex */
    'DIGEX' = 'DIGEX',
    /** Pundi X (Old) */
    'NPXS' = 'NPXS',
    /** ANSWER Governance */
    'AGOV' = 'AGOV',
    /** HedgeTrade */
    'HEDG' = 'HEDG',
    /** Satopay Network */
    'STOP' = 'STOP',
    /** Dynex GPU */
    'DYNEX' = 'DYNEX',
    /** BDID */
    'BDID' = 'BDID',
    /** Prostarter */
    'PROT' = 'PROT',
    /** BNSD Finance */
    'BNSD' = 'BNSD',
    /** Colibri Protocol */
    'CLBR' = 'CLBR',
    /** Fancy Games */
    'FNC' = 'FNC',
    /** Crypto Village Accelerator */
    'CVAG' = 'CVAG',
    /** Seele-N */
    'SEELE' = 'SEELE',
    /** United Traders Token */
    'UTT' = 'UTT',
    /** Memecoin */
    'MEM' = 'MEM',
    /** Baby Maga */
    'BABYMAGA' = 'BABYMAGA',
    /** Substratum */
    'SUB' = 'SUB',
    /** Bright Union */
    'BRIGHT' = 'BRIGHT',
    /** U Network */
    'UUU' = 'UUU',
    /** Hiveterminal Token */
    'HVN' = 'HVN',
    /** CDbio */
    'MCD' = 'MCD',
    /** Metropoly */
    'METRO' = 'METRO',
    /** Ballswap */
    'BSP' = 'BSP',
    /** Bela */
    'BELA' = 'BELA',
    /** Xaurum */
    'XAUR' = 'XAUR',
    /** BitCrystals */
    'BCY' = 'BCY',
    /** DigixDAO */
    'DGD' = 'DGD',
    /** WeTrust */
    'TRST' = 'TRST',
    /** SpeedCash */
    'SCS' = 'SCS',
    /** Lunyr */
    'LUN' = 'LUN',
    /** Monolith */
    'TKN' = 'TKN',
    /** Patientory */
    'PTOY' = 'PTOY',
    /** Polybius */
    'PLBT' = 'PLBT',
    /** Santiment Network Token */
    'SAN' = 'SAN',
    /** SkinCoin */
    'SKIN' = 'SKIN',
    /** InvestFeed */
    'IFT' = 'IFT',
    /** Po.et */
    'POE' = 'POE',
    /** Agrello */
    'DLT' = 'DLT',
    /** XPA */
    'XPA' = 'XPA',
    /** Kyber Network Crystal Legacy */
    'KNCL' = 'KNCL',
    /** VIBE */
    'VIBE' = 'VIBE',
    /** Rivetz */
    'RVT' = 'RVT',
    /** Cobinhood */
    'COB' = 'COB',
    /** EXRNchain */
    'EXRN' = 'EXRN',
    /** iEthereum */
    'IETH' = 'IETH',
    /** FlypMe */
    'FYP' = 'FYP',
    /** ATLANT */
    'ATL' = 'ATL',
    /** ELTCOIN */
    'ELTCOIN' = 'ELTCOIN',
    /** Upfiring */
    'UFR' = 'UFR',
    /** Privatix */
    'PRIX' = 'PRIX',
    /** Paypex */
    'PAYX' = 'PAYX',
    /** Eroscoin */
    'ERO' = 'ERO',
    /** Bonpay */
    'BON' = 'BON',
    /** MicroMoney */
    'AMM' = 'AMM',
    /** LockTrip */
    'LOC' = 'LOC',
    /** SIRIN LABS Token */
    'SRN' = 'SRN',
    /** AppCoins */
    'APPC' = 'APPC',
    /** ArbitrageCT */
    'ARCT' = 'ARCT',
    /** VouchForMe */
    'IPL' = 'IPL',
    /** indaHash */
    'IDH' = 'IDH',
    /** AidCoin */
    'AID' = 'AID',
    /** Moola */
    'AXPR' = 'AXPR',
    /** WePower */
    'WPR' = 'WPR',
    /** Neurotoken */
    'NTK' = 'NTK',
    /** StarterCoin */
    'STAC' = 'STAC',
    /** NANJCOIN */
    'NANJ' = 'NANJ',
    /** BlitzPick */
    'XBP' = 'XBP',
    /** Friendz */
    'FDZ' = 'FDZ',
    /** Smart MFG */
    'MFG' = 'MFG',
    /** DAEX */
    'DAX' = 'DAX',
    /** Transcodium */
    'TNS' = 'TNS',
    /** Amon */
    'AMN' = 'AMN',
    /** FLIP */
    'FLP' = 'FLP',
    /** MyToken */
    'MT' = 'MT',
    /** Zippie */
    'ZIPT' = 'ZIPT',
    /** Digix Gold Token */
    'DGX' = 'DGX',
    /** Sakura Bloom */
    'SKB' = 'SKB',
    /** Joint Ventures */
    'JOINT' = 'JOINT',
    /** Datarius Credit */
    'DTRC' = 'DTRC',
    /** Smartshare */
    'SSP' = 'SSP',
    /** ZINC */
    'ZINC' = 'ZINC',
    /** KanadeCoin */
    'KNDC' = 'KNDC',
    /** OTCBTC Token */
    'OTB' = 'OTB',
    /** VikkyToken */
    'VIKKY' = 'VIKKY',
    /** WABnetwork */
    'WAB' = 'WAB',
    /** Thore Cash */
    'TCH' = 'TCH',
    /** XOVBank */
    'XOV' = 'XOV',
    /** PKG Token */
    'PKG' = 'PKG',
    /** IGToken */
    'IG' = 'IG',
    /** SiaCashCoin */
    'SCC' = 'SCC',
    /** Blockpass */
    'PASS' = 'PASS',
    /** Obitan Chain */
    'OBTC' = 'OBTC',
    /** PumaPay */
    'PMA' = 'PMA',
    /** HeartBout */
    'HB' = 'HB',
    /** ShowHand */
    'HAND' = 'HAND',
    /** KingXChain */
    'KXC' = 'KXC',
    /** VeriDocGlobal */
    'VDG' = 'VDG',
    /** YUKI */
    'YUKI' = 'YUKI',
    /** FUTURAX */
    'FTXT' = 'FTXT',
    /** ABCC Token */
    'AT' = 'AT',
    /** Fire Lotto */
    'FLOT' = 'FLOT',
    /** CyberMusic */
    'CYMT' = 'CYMT',
    /** EvenCoin */
    'EVN' = 'EVN',
    /** Havy */
    'HAVY' = 'HAVY',
    /** MobilinkToken */
    'MOLK' = 'MOLK',
    /** Cryptrust */
    'CTRT' = 'CTRT',
    /** Robotina */
    'ROX' = 'ROX',
    /** CMITCOIN */
    'CMIT' = 'CMIT',
    /** QChi */
    'QCH' = 'QCH',
    /** TRONCLASSIC */
    'TRXC' = 'TRXC',
    /** Knekted */
    'KNT' = 'KNT',
    /** Neural Protocol */
    'NRP' = 'NRP',
    /** Wixlar */
    'WIX' = 'WIX',
    /** Zenswap Network Token */
    'ZNT' = 'ZNT',
    /** Decentralized Asset Trading Platform */
    'DATP' = 'DATP',
    /** TrueDeck */
    'TDP' = 'TDP',
    /** MODEL-X-coin */
    'MODX' = 'MODX',
    /** Alpha Coin */
    'APC' = 'APC',
    /** PlayGame */
    'PXG' = 'PXG',
    /** TravelNote */
    'TVNT' = 'TVNT',
    /** Shivers */
    'SHVR' = 'SHVR',
    /** Herbalist Token */
    'HERB' = 'HERB',
    /** QUINADS */
    'QUIN' = 'QUIN',
    /** The Currency Analytics */
    'TCAT' = 'TCAT',
    /** PlayChip */
    'PLA' = 'PLA',
    /** BTU Protocol */
    'BTU' = 'BTU',
    /** Decentralized Crypto Token */
    'DCTO' = 'DCTO',
    /** CustomContractNetwork */
    'CCN' = 'CCN',
    /** BoatPilot Token */
    'NAVY' = 'NAVY',
    /** LitecoinToken */
    'LTK' = 'LTK',
    /** Ethereum Gold Project */
    'ETGP' = 'ETGP',
    /** botXcoin */
    'BOTX' = 'BOTX',
    /** Tratok */
    'TRAT' = 'TRAT',
    /** ETHplode */
    'ETHPLO' = 'ETHPLO',
    /** Maya Preferred */
    'MAYP' = 'MAYP',
    /** EOS TRUST */
    'EOST' = 'EOST',
    /** GLOBEX */
    'GEX' = 'GEX',
    /** BitcoinRegular */
    'BTRL' = 'BTRL',
    /** 12Ships */
    'TSHP' = 'TSHP',
    /** BSOV Token */
    'BSOV' = 'BSOV',
    /** FinexboxToken */
    'FNB' = 'FNB',
    /** Apple Network */
    'ANK' = 'ANK',
    /** AfroDex */
    'AfroX' = 'AfroX',
    /** Compound Dai */
    'CDAI' = 'CDAI',
    /** B ONE PAYMENT */
    'B1P' = 'B1P',
    /** CoinLoan */
    'CLT' = 'CLT',
    /** Bali Coin */
    'BALI' = 'BALI',
    /** Jack Token */
    'JACK' = 'JACK',
    /** REBIT */
    'KEYT' = 'KEYT',
    /** Litecoin SV */
    'LSV' = 'LSV',
    /** DXdao */
    'DXD' = 'DXD',
    /** CryptoBet */
    'CBET' = 'CBET',
    /** DMM: Governance */
    'DMG' = 'DMG',
    /** STPAY */
    'STP' = 'STP',
    /** CoinZoom */
    'ZOOM' = 'ZOOM',
    /** DigiMax DGMT */
    'DGMT' = 'DGMT',
    /** DeFiPie */
    'PIE' = 'PIE',
    /** Jubi Token */
    'JT' = 'JT',
    /** Upper Euro */
    'EURU' = 'EURU',
    /** Upper Pound */
    'GBPU' = 'GBPU',
    /** Upper Dollar */
    'USDU' = 'USDU',
    /** Betherchip */
    'BEC' = 'BEC',
    /** Crypto Accept */
    'ACPT' = 'ACPT',
    /** cVault.finance */
    'CORE' = 'CORE',
    /** HLP Token */
    'HLP' = 'HLP',
    /** Auric Network */
    'AUSCM' = 'AUSCM',
    /** DefiDollar DAO */
    'DFD' = 'DFD',
    /** Libartysharetoken */
    'LST' = 'LST',
    /** GoldFinX */
    'G1X' = 'G1X',
    /** Folder Protocol */
    'FOL' = 'FOL',
    /** Unicap.finance */
    'UCAP' = 'UCAP',
    /** DGPayment */
    'DGP' = 'DGP',
    /** Guarded Ether */
    'GETH' = 'GETH',
    /** Alpha5 */
    'A5T' = 'A5T',
    /** SpiderDAO */
    'SPDR' = 'SPDR',
    /** Potentiam */
    'PTM' = 'PTM',
    /** Marginswap */
    'MFI' = 'MFI',
    /** LUXO */
    'LUXO' = 'LUXO',
    /** BT.Finance */
    'BT' = 'BT',
    /** Advertise Coin */
    'ADCO' = 'ADCO',
    /** CAD Coin */
    'CADC' = 'CADC',
    /** EDDASwap */
    'EDDA' = 'EDDA',
    /** ImpulseVen */
    'VEN' = 'VEN',
    /** Stater */
    'STR' = 'STR',
    /** Delta */
    'DELTA' = 'DELTA',
    /** DAOhaus */
    'HAUS' = 'HAUS',
    /** Stone DeFi */
    'STN' = 'STN',
    /** XFai */
    'XFIT' = 'XFIT',
    /** FrogeX */
    'FROGEX' = 'FROGEX',
    /** Community Business Token */
    'CBT' = 'CBT',
    /** Wrapped Statera */
    'WSTA' = 'WSTA',
    /** Unicly */
    'UNIC' = 'UNIC',
    /** Xiglute Coin */
    'XGC' = 'XGC',
    /** Curio Stable Coin */
    'CSC' = 'CSC',
    /** Liti Capital */
    'WLITI' = 'WLITI',
    /** Yieldly */
    'YLDY' = 'YLDY',
    /** Boost */
    'BOOST' = 'BOOST',
    /** Unreal Finance */
    'UGT' = 'UGT',
    /** CWallet */
    'CW' = 'CW',
    /** Crypto Carbon Energy */
    'CYCE' = 'CYCE',
    /** Raid Token */
    'RAID' = 'RAID',
    /** Falcon 9 */
    'F9' = 'F9',
    /** Dog Collar */
    'COLLAR' = 'COLLAR',
    /** Wrapped Curio Ferrari F12tdf */
    'WCT1' = 'WCT1',
    /** Universal Basic Income */
    'UBI' = 'UBI',
    /** ChilliSwap */
    'CHLI' = 'CHLI',
    /** Jacy */
    'JACY' = 'JACY',
    /** Alpha Impact */
    'FOLO' = 'FOLO',
    /** Upper Swiss Franc */
    'CHFU' = 'CHFU',
    /** KEYS */
    'KEYS' = 'KEYS',
    /** XIDR */
    'XIDR' = 'XIDR',
    /** Firulais */
    'FIRU' = 'FIRU',
    /** MetaBrands */
    'MAGE' = 'MAGE',
    /** KRYZA Exchange */
    'KRX' = 'KRX',
    /** Empower */
    'MPWR' = 'MPWR',
    /** Mongoose */
    'MONGOOSE' = 'MONGOOSE',
    /** Elon GOAT */
    'EGT' = 'EGT',
    /** POW */
    'POW' = 'POW',
    /** LondonCoinGold */
    'LDXG' = 'LDXG',
    /** Dream */
    'DREAM' = 'DREAM',
    /** Apollo Crypto DAO */
    'APOLLO' = 'APOLLO',
    /** MEME KONG */
    '$MKONG' = '$MKONG',
    /** Envision */
    'VIS' = 'VIS',
    /** Bitazza */
    'BTZ' = 'BTZ',
    /** Lyfe Silver */
    'LSILVER' = 'LSILVER',
    /** FloorDAO */
    'FLOOR' = 'FLOOR',
    /** StakeWise */
    'RETH2' = 'RETH2',
    /** Telefy */
    'TELE' = 'TELE',
    /** Crypviser */
    'CVNX' = 'CVNX',
    /** LYFE GOLD */
    'LGOLD' = 'LGOLD',
    /** VIKTAMA */
    'VIK' = 'VIK',
    /** Vehicle Mining System */
    'VMS' = 'VMS',
    /** Maximus Token */
    'MAXI' = 'MAXI',
    /** OmniaVerse */
    'OMNIA' = 'OMNIA',
    /** Talent */
    'TNT' = 'TNT',
    /** HITOP */
    'HITOP' = 'HITOP',
    /** Saltmarble */
    'SML' = 'SML',
    /** MicroChains Gov Token */
    'MCG' = 'MCG',
    /** ALLPAYCOIN */
    'APCG' = 'APCG',
    /** STEED */
    'STD' = 'STD',
    /** JPGold Coin */
    'JPGC' = 'JPGC',
    /** MUU */
    'MUU' = 'MUU',
    /** Beauty Bakery Linked Operation Transaction Technology */
    'LOTT' = 'LOTT',
    /** Icosa */
    'ICSA' = 'ICSA',
    /** CLever Token */
    'CLEV' = 'CLEV',
    /** X7DAO */
    'X7DAO' = 'X7DAO',
    /** X7R */
    'X7R' = 'X7R',
    /** Serum SER */
    'SER' = 'SER',
    /** Worldcore Coin */
    'WCC' = 'WCC',
    /** Ushi */
    'USHI' = 'USHI',
    /** ZudgeZury */
    'ZZC' = 'ZZC',
    /** SILK */
    'SILK' = 'SILK',
    /** stake.link */
    'SDL' = 'SDL',
    /** Advantis */
    'ADVT' = 'ADVT',
    /** Protocol Zero */
    'ZRO' = 'ZRO',
    /** LYFE */
    'LYFE' = 'LYFE',
    /** PulseBitcoin */
    'PLSB' = 'PLSB',
    /** Archive AI */
    'ARCAI' = 'ARCAI',
    /** Stablz */
    'STABLZ' = 'STABLZ',
    /** Eggs */
    'EGGS' = 'EGGS',
    /** ZkTsunami */
    ':ZKT:' = ':ZKT:',
    /** Tradix */
    'TX' = 'TX',
    /** Doke Inu */
    'DOKE' = 'DOKE',
    /** TUF Token */
    'TUF' = 'TUF',
    /** Formula Inu */
    'FINU' = 'FINU',
    /** Yield Magnet */
    'MAGNET' = 'MAGNET',
    /** Zero Liquid */
    'ZERO' = 'ZERO',
    /** HairDAO */
    'HAIR' = 'HAIR',
    /** Catgirl Optimus */
    'OPTIG' = 'OPTIG',
    /** Meeds */
    'MEED' = 'MEED',
    /** Crypto SDG */
    'SDG' = 'SDG',
    /** Laelaps */
    'LAELAPS' = 'LAELAPS',
    /** DeeLance */
    'DLANCE' = 'DLANCE',
    /** Pepechain */
    'PC' = 'PC',
    /** WallstreetBets Classic */
    'WSBC' = 'WSBC',
    /** Smol Su */
    'SU' = 'SU',
    /** BLAZE TOKEN */
    'BLZE' = 'BLZE',
    /** Smudge Lord */
    'SMUDGE' = 'SMUDGE',
    /** LarryCoin */
    'LARRY' = 'LARRY',
    /** WEWE */
    'WEWE' = 'WEWE',
    /** NGMI Coin */
    'NGMI' = 'NGMI',
    /** MASYA */
    'MASYA' = 'MASYA',
    /** Frens */
    'FRENS' = 'FRENS',
    /** YUKKY */
    'YUKKY' = 'YUKKY',
    /** All Your Base */
    'YOBASE' = 'YOBASE',
    /** A Fund Baby */
    'AFB' = 'AFB',
    /** Gatsby Inu */
    'GATSBY' = 'GATSBY',
    /** President Ron DeSantis */
    'RON' = 'RON',
    /** KIKO */
    'KIKO' = 'KIKO',
    /** GenieCoin */
    'GNC' = 'GNC',
    /** Rin Tin Tin */
    'RIN' = 'RIN',
    /** Arweave Name Service */
    'ANS' = 'ANS',
    /** Florky */
    'FLORKY' = 'FLORKY',
    /** Fat Cat Killer */
    'KUSDC' = 'KUSDC',
    /** DAVE */
    'DAVE' = 'DAVE',
    /** Lisa Simpson */
    'LISA' = 'LISA',
    /** SaitaBit */
    'SAITABIT' = 'SAITABIT',
    /** Alien Milady Fumo */
    'FUMO' = 'FUMO',
    /** Gemach */
    'GMAC' = 'GMAC',
    /** 9GAG */
    '9GAG' = '9GAG',
    /** SHEESH */
    'SHS' = 'SHS',
    /** Ethane */
    'C2H6' = 'C2H6',
    /** HarryPotterObamaSonic10Inu2.0 */
    'BITCOIN2.0' = 'BITCOIN2.0',
    /** SHIBADOGEPEPE */
    '$SDOPE' = '$SDOPE',
    /** Jared From Subway */
    'JARED' = 'JARED',
    /** GenieSwap */
    'GENIE' = 'GENIE',
    /** BOB 2.0 */
    'BOB 2.0' = 'BOB 2.0',
    /** BOBO 2.0 */
    'BOBO 2.0' = 'BOBO 2.0',
    /** TruthGPT (ETH) */
    '$TRUTH' = '$TRUTH',
    /** Dogelon Mars 2.0 */
    'ELON2.0' = 'ELON2.0',
    /** Wrapped Pepe */
    'WPEPE' = 'WPEPE',
    /** Thug Life */
    'THUG' = 'THUG',
    /** Hamsters */
    'HAMS' = 'HAMS',
    /** XRP2.0 */
    'XRP2' = 'XRP2',
    /** Snek on Ethereum */
    'SNEKE' = 'SNEKE',
    /** X.COM */
    'XCOM' = 'XCOM',
    /** Test */
    'TEST' = 'TEST',
    /** StrongX */
    'STRONGX' = 'STRONGX',
    /** None Trading */
    'NONE' = 'NONE',
    /** Beautiful Princess Disorder */
    'BPD' = 'BPD',
    /** shares.finance */
    'SHARES' = 'SHARES',
    /** Mixaverse */
    'MIXCOIN' = 'MIXCOIN',
    /** Ultimate Tipbot */
    'ULTIMATEBOT' = 'ULTIMATEBOT',
    /** Dejitaru Hoshi */
    'HOSHI' = 'HOSHI',
    /** BarbieCrashBandicootRFK777Inu */
    'SOLANA 2.0' = 'SOLANA 2.0',
    /** Wojak 2.69 */
    'WOJAK2.69' = 'WOJAK2.69',
    /** Curve Inu */
    'CRVY' = 'CRVY',
    /** JRVGCUPVSC */
    'PVFYBO' = 'PVFYBO',
    /** Hydra Coin */
    'HYDRA' = 'HYDRA',
    /** Viralsniper */
    'VIRAL' = 'VIRAL',
    /** JOEBIDEN2024 */
    'JOEBIDEN2024' = 'JOEBIDEN2024',
    /** EmotiCoin */
    'EMOTI' = 'EMOTI',
    /** SILVER */
    'SILVER' = 'SILVER',
    /** ROBIN HOOD */
    'HOOD' = 'HOOD',
    /** BUGATTI */
    'BUGATTI' = 'BUGATTI',
    /** GUCCI */
    'GUCCI' = 'GUCCI',
    /** XINU */
    'XINU' = 'XINU',
    /** Reserve */
    'RSRV' = 'RSRV',
    /** R-DEE Protocol */
    'RDGX' = 'RDGX',
    /** Gravitas */
    'GRAVITAS' = 'GRAVITAS',
    /** BTEX */
    'BTEX' = 'BTEX',
    /** FIDO */
    'FIDO' = 'FIDO',
    /** DogeBoy */
    'DOGB' = 'DOGB',
    /** PEIPEI */
    'PEIPEI' = 'PEIPEI',
    /** SimpleHub */
    'SHUB' = 'SHUB',
    /** Javelin */
    'JVL' = 'JVL',
    /** Golden Zen Token */
    'GZT' = 'GZT',
    /** Deflect Harbor AI */
    'DEFLECT' = 'DEFLECT',
    /** ROTTYCOIN */
    'ROTTY' = 'ROTTY',
    /** Uni Terminal */
    'UNIT' = 'UNIT',
    /** Green */
    'GREEN' = 'GREEN',
    /** Wolf of Wall Street */
    '$WOLF' = '$WOLF',
    /** Foox */
    'FOOX' = 'FOOX',
    /** Lemon Terminal */
    'LEMON' = 'LEMON',
    /** Fluid */
    'FLUID' = 'FLUID',
    /** Two Lands */
    'LANDS' = 'LANDS',
    /** Wisdom Bank Network */
    'WBN' = 'WBN',
    /** Molly */
    'MOLLY' = 'MOLLY',
    /** Silly Dragon */
    'SILLY' = 'SILLY',
    /** BitBullBot */
    'BBB' = 'BBB',
    /** Bundl Tools */
    'BUNDL' = 'BUNDL',
    /** Siphon Life Spell */
    'SLS' = 'SLS',
    /** Luna28 */
    '$LUNA' = '$LUNA',
    /** xRealm.ai */
    'XRLM' = 'XRLM',
    /** Avatar */
    'AVATAR' = 'AVATAR',
    /** PEPEYE 2.0 */
    'PEPEYE 2.0' = 'PEPEYE 2.0',
    /** Wrapped CellMates */
    'WCELL' = 'WCELL',
    /** Sora Porn */
    'SORA PORN' = 'SORA PORN',
    /** ERROR404 MEME */
    'ERROR404' = 'ERROR404',
    /** MAGA 2.0 */
    'MAGA 2.0' = 'MAGA 2.0',
    /** Criminal Flamingo */
    'CRIMINGO' = 'CRIMINGO',
    /** PolyBet */
    'PBT' = 'PBT',
    /** Diamond Coin */
    'DIAMOND' = 'DIAMOND',
    /** AIMEE */
    '$AIMEE' = '$AIMEE',
    /** Shin Chan */
    'SHIN' = 'SHIN',
    /** X-Ratio AI */
    'XRAI' = 'XRAI',
    /** Bomb Shelter Inu */
    'BOOM' = 'BOOM',
    /** iRYDE COIN */
    'IRYDE' = 'IRYDE',
    /** Dibbles */
    'DIBBLE' = 'DIBBLE',
    /** Trump Doge */
    'TRUMPDOGE' = 'TRUMPDOGE',
    /** Caesar's Arena */
    'CAESAR' = 'CAESAR',
    /** Ionic Pocket Token */
    'INP' = 'INP',
    /** Cronos */
    'CRO' = 'CRO',
    /** Flow */
    'FLOW' = 'FLOW',
    /** WAX */
    'WAXP' = 'WAXP',
    /** Creditcoin */
    'CTC' = 'CTC',
    /** Alephium */
    'ALPH' = 'ALPH',
    /** Fasttoken */
    'FTN' = 'FTN',
    /** Phala Network */
    'PHA' = 'PHA',
    /** Radworks */
    'RAD' = 'RAD',
    /** Forta */
    'FORT' = 'FORT',
    /** Smog */
    'SMOG' = 'SMOG',
    /** Elastos */
    'ELA' = 'ELA',
    /** Wirex Token */
    'WXT' = 'WXT',
    /** Boba Network */
    'BOBA' = 'BOBA',
    /** Astrafer */
    'ASTRAFER' = 'ASTRAFER',
    /** DIMO */
    'DIMO' = 'DIMO',
    /** IRISnet */
    'IRIS' = 'IRIS',
    /** Sentinel */
    'DVPN' = 'DVPN',
    /** CoinEx Token */
    'CET' = 'CET',
    /** Graphlinq Chain */
    'GLQ' = 'GLQ',
    /** Mintlayer */
    'ML' = 'ML',
    /** Bytom */
    'BTM' = 'BTM',
    /** Tokemak */
    'TOKE' = 'TOKE',
    /** Pip */
    'PIP' = 'PIP',
    /** Ambire Wallet */
    'WALLET' = 'WALLET',
    /** MEVerse */
    'MEV' = 'MEV',
    /** Energi */
    'NRG' = 'NRG',
    /** Divi */
    'DIVI' = 'DIVI',
    /** Zenon */
    'ZNN' = 'ZNN',
    /** Shyft Network */
    'SHFT' = 'SHFT',
    /** Amulet */
    'AMU' = 'AMU',
    /** BOSagora */
    'BOA' = 'BOA',
    /** Anchor Protocol */
    'ANC' = 'ANC',
    /** x42 Protocol */
    'X42' = 'X42',
    /** Centaur */
    'CNTR' = 'CNTR',
    /** SifChain */
    'erowan' = 'erowan',
    /** Sator */
    'SAO' = 'SAO',
    /** AquariusCoin */
    'ARCO' = 'ARCO',
    /** Gaj Finance */
    'GAJ' = 'GAJ',
    /** Moneta Digital */
    'MMXN' = 'MMXN',
    /** Seamless */
    'SEAM' = 'SEAM',
    /** Wrapped CANTO */
    'WCANTO' = 'WCANTO',
    /** Paycoin */
    'PCI' = 'PCI',
    /** Evmos */
    'EVMOS' = 'EVMOS',
    /** TENET */
    'TENET' = 'TENET',
    /** Bitkub Coin */
    'KUB' = 'KUB',
    /** CorgiAI */
    'CORGIAI' = 'CORGIAI',
    /** ROND */
    'ROND' = 'ROND',
    /** Puff The Dragon */
    'PUFF' = 'PUFF',
    /** xx network */
    'XX' = 'XX',
    /** JUNO */
    'JUNO' = 'JUNO',
    /** Raini Studios Token */
    'RST' = 'RST',
    /** MinePlex */
    'PLEX' = 'PLEX',
    /** BTC Proxy */
    'BTCPX' = 'BTCPX',
    /** MaxxChain */
    'PWR' = 'PWR',
    /** xFund */
    'XFUND' = 'XFUND',
    /** ION */
    'ION' = 'ION',
    /** Polkaswap */
    'PSWAP' = 'PSWAP',
    /** JD Coin */
    'JDC' = 'JDC',
    /** BitCanna */
    'BCNA' = 'BCNA',
    /** e-Money EUR */
    'EEUR' = 'EEUR',
    /** Polygen */
    'PGEN' = 'PGEN',
    /** Sovi Finance */
    'SOVI' = 'SOVI',
    /** GNFT */
    'GNFT' = 'GNFT',
    /** Aelin */
    'AELIN' = 'AELIN',
    /** Staked USDT */
    'STUSDT' = 'STUSDT',
    /** Manna */
    'MANNA' = 'MANNA',
    /** GoldMint */
    'MNTP' = 'MNTP',
    /** POA Network */
    'POA' = 'POA',
    /** Charg Coin */
    'CHG' = 'CHG',
    /** SonoCoin */
    'SONO' = 'SONO',
    /** BabyJesusCoin */
    'BABYJESUS' = 'BABYJESUS',
    /** Wrapped Everscale */
    'WEVER' = 'WEVER',
    /** FlatQube */
    'QUBE' = 'QUBE',
    /** Kaspa */
    'KAS' = 'KAS',
    /** Klaytn */
    'KLAY' = 'KLAY',
    /** DeFi Kingdoms */
    'JEWEL' = 'JEWEL',
    /** KLAYswap Protocol */
    'KSP' = 'KSP',
    /** SuperWalk */
    'GRND' = 'GRND',
    /** Observer */
    'OBSR' = 'OBSR',
    /** TEMCO */
    'TEMCO' = 'TEMCO',
    /** Azit */
    'AZIT' = 'AZIT',
    /** SELO+ */
    'SELO' = 'SELO',
    /** Klaydice */
    'DICE' = 'DICE',
    /** KLEVA Protocol */
    'KLEVA' = 'KLEVA',
    /** GALAXIA */
    'GXA' = 'GXA',
    /** Drawshop Kingdom Reverse */
    'JOY' = 'JOY',
    /** Hiblocks */
    'HIBS' = 'HIBS',
    /** Payment Swap Utility Board */
    'PSUB' = 'PSUB',
    /** CONUN */
    'CYCON' = 'CYCON',
    /** FAVOR */
    'FAVR' = 'FAVR',
    /** LEMON */
    'LEMN' = 'LEMN',
    /** MetaMAFIA */
    'MAF' = 'MAF',
    /** Gametree */
    'GTCOIN' = 'GTCOIN',
    /** Round X */
    'RNDX' = 'RNDX',
    /** 3 Kingdoms Multiverse */
    '3KM' = '3KM',
    /** Another World */
    'AWM' = 'AWM',
    /** Orbit Bridge Klaytn USDC */
    'OUSDC' = 'OUSDC',
    /** Tari World */
    'TARI' = 'TARI',
    /** Orbit Bridge Klaytn Orbit Chain */
    'OORC' = 'OORC',
    /** ASAN VERSE */
    'ASAN' = 'ASAN',
    /** Walk Token */
    'WALK' = 'WALK',
    /** D-SHOP */
    'DP' = 'DP',
    /** ClaimSwap */
    'CLA' = 'CLA',
    /** Perproject */
    'PER' = 'PER',
    /** Aurora Klay */
    'ARA' = 'ARA',
    /** Meta Kongz */
    'MKC' = 'MKC',
    /** LEMONCHAIN */
    'LEMC' = 'LEMC',
    /** Hello Art */
    'HTT' = 'HTT',
    /** NOX */
    'NOX' = 'NOX',
    /** Ducato Finance Token */
    'DUCATO' = 'DUCATO',
    /** Cojam */
    'CT' = 'CT',
    /** Klaymore Stakehouse */
    'HOUSE' = 'HOUSE',
    /** NIKPLACE */
    'NIK' = 'NIK',
    /** KlayFi Finance */
    'KFI' = 'KFI',
    /** KROME Shares */
    'KROME' = 'KROME',
    /** CRYPTOKKI */
    'TOKKI' = 'TOKKI',
    /** Orbit Bridge Klaytn Handy */
    'OHANDY' = 'OHANDY',
    /** Swapscanner */
    'SCNR' = 'SCNR',
    /** BORA */
    'BORA' = 'BORA',
    /** Treecle */
    'TRCL' = 'TRCL',
    /** Wrapped Klaytn */
    'WKLAY' = 'WKLAY',
    /** Angry Bulls Club */
    'ABC' = 'ABC',
    /** Nakamoto Games */
    'NAKA' = 'NAKA',
    /** Art de Finance */
    'ADF' = 'ADF',
    /** Voxies */
    'VOXEL' = 'VOXEL',
    /** ViciCoin */
    'VCNT' = 'VCNT',
    /** Farcana */
    'FAR' = 'FAR',
    /** WiFi Map */
    'WIFI' = 'WIFI',
    /** Carrieverse */
    'CVTX' = 'CVTX',
    /** KlimaDAO */
    'KLIMA' = 'KLIMA',
    /** Kasta */
    'KASTA' = 'KASTA',
    /** BIM */
    'BIM' = 'BIM',
    /** Affyn */
    'FYN' = 'FYN',
    /** Medieval Empires */
    'MEE' = 'MEE',
    /** SwissCheese */
    'SWCH' = 'SWCH',
    /** UpOnly */
    'UPO' = 'UPO',
    /** Soil */
    'SOIL' = 'SOIL',
    /** MetaBeat */
    'BEAT' = 'BEAT',
    /** Permission Coin */
    'ASK' = 'ASK',
    /** Rainbow Token */
    'RBW' = 'RBW',
    /** Captain Tsubasa */
    'TSUGT' = 'TSUGT',
    /** Algebra */
    'ALGB' = 'ALGB',
    /** Cipher */
    'CPR' = 'CPR',
    /** DEFIT */
    'DEFIT' = 'DEFIT',
    /** Rebel Bots */
    'RBLS' = 'RBLS',
    /** Cleo Tech */
    'CLEO' = 'CLEO',
    /** Battle World */
    'BWO' = 'BWO',
    /** MetaTrace */
    'TRC' = 'TRC',
    /** Ideaology */
    'IDEA' = 'IDEA',
    /** BitCone */
    'CONE' = 'CONE',
    /** Polylastic */
    'POLX' = 'POLX',
    /** OneRare */
    'ORARE' = 'ORARE',
    /** The Unfettered Ecosystem */
    'SOULS' = 'SOULS',
    /** Aki Network */
    'AKI' = 'AKI',
    /** Mars Token */
    'MRST' = 'MRST',
    /** Artisse */
    'CATHEON' = 'CATHEON',
    /** Playermon */
    'PYM' = 'PYM',
    /** ISLAMICOIN */
    'ISLAMI' = 'ISLAMI',
    /** FrontFanz */
    'FANX' = 'FANX',
    /** Revolt 2 Earn */
    'RVLT' = 'RVLT',
    /** POLKER */
    'PKR' = 'PKR',
    /** MetaSoccer */
    'MSU' = 'MSU',
    /** Galaxy Fight Club */
    'GCOIN' = 'GCOIN',
    /** GrabCoinClub */
    'GC' = 'GC',
    /** SKYPlay */
    'SKP' = 'SKP',
    /** Lithosphere */
    'LITHO' = 'LITHO',
    /** Crypto Sports Network */
    'CSPN' = 'CSPN',
    /** Genesis Worlds */
    'GENESIS' = 'GENESIS',
    /** UZX */
    'UZX' = 'UZX',
    /** Websea */
    'WBS' = 'WBS',
    /** cLFi */
    'CLFI' = 'CLFI',
    /** AI PEPE KING */
    'AIPEPE' = 'AIPEPE',
    /** Seamans Token */
    'SEAT' = 'SEAT',
    /** SurfBoard Finance */
    'BOARD' = 'BOARD',
    /** GONE */
    'GONE' = 'GONE',
    /** Icon.X World */
    'ICNX' = 'ICNX',
    /** Enegra (EGX) */
    'EGX' = 'EGX',
    /** Creta World */
    'CRETA' = 'CRETA',
    /** GEODNET */
    'GEOD' = 'GEOD',
    /** FireStarter */
    'FLAME' = 'FLAME',
    /** Africarare Ubuntu */
    'UBU' = 'UBU',
    /** Game Meteor Coin */
    'GMTO' = 'GMTO',
    /** Ethernity CLOUD */
    'ECLD' = 'ECLD',
    /** Metavault Trade */
    'MVX' = 'MVX',
    /** IPMB */
    'IPMB' = 'IPMB',
    /** Oshi Token */
    'OSHI' = 'OSHI',
    /** Bounty Temple */
    'TYT' = 'TYT',
    /** PolyDoge */
    'POLYDOGE' = 'POLYDOGE',
    /** EnviDa */
    'EDAT' = 'EDAT',
    /** Crepe Coin */
    'CREPE' = 'CREPE',
    /** Leandro Lopes */
    'LOPES' = 'LOPES',
    /** Decentrawood */
    'DEOD' = 'DEOD',
    /** Versus-X */
    'VSX' = 'VSX',
    /** Digital Swiss Franc */
    'DSFR' = 'DSFR',
    /** Carnomaly */
    'CARR' = 'CARR',
    /** Proxy */
    'PRXY' = 'PRXY',
    /** Bullieverse */
    '$BULL' = '$BULL',
    /** Fidira */
    'FID' = 'FID',
    /** Sheertopia */
    'AMBO' = 'AMBO',
    /** PixelWorldCoin */
    'PWC' = 'PWC',
    /** Tanpin */
    'TANPIN' = 'TANPIN',
    /** RuufCoin */
    'RUUF' = 'RUUF',
    /** Floyx */
    'FLOYX' = 'FLOYX',
    /** AETERNUS */
    'ATRNO' = 'ATRNO',
    /** CULO */
    'CULO' = 'CULO',
    /** Vendetta */
    'VDT' = 'VDT',
    /** GeniuX */
    'IUX' = 'IUX',
    /** Floxypay */
    'FXY' = 'FXY',
    /** LUXY */
    'LUXY' = 'LUXY',
    /** XActRewards */
    'XACT' = 'XACT',
    /** Attack Wagon */
    'ATK' = 'ATK',
    /** UFORIKA */
    'FORA' = 'FORA',
    /** Nodewaves */
    'NWS' = 'NWS',
    /** GoldPesa Option */
    'GPO' = 'GPO',
    /** KridaFans */
    'KRIDA' = 'KRIDA',
    /** Sunflower Land */
    'SFL' = 'SFL',
    /** Planet IX(formerly IX token) */
    'IXT' = 'IXT',
    /** TETU */
    'TETU' = 'TETU',
    /** JennyCo */
    'JCO' = 'JCO',
    /** Aventis Metaverse */
    'AVTM' = 'AVTM',
    /** Aree Shards */
    'AES' = 'AES',
    /** TMN Global */
    'TMNG' = 'TMNG',
    /** Farmsent */
    'FARMS' = 'FARMS',
    /** UMMA Token */
    'UMMA' = 'UMMA',
    /** CryptoTradingFund */
    'CTF' = 'CTF',
    /** NFT Champions */
    'CHAMP' = 'CHAMP',
    /** Croatian FF Fan Token */
    'VATRENI' = 'VATRENI',
    /** PIVN */
    'PIVN' = 'PIVN',
    /** Torah Network */
    'VP' = 'VP',
    /** Giddy */
    'GIDDY' = 'GIDDY',
    /** Artmeta */
    'MART' = 'MART',
    /** NFTBooks */
    'NFTBS' = 'NFTBS',
    /** Derby Stars */
    'DSRUN' = 'DSRUN',
    /** Karmaverse */
    'KNOT' = 'KNOT',
    /** POPKON */
    'POPK' = 'POPK',
    /** Unicorn Milk */
    'UNIM' = 'UNIM',
    /** o-mee */
    'OME' = 'OME',
    /** ITSBLOC */
    'ITSB' = 'ITSB',
    /** Snook */
    'SNK' = 'SNK',
    /** Lucidao */
    'LCD' = 'LCD',
    /** Ribus */
    'RIB' = 'RIB',
    /** Pegaxy */
    'PGX' = 'PGX',
    /** MammothAI */
    'MAMAI' = 'MAMAI',
    /** Sway Protocol */
    'SWAY' = 'SWAY',
    /** Nurcoin */
    'NUR' = 'NUR',
    /** Map Node */
    'MNI' = 'MNI',
    /** MoonEdge */
    'MOONED' = 'MOONED',
    /** Homeety */
    'HOM' = 'HOM',
    /** BundlesBets V2 */
    'BUND' = 'BUND',
    /** SPONGE */
    'SPONGE' = 'SPONGE',
    /** Phantom of the Kill */
    'HIME' = 'HIME',
    /** JUGNI */
    'JUGNI' = 'JUGNI',
    /** NextEarth */
    'NXTT' = 'NXTT',
    /** Patriot Pay */
    'PPY' = 'PPY',
    /** EQ9 */
    'EQ9' = 'EQ9',
    /** Infinity Skies */
    'ISKY' = 'ISKY',
    /** Wolf Works DAO */
    'WWD' = 'WWD',
    /** Sphere Finance */
    'SPHERE' = 'SPHERE',
    /** Nexus Dubai */
    'NXD' = 'NXD',
    /** DDAO Hunters */
    'DDAO' = 'DDAO',
    /** MSQUARE GLOBAL */
    'MSQ' = 'MSQ',
    /** Pearl */
    'PEARL' = 'PEARL',
    /** Time Raiders */
    'XPND' = 'XPND',
    /** Tangible */
    'TNGBL' = 'TNGBL',
    /** Ethical Finance */
    'ETHI' = 'ETHI',
    /** A51 Finance */
    'A51' = 'A51',
    /** GIB */
    'GIB' = 'GIB',
    /** Bonkcon */
    'BONKCON' = 'BONKCON',
    /** CRI3X */
    'CRI3X' = 'CRI3X',
    /** Web3Games.com */
    'WGT' = 'WGT',
    /** INDU4.0 */
    'INDU' = 'INDU',
    /** GameStation */
    'GAMER' = 'GAMER',
    /** Flare Token */
    '1FLR' = '1FLR',
    /** Sheesha Finance Polygon */
    'MSHEESHA' = 'MSHEESHA',
    /** Axion */
    'AXN' = 'AXN',
    /** Polycat Finance */
    'FISH' = 'FISH',
    /** Aavegotchi FOMO */
    'FOMO' = 'FOMO',
    /** DUX */
    'DUX' = 'DUX',
    /** OvalPixel */
    'OPAIG' = 'OPAIG',
    /** Fitmint */
    'FITT' = 'FITT',
    /** Captain Tsubasa -RIVALS */
    'TSUBASAUT' = 'TSUBASAUT',
    /** QiDao */
    'QI' = 'QI',
    /** Aavegotchi FUD */
    'FUD' = 'FUD',
    /** Ommniverse */
    'OMMI' = 'OMMI',
    /** WaultSwap Polygon */
    'WEXPOLY' = 'WEXPOLY',
    /** Cryptomus */
    'CRMS' = 'CRMS',
    /** VersaGames */
    'VERSA' = 'VERSA',
    /** Geopoly */
    'GEO$' = 'GEO$',
    /** Anito Legends */
    'LARO' = 'LARO',
    /** CoinCollect */
    'COLLECT' = 'COLLECT',
    /** Adamant */
    'ADDY' = 'ADDY',
    /** Hibiki Run */
    'HUT' = 'HUT',
    /** Xion Finance */
    'XGT' = 'XGT',
    /** PolygonFarm Finance */
    'SPADE' = 'SPADE',
    /** Dragoma */
    'DMA' = 'DMA',
    /** Tutellus */
    'TUT' = 'TUT',
    /** Karmaverse Zombie */
    'SERUM' = 'SERUM',
    /** CoinPoker */
    'CHP' = 'CHP',
    /** KogeCoin.io */
    'KOGECOIN' = 'KOGECOIN',
    /** Energy8 */
    'E8' = 'E8',
    /** KPAPA */
    'KPAPA' = 'KPAPA',
    /** Saiyan PEPE */
    'SPEPE' = 'SPEPE',
    /** Rise of Elves */
    'ROE' = 'ROE',
    /** StrongNode Edge */
    'SNE' = 'SNE',
    /** Verity One Ltd. TRUTH MATTERS */
    'V' = 'V',
    /** Parrotly */
    'PBIRB' = 'PBIRB',
    /** Ocavu Network Token */
    'OCAVU' = 'OCAVU',
    /** World$tateCoin */
    'W$C' = 'W$C',
    /** PolyDragon */
    'DGOLD' = 'DGOLD',
    /** Virtu */
    'VTU' = 'VTU',
    /** Daily COP */
    'DLYCOP' = 'DLYCOP',
    /** DPEX */
    'DPEX' = 'DPEX',
    /** PolyCub */
    'POLYCUB' = 'POLYCUB',
    /** Gooeys */
    'GOO' = 'GOO',
    /** Honey */
    'HNY' = 'HNY',
    /** MEDIEUS */
    'MDUS' = 'MDUS',
    /** Crystl Finance */
    'CRYSTL' = 'CRYSTL',
    /** POLYDeFI */
    'OCTAGON' = 'OCTAGON',
    /** Stasis Network */
    'STS' = 'STS',
    /** JetSwap pWings */
    'pWINGS' = 'pWINGS',
    /** Dovish Finance */
    'DOV' = 'DOV',
    /** SafeSwap */
    'SSGTX' = 'SSGTX',
    /** Today's Crypto */
    'TCG' = 'TCG',
    /** Amber Soul Stone */
    'AMBER' = 'AMBER',
    /** Exchange Genesis Ethlas Medium */
    'XGEM' = 'XGEM',
    /** Kitsumon */
    '$KMC' = '$KMC',
    /** Dystopia */
    'DYST' = 'DYST',
    /** Sunflower Farm */
    'SFF' = 'SFF',
    /** Albino */
    'ABO' = 'ABO',
    /** SupremeX */
    'SXC' = 'SXC',
    /** SingularFarm */
    'SING' = 'SING',
    /** SPORTZCHAIN */
    'SPN' = 'SPN',
    /** OkLetsPlay */
    'OKLP' = 'OKLP',
    /** Cresio */
    'XCRE' = 'XCRE',
    /** Vault Hill City */
    'VHC' = 'VHC',
    /** Alpha Petto Shells */
    'APES' = 'APES',
    /** LunaFi */
    'LFI' = 'LFI',
    /** PDDOLLAR */
    'PDD' = 'PDD',
    /** Polygon HBD */
    'pHBD' = 'pHBD',
    /** Cyber City */
    'CYBR' = 'CYBR',
    /** Power Of Deep Ocean */
    'PODO' = 'PODO',
    /** DuDe */
    'DUDE' = 'DUDE',
    /** Branaverse */
    'BRANA' = 'BRANA',
    /** MAXX Finance */
    'MAXX' = 'MAXX',
    /** Cookies Protocol */
    'CP' = 'CP',
    /** Runy */
    'RUNY' = 'RUNY',
    /** Reel Token */
    'Reelt' = 'Reelt',
    /** MonoLend */
    'MLD' = 'MLD',
    /** Bitsniper */
    'AMMO' = 'AMMO',
    /** CLIMBERS */
    'MITHRIL' = 'MITHRIL',
    /** Retreeb */
    'TREEB' = 'TREEB',
    /** Ghost */
    'GHOST' = 'GHOST',
    /** X-CASH */
    'XCASH' = 'XCASH',
    /** Taki Games */
    'TAKI' = 'TAKI',
    /** Kassandra */
    'KACY' = 'KACY',
    /** Crypto Royale */
    'ROY' = 'ROY',
    /** Minerva Wallet */
    'MIVA' = 'MIVA',
    /** INTDESTCOIN */
    'INTD' = 'INTD',
    /** Optimism */
    'OP' = 'OP',
    /** Kwenta */
    'KWENTA' = 'KWENTA',
    /** DexToro */
    'DTORO' = 'DTORO',
    /** Extra Finance */
    'EXTRA' = 'EXTRA',
    /** Sonne Finance */
    'SONNE' = 'SONNE',
    /** LERNITAS */
    '#2192' = '2192',
    /** OpenSwap Optimism Token */
    'OPENX' = 'OPENX',
    /** Exactly Protocol */
    'EXA' = 'EXA',
    /** OpenX Locked Velo */
    'OPXVEVELO' = 'OPXVEVELO',
    /** Tranquil Staked ONE */
    'STONE' = 'STONE',
    /** Speed Star JOC */
    'JOC' = 'JOC',
    /** Defira */
    'FIRA' = 'FIRA',
    /** Viper Protocol */
    'VIPER' = 'VIPER',
    /** MarsColony */
    'CLNY' = 'CLNY',
    /** Tranquil Finance */
    'TRANQ' = 'TRANQ',
    /** Speed Star SPEED */
    'SPEED' = 'SPEED',
    /** dogwifhat */
    'WIF' = 'WIF',
    /** Wormhole */
    'W' = 'W',
    /** Pyth Network */
    'PYTH' = 'PYTH',
    /** cat in a dogs world */
    'MEW' = 'MEW',
    /** BOOK OF MEME */
    'BOME' = 'BOME',
    /** Jito */
    'JTO' = 'JTO',
    /** SLERF */
    'SLERF' = 'SLERF',
    /** Helium Mobile */
    'MOBILE' = 'MOBILE',
    /** Myro */
    'MYRO' = 'MYRO',
    /** Zebec Network */
    'ZBCN' = 'ZBCN',
    /** Hivemapper */
    'HONEY' = 'HONEY',
    /** Orca */
    'ORCA' = 'ORCA',
    /** Popcat (SOL) */
    'POPCAT' = 'POPCAT',
    /** Tensor */
    'TNSR' = 'TNSR',
    /** Staika */
    'STIK' = 'STIK',
    /** Neon EVM */
    'NEON' = 'NEON',
    /** Artrade */
    'ATR' = 'ATR',
    /** Metaplex */
    'MPLX' = 'MPLX',
    /** Ponke */
    'PONKE' = 'PONKE',
    /** Bonfida */
    'FIDA' = 'FIDA',
    /** Solend */
    'SLND' = 'SLND',
    /** Aurory */
    'AURY' = 'AURY',
    /** Only1 */
    'LIKE' = 'LIKE',
    /** Mango */
    'MNGO' = 'MNGO',
    /** Solanium */
    'SLIM' = 'SLIM',
    /** Octokn */
    'OTK' = 'OTK',
    /** Decimated */
    'DIO' = 'DIO',
    /** ROA CORE */
    'ROA' = 'ROA',
    /** Solama */
    'SOLAMA' = 'SOLAMA',
    /** DUKO */
    'DUKO' = 'DUKO',
    /** Gari Network */
    'GARI' = 'GARI',
    /** neversol */
    'NEVER' = 'NEVER',
    /** Cheems */
    'CHEEMS' = 'CHEEMS',
    /** Ben the Dog */
    'BENDOG' = 'BENDOG',
    /** analoS */
    'ANALOS' = 'ANALOS',
    /** SpaceFalcon */
    'FCON' = 'FCON',
    /** Cryowar */
    'CWAR' = 'CWAR',
    /** UNKJD */
    'MBS' = 'MBS',
    /** The First Youtube Cat */
    'PAJAMAS' = 'PAJAMAS',
    /** Crowny */
    'CRWNY' = 'CRWNY',
    /** BOZO */
    'BOZO' = 'BOZO',
    /** Prism */
    'PRISM' = 'PRISM',
    /** xHashtag AI */
    'XTAG' = 'XTAG',
    /** All.Art Protocol */
    'AART' = 'AART',
    /** Hawksight */
    'HAWK' = 'HAWK',
    /** Catman */
    'CATMAN' = 'CATMAN',
    /** Tulip Protocol */
    'TULIP' = 'TULIP',
    /** Solzilla */
    'SOLZILLA' = 'SOLZILLA',
    /** FRAKT Token */
    'FRKT' = 'FRKT',
    /** Dexlab */
    'DXL' = 'DXL',
    /** CropperFinance */
    'CRP' = 'CRP',
    /** RaceFi */
    'RACEFI' = 'RACEFI',
    /** EXGOLAND */
    'EXGO' = 'EXGO',
    /** Sypool */
    'SYP' = 'SYP',
    /** SolChicks Token */
    'CHICKS' = 'CHICKS',
    /** Synthetify */
    'SNY' = 'SNY',
    /** Soldex */
    'SOLX' = 'SOLX',
    /** Jito Staked SOL */
    'JITOSOL' = 'JITOSOL',
    /** Jupiter Perps LP */
    'JLP' = 'JLP',
    /** BlazeStake Staked SOL */
    'BSOL' = 'BSOL',
    /** Jeo Boden */
    'BODEN' = 'BODEN',
    /** Zeus Network */
    'ZEUS' = 'ZEUS',
    /** Sharky */
    'SHARK' = 'SHARK',
    /** Shark Cat */
    'SC' = 'SC',
    /** Socean Staked Sol */
    'SCNSOL' = 'SCNSOL',
    /** Solana Kit */
    'SOLKIT' = 'SOLKIT',
    /** nubcat */
    'NUB' = 'NUB',
    /** Elumia Crowns */
    'ELU' = 'ELU',
    /** MeowGangs */
    'MEOWG' = 'MEOWG',
    /** Whales Market */
    'WHALES' = 'WHALES',
    /** smolecoin */
    'SMOLE' = 'SMOLE',
    /** Pundu */
    'PUNDU' = 'PUNDU',
    /** Monku */
    'MONKU' = 'MONKU',
    /** Doland Tremp */
    'TREMP' = 'TREMP',
    /** Peng */
    'PENG' = 'PENG',
    /** IQ50 */
    'IQ50' = 'IQ50',
    /** Shadow Token */
    'SHDW' = 'SHDW',
    /** Saros */
    'SAROS' = 'SAROS',
    /** sols */
    'SOLS' = 'SOLS',
    /** Nosana */
    'NOS' = 'NOS',
    /** Costco Hot Dog */
    'COST' = 'COST',
    /** KittenWifHat */
    'KITTENWIF' = 'KITTENWIF',
    /** Moutai */
    'MOUTAI' = 'MOUTAI',
    /** Bork */
    'BORK' = 'BORK',
    /** Book of Meow */
    'BOMEOW' = 'BOMEOW',
    /** Lido Staked SOL */
    'stSOL' = 'stSOL',
    /** Loopy */
    'LOOPY' = 'LOOPY',
    /** catwifhat */
    'CWIF' = 'CWIF',
    /** JizzLord */
    'JIZZLORD' = 'JIZZLORD',
    /** Anita Max Wynn */
    'WYNN' = 'WYNN',
    /** Hump */
    'HUMP' = 'HUMP',
    /** shork */
    'SHORK' = 'SHORK',
    /** CHONKY */
    'CHONKY' = 'CHONKY',
    /** Ligma Node */
    'LIGMA' = 'LIGMA',
    /** Guufy */
    'GUUFY' = 'GUUFY',
    /** SNAP */
    '$NAP' = '$NAP',
    /** Bonk 2.0 */
    'BONK2.0' = 'BONK2.0',
    /** Powsche */
    'POWSCHE' = 'POWSCHE',
    /** Baby of BOMEOW */
    'BABYBOMEOW' = 'BABYBOMEOW',
    /** Book of Solana */
    'BOSOL' = 'BOSOL',
    /** UXD Stablecoin */
    'UXD' = 'UXD',
    /** Candle Cat */
    'CANDLE' = 'CANDLE',
    /** Wolf Wif */
    'BALLZ' = 'BALLZ',
    /** Marinade */
    'MNDE' = 'MNDE',
    /** Chinu */
    'CHINU' = 'CHINU',
    /** Pepewifhat */
    'PEPEWIFHAT' = 'PEPEWIFHAT',
    /** CatWifHat */
    'CATWIF' = 'CATWIF',
    /** Tongue Cat */
    'LUIS' = 'LUIS',
    /** HUND */
    'HUND' = 'HUND',
    /** Harold */
    'HAROLD' = 'HAROLD',
    /** Guacamole */
    'GUAC' = 'GUAC',
    /** Media Network */
    'MEDIA' = 'MEDIA',
    /** Abble */
    'AABL' = 'AABL',
    /** Hubble Protocol */
    'HBB' = 'HBB',
    /** burek obema */
    'OBEMA' = 'OBEMA',
    /** Mahabibi Bin Solman */
    '$MBS' = '$MBS',
    /** bul */
    'BUL' = 'BUL',
    /** Uranus (SOL) */
    'ANUS' = 'ANUS',
    /** Dither AI */
    'DITH' = 'DITH',
    /** Koala AI */
    'KOKO' = 'KOKO',
    /** Smilek to the Bank */
    'SMILEK' = 'SMILEK',
    /** Honeyland */
    'HXD' = 'HXD',
    /** Success Kid */
    'SKID' = 'SKID',
    /** SolCard */
    'SOLC' = 'SOLC',
    /** Orbitt Token */
    'ORBT' = 'ORBT',
    /** MOMO 2.0 */
    'MOMO' = 'MOMO',
    /** PunkAI */
    'PUNKAI' = 'PUNKAI',
    /** Milady Wif Hat */
    'LADYF' = 'LADYF',
    /** ApeWifHat */
    'APEWIFHAT' = 'APEWIFHAT',
    /** Fluxbot */
    'FLUXB' = 'FLUXB',
    /** AI Dragon */
    'CHATGPT' = 'CHATGPT',
    /** BitBrawl */
    'BRAWL' = 'BRAWL',
    /** Alickshundra Occasional-Cortex */
    'AOC' = 'AOC',
    /** Solbank */
    'SB' = 'SB',
    /** Solana Crash */
    'CRASH' = 'CRASH',
    /** SonicWifHat */
    'SONICWIF' = 'SONICWIF',
    /** Vibing Cat */
    'VCAT' = 'VCAT',
    /** A Gently Used 2001 Honda Civic */
    'USEDCAR' = 'USEDCAR',
    /** Wolf Of Solana */
    'WOS' = 'WOS',
    /** Epep */
    'EPEP' = 'EPEP',
    /** Ninja Protocol */
    'NINJA' = 'NINJA',
    /** MochiCat */
    'MOCHICAT' = 'MOCHICAT',
    /** Sealwifhat */
    'SI' = 'SI',
    /** Port Finance */
    'PORT' = 'PORT',
    /** Atlas DEX */
    'ATS' = 'ATS',
    /** WC Token */
    'WC' = 'WC',
    /** Daumenfrosch */
    '$DAUMEN' = '$DAUMEN',
    /** Mini Bitcoin */
    'MBTC' = 'MBTC',
    /** BookOfBullrun */
    'BOOB' = 'BOOB',
    /** CZOL */
    'CZOL' = 'CZOL',
    /** You Looked */
    'CIRCLE' = 'CIRCLE',
    /** Rex */
    'REX' = 'REX',
    /** Memeolorgy */
    'LORGY' = 'LORGY',
    /** MOGGO */
    'MOGGO' = 'MOGGO',
    /** Banx.gg */
    'BANX' = 'BANX',
    /** elizabath whoren */
    'WHOREN' = 'WHOREN',
    /** Not a lion, a... */
    'NALA' = 'NALA',
    /** Family */
    'FAM' = 'FAM',
    /** Pussy In Bio */
    'PUSSYINBIO' = 'PUSSYINBIO',
    /** WIFLAMA */
    'WIFLAMA' = 'WIFLAMA',
    /** AtPay */
    'ATPAY' = 'ATPAY',
    /** SOLGOAT */
    'SOLGOAT' = 'SOLGOAT',
    /** LOAF CAT */
    'LOAF' = 'LOAF',
    /** Queen of Engrand */
    'QUEEN' = 'QUEEN',
    /** Joram Poowel */
    'POOWEL' = 'POOWEL',
    /** Gecko Coin */
    'GECKO' = 'GECKO',
    /** JIZZUS CHRIST */
    'JIZZUS' = 'JIZZUS',
    /** The Simpsons */
    'DONUTS' = 'DONUTS',
    /** Kurobi */
    'KURO' = 'KURO',
    /** ZEBU */
    'ZEBU' = 'ZEBU',
    /** Bird Dog */
    'BIRDDOG' = 'BIRDDOG',
    /** MixMob */
    'MXM' = 'MXM',
    /** BaoBaoSol */
    'BAOS' = 'BAOS',
    /** dark boden */
    'DOBEN' = 'DOBEN',
    /** Byat */
    'BYAT' = 'BYAT',
    /** babydogwifhat */
    'BABYWIF' = 'BABYWIF',
    /** MBAPEPE */
    'MBAPEPE' = 'MBAPEPE',
    /** Hunter Biden's Laptop */
    '$LAPTOP' = '$LAPTOP',
    /** YOM */
    'YOM' = 'YOM',
    /** Solstream */
    'STREAM' = 'STREAM',
    /** Yikes Dog */
    'YIKES' = 'YIKES',
    /** CEILING CAT */
    'CEICAT' = 'CEICAT',
    /** Buftoad */
    'BUF' = 'BUF',
    /** Jake The Dog */
    'JAKE' = 'JAKE',
    /** NovaDEX */
    'NVX' = 'NVX',
    /** RIBBIT */
    'RBT' = 'RBT',
    /** Real Smurf Cat - шайлушай */
    'SMURF' = 'SMURF',
    /** Larix */
    'LARIX' = 'LARIX',
    /** Lifinity */
    'LFNTY' = 'LFNTY',
    /** ROD.AI */
    'RODAI' = 'RODAI',
    /** Solara */
    'SOLARA' = 'SOLARA',
    /** Rocky */
    'ROCKY' = 'ROCKY',
    /** DOGGO */
    'DOGGO' = 'DOGGO',
    /** bonkwifhat */
    'BIF' = 'BIF',
    /** Hadeswap */
    'HADES' = 'HADES',
    /** Indian Call Center */
    'ICC' = 'ICC',
    /** i dont know */
    'IDK' = 'IDK',
    /** Megapix */
    'MPIX' = 'MPIX',
    /** Whole Earth Coin */
    'WEC' = 'WEC',
    /** GooseFX */
    'GOFX' = 'GOFX',
    /** DePlan */
    'DPLN' = 'DPLN',
    /** SolSpend */
    'SPEND' = 'SPEND',
    /** KORRA */
    'KORRA' = 'KORRA',
    /** Doginhood */
    'DOGIN' = 'DOGIN',
    /** Baby Solana */
    'BABYSOL' = 'BABYSOL',
    /** Solex Launchpad */
    'SOLEX' = 'SOLEX',
    /** Kingdom of Ants */
    'ANTC' = 'ANTC',
    /** EFK Token */
    'EFK' = 'EFK',
    /** Bonk of America */
    'BONKFA' = 'BONKFA',
    /** SAFEMOON SOLANA */
    'SAFEMOON' = 'SAFEMOON',
    /** Andrea Von Speed */
    'VONSPEED' = 'VONSPEED',
    /** What's Updog */
    'UPDOG' = 'UPDOG',
    /** SoBit */
    'SOBB' = 'SOBB',
    /** Solnic */
    'SOLNIC' = 'SOLNIC',
    /** Alan the Alien */
    'ALAN' = 'ALAN',
    /** Baby Slerf */
    'BABYSLERF' = 'BABYSLERF',
    /** Solabrador */
    'SOBER' = 'SOBER',
    /** Sunny Aggregator */
    'SUNNY' = 'SUNNY',
    /** Pou */
    'POU' = 'POU',
    /** SonarWatch */
    'SONAR' = 'SONAR',
    /** NeverSurrenderOne's */
    'NSO' = 'NSO',
    /** SolTradingBot */
    'STBOT' = 'STBOT',
    /** HMM */
    'HMM' = 'HMM',
    /** Snoopy */
    'SNOOPY' = 'SNOOPY',
    /** Baba */
    '$BABA' = '$BABA',
    /** el gato */
    'ELGATO' = 'ELGATO',
    /** LICKGOAT */
    'LICK' = 'LICK',
    /** Solice */
    'SLC' = 'SLC',
    /** CHURRO-The Jupiter Dog */
    'CHURRO' = 'CHURRO',
    /** SHIBONK */
    'SBONK' = 'SBONK',
    /** Palmy */
    'PALMY' = 'PALMY',
    /** 21X */
    '21X' = '21X',
    /** BPINKY */
    'BPINKY' = 'BPINKY',
    /** Gary Banking */
    'GB' = 'GB',
    /** Kineko */
    'KNK' = 'KNK',
    /** MemeCoinDAO */
    'MEMES' = 'MEMES',
    /** CATWIFHAT */
    'CIF' = 'CIF',
    /** Solpaka */
    'SOLPAKA' = 'SOLPAKA',
    /** Pepe Neko */
    '$PEKO' = '$PEKO',
    /** Cykura */
    'CYS' = 'CYS',
    /** Solana Shib */
    'SSHIB' = 'SSHIB',
    /** Spoofify */
    'SPOOF' = 'SPOOF',
    /** Balin Bank */
    'BALIN' = 'BALIN',
    /** lowq frends */
    'LOWQ' = 'LOWQ',
    /** Edgevana Staked SOL */
    'EDGESOL' = 'EDGESOL',
    /** dogwifscarf */
    'WIFS' = 'WIFS',
    /** Trump Pepe */
    'TRUMPEPE' = 'TRUMPEPE',
    /** BLACK COQINU */
    'BCOQ' = 'BCOQ',
    /** Parrot Protocol */
    'PRT' = 'PRT',
    /** catwifhat */
    'CATHAT' = 'CATHAT',
    /** Crustieno Renaldo */
    'SIUUU' = 'SIUUU',
    /** CoreStarter */
    'CSTR' = 'CSTR',
    /** GPU Inu */
    'GPUINU' = 'GPUINU',
    /** TabTrader Token */
    'TTT' = 'TTT',
    /** GameBoy */
    'GBOY' = 'GBOY',
    /** WAR OF MEME */
    'WOME' = 'WOME',
    /** PsyOptions */
    'PSY' = 'PSY',
    /** STRAY */
    'STRAY' = 'STRAY',
    /** Siu */
    'SIU' = 'SIU',
    /** Graphite Protocol */
    'GP' = 'GP',
    /** coolcat */
    'COOL' = 'COOL',
    /** Blocksmith Labs Forge */
    '$FORGE' = '$FORGE',
    /** Magaiba */
    'MAGAIBA' = 'MAGAIBA',
    /** Wall Street Bets Solana */
    'WSBS' = 'WSBS',
    /** CAT DOGE */
    'CATDOGE' = 'CATDOGE',
    /** FUBAO */
    'FUBAO' = 'FUBAO',
    /** UXD Protocol */
    'UXP' = 'UXP',
    /** Solfiles */
    'FILES' = 'FILES',
    /** Book of Whales */
    'BOWE' = 'BOWE',
    /** Mike Tython */
    'THAVAGE' = 'THAVAGE',
    /** PUG AI */
    'PUGAI' = 'PUGAI',
    /** HotDog */
    'HOTDOG' = 'HOTDOG',
    /** Yaoyao's Cat */
    'YAOYAO' = 'YAOYAO',
    /** Unique Venture Clubs */
    'UNQ' = 'UNQ',
    /** Ratio Finance */
    'RATIO' = 'RATIO',
    /** Peepo (SOL) */
    '$PEEP' = '$PEEP',
    /** Catfish */
    'CATFISH' = 'CATFISH',
    /** Fronk */
    'FRONK' = 'FRONK',
    /** KNOB$ */
    'KNOB' = 'KNOB',
    /** Yaku */
    '$YAKU' = '$YAKU',
    /** DeltaFi */
    'DELFI' = 'DELFI',
    /** Flowmatic */
    '$FM' = '$FM',
    /** TMRW Coin */
    'TMRW' = 'TMRW',
    /** BOMK */
    'BOMK' = 'BOMK',
    /** Angry Girlfriend */
    '205/45R16' = '205/45R16',
    /** RazrFi */
    'SOLR' = 'SOLR',
    /** simbawifhat */
    'SWH' = 'SWH',
    /** Wenwifhat */
    'WHY' = 'WHY',
    /** Bitcoin Cat */
    'BTCAT' = 'BTCAT',
    /** SoliDefi */
    'SOLFI' = 'SOLFI',
    /** Solpad Finance */
    'SOLPAD' = 'SOLPAD',
    /** Bible of Memes */
    'BIBO' = 'BIBO',
    /** Jeet */
    'JEET' = 'JEET',
    /** Dogelana */
    'DGLN' = 'DGLN',
    /** Kabosu */
    '$KABOSU' = '$KABOSU',
    /** Dynasty Wars */
    'DWARS' = 'DWARS',
    /** Sentre Protocol */
    'SNTR' = 'SNTR',
    /** Famous Fox Federation */
    'FOXY' = 'FOXY',
    /** Just The Tip */
    'TIPS' = 'TIPS',
    /** Bonk Earn */
    'BERN' = 'BERN',
    /** Krypdraw */
    'DRAW' = 'DRAW',
    /** MonkeDAO */
    'DAOSOL' = 'DAOSOL',
    /** YUGE COIN */
    'YUGE' = 'YUGE',
    /** HashBit AI */
    'HBIT' = 'HBIT',
    /** Garfield Wif Hat */
    'GARWIF' = 'GARWIF',
    /** CATO */
    'CATO' = 'CATO',
    /** Bong - Bonk's Brother */
    '$BONG' = '$BONG',
    /** CAT KING */
    'CATKING' = 'CATKING',
    /** Corn Dog */
    'CDOG' = 'CDOG',
    /** NexMillionaires */
    'NEXMS' = 'NEXMS',
    /** Sillycat */
    'SILLYCAT' = 'SILLYCAT',
    /** Ewon Mucks */
    'EWON' = 'EWON',
    /** monkeywifhat */
    'MIF' = 'MIF',
    /** Solamander */
    'SOLY' = 'SOLY',
    /** yotoshi */
    'YOTO' = 'YOTO',
    /** CHILI */
    'CHILI' = 'CHILI',
    /** SBF In Jail */
    'SBF' = 'SBF',
    /** GRIMREAPER */
    'GRIM' = 'GRIM',
    /** BURRRD */
    'BURRRD' = 'BURRRD',
    /** ViZion Protocol */
    'VIZION' = 'VIZION',
    /** Phauntem */
    'PHAUNTEM' = 'PHAUNTEM',
    /** Nikola Tesla Token */
    '#369' = '369',
    /** Plerf */
    'PLERF' = 'PLERF',
    /** Libra Protocol */
    'LIBRA' = 'LIBRA',
    /** CHUCHU */
    'CHUCHU' = 'CHUCHU',
    /** Plutonians */
    'PLD' = 'PLD',
    /** JPool Staked SOL (JSOL) */
    'JSOL' = 'JSOL',
    /** Pepe Sora AI */
    'PEPESORA' = 'PEPESORA',
    /** Badluckbaby */
    'BLBY' = 'BLBY',
    /** WifStash Coin */
    'WIF~' = 'WIF~',
    /** CRASHBOYS */
    'BOYS' = 'BOYS',
    /** Pigeon In Yellow Boots */
    'PIGEON' = 'PIGEON',
    /** The MVP Society */
    'DGEN' = 'DGEN',
    /** NEKO */
    'NEKO' = 'NEKO',
    /** PEPE by Matt Furie */
    '$PEPE' = '$PEPE',
    /** DogSwaghat */
    'DOGSWAG' = 'DOGSWAG',
    /** Safemuun */
    'SAFEMUUN' = 'SAFEMUUN',
    /** Gary */
    'GARY' = 'GARY',
    /** Homer Simpson(Solana) */
    'HOMER' = 'HOMER',
    /** Rich Of Memes */
    'ROME' = 'ROME',
    /** SNOOPYBABE */
    'SBABE' = 'SBABE',
    /** basis.markets */
    'BASIS' = 'BASIS',
    /** Leancoin */
    'LEAN' = 'LEAN',
    /** SOLAMB */
    'SOLAMB' = 'SOLAMB',
    /** Boring Protocol */
    'BOP' = 'BOP',
    /** DONALD TRUMP */
    'DONALD' = 'DONALD',
    /** Sol Cat Warrior */
    'WCAT' = 'WCAT',
    /** WALLi */
    'WALLI' = 'WALLI',
    /** Solberg */
    'SLB' = 'SLB',
    /** Astronaut Aron */
    'ARON' = 'ARON',
    /** Sekai DAO */
    'SEKAI' = 'SEKAI',
    /** DecentraCloud */
    'DCLOUD' = 'DCLOUD',
    /** IDOLINU */
    'IDOL' = 'IDOL',
    /** SolAPE Token */
    'SOLAPE' = 'SOLAPE',
    /** TrendingTool.io */
    'SMM' = 'SMM',
    /** Pepe the pepe */
    'PEPEE' = 'PEPEE',
    /** Style */
    'STYLE' = 'STYLE',
    /** KING OF CATS */
    'KCAT' = 'KCAT',
    /** Lambda Markets */
    'LMDA' = 'LMDA',
    /** Cat Hero */
    'CATHERO' = 'CATHERO',
    /** Compendium */
    'CMFI' = 'CMFI',
    /** Genopets KI */
    'KI' = 'KI',
    /** SolFarm */
    'SFARM' = 'SFARM',
    /** SOLPENG */
    'SOLPENG' = 'SOLPENG',
    /** Snakes Game */
    'SNAKES' = 'SNAKES',
    /** Voodoo Token */
    'LDZ' = 'LDZ',
    /** Myro Dog */
    'MYROO' = 'MYROO',
    /** KITTI TOKEN */
    'KITTI' = 'KITTI',
    /** Swole Doge */
    'SWOLE' = 'SWOLE',
    /** ChikinCoin */
    'CKC' = 'CKC',
    /** PenisGrow */
    'PENIS' = 'PENIS',
    /** SlerfFork */
    'SLERFFORK' = 'SLERFFORK',
    /** GENZ Token */
    'GENZ' = 'GENZ',
    /** SLORK */
    'SLORK' = 'SLORK',
    /** Robin of Da Hood */
    'ROBIN' = 'ROBIN',
    /** Chinese Communist Dragon */
    'FENTANYL' = 'FENTANYL',
    /** CREW INU */
    'CREW' = 'CREW',
    /** DUGE */
    'DUGE' = 'DUGE',
    /** HolyGrails.io */
    'HOLY' = 'HOLY',
    /** aping */
    'APING' = 'APING',
    /** dogald trump */
    'DOGALD' = 'DOGALD',
    /** Heavenland */
    'HTO' = 'HTO',
    /** BOOK OF KILLER */
    'BOKI' = 'BOKI',
    /** Pepe v2 */
    'PE' = 'PE',
    /** Cirque Du Sol */
    'CIRCUS' = 'CIRCUS',
    /** Boryoku Dragonz */
    'BOKU' = 'BOKU',
    /** AvocadoCoin */
    'AVDO' = 'AVDO',
    /** larrywifhat */
    'LIF' = 'LIF',
    /** Sandy */
    'SANDY' = 'SANDY',
    /** Sussy Baka Impostor */
    'AMOGUS' = 'AMOGUS',
    /** Book of Meme 2.0 */
    'BOME2' = 'BOME2',
    /** Welsh Corgi */
    '$CORGI' = '$CORGI',
    /** YoloNolo */
    'YOLO' = 'YOLO',
    /** Solsponge */
    'SOLSPONGE' = 'SOLSPONGE',
    /** FONE */
    'FONE' = 'FONE',
    /** nioctiB */
    'NIOCTIB' = 'NIOCTIB',
    /** Dragy */
    'DRAGY' = 'DRAGY',
    /** Investin */
    'IVN' = 'IVN',
    /** GameFork */
    'GAMEFORK' = 'GAMEFORK',
    /** Johm lemmon */
    'JOHM' = 'JOHM',
    /** Worried */
    'BUFFET' = 'BUFFET',
    /** Solareum Wallet */
    'XSB' = 'XSB',
    /** Potato */
    'POTATO' = 'POTATO',
    /** AstraDEX */
    'ASDEX' = 'ASDEX',
    /** Plutonians */
    'RPC' = 'RPC',
    /** Akita Inu */
    'AKT' = 'AKT',
    /** SAUDI PEPE */
    'SAUDIPEPE' = 'SAUDIPEPE',
    /** Solcial */
    'SLCL' = 'SLCL',
    /** pepewifhat */
    'PWH' = 'PWH',
    /** Watergate */
    'WTG' = 'WTG',
    /** OOGI */
    'OOGI' = 'OOGI',
    /** Moonlana */
    'MOLA' = 'MOLA',
    /** Bitcat */
    'BITCAT' = 'BITCAT',
    /** Lola */
    'LOLA' = 'LOLA',
    /** WIFCAT COIN */
    'WIFCAT' = 'WIFCAT',
    /** Danol Tremp */
    'TRWP' = 'TRWP',
    /** SAM */
    'ALTMAN' = 'ALTMAN',
    /** Bamboo Coin */
    'BMBO' = 'BMBO',
    /** CRONK */
    'CRONK' = 'CRONK',
    /** Scrap */
    'SCRAP' = 'SCRAP',
    /** Puma */
    'PUMA' = 'PUMA',
    /** Noka Solana AI */
    'NOKA' = 'NOKA',
    /** SOLANA MEME TOKEN */
    'SOL10' = 'SOL10',
    /** Memes Street */
    'MST' = 'MST',
    /** BOOK OF MEME 2.0 */
    'BOME 2.0' = 'BOME 2.0',
    /** Sunny Side up */
    'SSU' = 'SSU',
    /** Apricot Finance */
    'APT' = 'APT',
    /** Croco */
    '$CROCO' = '$CROCO',
    /** ROPE Token */
    'ROPE' = 'ROPE',
    /** GhostKidDao */
    '$BOO' = '$BOO',
    /** Bruv */
    'BRUV' = 'BRUV',
    /** Simpson Family */
    'SMF' = 'SMF',
    /** Gaysler */
    'GAYSLER' = 'GAYSLER',
    /** FLOOF */
    'FLOOF' = 'FLOOF',
    /** Galaxy */
    'GLXY' = 'GLXY',
    /** Doge Of Grok AI */
    'DOGEGROKAI' = 'DOGEGROKAI',
    /** Courage the Cowardly Dog */
    'COURAGE' = 'COURAGE',
    /** The OG Cheems Inu */
    'OGCINU' = 'OGCINU',
    /** BANG */
    'BANG' = 'BANG',
    /** Wrapped ALGO */
    'XALGO' = 'XALGO',
    /** Metamall */
    'MALL' = 'MALL',
    /** MoonKize */
    'MOONKIZE' = 'MOONKIZE',
    /** TheSolanDAO */
    'SDO' = 'SDO',
    /** BOOK OF MEMES */
    'BOMES' = 'BOMES',
    /** Yawww */
    'YAW' = 'YAW',
    /** King Slerf */
    'KINGSLERF' = 'KINGSLERF',
    /** Nirvana NIRV */
    'NIRV' = 'NIRV',
    /** DeFi Land Gold */
    'GOLDY' = 'GOLDY',
    /** Perpetual Motion Machine */
    'PMM' = 'PMM',
    /** doginwotah */
    'WATER' = 'WATER',
    /** Elusk Mon */
    'ELUSKMON' = 'ELUSKMON',
    /** SWEETS */
    'SWTS' = 'SWTS',
    /** Charactbit */
    'CHB' = 'CHB',
    /** Elumia Krystal Shards */
    'EKS' = 'EKS',
    /** SLERF 2.0 */
    'SLERF2' = 'SLERF2',
    /** Secret Skellies Society */
    '$CRYPT' = '$CRYPT',
    /** Book of Derp */
    'BODE' = 'BODE',
    /** Learning Star */
    'LSTAR' = 'LSTAR',
    /** SouloCoin */
    'SOULO' = 'SOULO',
    /** TinyBits */
    'TINY' = 'TINY',
    /** XGLI DAO Protocol */
    'XGLI' = 'XGLI',
    /** SolChicks Shards */
    'SHARDS' = 'SHARDS',
    /** Vampire Inu */
    'VAMPIRE' = 'VAMPIRE',
    /** Theca */
    'THECA' = 'THECA',
    /** Mindfolk Wood */
    '$WOOD' = '$WOOD',
    /** Solvent */
    'SVT' = 'SVT',
    /** Rally (Solana) */
    'SRLY' = 'SRLY',
    /** Enrex */
    'ENRX' = 'ENRX',
    /** Aevum */
    'AEVUM' = 'AEVUM',
    /** Leia */
    'LEIA' = 'LEIA',
    /** SOCOLA INU */
    'SOCOLA' = 'SOCOLA',
    /** SolanaApe */
    'SAPE' = 'SAPE',
    /** ELON DRAGON */
    'ELONDRAGON' = 'ELONDRAGON',
    /** Randall */
    'SNITCH' = 'SNITCH',
    /** WC Donalds */
    'WCDONALDS' = 'WCDONALDS',
    /** Funassyi */
    'FUNASSYI' = 'FUNASSYI',
    /** CITI Fediverse */
    'CITI' = 'CITI',
    /** BOOK OF MEME 2.0 */
    'BOME2.0' = 'BOME2.0',
    /** FigureWifHat */
    'FWH' = 'FWH',
    /** ATLAZ */
    'AAZ' = 'AAZ',
    /** Helium */
    'HNT' = 'HNT',
    /** USDH */
    'USDH' = 'USDH',
    /** Saber */
    'SBR' = 'SBR',
    /** BONKLANA */
    'BOK' = 'BOK',
    /** JetTon Game */
    'JETTON' = 'JETTON',
    /** Resistance Dog */
    'REDO' = 'REDO',
    /** TON Bridged USDT */
    'JUSDT' = 'JUSDT',
    /** Gram */
    'GRAM' = 'GRAM',
    /** STON.fi */
    'STON' = 'STON',
    /** TG20 TGram */
    'TGRAM' = 'TGRAM',
    /** TONY THE DUCK */
    'TONY' = 'TONY',
    /** Tonk Inu */
    'TONK' = 'TONK',
    /** Ton Raffles */
    'RAFF' = 'RAFF',
    /** KINGYTON */
    'KINGY' = 'KINGY',
    /** TONNEL Network */
    'TONNEL' = 'TONNEL',
    /** Paper Plane */
    'PLANE' = 'PLANE',
    /** ARBUZ */
    'ARBUZ' = 'ARBUZ',
    /** Fanzee */
    'FNZ' = 'FNZ',
    /** Megaton Finance */
    'MEGA' = 'MEGA',
    /** GEMSTON */
    'GEMSTON' = 'GEMSTON',
    /** Glint Coin */
    'GLINT' = 'GLINT',
    /** Lavandos */
    'LAVE' = 'LAVE',
    /** Find & Check */
    'FCK' = 'FCK',
    /** SafemoonTon */
    'SAFET' = 'SAFET',
    /** bemo staked TON */
    'stTON' = 'stTON',
    /** Margaritis */
    'MARGA' = 'MARGA',
    /** TONSniper */
    'TONS' = 'TONS',
    /** Ambra */
    'AMBR' = 'AMBR',
    /** Tonex */
    'TNX' = 'TNX',
    /** JUST */
    'JST' = 'JST',
    /** USDJ */
    'USDJ' = 'USDJ',
    /** wstUSDT */
    'WSTUSDT' = 'WSTUSDT',
    /** Bitcoin TRC20 */
    'BTCT' = 'BTCT',
    /** PayNet Coin */
    'PAYN' = 'PAYN',
    /** Kyrrex */
    'KRRX' = 'KRRX',
    /** Fuji */
    'FJT' = 'FJT',
    /** Metaworld */
    'MWCC' = 'MWCC',
    /** Guarantee */
    'TEE' = 'TEE',
    /** CORN */
    'CORN' = 'CORN',
    /** SalmonSwap */
    'SAL' = 'SAL',
    /** FIX00 */
    'FIX00' = 'FIX00',
    /** BemChain */
    'BCN' = 'BCN',
    /** UpStableToken */
    'USTX' = 'USTX',
    /** uShark */
    'USHARK' = 'USHARK',
    /** GreenZoneX */
    'GZX' = 'GZX',
    /** Degree Crypto Token */
    'DCT' = 'DCT',
    /** Tourism Industry Metaverse */
    'TIM' = 'TIM',
    /** X7 Coin */
    'X7C' = 'X7C',
    /** Litecoin TRC20 */
    'LTCT' = 'LTCT',
    /** JackPool.finance */
    'JFI' = 'JFI',
    /** MUSE ENT NFT */
    'MSCT' = 'MSCT',
    /** Healium */
    'HLM' = 'HLM',
    /** CryptoBharatCoin */
    'CBC' = 'CBC',
    /** Jot Art */
    'JOT' = 'JOT',
    /** TamaKitty */
    'TITTY' = 'TITTY',
    /** Chainpay */
    'CPAY' = 'CPAY',
    /** BIZZCOIN */
    'BIZZ' = 'BIZZ',
    /** 888tron */
    '#888' = '888',
    /** TRONbetLive */
    'LIVE' = 'LIVE',
    /** Extraterrestrial Token */
    'ET' = 'ET',
    /** TrueCNH */
    'TCNH' = 'TCNH',
    /** ITC */
    'ITC' = 'ITC',
    /** Wrapped TRON */
    'WTRX' = 'WTRX',
    /** BitBegin */
    'BRIT' = 'BRIT',
    /** Venom */
    'VENOM' = 'VENOM',
    /** VeChain */
    'VET' = 'VET',
    /** VeThor Token */
    'VTHO' = 'VTHO',
    /** OceanEx Token */
    'OCE' = 'OCE',
    /** Jur */
    'JUR' = 'JUR',
    /** XDC Network */
    'XDC' = 'XDC',
    /** Comtech Gold */
    'CGO' = 'CGO',
    /** Plugin */
    'PLI' = 'PLI',
    /** StorX Network */
    'SRX' = 'SRX',
    /** Fathom Dollar */
    'FXD' = 'FXD',
    /** Globiance Exchange Token */
    'GBEX' = 'GBEX',
    /** Law Blocks (AI) */
    'LBT' = 'LBT',
    /** Fathom Protocol */
    'FTHM' = 'FTHM',
    /** XSwap Treasure */
    'XTT' = 'XTT',
    /** Fluent Finance */
    'USPLUS' = 'USPLUS',
    /** Wrapped XDC */
    'WXDC' = 'WXDC',
    /** Stellar */
    'XLM' = 'XLM',
    /** Lumenswap */
    'LSP' = 'LSP',
    /** Edgecoin */
    'EDGT' = 'EDGT',
    /** Scopuly */
    'SCOP' = 'SCOP',
    /** TheFutbolCoin */
    'TFC' = 'TFC',
    /** Afreum */
    'AFR' = 'AFR',
    /** XRP Healthcare */
    'XRPH' = 'XRPH',
    /** Galaxy Coin */
    'GALAXY' = 'GALAXY',
    /** XRPayNet */
    'XRPAYNET' = 'XRPAYNET',
    /** Equilibrium Games */
    'EQ' = 'EQ',
    /** Xrp Classic (new) */
    'XRPC' = 'XRPC',
    /** xSPECTAR */
    'XSPECTAR' = 'XSPECTAR',
    /** XGOLD COIN */
    'XGOLD' = 'XGOLD',
    /** SmartNFT */
    'SMARTNFT' = 'SMARTNFT',
    /** XList */
    'XLIST' = 'XLIST',
    /** SmartLOX */
    'SMARTLOX' = 'SMARTLOX',
    /** The Reaper */
    'RPR' = 'RPR',
}
