import * as amplitude from '@amplitude/analytics-browser';
import classnames from 'classnames/bind';
import { memo, useCallback, useState } from 'react';

import { Swap } from '@swing.xyz/ui';

import { Button, Flex, FlexContainer, IButtonIcon, Icon, Modal } from '@smartfolly/frontend.web-ui';

import { swingSwapId } from '../../../configs';

import { Tooltip } from '../Common';

import './swing.css';

import styles from './Sidebar.module.scss';

const cnb = classnames.bind(styles);

const leftIcon: IButtonIcon = {
    icon: <Icon icon="swap" />,
};

export const SwapButton = memo(function SwapButton() {
    // States

    const [showSwap, setShowSwap] = useState<boolean>(false);

    // Getters

    const btnHint = 'Swing Swap'; // TODO: localize
    const btnTitle = 'Swap'; // TODO: localize

    // Actions

    const showModal = useCallback(() => {
        amplitude.logEvent('[swing.xyz] Open Swap Modal');
        setShowSwap(true);
    }, []);
    const hideModal = useCallback(() => setShowSwap(false), []);

    // Render

    return (
        <>
            <Modal
                className={cnb('swap-modal')}
                show={showSwap}
                onHide={hideModal}
                header={
                    <FlexContainer justify="space-between" align="center">
                        <Flex className="title title-large widget-text-primary">Swing Swap</Flex>
                    </FlexContainer>
                }
            >
                <Swap analytics projectId={swingSwapId} />
            </Modal>

            <Tooltip content={btnHint}>
                <div className={cnb('swap-button-wrapper')}>
                    <Button
                        onClick={showModal}
                        className="btn-primary back-special-accent-dark p-r-0.5 p-l-0.5 b-r-3"
                        aria-label={btnHint}
                        iconLeft={leftIcon}
                    >
                        <span className="action action-special d-none d-sm-block m-l-0.25 m-r-0.25">
                            {btnTitle}
                        </span>
                    </Button>
                </div>
            </Tooltip>
        </>
    );
});
