/* eslint-disable max-lines */
export enum StableCoins {
    'djed' = 'djed',
    'tether' = 'tether',
    'usd-coin' = 'usd-coin',
    'mai' = 'mai',
    'sperax-usd' = 'sperax-usd',
    'multi-collateral-dai' = 'multi-collateral-dai',
    'frax' = 'frax',
    'ethena-usde' = 'ethena-usde',
    'crvusd' = 'crvusd',
    'ethena-staked-usde' = 'ethena-staked-usde',
    'inverse-finance-dola-stablecoin' = 'inverse-finance-dola-stablecoin',
    'magic-internet-money' = 'magic-internet-money',
    'dram' = 'dram',
    'token-force-usd' = 'token-force-usd',
    'overnight-dai' = 'overnight-dai',
    'xdollar-stablecoin' = 'xdollar-stablecoin',
    'binance-usd' = 'binance-usd',
    'binance-idr' = 'binance-idr',
    'trueusd' = 'trueusd',
    'rupiah-token' = 'rupiah-token',
    'vai' = 'vai',
    'helio-money' = 'helio-money',
    'first-digital-usd' = 'first-digital-usd',
    'usdd' = 'usdd',
    'paxos-standard' = 'paxos-standard',
    'terrausd' = 'terrausd',
    'anchored-coins-aeur' = 'anchored-coins-aeur',
    'verified-usd' = 'verified-usd',
    'idrx' = 'idrx',
    'usdz' = 'usdz',
    'brz' = 'brz',
    'bilira' = 'bilira',
    'tor' = 'tor',
    'spiceusd' = 'spiceusd',
    'coin98-dollar' = 'coin98-dollar',
    'celo-dollar' = 'celo-dollar',
    'celo-euro' = 'celo-euro',
    'moola-celo-usd' = 'moola-celo-usd',
    'moola-celo-eur' = 'moola-celo-eur',
    'paypal-usd' = 'paypal-usd',
    'stasis-euro' = 'stasis-euro',
    'liquity-usd' = 'liquity-usd',
    'gemini-dollar' = 'gemini-dollar',
    'susd' = 'susd',
    'reserve' = 'reserve',
    'euro-coin' = 'euro-coin',
    'tether-eurt' = 'tether-eurt',
    'xsgd' = 'xsgd',
    'usdk' = 'usdk',
    'zusd' = 'zusd',
    'prisma-mkusd' = 'prisma-mkusd',
    'fei-usd' = 'fei-usd',
    'gyen' = 'gyen',
    'husd' = 'husd',
    'origin-dollar' = 'origin-dollar',
    'vnx-euro' = 'vnx-euro',
    'vnx-swiss-franc' = 'vnx-swiss-franc',
    'empty-set-dollar' = 'empty-set-dollar',
    'basis-cash' = 'basis-cash',
    'savings-dai' = 'savings-dai',
    'angle-protocol' = 'angle-protocol',
    'usdp' = 'usdp',
    'gho' = 'gho',
    'worldwide-usd' = 'worldwide-usd',
    'one-cash' = 'one-cash',
    'jpy-coin' = 'jpy-coin',
    'parallel' = 'parallel',
    'zarp-stablecoin' = 'zarp-stablecoin',
    'tether-mxnt' = 'tether-mxnt',
    'cryptofranc' = 'cryptofranc',
    'sora-synthetic-usd' = 'sora-synthetic-usd',
    'jpycoin' = 'jpycoin',
    'usd-bancor' = 'usd-bancor',
    'digixdao' = 'digixdao',
    'digix-gold-token' = 'digix-gold-token',
    'karatgold-coin' = 'karatgold-coin',
    'cad-coin' = 'cad-coin',
    'straitsx-indonesia-rupiah' = 'straitsx-indonesia-rupiah',
    'usdh' = 'usdh',
    'ton-bridged-usdt' = 'ton-bridged-usdt',
    'ton-bridged-usdc' = 'ton-bridged-usdc',
    'usdj' = 'usdj',
    'fathom-protocol' = 'fathom-protocol',
}
