/* eslint-disable max-lines */
/**
 * Supported tokens list.
 * Note: values are taken as CoinMarketCap unique `slug` identifiers.
 */
export enum Tokens {
    'cardano' = 'cardano',
    'snek' = 'snek',
    'iagon' = 'iagon',
    'minswap' = 'minswap',
    'indigo-protocol' = 'indigo-protocol',
    'hosky-token' = 'hosky-token',
    'djed' = 'djed',
    'kubecoin' = 'kubecoin',
    'adaswap' = 'adaswap',
    'book-io' = 'book-io',
    'indigo-protocol-iusd' = 'indigo-protocol-iusd',
    'newm' = 'newm',
    'liqwid-finance' = 'liqwid-finance',
    'revuto' = 'revuto',
    'wingriders' = 'wingriders',
    'fren-ada-peepos' = 'fren-ada-peepos',
    'axo' = 'axo',
    'aada-finance' = 'aada-finance',
    'indigo-protocol-ibtc' = 'indigo-protocol-ibtc',
    'genius-yield' = 'genius-yield',
    'pavia' = 'pavia',
    'ardana' = 'ardana',
    'orcfax' = 'orcfax',
    'charli3' = 'charli3',
    'shen' = 'shen',
    'encoins' = 'encoins',
    'anetabtc' = 'anetabtc',
    'stablecoinada' = 'stablecoinada',
    'ray-network' = 'ray-network',
    'venom-token' = 'venom-token',
    'muesliswap' = 'muesliswap',
    'vyfinance' = 'vyfinance',
    'empowa' = 'empowa',
    'bankercoinada' = 'bankercoinada',
    'clay-nation' = 'clay-nation',
    'adax' = 'adax',
    'chains-of-war' = 'chains-of-war',
    'camelcoin' = 'camelcoin',
    'yummi-universe' = 'yummi-universe',
    'tangent' = 'tangent',
    'ibilecoin' = 'ibilecoin',
    'cpiggy-bank-token' = 'cpiggy-bank-token',
    'tether' = 'tether',
    'chainlink' = 'chainlink',
    'singularitynet' = 'singularitynet',
    'usd-coin' = 'usd-coin',
    'wrapped-bitcoin' = 'wrapped-bitcoin',
    'wbnb' = 'wbnb',
    'world-mobile-token' = 'world-mobile-token',
    'cornucopias' = 'cornucopias',
    'nunet' = 'nunet',
    'paribus' = 'paribus',
    'weth' = 'weth',
    'wavax' = 'wavax',
    'wrapped-fantom' = 'wrapped-fantom',
    'rejuve-ai' = 'rejuve-ai',
    'meld' = 'meld',
    'cogito-protocol' = 'cogito-protocol',
    'mai' = 'mai',
    'blueshift' = 'blueshift',
    'wrapped-cardano' = 'wrapped-cardano',
    'sundaeswap' = 'sundaeswap',
    'nft-maker' = 'nft-maker',
    'adadao' = 'adadao',
    'algorand' = 'algorand',
    'ethereum' = 'ethereum',
    'arbitrum' = 'arbitrum',
    'magic-token' = 'magic-token',
    'xai-games' = 'xai-games',
    'arbdoge-ai' = 'arbdoge-ai',
    'vertex-protocol' = 'vertex-protocol',
    'ztx' = 'ztx',
    'wall-street-games-new' = 'wall-street-games-new',
    'hydranet' = 'hydranet',
    'reboot' = 'reboot',
    'mozaic' = 'mozaic',
    'sperax-usd' = 'sperax-usd',
    'openworld' = 'openworld',
    'zyberswap' = 'zyberswap',
    'neutra-finance' = 'neutra-finance',
    'bluesale-finance' = 'bluesale-finance',
    'pogai' = 'pogai',
    'fluidity-money' = 'fluidity-money',
    'xpet-tech' = 'xpet-tech',
    'camelot-token' = 'camelot-token',
    'avive-world' = 'avive-world',
    'grimace-doge' = 'grimace-doge',
    'gta-token' = 'gta-token',
    'vela-token' = 'vela-token',
    'fluidity' = 'fluidity',
    'roseon' = 'roseon',
    'edge-matrix-computing' = 'edge-matrix-computing',
    'aishiba' = 'aishiba',
    'a3s-protocol' = 'a3s-protocol',
    'ai-code' = 'ai-code',
    'ramses-exchange' = 'ramses-exchange',
    'unibit' = 'unibit',
    'florence-finance-medici' = 'florence-finance-medici',
    'arbipad' = 'arbipad',
    'mymetatrader' = 'mymetatrader',
    'hmx' = 'hmx',
    'plexus' = 'plexus',
    'lodestar' = 'lodestar',
    'the-ennead' = 'the-ennead',
    'yachtingverse' = 'yachtingverse',
    'size-arbitrum' = 'size-arbitrum',
    'factor' = 'factor',
    'arbswap' = 'arbswap',
    'zen-panda-coin' = 'zen-panda-coin',
    'neptune-mutual' = 'neptune-mutual',
    'umami-finance' = 'umami-finance',
    'arbidoge' = 'arbidoge',
    'jones-usdc' = 'jones-usdc',
    'arbidex-token' = 'arbidex-token',
    'plutusdao' = 'plutusdao',
    'world-peace-project' = 'world-peace-project',
    'yieldfarming-index' = 'yieldfarming-index',
    'chronos-arbitrum' = 'chronos-arbitrum',
    'cradles' = 'cradles',
    'arbgpt' = 'arbgpt',
    'rwa-finance' = 'rwa-finance',
    'realm-anima' = 'realm-anima',
    'tigris' = 'tigris',
    'lfgswap-finance-arbitrum' = 'lfgswap-finance-arbitrum',
    'crystal-erc404' = 'crystal-erc404',
    'yuri' = 'yuri',
    'hamachi' = 'hamachi',
    'rodeo-finance-xyz' = 'rodeo-finance-xyz',
    'mizar' = 'mizar',
    'gmd-protocol' = 'gmd-protocol',
    'vesta-finance' = 'vesta-finance',
    'foxify' = 'foxify',
    'buffer-finance' = 'buffer-finance',
    'ellerium-new' = 'ellerium-new',
    'aicore' = 'aicore',
    'arbshib' = 'arbshib',
    'ethforestai' = 'ethforestai',
    'alien-fi' = 'alien-fi',
    'arbinyan' = 'arbinyan',
    'sundae-the-dog' = 'sundae-the-dog',
    'oreoswap' = 'oreoswap',
    'honor-world-token' = 'honor-world-token',
    'autodca' = 'autodca',
    'lsdoge' = 'lsdoge',
    'archi-finance' = 'archi-finance',
    'leverageinu' = 'leverageinu',
    'exponential-capital-v2' = 'exponential-capital-v2',
    'gbot' = 'gbot',
    'smart-aliens' = 'smart-aliens',
    'sharky-swap' = 'sharky-swap',
    'liquid-finance' = 'liquid-finance',
    'modular-wallet' = 'modular-wallet',
    '3xcalibur' = '3xcalibur',
    'sector-finance' = 'sector-finance',
    'ghast' = 'ghast',
    'arcadeum' = 'arcadeum',
    'frozenai' = 'frozenai',
    'legends-lg' = 'legends-lg',
    'ai-english' = 'ai-english',
    'toxicgarden-finance-seed' = 'toxicgarden-finance-seed',
    'unlimited-network' = 'unlimited-network',
    'seneca' = 'seneca',
    'gnosis-gno' = 'gnosis-gno',
    'multi-collateral-dai' = 'multi-collateral-dai',
    'the-graph' = 'the-graph',
    'uniswap' = 'uniswap',
    'lido-dao' = 'lido-dao',
    'pendle' = 'pendle',
    'ankr' = 'ankr',
    'curve-dao-token' = 'curve-dao-token',
    'frax-share' = 'frax-share',
    'pancakeswap' = 'pancakeswap',
    'wootrade' = 'wootrade',
    'axelar' = 'axelar',
    'balancer' = 'balancer',
    'yearn-finance' = 'yearn-finance',
    'sushiswap' = 'sushiswap',
    'frax' = 'frax',
    'marlin' = 'marlin',
    'seedify-fund' = 'seedify-fund',
    'joe' = 'joe',
    'synapse-2' = 'synapse-2',
    'kujira' = 'kujira',
    'ethena-usde' = 'ethena-usde',
    'orbs' = 'orbs',
    'tellor' = 'tellor',
    'cartesi' = 'cartesi',
    'gains-network' = 'gains-network',
    'apex-token' = 'apex-token',
    'smardex' = 'smardex',
    'crvusd' = 'crvusd',
    'dodo' = 'dodo',
    'alpha-finance-lab' = 'alpha-finance-lab',
    'badger-dao' = 'badger-dao',
    'chainge' = 'chainge',
    'mobox' = 'mobox',
    'kyber-network-crystal-v2' = 'kyber-network-crystal-v2',
    'spell-token' = 'spell-token',
    'stargate-finance' = 'stargate-finance',
    'radiant-capital' = 'radiant-capital',
    'milady-meme-coin' = 'milady-meme-coin',
    'router-protocol' = 'router-protocol',
    'opulous' = 'opulous',
    'lumerin' = 'lumerin',
    'mxc' = 'mxc',
    'sperax' = 'sperax',
    'dforce' = 'dforce',
    'moon' = 'moon',
    'xend-finance' = 'xend-finance',
    'symbiosis-finance' = 'symbiosis-finance',
    'ubxs' = 'ubxs',
    'unlimitedip' = 'unlimitedip',
    'ferrum-network' = 'ferrum-network',
    'bridge-oracle' = 'bridge-oracle',
    'tarot' = 'tarot',
    'openleverage' = 'openleverage',
    'trexcoin' = 'trexcoin',
    'mahadao' = 'mahadao',
    'rigoblock' = 'rigoblock',
    'openocean' = 'openocean',
    'rubic' = 'rubic',
    'x-world-games' = 'x-world-games',
    'wicrypt' = 'wicrypt',
    'roobee' = 'roobee',
    'dsla-protocol' = 'dsla-protocol',
    'xaya' = 'xaya',
    'kommunitas' = 'kommunitas',
    'checkdot' = 'checkdot',
    'edgeware' = 'edgeware',
    'kromatika' = 'kromatika',
    'donut' = 'donut',
    'o3-swap' = 'o3-swap',
    'hord' = 'hord',
    'safeblast' = 'safeblast',
    'qatar-2022-token' = 'qatar-2022-token',
    'bullbear-ai' = 'bullbear-ai',
    'goons-of-balatroon' = 'goons-of-balatroon',
    'blackhat' = 'blackhat',
    'apeswap-finance' = 'apeswap-finance',
    'april' = 'april',
    'project-txa' = 'project-txa',
    'openblox' = 'openblox',
    'plasma-finance' = 'plasma-finance',
    'fantomstarter' = 'fantomstarter',
    'erc20' = 'erc20',
    'aluna-social' = 'aluna-social',
    'flashstake' = 'flashstake',
    'lido-finance-wsteth' = 'lido-finance-wsteth',
    'usd-coin-bridged-usdc-e' = 'usd-coin-bridged-usdc-e',
    'usd' = 'usd',
    'axlusdc' = 'axlusdc',
    'renzo-restaked-eth' = 'renzo-restaked-eth',
    'bvm' = 'bvm',
    'the-doge-nft' = 'the-doge-nft',
    'frax-finance-frax-ether' = 'frax-finance-frax-ether',
    'tbtc-token' = 'tbtc-token',
    'ice-decentralized-future' = 'ice-decentralized-future',
    'omnicat' = 'omnicat',
    'port3-network' = 'port3-network',
    'ethena-staked-usde' = 'ethena-staked-usde',
    'gam3s-gg' = 'gam3s-gg',
    'metados' = 'metados',
    'frax-staked-ether' = 'frax-staked-ether',
    'overnight' = 'overnight',
    'inverse-finance-dola-stablecoin' = 'inverse-finance-dola-stablecoin',
    'rabbit' = 'rabbit',
    'lybra-finance' = 'lybra-finance',
    'osaka-protocol' = 'osaka-protocol',
    'nutcoin-org' = 'nutcoin-org',
    'any-inu' = 'any-inu',
    'ox-coin' = 'ox-coin',
    'battle-pets' = 'battle-pets',
    'dopex' = 'dopex',
    'sanko-game-corp' = 'sanko-game-corp',
    'starheroes' = 'starheroes',
    'magic-internet-money' = 'magic-internet-money',
    'kap-games' = 'kap-games',
    'magpie' = 'magpie',
    'franklin' = 'franklin',
    'dram' = 'dram',
    'starfish-finance' = 'starfish-finance',
    'wrapped-usdr' = 'wrapped-usdr',
    'thorwallet' = 'thorwallet',
    'eno' = 'eno',
    'sharbi' = 'sharbi',
    'ai-meta-club' = 'ai-meta-club',
    'governance-ohm' = 'governance-ohm',
    'nitro-cartel' = 'nitro-cartel',
    'winr-protocol' = 'winr-protocol',
    'equilibria-finance' = 'equilibria-finance',
    'wagmi-com' = 'wagmi-com',
    'bumper' = 'bumper',
    'genomesdao' = 'genomesdao',
    'perpy' = 'perpy',
    'fore-protocol' = 'fore-protocol',
    'crazyrabbit' = 'crazyrabbit',
    'jones-dao' = 'jones-dao',
    'ipor' = 'ipor',
    'alongside-crypto-market-index' = 'alongside-crypto-market-index',
    'kenshi-v3' = 'kenshi-v3',
    'bonusblock' = 'bonusblock',
    'elk-finance' = 'elk-finance',
    'yieldification' = 'yieldification',
    'xdao' = 'xdao',
    'astra-dao' = 'astra-dao',
    'euroe-stablecoin' = 'euroe-stablecoin',
    'degenreborn' = 'degenreborn',
    'zoomer-coin' = 'zoomer-coin',
    'equation' = 'equation',
    'eclipse-fi' = 'eclipse-fi',
    'smolcoin' = 'smolcoin',
    'axm-token' = 'axm-token',
    'lumi-finance' = 'lumi-finance',
    'silo-finance' = 'silo-finance',
    'spool-dao' = 'spool-dao',
    'steakhut-finance' = 'steakhut-finance',
    'rekt-arb' = 'rekt-arb',
    'sex-one' = 'sex-one',
    'gnomeland' = 'gnomeland',
    'tridentdao' = 'tridentdao',
    'hepton' = 'hepton',
    'aiakita' = 'aiakita',
    'volta-club' = 'volta-club',
    'thales' = 'thales',
    'token-force-usd' = 'token-force-usd',
    'battlefly' = 'battlefly',
    'frax-price-index-share' = 'frax-price-index-share',
    'superpower-squad' = 'superpower-squad',
    'mummy-finance' = 'mummy-finance',
    'kabosu-family' = 'kabosu-family',
    'taobank' = 'taobank',
    'yield-yak' = 'yield-yak',
    'unsheth-ether' = 'unsheth-ether',
    'bpet' = 'bpet',
    'dfx-finance' = 'dfx-finance',
    'spherium' = 'spherium',
    'handle-fi' = 'handle-fi',
    'usdr' = 'usdr',
    'davos-protocol' = 'davos-protocol',
    'plutus-arb' = 'plutus-arb',
    'memepad' = 'memepad',
    'overnight-dai' = 'overnight-dai',
    'quick-intel-v2' = 'quick-intel-v2',
    'gnome' = 'gnome',
    'collab-land' = 'collab-land',
    'liquid-driver' = 'liquid-driver',
    'unsheth' = 'unsheth',
    'nola' = 'nola',
    'blocjerk' = 'blocjerk',
    'unidex' = 'unidex',
    'betswirl' = 'betswirl',
    'artichoke-protocol' = 'artichoke-protocol',
    'foom' = 'foom',
    'radioshack' = 'radioshack',
    'hold-vip' = 'hold-vip',
    'guardian' = 'guardian',
    'flourishing-ai' = 'flourishing-ai',
    'dopex-rdpx' = 'dopex-rdpx',
    'interport-token' = 'interport-token',
    'poison' = 'poison',
    'peg-eusd' = 'peg-eusd',
    'wardenswap' = 'wardenswap',
    'savvy' = 'savvy',
    'arken' = 'arken',
    'parallax-finance' = 'parallax-finance',
    'frax-price-index' = 'frax-price-index',
    'otterhome' = 'otterhome',
    'kunji-finance' = 'kunji-finance',
    'oath' = 'oath',
    'wombex-finance' = 'wombex-finance',
    'solidlizard' = 'solidlizard',
    'premia' = 'premia',
    'onchain-trade' = 'onchain-trade',
    'global-token' = 'global-token',
    'minato' = 'minato',
    'mmfinance-arbitrum' = 'mmfinance-arbitrum',
    'hyperbolic-protocol' = 'hyperbolic-protocol',
    'uranium3o8' = 'uranium3o8',
    'wepiggy' = 'wepiggy',
    'magikal-ai' = 'magikal-ai',
    'cap' = 'cap',
    'wrapped-memory' = 'wrapped-memory',
    'lakeviewmeta' = 'lakeviewmeta',
    'layer2dao' = 'layer2dao',
    'y2k' = 'y2k',
    'future-ai' = 'future-ai',
    'lexer' = 'lexer',
    'digitex' = 'digitex',
    'bitcoinbr' = 'bitcoinbr',
    'rovi-network' = 'rovi-network',
    'xdollar-stablecoin' = 'xdollar-stablecoin',
    'kortana' = 'kortana',
    'hundred-finance' = 'hundred-finance',
    'altitude' = 'altitude',
    'quoll-finance' = 'quoll-finance',
    'nftearth' = 'nftearth',
    'flux-protocol' = 'flux-protocol',
    'stereoai' = 'stereoai',
    'dei' = 'dei',
    'ptokens-btc' = 'ptokens-btc',
    'bolide' = 'bolide',
    'bitcoin-classic' = 'bitcoin-classic',
    'ethereummax' = 'ethereummax',
    'okcash' = 'okcash',
    'ether-wars' = 'ether-wars',
    'acryptos' = 'acryptos',
    'unlock-protocol' = 'unlock-protocol',
    'sarcophagus' = 'sarcophagus',
    'swapr' = 'swapr',
    'popcorn-network' = 'popcorn-network',
    'doubloon-token' = 'doubloon-token',
    'twoge-inu' = 'twoge-inu',
    'falcon-space-dex' = 'falcon-space-dex',
    'compounded-marinated-umami' = 'compounded-marinated-umami',
    'arbinu' = 'arbinu',
    'jones-glp' = 'jones-glp',
    'asgardx' = 'asgardx',
    'gridex' = 'gridex',
    'zenland' = 'zenland',
    'liondex' = 'liondex',
    'gnd-protocol-gnd' = 'gnd-protocol-gnd',
    'taikula-coin' = 'taikula-coin',
    'the-white-lotus' = 'the-white-lotus',
    'traderdao' = 'traderdao',
    'monopoly-finance-layer-3' = 'monopoly-finance-layer-3',
    'magicverse' = 'magicverse',
    'gmbl-computer' = 'gmbl-computer',
    'yakdao' = 'yakdao',
    'bitcoin-cash' = 'bitcoin-cash',
    'green-ben' = 'green-ben',
    'wrapped-bitcoin-cash' = 'wrapped-bitcoin-cash',
    'bitcoin' = 'bitcoin',
    'majo' = 'majo',
    'bnb' = 'bnb',
    'ftx-token' = 'ftx-token',
    'bitcoin-bep2' = 'bitcoin-bep2',
    'wazirx' = 'wazirx',
    'binance-usd' = 'binance-usd',
    'contentos' = 'contentos',
    'troy' = 'troy',
    'binance-idr' = 'binance-idr',
    'sharetoken' = 'sharetoken',
    'mithril' = 'mithril',
    'atlas-protocol' = 'atlas-protocol',
    'gowithmi' = 'gowithmi',
    'ormeus-ecosystem' = 'ormeus-ecosystem',
    'aeron' = 'aeron',
    'newton' = 'newton',
    'raven-protocol' = 'raven-protocol',
    'lition' = 'lition',
    'canyacoin' = 'canyacoin',
    'cpchain' = 'cpchain',
    'aditus' = 'aditus',
    'honest' = 'honest',
    'mcashchain' = 'mcashchain',
    'fantom' = 'fantom',
    'chiliz' = 'chiliz',
    'trueusd' = 'trueusd',
    'nexo' = 'nexo',
    'kava' = 'kava',
    'trust-wallet-token' = 'trust-wallet-token',
    'coti' = 'coti',
    'dusk' = 'dusk',
    'lto-network' = 'lto-network',
    'viction' = 'viction',
    'aergo' = 'aergo',
    'rupiah-token' = 'rupiah-token',
    'oxen' = 'oxen',
    'now-token' = 'now-token',
    'cashaa' = 'cashaa',
    'multivac' = 'multivac',
    'wagerr' = 'wagerr',
    'truechain' = 'truechain',
    'safepal' = 'safepal',
    'binaryx-new' = 'binaryx-new',
    'cheelee' = 'cheelee',
    'venus-bnb' = 'venus-bnb',
    'venus-eth' = 'venus-eth',
    'ontology-gas' = 'ontology-gas',
    'open-campus' = 'open-campus',
    'metfi2' = 'metfi2',
    'h2o-dao' = 'h2o-dao',
    'venus' = 'venus',
    'metars-genesis' = 'metars-genesis',
    'sleepless-ai' = 'sleepless-ai',
    'hooked-protocol' = 'hooked-protocol',
    'fusionist' = 'fusionist',
    'solidus-ai-tech' = 'solidus-ai-tech',
    'chaingpt' = 'chaingpt',
    'l7-dex' = 'l7-dex',
    'nfprompt' = 'nfprompt',
    'tokenfi' = 'tokenfi',
    'velo' = 'velo',
    'gamefi' = 'gamefi',
    'memetoon' = 'memetoon',
    'tokocrypto' = 'tokocrypto',
    'bakerytoken' = 'bakerytoken',
    'strikecoin' = 'strikecoin',
    'clash-of-lilliput' = 'clash-of-lilliput',
    'gaimin' = 'gaimin',
    'alitas' = 'alitas',
    'games-for-a-living' = 'games-for-a-living',
    'sologenic' = 'sologenic',
    'richquack-com' = 'richquack-com',
    'apollox' = 'apollox',
    'tenset' = 'tenset',
    'santos-fc-fan-token' = 'santos-fc-fan-token',
    'metacraft' = 'metacraft',
    'biswap' = 'biswap',
    'altura' = 'altura',
    'ime-lab' = 'ime-lab',
    'openfabric-ai' = 'openfabric-ai',
    'decubate' = 'decubate',
    'pitbull' = 'pitbull',
    'tranchess' = 'tranchess',
    'connex' = 'connex',
    'unifi-protocol-dao' = 'unifi-protocol-dao',
    'scallop' = 'scallop',
    'burger-cities' = 'burger-cities',
    'alpaca-finance' = 'alpaca-finance',
    'creo-engine' = 'creo-engine',
    'bloktopia' = 'bloktopia',
    'lazio-fan-token' = 'lazio-fan-token',
    'poolz-finance' = 'poolz-finance',
    'alpine-f1-team-fan-token' = 'alpine-f1-team-fan-token',
    'petals' = 'petals',
    'metahero' = 'metahero',
    'catgirl' = 'catgirl',
    'tokenpocket' = 'tokenpocket',
    'yooshi' = 'yooshi',
    'fc-porto' = 'fc-porto',
    'ellipsis' = 'ellipsis',
    'squid-grow' = 'squid-grow',
    'mangoman-intelligent' = 'mangoman-intelligent',
    'taboo-token' = 'taboo-token',
    'spacen' = 'spacen',
    'defigram' = 'defigram',
    'magic-square' = 'magic-square',
    'gifto' = 'gifto',
    'splintershards' = 'splintershards',
    'gt-protocol' = 'gt-protocol',
    'uns-token' = 'uns-token',
    'diamond-launch' = 'diamond-launch',
    'wall-street-memes' = 'wall-street-memes',
    'ellipsis-epx' = 'ellipsis-epx',
    'landshare' = 'landshare',
    'ritestream' = 'ritestream',
    'genopets' = 'genopets',
    'oxbull-tech' = 'oxbull-tech',
    'baby-bonk-coin' = 'baby-bonk-coin',
    'bscpad' = 'bscpad',
    'marsdao' = 'marsdao',
    'brn-metaverse' = 'brn-metaverse',
    'playzap' = 'playzap',
    'cumrocket' = 'cumrocket',
    'artyfact' = 'artyfact',
    'weway' = 'weway',
    'pomerium-ecosystem-token' = 'pomerium-ecosystem-token',
    'magiccraft' = 'magiccraft',
    'torum' = 'torum',
    'thetan-arena' = 'thetan-arena',
    'dechat' = 'dechat',
    'everdome' = 'everdome',
    'script-network' = 'script-network',
    'pallapay' = 'pallapay',
    'rev3al' = 'rev3al',
    'outer-ring-mmo-gq' = 'outer-ring-mmo-gq',
    'sakai-vault' = 'sakai-vault',
    'thena' = 'thena',
    'unitymeta' = 'unitymeta',
    'litlab-games' = 'litlab-games',
    'quint' = 'quint',
    'aca-token' = 'aca-token',
    'blocto' = 'blocto',
    'dogebonk' = 'dogebonk',
    'gamestarter' = 'gamestarter',
    'babyswap' = 'babyswap',
    'dark-frontiers' = 'dark-frontiers',
    'vai' = 'vai',
    'mint-club' = 'mint-club',
    'sincity-token' = 'sincity-token',
    'hamster' = 'hamster',
    'horizon-protocol' = 'horizon-protocol',
    'btc-standard-hashrate-token' = 'btc-standard-hashrate-token',
    'green-shiba-inu-new' = 'green-shiba-inu-new',
    'ethax' = 'ethax',
    'heroes-td' = 'heroes-td',
    'meta-apes-peel' = 'meta-apes-peel',
    'ego-paysenger' = 'ego-paysenger',
    'milestonebased' = 'milestonebased',
    'wesendit' = 'wesendit',
    'luca' = 'luca',
    'operation-phoenix' = 'operation-phoenix',
    'vinuchain' = 'vinuchain',
    'vimworld' = 'vimworld',
    'revomon' = 'revomon',
    'xsl-labs' = 'xsl-labs',
    'ret' = 'ret',
    'venus-reward-token' = 'venus-reward-token',
    'kicktoken' = 'kicktoken',
    'okratech-token' = 'okratech-token',
    'fabwelt' = 'fabwelt',
    'pornrocket' = 'pornrocket',
    'satoshi-island' = 'satoshi-island',
    'vision-game' = 'vision-game',
    'green-satoshi-token-bsc' = 'green-satoshi-token-bsc',
    'project-seed' = 'project-seed',
    'topgoal' = 'topgoal',
    'bitcoin-bam' = 'bitcoin-bam',
    'bscs' = 'bscs',
    'ertha' = 'ertha',
    'bullperks' = 'bullperks',
    'nftb' = 'nftb',
    'sonar' = 'sonar',
    'dreams-quest' = 'dreams-quest',
    'realfevr' = 'realfevr',
    'threefold' = 'threefold',
    'unice' = 'unice',
    'minu' = 'minu',
    'my-defi-pet' = 'my-defi-pet',
    'monsta-infinite' = 'monsta-infinite',
    'julswap' = 'julswap',
    'infinity-pad' = 'infinity-pad',
    'inflation-hedging-coin' = 'inflation-hedging-coin',
    'crowns' = 'crowns',
    'nuritopia' = 'nuritopia',
    'scottybeam' = 'scottybeam',
    'lightning' = 'lightning',
    'gocrypto-token' = 'gocrypto-token',
    'portuma' = 'portuma',
    'kryptomon' = 'kryptomon',
    'hodooi' = 'hodooi',
    'drife' = 'drife',
    'movez' = 'movez',
    'spores-network' = 'spores-network',
    'astroswap' = 'astroswap',
    'tifi-token' = 'tifi-token',
    'laqira-protocol' = 'laqira-protocol',
    'xcad-network-play' = 'xcad-network-play',
    'baby-trump-bnb' = 'baby-trump-bnb',
    'doggy' = 'doggy',
    'monsterra' = 'monsterra',
    'labs-group' = 'labs-group',
    'trava-finance' = 'trava-finance',
    'eldarune' = 'eldarune',
    'revolve-games' = 'revolve-games',
    'sovi-universe' = 'sovi-universe',
    'nft-art-finance' = 'nft-art-finance',
    'auto' = 'auto',
    'king-shiba' = 'king-shiba',
    'bholdus' = 'bholdus',
    'airight' = 'airight',
    'elvishmagic' = 'elvishmagic',
    'iguverse' = 'iguverse',
    'city-tycoon-games' = 'city-tycoon-games',
    'kalmar' = 'kalmar',
    'position-exchange' = 'position-exchange',
    'zaibot' = 'zaibot',
    'spore' = 'spore',
    'nemesis-downfall' = 'nemesis-downfall',
    'metafighter' = 'metafighter',
    'wall-street-games' = 'wall-street-games',
    'streamity' = 'streamity',
    'ibstoken' = 'ibstoken',
    'the-dons' = 'the-dons',
    'gamespad' = 'gamespad',
    'belt' = 'belt',
    'ageofgods' = 'ageofgods',
    'peardao' = 'peardao',
    'spartan-protocol' = 'spartan-protocol',
    'metaverse-face' = 'metaverse-face',
    'qrkita-token' = 'qrkita-token',
    'elemon' = 'elemon',
    'self-crypto' = 'self-crypto',
    'metaverser' = 'metaverser',
    'carbon' = 'carbon',
    'plantvsundead' = 'plantvsundead',
    'aimalls' = 'aimalls',
    'nafter' = 'nafter',
    'cryptozoon' = 'cryptozoon',
    'oggy-inu' = 'oggy-inu',
    'regent-coin' = 'regent-coin',
    'wizardia' = 'wizardia',
    'tethereum' = 'tethereum',
    'hyperchainx' = 'hyperchainx',
    'starship' = 'starship',
    'agatech' = 'agatech',
    'cryptoblades' = 'cryptoblades',
    'faraland' = 'faraland',
    'binamon' = 'binamon',
    'cyclone-protocol' = 'cyclone-protocol',
    'acknoledger' = 'acknoledger',
    'chain-of-legends' = 'chain-of-legends',
    'azbit' = 'azbit',
    'metaq' = 'metaq',
    'xcel-swap' = 'xcel-swap',
    'terran-coin' = 'terran-coin',
    'sonm-bep20' = 'sonm-bep20',
    'mist' = 'mist',
    'wonderman-nation' = 'wonderman-nation',
    'kaby-arena' = 'kaby-arena',
    'kccpad' = 'kccpad',
    'little-rabbit-v2' = 'little-rabbit-v2',
    'binemon' = 'binemon',
    'xp-network' = 'xp-network',
    'dexsport' = 'dexsport',
    'centric-swap' = 'centric-swap',
    'lunar-v2' = 'lunar-v2',
    'metagalaxy-land' = 'metagalaxy-land',
    'yieldwatch' = 'yieldwatch',
    'silva-token' = 'silva-token',
    'blink' = 'blink',
    'pacoca' = 'pacoca',
    'ninneko' = 'ninneko',
    'moonft' = 'moonft',
    'cryptex' = 'cryptex',
    'my-master-war' = 'my-master-war',
    'propel-token' = 'propel-token',
    'legend-of-fantasy-war' = 'legend-of-fantasy-war',
    'amazy' = 'amazy',
    'kissan' = 'kissan',
    'cryptotycoon' = 'cryptotycoon',
    'helmet-insure' = 'helmet-insure',
    'lanceria' = 'lanceria',
    'cryptotanks' = 'cryptotanks',
    'step' = 'step',
    'kingdomstarter' = 'kingdomstarter',
    'refinable' = 'refinable',
    'cz-the-goat' = 'cz-the-goat',
    'polkawar' = 'polkawar',
    'defina-finance' = 'defina-finance',
    'aspo-world' = 'aspo-world',
    'rainbow-token' = 'rainbow-token',
    'hashsbx' = 'hashsbx',
    'neurashi' = 'neurashi',
    'metashooter' = 'metashooter',
    'dynamix' = 'dynamix',
    'shredn' = 'shredn',
    'nerve-finance' = 'nerve-finance',
    'meme-mint' = 'meme-mint',
    'dragon-kart' = 'dragon-kart',
    'battle-hero' = 'battle-hero',
    'wolf-safe-poor-people' = 'wolf-safe-poor-people',
    'metaversevr' = 'metaversevr',
    'hero-blaze-three-kingdoms' = 'hero-blaze-three-kingdoms',
    'mytheria' = 'mytheria',
    'hunter-token' = 'hunter-token',
    'cryptopolis' = 'cryptopolis',
    'infinity-angel' = 'infinity-angel',
    'seedon' = 'seedon',
    'argon' = 'argon',
    '9d-nft' = '9d-nft',
    'realvirm' = 'realvirm',
    'gunstar-metaverse' = 'gunstar-metaverse',
    'donaswap' = 'donaswap',
    'tripcandy' = 'tripcandy',
    'wowswap' = 'wowswap',
    'elpis-battle' = 'elpis-battle',
    'zuki-moba' = 'zuki-moba',
    'hype' = 'hype',
    'shopnext-loyalty-token' = 'shopnext-loyalty-token',
    'shield-protocol' = 'shield-protocol',
    'sable-finance' = 'sable-finance',
    'bunicorn' = 'bunicorn',
    'the-corgi-of-polkabridge' = 'the-corgi-of-polkabridge',
    'rune' = 'rune',
    'jade-currency' = 'jade-currency',
    'airnfts' = 'airnfts',
    'pancakebunny' = 'pancakebunny',
    'moneyswap' = 'moneyswap',
    'benft-solutions' = 'benft-solutions',
    'berry-data' = 'berry-data',
    'sugarbounce' = 'sugarbounce',
    'joops' = 'joops',
    'theforce-trade' = 'theforce-trade',
    'openswap' = 'openswap',
    'bitteam-token' = 'bitteam-token',
    'meraversex' = 'meraversex',
    'shackleford' = 'shackleford',
    'mmocoin' = 'mmocoin',
    'citadel-one' = 'citadel-one',
    'gamerse' = 'gamerse',
    'billionhappiness' = 'billionhappiness',
    'beer-money' = 'beer-money',
    'kawaii-islands' = 'kawaii-islands',
    'sparkpoint-fuel' = 'sparkpoint-fuel',
    'classicdoge-io' = 'classicdoge-io',
    'optionroom' = 'optionroom',
    'afen-blockchain' = 'afen-blockchain',
    'goose-finance' = 'goose-finance',
    'smoothy' = 'smoothy',
    'step-hero' = 'step-hero',
    'era-token' = 'era-token',
    'vektor' = 'vektor',
    'bemil-coin' = 'bemil-coin',
    'betu' = 'betu',
    'kiwigo' = 'kiwigo',
    'twinci' = 'twinci',
    'ancient-kingdom' = 'ancient-kingdom',
    'bytenext' = 'bytenext',
    'hertz-network' = 'hertz-network',
    'lucretius' = 'lucretius',
    'warena' = 'warena',
    'befitter' = 'befitter',
    'stepwatch' = 'stepwatch',
    'moniwar' = 'moniwar',
    'luna-rush' = 'luna-rush',
    'pixer-eternity' = 'pixer-eternity',
    'privacoin' = 'privacoin',
    'lympo-market-token' = 'lympo-market-token',
    'avatly' = 'avatly',
    'knight-war' = 'knight-war',
    'paybswap' = 'paybswap',
    'swirge' = 'swirge',
    'bsclaunch' = 'bsclaunch',
    'etna-network' = 'etna-network',
    'mate' = 'mate',
    'fota' = 'fota',
    'cubiex-power' = 'cubiex-power',
    'pias' = 'pias',
    'wolf-safe-poor-people-polygon' = 'wolf-safe-poor-people-polygon',
    'cryptoplanes' = 'cryptoplanes',
    'yffii-finance' = 'yffii-finance',
    'pepepad-vip' = 'pepepad-vip',
    'qna3ai' = 'qna3ai',
    'serenity-shield' = 'serenity-shield',
    'hypergpt' = 'hypergpt',
    'crypto-hunters-coin' = 'crypto-hunters-coin',
    'synclub' = 'synclub',
    '2024pump' = '2024pump',
    'blackcardcoin' = 'blackcardcoin',
    'biceps' = 'biceps',
    'intoverse' = 'intoverse',
    'sizlux' = 'sizlux',
    'beacon-eth' = 'beacon-eth',
    'supercells-token' = 'supercells-token',
    'orangedx' = 'orangedx',
    'king-of-legends-2' = 'king-of-legends-2',
    'seed-photo' = 'seed-photo',
    'grok-cat' = 'grok-cat',
    'octavia' = 'octavia',
    'hefi' = 'hefi',
    'biconomy-token' = 'biconomy-token',
    'chappyz' = 'chappyz',
    'slex-token' = 'slex-token',
    'bscm' = 'bscm',
    'friend3' = 'friend3',
    'ark-rivals' = 'ark-rivals',
    'baby-grok-bsc' = 'baby-grok-bsc',
    'purse' = 'purse',
    'gomdori' = 'gomdori',
    'helio-money' = 'helio-money',
    'diment-dollar' = 'diment-dollar',
    'eggy' = 'eggy',
    'matrix-chain' = 'matrix-chain',
    'axioma-token' = 'axioma-token',
    'libra-incentix' = 'libra-incentix',
    'bull-btc-club' = 'bull-btc-club',
    'cashbackpro' = 'cashbackpro',
    'il-capo-of-crypto' = 'il-capo-of-crypto',
    'dexnet' = 'dexnet',
    'ctomorrow-platform' = 'ctomorrow-platform',
    'trillioner' = 'trillioner',
    'milo-inu' = 'milo-inu',
    'infinity-rocket-token' = 'infinity-rocket-token',
    'aastoken' = 'aastoken',
    'starly' = 'starly',
    'rgames' = 'rgames',
    'vyvo-smart-chain' = 'vyvo-smart-chain',
    'probinex' = 'probinex',
    'bombcrypto' = 'bombcrypto',
    'burnedfi-app' = 'burnedfi-app',
    'webmind-network' = 'webmind-network',
    'heaven-token' = 'heaven-token',
    'mar3-ai' = 'mar3-ai',
    'project-ailey' = 'project-ailey',
    'degenduckrace' = 'degenduckrace',
    'ozone-metaverse' = 'ozone-metaverse',
    'agii' = 'agii',
    'darkshield' = 'darkshield',
    'edu3labs' = 'edu3labs',
    'gami-world' = 'gami-world',
    'ankr-staked-bnb' = 'ankr-staked-bnb',
    'flokita' = 'flokita',
    'bank-token' = 'bank-token',
    'payslink' = 'payslink',
    'kyberdyne' = 'kyberdyne',
    'sonorus' = 'sonorus',
    'ivendpay' = 'ivendpay',
    'marumarunft' = 'marumarunft',
    'getkicks' = 'getkicks',
    'platform-of-meme-coins' = 'platform-of-meme-coins',
    'elephant-money-trunk' = 'elephant-money-trunk',
    'cakepie-xyz' = 'cakepie-xyz',
    'disney' = 'disney',
    'gym-network' = 'gym-network',
    'arker' = 'arker',
    'forward-protocol' = 'forward-protocol',
    'fandomdao' = 'fandomdao',
    'lumishare' = 'lumishare',
    'halo-nft' = 'halo-nft',
    'bowled' = 'bowled',
    'baby-elon' = 'baby-elon',
    'vaporwallet' = 'vaporwallet',
    'botopia-finance' = 'botopia-finance',
    'tether-usd-bridged-zed20-usdt' = 'tether-usd-bridged-zed20-usdt',
    'fame-mma' = 'fame-mma',
    'squid-game' = 'squid-game',
    'pop-social' = 'pop-social',
    'dogemob' = 'dogemob',
    'blockasset' = 'blockasset',
    'shoki' = 'shoki',
    'ordify' = 'ordify',
    'grow-token' = 'grow-token',
    'black-whale' = 'black-whale',
    'apecoin' = 'apecoin',
    'xpense' = 'xpense',
    'ultron' = 'ultron',
    'devomon' = 'devomon',
    'meta-merge' = 'meta-merge',
    'the-debt-box' = 'the-debt-box',
    'hello-puppy' = 'hello-puppy',
    'fine-bsc' = 'fine-bsc',
    'good-games-guild' = 'good-games-guild',
    'shibaai' = 'shibaai',
    'planet-gamma' = 'planet-gamma',
    'funch-app' = 'funch-app',
    'aet' = 'aet',
    'safe-anwang' = 'safe-anwang',
    'nelore-coin' = 'nelore-coin',
    'binaryx' = 'binaryx',
    'smooth-network-solutions-token' = 'smooth-network-solutions-token',
    'digiverse-crypto' = 'digiverse-crypto',
    'e-coin-finance-new' = 'e-coin-finance-new',
    'robofi' = 'robofi',
    'sweeptoken' = 'sweeptoken',
    'absolute-sync' = 'absolute-sync',
    'centcex' = 'centcex',
    'xai-bsc' = 'xai-bsc',
    'gulf-coin' = 'gulf-coin',
    'poollotto-finance' = 'poollotto-finance',
    'joltify' = 'joltify',
    'bitcoin-future' = 'bitcoin-future',
    'tap-fantasy' = 'tap-fantasy',
    'gode-chain' = 'gode-chain',
    'marhabadefi' = 'marhabadefi',
    'thunder-brawl' = 'thunder-brawl',
    'jen-coin' = 'jen-coin',
    'elons-cat' = 'elons-cat',
    'privateum-initiative' = 'privateum-initiative',
    'love-earn-enjoy' = 'love-earn-enjoy',
    'redluna' = 'redluna',
    'kittycake' = 'kittycake',
    'wam-app' = 'wam-app',
    'vlaunch' = 'vlaunch',
    'stader-bnbx' = 'stader-bnbx',
    'plxyer' = 'plxyer',
    'keeps-blockchain' = 'keeps-blockchain',
    'miketoken' = 'miketoken',
    'tendacoin' = 'tendacoin',
    'tarality-v2' = 'tarality-v2',
    'frutti-dino' = 'frutti-dino',
    'metax-global' = 'metax-global',
    'meme-ai-fun' = 'meme-ai-fun',
    'grok-token' = 'grok-token',
    'chumbi-valley' = 'chumbi-valley',
    'all-crypto-mechanics' = 'all-crypto-mechanics',
    'frgx-finance' = 'frgx-finance',
    'typeit' = 'typeit',
    'babyrwa' = 'babyrwa',
    'capitalrock' = 'capitalrock',
    'bibi' = 'bibi',
    'euro-shiba-inu' = 'euro-shiba-inu',
    'magic-crystal' = 'magic-crystal',
    'coloniume-network' = 'coloniume-network',
    'metamecha' = 'metamecha',
    'wizarre' = 'wizarre',
    'virtual-tourist' = 'virtual-tourist',
    'rabi' = 'rabi',
    'baby-doge-ceo' = 'baby-doge-ceo',
    'virtual-x' = 'virtual-x',
    'wecoin' = 'wecoin',
    'storepay' = 'storepay',
    'project-matthew-mc' = 'project-matthew-mc',
    'cebiolabs' = 'cebiolabs',
    'pacman' = 'pacman',
    'finceptor' = 'finceptor',
    'windfall-token' = 'windfall-token',
    'metafluence' = 'metafluence',
    'muratiai' = 'muratiai',
    'thing' = 'thing',
    'bnb-dog-inu' = 'bnb-dog-inu',
    'polymoon' = 'polymoon',
    'kingdomx' = 'kingdomx',
    'owldao' = 'owldao',
    'daoverse' = 'daoverse',
    'meta-games-coin' = 'meta-games-coin',
    'karasou' = 'karasou',
    'inery' = 'inery',
    'sportium' = 'sportium',
    'letsgobrandontoken' = 'letsgobrandontoken',
    'ceo' = 'ceo',
    'd3d-social' = 'd3d-social',
    'bitfloki' = 'bitfloki',
    'evulus-token' = 'evulus-token',
    'gamer' = 'gamer',
    'lendr-network' = 'lendr-network',
    'ferma-sosedi' = 'ferma-sosedi',
    'ginoa' = 'ginoa',
    'global-virtual-coin' = 'global-virtual-coin',
    'utility-nexusmind' = 'utility-nexusmind',
    'felix-token' = 'felix-token',
    'optimus-ai-' = 'optimus-ai-',
    'infiblue-world' = 'infiblue-world',
    'grapecoin' = 'grapecoin',
    'spix' = 'spix',
    'mth-network' = 'mth-network',
    'fitburn' = 'fitburn',
    'janex' = 'janex',
    'fishkoin' = 'fishkoin',
    'legion-network' = 'legion-network',
    'mechaverse' = 'mechaverse',
    'codai' = 'codai',
    'youclout' = 'youclout',
    'kryptonite-finance' = 'kryptonite-finance',
    'revoland-token' = 'revoland-token',
    'soroosh-smart-ecosystem' = 'soroosh-smart-ecosystem',
    'codexchain' = 'codexchain',
    'planq' = 'planq',
    'satoshi-panda' = 'satoshi-panda',
    'cavada' = 'cavada',
    'trading-gpt' = 'trading-gpt',
    'mmpro-token' = 'mmpro-token',
    'custodiy' = 'custodiy',
    'oni-token' = 'oni-token',
    'fantaverse' = 'fantaverse',
    'minto' = 'minto',
    'gli-token' = 'gli-token',
    'alita' = 'alita',
    'read2n' = 'read2n',
    'play-kingdom' = 'play-kingdom',
    'inpulsex-new' = 'inpulsex-new',
    'yay-games' = 'yay-games',
    'miner-arena' = 'miner-arena',
    'mane' = 'mane',
    'facedao' = 'facedao',
    'canadian-inuit-dog-v2' = 'canadian-inuit-dog-v2',
    'dollarmoon' = 'dollarmoon',
    'hebeto' = 'hebeto',
    'bitbook' = 'bitbook',
    'stemx' = 'stemx',
    'meflex' = 'meflex',
    'winners-coin' = 'winners-coin',
    'pig-finance' = 'pig-finance',
    'planet-sandbox' = 'planet-sandbox',
    'fxbox' = 'fxbox',
    'ents' = 'ents',
    'alium-finance' = 'alium-finance',
    'dega' = 'dega',
    'exit-designer-token' = 'exit-designer-token',
    'acet' = 'acet',
    'chica' = 'chica',
    'animal-farm-dogs' = 'animal-farm-dogs',
    'black-token' = 'black-token',
    'dogo' = 'dogo',
    'blueart-token' = 'blueart-token',
    'seor' = 'seor',
    'football-at-alphaverse' = 'football-at-alphaverse',
    'marvelous-nfts-bad-days' = 'marvelous-nfts-bad-days',
    'holdex-finance' = 'holdex-finance',
    'cyber-arena' = 'cyber-arena',
    'kstarnft' = 'kstarnft',
    'the-moon-metaverse' = 'the-moon-metaverse',
    'protocon' = 'protocon',
    'sociapol' = 'sociapol',
    'metastrike' = 'metastrike',
    'primal-token' = 'primal-token',
    'byepix' = 'byepix',
    'celebritycoinv2' = 'celebritycoinv2',
    'humanize' = 'humanize',
    'battleverse' = 'battleverse',
    'game-coin' = 'game-coin',
    'lofi' = 'lofi',
    'going-to-the-moon' = 'going-to-the-moon',
    'coinnavigator' = 'coinnavigator',
    'level-finance' = 'level-finance',
    'palmswap' = 'palmswap',
    'velhalla' = 'velhalla',
    'perezoso' = 'perezoso',
    'scapesmania' = 'scapesmania',
    'itemverse' = 'itemverse',
    'c-charge' = 'c-charge',
    'race-kingdom' = 'race-kingdom',
    'kwai-labs' = 'kwai-labs',
    'cat-cat-token' = 'cat-cat-token',
    'hype-meme-token' = 'hype-meme-token',
    'youcoin-metaverse-new' = 'youcoin-metaverse-new',
    'nexusmind' = 'nexusmind',
    'tradetomato' = 'tradetomato',
    'oceanland' = 'oceanland',
    'uvtoken' = 'uvtoken',
    'kenka-metaverse' = 'kenka-metaverse',
    'iguverse-igup' = 'iguverse-igup',
    'mind-matrix' = 'mind-matrix',
    'walletnow' = 'walletnow',
    'baby-pepe-io' = 'baby-pepe-io',
    'web3shot' = 'web3shot',
    'howinu' = 'howinu',
    'etherconnect' = 'etherconnect',
    'defi-warrior' = 'defi-warrior',
    'boss-token' = 'boss-token',
    'bitlocus' = 'bitlocus',
    'fistbump-vip' = 'fistbump-vip',
    'agro-global' = 'agro-global',
    'dramatic-chipmunk' = 'dramatic-chipmunk',
    'buff-doge-coin' = 'buff-doge-coin',
    'vira-lata-finance' = 'vira-lata-finance',
    'burnsdefi' = 'burnsdefi',
    'bitshiba-token' = 'bitshiba-token',
    'smart-wallet-token' = 'smart-wallet-token',
    'moonstarter' = 'moonstarter',
    'wiki-cat' = 'wiki-cat',
    'mecha-morphing' = 'mecha-morphing',
    'christmas-pump' = 'christmas-pump',
    'evergrowcoin' = 'evergrowcoin',
    'smart-block-chain-city' = 'smart-block-chain-city',
    'blast-frontiers' = 'blast-frontiers',
    'billionair' = 'billionair',
    'mcncoin' = 'mcncoin',
    'boboburn' = 'boboburn',
    'dfistarter' = 'dfistarter',
    'saveplanetearth' = 'saveplanetearth',
    'limoverse' = 'limoverse',
    'gaia-everworld' = 'gaia-everworld',
    'egoras-credit' = 'egoras-credit',
    'ai-genesis' = 'ai-genesis',
    'minebase' = 'minebase',
    'stripto' = 'stripto',
    'heroes-and-empires' = 'heroes-and-empires',
    'flightclupcoin' = 'flightclupcoin',
    'coinzix' = 'coinzix',
    'intelly' = 'intelly',
    'neuros' = 'neuros',
    'limocoin-swap' = 'limocoin-swap',
    'the-x-protocol' = 'the-x-protocol',
    'project-matthew' = 'project-matthew',
    'squibonk' = 'squibonk',
    'utility-cjournal' = 'utility-cjournal',
    'gay-pepe' = 'gay-pepe',
    'cvip' = 'cvip',
    'ivy-live' = 'ivy-live',
    'kickpad' = 'kickpad',
    'floki-ceo' = 'floki-ceo',
    'reborn-new' = 'reborn-new',
    'atmcore' = 'atmcore',
    'web3war' = 'web3war',
    'cc' = 'cc',
    'meme-token' = 'meme-token',
    'space-token' = 'space-token',
    'kaif-dao-platform' = 'kaif-dao-platform',
    'betfury' = 'betfury',
    'falcon-swaps' = 'falcon-swaps',
    'astro-cash' = 'astro-cash',
    'hodl' = 'hodl',
    'pokomon' = 'pokomon',
    'eg-new' = 'eg-new',
    'mobipad' = 'mobipad',
    'yfione' = 'yfione',
    'mashida' = 'mashida',
    'shelling' = 'shelling',
    'dehealth' = 'dehealth',
    'a-i-earn' = 'a-i-earn',
    'spacexcoin' = 'spacexcoin',
    'bnb48-club-token' = 'bnb48-club-token',
    'googly-cat' = 'googly-cat',
    'daily-finance' = 'daily-finance',
    'grizzlyfi' = 'grizzlyfi',
    't23' = 't23',
    'voice-street' = 'voice-street',
    'broovs-projects' = 'broovs-projects',
    'sugar-kingdom' = 'sugar-kingdom',
    'fart-coin' = 'fart-coin',
    'befasterholdertoken' = 'befasterholdertoken',
    'mad-viking-games' = 'mad-viking-games',
    'babydoge-20' = 'babydoge-20',
    'trustfi-network' = 'trustfi-network',
    'grok-ceo' = 'grok-ceo',
    'keyoflife-multichain-store-of-value' = 'keyoflife-multichain-store-of-value',
    'kaka-nft-world' = 'kaka-nft-world',
    'nitroken' = 'nitroken',
    'gaming-stars' = 'gaming-stars',
    'nftbomb' = 'nftbomb',
    'wrapped-zedxion' = 'wrapped-zedxion',
    'spacey' = 'spacey',
    'trust-ai' = 'trust-ai',
    'price-gap' = 'price-gap',
    'pi-inu' = 'pi-inu',
    'hughug-coin' = 'hughug-coin',
    'elephant-money' = 'elephant-money',
    'titan-hunters' = 'titan-hunters',
    'seek-tiger' = 'seek-tiger',
    'dogita' = 'dogita',
    'bsc-tools' = 'bsc-tools',
    'metawear' = 'metawear',
    'block-ape-scissors' = 'block-ape-scissors',
    'trustpad-new' = 'trustpad-new',
    'fanadise' = 'fanadise',
    'spintop' = 'spintop',
    'macaronswap' = 'macaronswap',
    'seedify-snfts' = 'seedify-snfts',
    'edufex' = 'edufex',
    'hummingbird-finance-new' = 'hummingbird-finance-new',
    'babylons' = 'babylons',
    'bnbtiger' = 'bnbtiger',
    'ocicat' = 'ocicat',
    'hollywood-capital-group-warrior' = 'hollywood-capital-group-warrior',
    'halo-coin' = 'halo-coin',
    'bunnypark' = 'bunnypark',
    'menzy' = 'menzy',
    'australian-safe-shepherd' = 'australian-safe-shepherd',
    'trivians' = 'trivians',
    'catelonmars' = 'catelonmars',
    'whales-cat' = 'whales-cat',
    'zodium' = 'zodium',
    'supe' = 'supe',
    'alaska-gold-rush' = 'alaska-gold-rush',
    'layerium' = 'layerium',
    'cyberdragon-gold' = 'cyberdragon-gold',
    'nine-dogs' = 'nine-dogs',
    'atem-network' = 'atem-network',
    'shibaqua' = 'shibaqua',
    'crypto-ai-robo' = 'crypto-ai-robo',
    'aussie-digital' = 'aussie-digital',
    'fxg' = 'fxg',
    'great-bounty-dealer' = 'great-bounty-dealer',
    'tip-inu' = 'tip-inu',
    'dnaxcat-token' = 'dnaxcat-token',
    'reign-of-terror' = 'reign-of-terror',
    'vietnam-smarthub-logistics' = 'vietnam-smarthub-logistics',
    'thoreum-v2' = 'thoreum-v2',
    'safemars' = 'safemars',
    'local-traders' = 'local-traders',
    'playpad' = 'playpad',
    'bountykinds' = 'bountykinds',
    'cherry-network' = 'cherry-network',
    'fantom-maker' = 'fantom-maker',
    'cyberdogecoin' = 'cyberdogecoin',
    'league-of-ancients' = 'league-of-ancients',
    'monbase' = 'monbase',
    'the-killbox' = 'the-killbox',
    'daolaunch' = 'daolaunch',
    'nomad-exiles' = 'nomad-exiles',
    'speciex' = 'speciex',
    'orenium-protocol' = 'orenium-protocol',
    'pokerfi' = 'pokerfi',
    'hero-arena' = 'hero-arena',
    'polypad' = 'polypad',
    'nftcloud' = 'nftcloud',
    'hydraverse' = 'hydraverse',
    'baby-dragon-coin' = 'baby-dragon-coin',
    'option-panda' = 'option-panda',
    'chonk-the-cat' = 'chonk-the-cat',
    'starpad' = 'starpad',
    'asyagro' = 'asyagro',
    'meli-games' = 'meli-games',
    'waultswap' = 'waultswap',
    'hashtagger-com' = 'hashtagger-com',
    'green-beli' = 'green-beli',
    'cloudname' = 'cloudname',
    'teddydoge' = 'teddydoge',
    'octopus-protocol' = 'octopus-protocol',
    'luncarmy' = 'luncarmy',
    'bob-lion-inu' = 'bob-lion-inu',
    'placewar' = 'placewar',
    'the-big-five-token' = 'the-big-five-token',
    'goldminer' = 'goldminer',
    'mir-token' = 'mir-token',
    'babylong' = 'babylong',
    'operon-origins' = 'operon-origins',
    'biblecoin' = 'biblecoin',
    'iinjaz-new' = 'iinjaz-new',
    'baby-floki' = 'baby-floki',
    'cryptomines' = 'cryptomines',
    'irena-green-energy' = 'irena-green-energy',
    'dragon-mainland-shards' = 'dragon-mainland-shards',
    'streakk' = 'streakk',
    'ftribe-fighters' = 'ftribe-fighters',
    'spacepi' = 'spacepi',
    'getaverse' = 'getaverse',
    'stella-fantasy' = 'stella-fantasy',
    'kermit-coin' = 'kermit-coin',
    'exatech-poai-blockchain' = 'exatech-poai-blockchain',
    'metaworth' = 'metaworth',
    'sonic-inu-bsc' = 'sonic-inu-bsc',
    'popcoin' = 'popcoin',
    'metan-evolutions' = 'metan-evolutions',
    'demole' = 'demole',
    'deherogame' = 'deherogame',
    'embr' = 'embr',
    'trump-maga' = 'trump-maga',
    'ryi-unity' = 'ryi-unity',
    'camly-coin' = 'camly-coin',
    'altbase' = 'altbase',
    'real-realm' = 'real-realm',
    'gigachadgpt' = 'gigachadgpt',
    'street-runner-nft' = 'street-runner-nft',
    'sta-token' = 'sta-token',
    'agodefi' = 'agodefi',
    'xdoge-network' = 'xdoge-network',
    'imo' = 'imo',
    'sinso' = 'sinso',
    'zambesigold' = 'zambesigold',
    'pepecashfun' = 'pepecashfun',
    'jp-token' = 'jp-token',
    'projectx' = 'projectx',
    'fidance' = 'fidance',
    'planet-finance' = 'planet-finance',
    'grok1' = 'grok1',
    'galatic-arena' = 'galatic-arena',
    'simpson-ai' = 'simpson-ai',
    'avocado-blockchain-group' = 'avocado-blockchain-group',
    'knightswap' = 'knightswap',
    'liquid-crypto' = 'liquid-crypto',
    'starsharks-sss' = 'starsharks-sss',
    'pepe-coin-bsc2' = 'pepe-coin-bsc2',
    'linkfi' = 'linkfi',
    'nemesis-dao' = 'nemesis-dao',
    'when-vip' = 'when-vip',
    'meme-lordz' = 'meme-lordz',
    'flozo-game-token' = 'flozo-game-token',
    'lenda' = 'lenda',
    'multifunctional-environmental-token' = 'multifunctional-environmental-token',
    'web3camp' = 'web3camp',
    'strip-finance' = 'strip-finance',
    'ritz-game' = 'ritz-game',
    'vemate' = 'vemate',
    'betterment-digital' = 'betterment-digital',
    'catex' = 'catex',
    'cryptounity' = 'cryptounity',
    'defi-for-you' = 'defi-for-you',
    'murasaki' = 'murasaki',
    'germany-rabbit-token' = 'germany-rabbit-token',
    'catcoin-bsc' = 'catcoin-bsc',
    'web4-ai' = 'web4-ai',
    'the-three-kingdoms' = 'the-three-kingdoms',
    'idle-ninja-online' = 'idle-ninja-online',
    'baby-woj' = 'baby-woj',
    'safelaunch' = 'safelaunch',
    'pin-token' = 'pin-token',
    'united' = 'united',
    'emp-money' = 'emp-money',
    'monkecoin' = 'monkecoin',
    'krypton-dao' = 'krypton-dao',
    'jojo' = 'jojo',
    'cryptorg' = 'cryptorg',
    'grok-bank' = 'grok-bank',
    'recharge' = 'recharge',
    'cheersland' = 'cheersland',
    'bitminerx' = 'bitminerx',
    'shibakeanu' = 'shibakeanu',
    'pikaster' = 'pikaster',
    'x-ai-cx' = 'x-ai-cx',
    'pikaster-rbp' = 'pikaster-rbp',
    'metagame-arena' = 'metagame-arena',
    'rabbit-finance' = 'rabbit-finance',
    'newgold' = 'newgold',
    'newb-farm' = 'newb-farm',
    'bitonite' = 'bitonite',
    'open-source-network' = 'open-source-network',
    'vitex-coin' = 'vitex-coin',
    'bitcoin-city-coin' = 'bitcoin-city-coin',
    'nominex-token' = 'nominex-token',
    'continuum-finance' = 'continuum-finance',
    'doge-ceo' = 'doge-ceo',
    'babybnbtiger' = 'babybnbtiger',
    'ivipcoin' = 'ivipcoin',
    'dot-dot-finance' = 'dot-dot-finance',
    'copycat-finance' = 'copycat-finance',
    'thinkwareai' = 'thinkwareai',
    'morse' = 'morse',
    'xwin-finance' = 'xwin-finance',
    'crypto-delivery' = 'crypto-delivery',
    'nftgamingstars' = 'nftgamingstars',
    'green-blue-coin' = 'green-blue-coin',
    'ruby-play-network' = 'ruby-play-network',
    'seba' = 'seba',
    'moonlight-token' = 'moonlight-token',
    'joker-token' = 'joker-token',
    'baby-sora-' = 'baby-sora-',
    'binstarter' = 'binstarter',
    'cairo-finance' = 'cairo-finance',
    'pandora-protocol' = 'pandora-protocol',
    'husky-ai' = 'husky-ai',
    'outter-finance' = 'outter-finance',
    'gowrap' = 'gowrap',
    'ari10' = 'ari10',
    'shihtzu-exchange' = 'shihtzu-exchange',
    'ivirse' = 'ivirse',
    'metashipping' = 'metashipping',
    'cryptoxpress' = 'cryptoxpress',
    'tesla-ai-bsc' = 'tesla-ai-bsc',
    'megahero' = 'megahero',
    'animal-farm-pigs' = 'animal-farm-pigs',
    'baby-troll' = 'baby-troll',
    'grok-alita' = 'grok-alita',
    'nix-token' = 'nix-token',
    'mars-ecosystem-governance-token' = 'mars-ecosystem-governance-token',
    'mino-inu' = 'mino-inu',
    'readfi' = 'readfi',
    'matrix-gpt-ai' = 'matrix-gpt-ai',
    'trossard' = 'trossard',
    'dogecoin-2' = 'dogecoin-2',
    'genshiro' = 'genshiro',
    'vip-token' = 'vip-token',
    'btaf-token' = 'btaf-token',
    'spritzmoon-crypto-token' = 'spritzmoon-crypto-token',
    'ookeenga' = 'ookeenga',
    'jonny-five' = 'jonny-five',
    'mobster' = 'mobster',
    'a2dao' = 'a2dao',
    'paragen' = 'paragen',
    'heroes-3-foundation' = 'heroes-3-foundation',
    'vibing' = 'vibing',
    'smarty-pay' = 'smarty-pay',
    't-mac-dao' = 't-mac-dao',
    'solidray-finance-new' = 'solidray-finance-new',
    'rakein' = 'rakein',
    'tbcc-labs' = 'tbcc-labs',
    'junkie-cats' = 'junkie-cats',
    'pibridge' = 'pibridge',
    'cake-monster' = 'cake-monster',
    'zillion-aakar-xo' = 'zillion-aakar-xo',
    'monopoly-millionaire-control' = 'monopoly-millionaire-control',
    'revault-network' = 'revault-network',
    'gbank-apy' = 'gbank-apy',
    'metarix' = 'metarix',
    'zeloop-eco-reward' = 'zeloop-eco-reward',
    'pepe-wif-hat' = 'pepe-wif-hat',
    'ktx-finance' = 'ktx-finance',
    'pepi-meme' = 'pepi-meme',
    'multi-ai' = 'multi-ai',
    'block-buster-tech-inc' = 'block-buster-tech-inc',
    'ninneko-mata' = 'ninneko-mata',
    'metagods' = 'metagods',
    'victory-gem' = 'victory-gem',
    'babyokx' = 'babyokx',
    'meme-musk' = 'meme-musk',
    'gccoin' = 'gccoin',
    'baby-cats-coin' = 'baby-cats-coin',
    'coinbuck' = 'coinbuck',
    'neuralink-nlink' = 'neuralink-nlink',
    'child-support' = 'child-support',
    'echo-of-the-horizon' = 'echo-of-the-horizon',
    'dogeking' = 'dogeking',
    'magnet-gold' = 'magnet-gold',
    'muzikoin' = 'muzikoin',
    'gburn' = 'gburn',
    'avrora-metaverse' = 'avrora-metaverse',
    'xspace' = 'xspace',
    'xai-token' = 'xai-token',
    'growthdefi-new' = 'growthdefi-new',
    'tethertv' = 'tethertv',
    'vulture-peak' = 'vulture-peak',
    'revenue-coin' = 'revenue-coin',
    'banus-finance' = 'banus-finance',
    'bedrock' = 'bedrock',
    'water-rabbit-token' = 'water-rabbit-token',
    'pikaster-srbp' = 'pikaster-srbp',
    'loopswap' = 'loopswap',
    'moveapp' = 'moveapp',
    'autocrypto' = 'autocrypto',
    'pecland' = 'pecland',
    'gyro' = 'gyro',
    'mother-of-memes' = 'mother-of-memes',
    'palmpay' = 'palmpay',
    'lucro' = 'lucro',
    'popo-bsc' = 'popo-bsc',
    'tipsycoin' = 'tipsycoin',
    'black-phoenix' = 'black-phoenix',
    'blu' = 'blu',
    'poocoin' = 'poocoin',
    'bnb-lion' = 'bnb-lion',
    'baby-cat-coin' = 'baby-cat-coin',
    'coinclaim' = 'coinclaim',
    'aarma' = 'aarma',
    'grok-girl' = 'grok-girl',
    'bmw' = 'bmw',
    'channels' = 'channels',
    'baby-musk-net' = 'baby-musk-net',
    'pesabase' = 'pesabase',
    'grok-x' = 'grok-x',
    'space-misfits' = 'space-misfits',
    'baby-cat' = 'baby-cat',
    'eth-fan-token' = 'eth-fan-token',
    'cat-pepe' = 'cat-pepe',
    'dogewhale' = 'dogewhale',
    'book-of-baby-memes' = 'book-of-baby-memes',
    'golden-doge' = 'golden-doge',
    'blocx' = 'blocx',
    'elon-mars' = 'elon-mars',
    'gagarin' = 'gagarin',
    'konstellation-network' = 'konstellation-network',
    'moon-cat' = 'moon-cat',
    'bit-hotel' = 'bit-hotel',
    'omniswap' = 'omniswap',
    'br34p' = 'br34p',
    'dibs-share' = 'dibs-share',
    'ten' = 'ten',
    'expert-infra' = 'expert-infra',
    'darik' = 'darik',
    'hudi' = 'hudi',
    'gege' = 'gege',
    'metarun' = 'metarun',
    'aitk' = 'aitk',
    'cryptohuntertrading' = 'cryptohuntertrading',
    'parma-fan-token' = 'parma-fan-token',
    'ratscoin' = 'ratscoin',
    'chitaverse' = 'chitaverse',
    'catcoin-inu' = 'catcoin-inu',
    'momoji' = 'momoji',
    'lunaone' = 'lunaone',
    'safeaffinity' = 'safeaffinity',
    'blackrock' = 'blackrock',
    'magatrump-xyz' = 'magatrump-xyz',
    'oxo-network' = 'oxo-network',
    'light-defi' = 'light-defi',
    'czodiac-stabletoken' = 'czodiac-stabletoken',
    'lulu' = 'lulu',
    'dao-space' = 'dao-space',
    'the-kingdom-coin' = 'the-kingdom-coin',
    'midway-ai' = 'midway-ai',
    'blin-metaverse' = 'blin-metaverse',
    'vitra-studios' = 'vitra-studios',
    'arabian-dragon' = 'arabian-dragon',
    'stabledoc' = 'stabledoc',
    'spartacats' = 'spartacats',
    'elonwifcoin' = 'elonwifcoin',
    'elondoge' = 'elondoge',
    'dkey-bank' = 'dkey-bank',
    'wojak-finance' = 'wojak-finance',
    'zakumifi' = 'zakumifi',
    'crosswallet' = 'crosswallet',
    'sora-doge' = 'sora-doge',
    'nexmillionaires' = 'nexmillionaires',
    'triathon' = 'triathon',
    'viblos' = 'viblos',
    'moonscape' = 'moonscape',
    'medamon' = 'medamon',
    'meta-ricaro' = 'meta-ricaro',
    'momo-key' = 'momo-key',
    'artificial-intelligence' = 'artificial-intelligence',
    'optimus' = 'optimus',
    'squadswap' = 'squadswap',
    'telo-meme-coin' = 'telo-meme-coin',
    'onschain' = 'onschain',
    'hectic-turkey' = 'hectic-turkey',
    'aquari' = 'aquari',
    'osk-token' = 'osk-token',
    'wednesday-inu' = 'wednesday-inu',
    'aiswap' = 'aiswap',
    'curry-pepe' = 'curry-pepe',
    'egold-token' = 'egold-token',
    'bocoin' = 'bocoin',
    'monkey-coin' = 'monkey-coin',
    'crogecoin' = 'crogecoin',
    'nerveflux' = 'nerveflux',
    'meta-apes-shell' = 'meta-apes-shell',
    'steakd' = 'steakd',
    'arithfi' = 'arithfi',
    'project-quantum' = 'project-quantum',
    'liquidus-new' = 'liquidus-new',
    'defi-tiger' = 'defi-tiger',
    'pi-network-defi' = 'pi-network-defi',
    'baby-bali' = 'baby-bali',
    'lada' = 'lada',
    'starship-2023' = 'starship-2023',
    'crazy-bunny' = 'crazy-bunny',
    'wallstreetbets-dapp' = 'wallstreetbets-dapp',
    'day-of-defeat' = 'day-of-defeat',
    'bib-token' = 'bib-token',
    'unidef' = 'unidef',
    'omni-consumer-protocols' = 'omni-consumer-protocols',
    'digimon-rabbit' = 'digimon-rabbit',
    'save-your-assets' = 'save-your-assets',
    'nzero' = 'nzero',
    'gaming-t-bot' = 'gaming-t-bot',
    'goatcoin' = 'goatcoin',
    'corgicoin-net' = 'corgicoin-net',
    'greentrust' = 'greentrust',
    'bit-game-verse-token' = 'bit-game-verse-token',
    'zeddex' = 'zeddex',
    'onemilliondollars' = 'onemilliondollars',
    'newcatcoin' = 'newcatcoin',
    'shelterz' = 'shelterz',
    'bnbitcoin' = 'bnbitcoin',
    'mtg-token' = 'mtg-token',
    'wanaka-farm' = 'wanaka-farm',
    'web3met' = 'web3met',
    'addmefast' = 'addmefast',
    'quidax' = 'quidax',
    'aicoin' = 'aicoin',
    'battle-infinity' = 'battle-infinity',
    'stacks-token' = 'stacks-token',
    'iris-ecosystem' = 'iris-ecosystem',
    'flork-bnb' = 'flork-bnb',
    'drip-network' = 'drip-network',
    'joe-coin-bsc' = 'joe-coin-bsc',
    'ramenswap' = 'ramenswap',
    'blokpad' = 'blokpad',
    'level-finance-token' = 'level-finance-token',
    'wrapped-pkt' = 'wrapped-pkt',
    'xtblock' = 'xtblock',
    'socaverse' = 'socaverse',
    'manga-token' = 'manga-token',
    'ordinal-hodl-meme' = 'ordinal-hodl-meme',
    'tokoqrt' = 'tokoqrt',
    'templardao' = 'templardao',
    'imov' = 'imov',
    'metawars' = 'metawars',
    'class-coin' = 'class-coin',
    'sports-future-exchange-token' = 'sports-future-exchange-token',
    'pepe-ai-token' = 'pepe-ai-token',
    'ginza-network' = 'ginza-network',
    'verve' = 'verve',
    'zmine' = 'zmine',
    'aione' = 'aione',
    'distributed-autonomous-organization' = 'distributed-autonomous-organization',
    'deathroad' = 'deathroad',
    'momentum-mass' = 'momentum-mass',
    'coinracer-reloaded' = 'coinracer-reloaded',
    'marsupilami-inu' = 'marsupilami-inu',
    'ponke-bnb' = 'ponke-bnb',
    'dexart' = 'dexart',
    'volley-token' = 'volley-token',
    'triipmiles' = 'triipmiles',
    'metagear' = 'metagear',
    'alita-finance' = 'alita-finance',
    'cosmic-fomo' = 'cosmic-fomo',
    'biokript' = 'biokript',
    'micropets-new' = 'micropets-new',
    'tag-protocol' = 'tag-protocol',
    'valas-finance' = 'valas-finance',
    'felixcoin' = 'felixcoin',
    'trump-2024' = 'trump-2024',
    'nudes' = 'nudes',
    'pepa-inu' = 'pepa-inu',
    'gmining' = 'gmining',
    'crypto-birds' = 'crypto-birds',
    'magic-beasties' = 'magic-beasties',
    'zetos' = 'zetos',
    'supernova-sunday-games' = 'supernova-sunday-games',
    'king-cat' = 'king-cat',
    'titi-financial' = 'titi-financial',
    'sky-frontier' = 'sky-frontier',
    'baby-tomcat' = 'baby-tomcat',
    'froggies-v2' = 'froggies-v2',
    'boda-token' = 'boda-token',
    'defihorse' = 'defihorse',
    'elon-doge' = 'elon-doge',
    'green-life-energy-token' = 'green-life-energy-token',
    'ethereal' = 'ethereal',
    'baby-toshi' = 'baby-toshi',
    'leve-invest' = 'leve-invest',
    'baby-jerry' = 'baby-jerry',
    'token-2049-bsc' = 'token-2049-bsc',
    'moonpot' = 'moonpot',
    'jerry-inu' = 'jerry-inu',
    'shinjiru-inu' = 'shinjiru-inu',
    'smars' = 'smars',
    'eneftiverse' = 'eneftiverse',
    'world-id-woid' = 'world-id-woid',
    'rocketfi' = 'rocketfi',
    'flash-technologies-ftt' = 'flash-technologies-ftt',
    'trump-coin' = 'trump-coin',
    'pepe-original-version' = 'pepe-original-version',
    'asva-finance' = 'asva-finance',
    'dogai' = 'dogai',
    'the-everlasting-parachain' = 'the-everlasting-parachain',
    'jaiho-crypto' = 'jaiho-crypto',
    'cat-coin-bsc' = 'cat-coin-bsc',
    'infinite-launch' = 'infinite-launch',
    'msgsender' = 'msgsender',
    'baby-pepe-floki' = 'baby-pepe-floki',
    'baby-shiba-inu' = 'baby-shiba-inu',
    'lasereyes' = 'lasereyes',
    'moonseer-bsc' = 'moonseer-bsc',
    'alaaddin-ai' = 'alaaddin-ai',
    'book-of-doge-memes' = 'book-of-doge-memes',
    'trustnft' = 'trustnft',
    'elonxaidogemessi69pepeinu' = 'elonxaidogemessi69pepeinu',
    'reflecto' = 'reflecto',
    'chat-ai' = 'chat-ai',
    'pam' = 'pam',
    'pepecoinbsc' = 'pepecoinbsc',
    'freedom-god-dao' = 'freedom-god-dao',
    'jesus-coin-bsc' = 'jesus-coin-bsc',
    'hongkongdao' = 'hongkongdao',
    'librium-tech' = 'librium-tech',
    'flokimooni' = 'flokimooni',
    'pomerium' = 'pomerium',
    'profit-blue' = 'profit-blue',
    'basketcoin' = 'basketcoin',
    'moonarch-app' = 'moonarch-app',
    'coinary-token' = 'coinary-token',
    'lunagens' = 'lunagens',
    'shitcoin-token' = 'shitcoin-token',
    'tangyuan' = 'tangyuan',
    'elon-cat-token' = 'elon-cat-token',
    'musk-metaverse' = 'musk-metaverse',
    'long' = 'long',
    'pop-coin' = 'pop-coin',
    'foxgirl' = 'foxgirl',
    'pvc-meta' = 'pvc-meta',
    'twelve-zodiac' = 'twelve-zodiac',
    'rxcgames' = 'rxcgames',
    'bomb-money' = 'bomb-money',
    'ents-2-0' = 'ents-2-0',
    'microcosm' = 'microcosm',
    'raichu-inu' = 'raichu-inu',
    'birb' = 'birb',
    'evai-io' = 'evai-io',
    'clean-carbon' = 'clean-carbon',
    'climb-token-finance' = 'climb-token-finance',
    'kyotoswap' = 'kyotoswap',
    'world-of-defish' = 'world-of-defish',
    'baby-pepe-fork' = 'baby-pepe-fork',
    'bitbull' = 'bitbull',
    'xproject' = 'xproject',
    'microcredittoken' = 'microcredittoken',
    'decentralized-community-investment-protocol' = 'decentralized-community-investment-protocol',
    'dogezilla-coin' = 'dogezilla-coin',
    'top-cat' = 'top-cat',
    'floki-safemoon' = 'floki-safemoon',
    'papa-bear' = 'papa-bear',
    'rex-token' = 'rex-token',
    'heritynetwork' = 'heritynetwork',
    'swapz' = 'swapz',
    'shitcoin' = 'shitcoin',
    'decentralized-mining-exchange' = 'decentralized-mining-exchange',
    'vulkania' = 'vulkania',
    'forthbox' = 'forthbox',
    'gays-inu' = 'gays-inu',
    'cybertruck-bsc' = 'cybertruck-bsc',
    'dragonking' = 'dragonking',
    'metaverse-m' = 'metaverse-m',
    'bonk-kong' = 'bonk-kong',
    'clear-water' = 'clear-water',
    'jerryinu' = 'jerryinu',
    'mybricks' = 'mybricks',
    'phoenixco-token' = 'phoenixco-token',
    'gibx-swap' = 'gibx-swap',
    'nusa' = 'nusa',
    'nanometer-bitcoin' = 'nanometer-bitcoin',
    'flag-network' = 'flag-network',
    'bitpaid-token' = 'bitpaid-token',
    'burnifyai' = 'burnifyai',
    'dyor-token' = 'dyor-token',
    'thetan-coin' = 'thetan-coin',
    'turbodex' = 'turbodex',
    'vegasino' = 'vegasino',
    'octavus-prime' = 'octavus-prime',
    'tom-cat' = 'tom-cat',
    'bull' = 'bull',
    'kingdom-raids' = 'kingdom-raids',
    'futurecoin' = 'futurecoin',
    'sir' = 'sir',
    'transhuman-coin' = 'transhuman-coin',
    'hare-token' = 'hare-token',
    'baby-myro' = 'baby-myro',
    'satellite-doge' = 'satellite-doge',
    'pico-coin' = 'pico-coin',
    'trans-pepe' = 'trans-pepe',
    'galaxy-finance-token' = 'galaxy-finance-token',
    'for-meta-world' = 'for-meta-world',
    'kephi-gallery' = 'kephi-gallery',
    'moon-doge' = 'moon-doge',
    'tokenpikachuinu' = 'tokenpikachuinu',
    'duckie-land' = 'duckie-land',
    'zedxion-coin' = 'zedxion-coin',
    'arena-token' = 'arena-token',
    'pomerium-community-meme-token' = 'pomerium-community-meme-token',
    'baby-dragon-bsc' = 'baby-dragon-bsc',
    'wedex-token-v2' = 'wedex-token-v2',
    'bitcoin-pay' = 'bitcoin-pay',
    'rocketxrp-official' = 'rocketxrp-official',
    'gokuswap' = 'gokuswap',
    'streakk-chain' = 'streakk-chain',
    'zcore-finance' = 'zcore-finance',
    'uniwhale' = 'uniwhale',
    'baby-rats' = 'baby-rats',
    'bsc-farm' = 'bsc-farm',
    'bnb-snake' = 'bnb-snake',
    'cryptostone' = 'cryptostone',
    'xai' = 'xai',
    'shiba-floki' = 'shiba-floki',
    'kingdom-karnage' = 'kingdom-karnage',
    'lol-coin' = 'lol-coin',
    'karastar-umy' = 'karastar-umy',
    'meow-meow' = 'meow-meow',
    'wolf-inu-' = 'wolf-inu-',
    'scrooge-new' = 'scrooge-new',
    'dexshare' = 'dexshare',
    'the-word-token' = 'the-word-token',
    'trump-inu-token' = 'trump-inu-token',
    'monomoney' = 'monomoney',
    'rage-on-wheels' = 'rage-on-wheels',
    'bnbtiger-2-0' = 'bnbtiger-2-0',
    'satozhi' = 'satozhi',
    'ai-bob' = 'ai-bob',
    'optimus-x' = 'optimus-x',
    'kepple' = 'kepple',
    'pepe-ai-bsc' = 'pepe-ai-bsc',
    'floki-x' = 'floki-x',
    'waifu' = 'waifu',
    'fufu' = 'fufu',
    'king-pepe' = 'king-pepe',
    'hololoot' = 'hololoot',
    'perry-the-bnb-v2' = 'perry-the-bnb-v2',
    'treedefi' = 'treedefi',
    'space-cat' = 'space-cat',
    'fink' = 'fink',
    'disabled-pepe' = 'disabled-pepe',
    'pepe-potato' = 'pepe-potato',
    'jetset' = 'jetset',
    'troll-face' = 'troll-face',
    'cub-finance' = 'cub-finance',
    'zone-of-avoidance' = 'zone-of-avoidance',
    'carmin-labs' = 'carmin-labs',
    'hongkong-doge' = 'hongkong-doge',
    'olive-cash' = 'olive-cash',
    'caw-ceo' = 'caw-ceo',
    'crazy-floki' = 'crazy-floki',
    'revolutiongames' = 'revolutiongames',
    'corgidoge-real-estate-payment' = 'corgidoge-real-estate-payment',
    'babykitty' = 'babykitty',
    'onno-vault' = 'onno-vault',
    'jk-coin' = 'jk-coin',
    'travel-coin' = 'travel-coin',
    'pexcoin' = 'pexcoin',
    'bic' = 'bic',
    'galaxy-finance' = 'galaxy-finance',
    'usd-open-dollar' = 'usd-open-dollar',
    'fayda' = 'fayda',
    'safemoo' = 'safemoo',
    'furio' = 'furio',
    'lucky7' = 'lucky7',
    'nga-tiger' = 'nga-tiger',
    'space-doge' = 'space-doge',
    'christmas-doge-2023' = 'christmas-doge-2023',
    'chinese-ny-dragon' = 'chinese-ny-dragon',
    'obrok-token' = 'obrok-token',
    'crazy-cat' = 'crazy-cat',
    'swaperry' = 'swaperry',
    'evereth' = 'evereth',
    'superalgos' = 'superalgos',
    'hotmoon-token' = 'hotmoon-token',
    'ufc-win' = 'ufc-win',
    'interstellar-domain-order' = 'interstellar-domain-order',
    'model-labs' = 'model-labs',
    'print-cash-bnb' = 'print-cash-bnb',
    'saltswap-finance' = 'saltswap-finance',
    'niob-finance' = 'niob-finance',
    'gafa' = 'gafa',
    'the-simpsons-bnb' = 'the-simpsons-bnb',
    'wifedoge' = 'wifedoge',
    'duck-off-coin' = 'duck-off-coin',
    'jeff-bsc' = 'jeff-bsc',
    'flokiburn' = 'flokiburn',
    'baby-squid-game' = 'baby-squid-game',
    'crazy-tiger' = 'crazy-tiger',
    'liquid-collectibles' = 'liquid-collectibles',
    'bnbdoge' = 'bnbdoge',
    'poodle' = 'poodle',
    'coin-of-champions' = 'coin-of-champions',
    'vbswap' = 'vbswap',
    'jeromeandgary' = 'jeromeandgary',
    'secured-moonrat-token' = 'secured-moonrat-token',
    'amateras' = 'amateras',
    'ainu-token' = 'ainu-token',
    'white-dragon' = 'white-dragon',
    'mario-ceo' = 'mario-ceo',
    'floki-inu-coin' = 'floki-inu-coin',
    'xrius' = 'xrius',
    'xmas' = 'xmas',
    'gotem' = 'gotem',
    'bnb-bonk' = 'bnb-bonk',
    'ramifi-protocol' = 'ramifi-protocol',
    'gcwine' = 'gcwine',
    'golden-ball' = 'golden-ball',
    'tronpad' = 'tronpad',
    'cyber-truck' = 'cyber-truck',
    'xquok' = 'xquok',
    'jswap-finance' = 'jswap-finance',
    'fidelis' = 'fidelis',
    'asix' = 'asix',
    'space-dragon' = 'space-dragon',
    'bitx' = 'bitx',
    'crazy-musk' = 'crazy-musk',
    'ai-dogex' = 'ai-dogex',
    'leopard' = 'leopard',
    'hongkong-token' = 'hongkong-token',
    'hong-kong-floki' = 'hong-kong-floki',
    'bnb-dragon' = 'bnb-dragon',
    'zoo-crypto-world' = 'zoo-crypto-world',
    'dogs-rock' = 'dogs-rock',
    'neutrinos' = 'neutrinos',
    'noot' = 'noot',
    'metadoge-token' = 'metadoge-token',
    'daw-currency' = 'daw-currency',
    'super-dragon' = 'super-dragon',
    'crazy-dragon' = 'crazy-dragon',
    'galixcity' = 'galixcity',
    'shiba-inu-x' = 'shiba-inu-x',
    'sorabnb' = 'sorabnb',
    'white-tiger-moon' = 'white-tiger-moon',
    'crazy-doge' = 'crazy-doge',
    'minu-2' = 'minu-2',
    'bnbsongoku' = 'bnbsongoku',
    'halloween-x' = 'halloween-x',
    'crazy-bonk' = 'crazy-bonk',
    'super-pepe' = 'super-pepe',
    'olympic-games-doge' = 'olympic-games-doge',
    'dogecoin-x' = 'dogecoin-x',
    'dollar-shiba-inu' = 'dollar-shiba-inu',
    'spiderman-bitcoin' = 'spiderman-bitcoin',
    'paw-patrol-inu' = 'paw-patrol-inu',
    'speedex' = 'speedex',
    'milo-dog' = 'milo-dog',
    'chinazilla' = 'chinazilla',
    'pepe-dao' = 'pepe-dao',
    'stt' = 'stt',
    'minipepe' = 'minipepe',
    'apemove' = 'apemove',
    'spoody-man' = 'spoody-man',
    'calo-indoor' = 'calo-indoor',
    'tweety-vip' = 'tweety-vip',
    'super-cat' = 'super-cat',
    'cloak' = 'cloak',
    'vetter-token' = 'vetter-token',
    'optimism-doge' = 'optimism-doge',
    'shibacorgi' = 'shibacorgi',
    'starship-doge' = 'starship-doge',
    'run-together' = 'run-together',
    'cheese-v2' = 'cheese-v2',
    'lil-floki' = 'lil-floki',
    'grok-coin' = 'grok-coin',
    'baby-binance' = 'baby-binance',
    'one-basis-cash' = 'one-basis-cash',
    'tiny-coin' = 'tiny-coin',
    'dex-on-crypto' = 'dex-on-crypto',
    'iotec' = 'iotec',
    'pepeinu' = 'pepeinu',
    'wrapped-pi' = 'wrapped-pi',
    'baby-pepe-bsc' = 'baby-pepe-bsc',
    'shambala' = 'shambala',
    'elon-2024-bsc' = 'elon-2024-bsc',
    'qunatumxc' = 'qunatumxc',
    'babypepe-bsc' = 'babypepe-bsc',
    'metaverse-miner' = 'metaverse-miner',
    'grok-elo' = 'grok-elo',
    'chitcat' = 'chitcat',
    'justus' = 'justus',
    'eggman-inu' = 'eggman-inu',
    'cat-inu' = 'cat-inu',
    'dogefather-token' = 'dogefather-token',
    'cakebot' = 'cakebot',
    'monsterra-nft-game' = 'monsterra-nft-game',
    'hypes' = 'hypes',
    'realital-metaverse' = 'realital-metaverse',
    'floki-cash' = 'floki-cash',
    'king-sora' = 'king-sora',
    'doge-legion' = 'doge-legion',
    'safuu' = 'safuu',
    'omni-real-estate-token' = 'omni-real-estate-token',
    'tosainu-bsc' = 'tosainu-bsc',
    'bull-finance' = 'bull-finance',
    'candylad' = 'candylad',
    'baby-meme-coin' = 'baby-meme-coin',
    'trumpbull' = 'trumpbull',
    'swirltoken' = 'swirltoken',
    'anypad' = 'anypad',
    'dx-spot' = 'dx-spot',
    'memes-vs-undead' = 'memes-vs-undead',
    'safemooncash' = 'safemooncash',
    'extocium' = 'extocium',
    'chibi-dinos' = 'chibi-dinos',
    'kalata' = 'kalata',
    'nero-token' = 'nero-token',
    'rimaunangis' = 'rimaunangis',
    'minameme' = 'minameme',
    'jellycoin' = 'jellycoin',
    'bitcoin801010101018101010101018101010108' = 'bitcoin801010101018101010101018101010108',
    'super-bonk-token' = 'super-bonk-token',
    'mongbnb' = 'mongbnb',
    'velorex' = 'velorex',
    'block-commerce-protocol' = 'block-commerce-protocol',
    'pipi1' = 'pipi1',
    'reflecto-usd' = 'reflecto-usd',
    'doragonland' = 'doragonland',
    'bnbcat' = 'bnbcat',
    'pepe-floki-meme' = 'pepe-floki-meme',
    'coinmarketprime' = 'coinmarketprime',
    'square-token' = 'square-token',
    'baby-moon-floki' = 'baby-moon-floki',
    'beatgen-nft' = 'beatgen-nft',
    'wow-token' = 'wow-token',
    'werecoin' = 'werecoin',
    'pepe-ai' = 'pepe-ai',
    'melega' = 'melega',
    'bitcoin-legend' = 'bitcoin-legend',
    'safebull' = 'safebull',
    'minifootball' = 'minifootball',
    'trustxgaming' = 'trustxgaming',
    'babypepefi' = 'babypepefi',
    'elon-pepe-robot' = 'elon-pepe-robot',
    'lucky-cats' = 'lucky-cats',
    'bob-ai' = 'bob-ai',
    'online-cold-wallet' = 'online-cold-wallet',
    'pandainu' = 'pandainu',
    'dinosaureggs' = 'dinosaureggs',
    'milkshakeswap-finance' = 'milkshakeswap-finance',
    'monsters-clan' = 'monsters-clan',
    'gameguru' = 'gameguru',
    'inuko-finance' = 'inuko-finance',
    'coxswap-v2' = 'coxswap-v2',
    'gpt-guru' = 'gpt-guru',
    'bitcashpay-new' = 'bitcashpay-new',
    'digihealth' = 'digihealth',
    'soccer-crypto' = 'soccer-crypto',
    'super-floki' = 'super-floki',
    'coinscope' = 'coinscope',
    'cubebase' = 'cubebase',
    'bnb-floki' = 'bnb-floki',
    'perry-the-bnb' = 'perry-the-bnb',
    'babybonk' = 'babybonk',
    'copiosa-coin' = 'copiosa-coin',
    'potter' = 'potter',
    'bnbpot' = 'bnbpot',
    'bithashex' = 'bithashex',
    'ctbnetwork' = 'ctbnetwork',
    'hungarian-vizsla-inu' = 'hungarian-vizsla-inu',
    'lucky-block' = 'lucky-block',
    'jindo-inu' = 'jindo-inu',
    'web3-inu' = 'web3-inu',
    'god-of-wealth' = 'god-of-wealth',
    'rxc-dna' = 'rxc-dna',
    'universe-island' = 'universe-island',
    'digital-bank-of-africa' = 'digital-bank-of-africa',
    'joey-inu' = 'joey-inu',
    'shaun-inu' = 'shaun-inu',
    'grok-gd' = 'grok-gd',
    'livegreen-coin' = 'livegreen-coin',
    'dhabi-coin' = 'dhabi-coin',
    'eifi-finance' = 'eifi-finance',
    'grokking' = 'grokking',
    'baby-safemoon' = 'baby-safemoon',
    'black-dragon' = 'black-dragon',
    'bfk-warzone' = 'bfk-warzone',
    'marsrise' = 'marsrise',
    'amazy-move-token' = 'amazy-move-token',
    'revolotto' = 'revolotto',
    'tryhards' = 'tryhards',
    'super-floki-xyz' = 'super-floki-xyz',
    'wanda-exchange' = 'wanda-exchange',
    'google-ai' = 'google-ai',
    'crypto-inu' = 'crypto-inu',
    'drover-inu' = 'drover-inu',
    'el-dorado-exchange' = 'el-dorado-exchange',
    'air' = 'air',
    'taroverse' = 'taroverse',
    'sonic-space-cat' = 'sonic-space-cat',
    'chaincade' = 'chaincade',
    'cats-coin' = 'cats-coin',
    'baby-luffy' = 'baby-luffy',
    'shib-army' = 'shib-army',
    'metacash' = 'metacash',
    'baby-cat-inu' = 'baby-cat-inu',
    'crypthub' = 'crypthub',
    'feeder-finance' = 'feeder-finance',
    'jny' = 'jny',
    'mech-master' = 'mech-master',
    'official-online' = 'official-online',
    'the-simpsons-inu' = 'the-simpsons-inu',
    'ryoshi-token-v2' = 'ryoshi-token-v2',
    'lesbian-inu' = 'lesbian-inu',
    'aerotyme' = 'aerotyme',
    'safemoneyup' = 'safemoneyup',
    'ai-dogemini' = 'ai-dogemini',
    'blizzard-money' = 'blizzard-money',
    'grok-inu' = 'grok-inu',
    'swincoin' = 'swincoin',
    'koinbulteni-token' = 'koinbulteni-token',
    'gemini-coin' = 'gemini-coin',
    'sheesha-finance-bep20' = 'sheesha-finance-bep20',
    'zamzam' = 'zamzam',
    'orbitau' = 'orbitau',
    'x-doge' = 'x-doge',
    'babymyro' = 'babymyro',
    'bnb-tiger-ai' = 'bnb-tiger-ai',
    'calo' = 'calo',
    'eternal-world' = 'eternal-world',
    'acetoken' = 'acetoken',
    'babybtc' = 'babybtc',
    'bonfire' = 'bonfire',
    'shibkiller' = 'shibkiller',
    'glory-finance' = 'glory-finance',
    'baby-maga' = 'baby-maga',
    'cancer' = 'cancer',
    'gamingdoge' = 'gamingdoge',
    'dobby' = 'dobby',
    'space-crypto-spe' = 'space-crypto-spe',
    'apexcoin-app' = 'apexcoin-app',
    'pandai' = 'pandai',
    'monetas' = 'monetas',
    'shibu-inu' = 'shibu-inu',
    'xmooney' = 'xmooney',
    'bigtycoon' = 'bigtycoon',
    'pepemo' = 'pepemo',
    'autoshark' = 'autoshark',
    'pepe-world' = 'pepe-world',
    'macro-protocol' = 'macro-protocol',
    'vicmove' = 'vicmove',
    'bonkfork' = 'bonkfork',
    'shiba-fame' = 'shiba-fame',
    'revolution' = 'revolution',
    'daddy-doge' = 'daddy-doge',
    'kitten-coin' = 'kitten-coin',
    'hatchypocket' = 'hatchypocket',
    'sangkara-misa' = 'sangkara-misa',
    'wirtual' = 'wirtual',
    'battlesaga' = 'battlesaga',
    'tigermoon' = 'tigermoon',
    'zkzone' = 'zkzone',
    'lifebird' = 'lifebird',
    'shira-cat' = 'shira-cat',
    'charitydao' = 'charitydao',
    'smartfi' = 'smartfi',
    'moby-dick-new' = 'moby-dick-new',
    'safe-energy' = 'safe-energy',
    'last-survivor' = 'last-survivor',
    'metoshi' = 'metoshi',
    'elon-buys-twitter' = 'elon-buys-twitter',
    'pudel' = 'pudel',
    'age-of-tanks' = 'age-of-tanks',
    'bordercolliebsc' = 'bordercolliebsc',
    'bolicai' = 'bolicai',
    'h2o-securities' = 'h2o-securities',
    'predictcoin' = 'predictcoin',
    'capverse' = 'capverse',
    '1nft' = '1nft',
    'bohr' = 'bohr',
    'pepe-the-frog' = 'pepe-the-frog',
    '3air' = '3air',
    'pepe-coin-bsc' = 'pepe-coin-bsc',
    'pai-inscription' = 'pai-inscription',
    'calo-app' = 'calo-app',
    'chief-troll-officer-bsc' = 'chief-troll-officer-bsc',
    'bart-simpson-coin' = 'bart-simpson-coin',
    'babyhktiger' = 'babyhktiger',
    'sensi' = 'sensi',
    'bnbee' = 'bnbee',
    'dragongrok' = 'dragongrok',
    'nether-nft' = 'nether-nft',
    'gamerfi' = 'gamerfi',
    'bull-inu' = 'bull-inu',
    'howl-city' = 'howl-city',
    'baby-doge-inu' = 'baby-doge-inu',
    'strite' = 'strite',
    'grok-queen' = 'grok-queen',
    'blaze' = 'blaze',
    'bali-token' = 'bali-token',
    'bull-token-finance' = 'bull-token-finance',
    'shibceo' = 'shibceo',
    'shib-generating' = 'shib-generating',
    'dancing-lizard-coin' = 'dancing-lizard-coin',
    'elon404' = 'elon404',
    'kryza-network-v2' = 'kryza-network-v2',
    'rising-sun' = 'rising-sun',
    'melo-token' = 'melo-token',
    'red-pepe' = 'red-pepe',
    'milk' = 'milk',
    'bhive' = 'bhive',
    'greengold' = 'greengold',
    'geoleaf-io' = 'geoleaf-io',
    'newsolution-token' = 'newsolution-token',
    'bshare' = 'bshare',
    'dfs-mafia-v2' = 'dfs-mafia-v2',
    'shiny-ore' = 'shiny-ore',
    'tom-coin' = 'tom-coin',
    'cloudchat' = 'cloudchat',
    'web5-inu' = 'web5-inu',
    'metabomb' = 'metabomb',
    'metaufo' = 'metaufo',
    'kingu' = 'kingu',
    'ampleswap-new' = 'ampleswap-new',
    'suteku' = 'suteku',
    'luffy-g5' = 'luffy-g5',
    'adroverse' = 'adroverse',
    'book-of-dogs' = 'book-of-dogs',
    'pepepi' = 'pepepi',
    'potent' = 'potent',
    'yearn-classic-finance' = 'yearn-classic-finance',
    'topshelf-finance' = 'topshelf-finance',
    'pepefloki' = 'pepefloki',
    'gamingshiba-v2' = 'gamingshiba-v2',
    'giant' = 'giant',
    'kingwif' = 'kingwif',
    'donkey-kong' = 'donkey-kong',
    'bnbfrog' = 'bnbfrog',
    'delot-io' = 'delot-io',
    'cmcc' = 'cmcc',
    'beeruscat' = 'beeruscat',
    'honk-bsc' = 'honk-bsc',
    'wairere-token' = 'wairere-token',
    'iassets' = 'iassets',
    'metakings' = 'metakings',
    'noshit' = 'noshit',
    'venom-ai' = 'venom-ai',
    'burnz' = 'burnz',
    '2024-bsc' = '2024-bsc',
    'cage-match' = 'cage-match',
    'ai-floki' = 'ai-floki',
    'pumpeth' = 'pumpeth',
    'moonstar' = 'moonstar',
    'safereum-bsc' = 'safereum-bsc',
    'harrypotterobamasonic10inu' = 'harrypotterobamasonic10inu',
    'subawu-token' = 'subawu-token',
    'blockaura' = 'blockaura',
    'catena-x' = 'catena-x',
    'bearai' = 'bearai',
    'bullshit-inu' = 'bullshit-inu',
    'solar-energy' = 'solar-energy',
    'pocoland' = 'pocoland',
    'sdao' = 'sdao',
    'luckychip' = 'luckychip',
    'peepee' = 'peepee',
    'yummy' = 'yummy',
    'vention' = 'vention',
    'cuanswapcom' = 'cuanswapcom',
    'samoydecoin' = 'samoydecoin',
    'surviving-soldiers' = 'surviving-soldiers',
    'bao-token' = 'bao-token',
    'tao-te-ching' = 'tao-te-ching',
    'stickman' = 'stickman',
    'dingo-token' = 'dingo-token',
    'meme-bsc' = 'meme-bsc',
    'babyflokizilla' = 'babyflokizilla',
    '1move' = '1move',
    'all-best-ico-satoshi' = 'all-best-ico-satoshi',
    'pepe-2-0-bsc' = 'pepe-2-0-bsc',
    'ore-token' = 'ore-token',
    'dogezilla-v2' = 'dogezilla-v2',
    'stkbnb' = 'stkbnb',
    'pinksale' = 'pinksale',
    'grapeswap' = 'grapeswap',
    'immutable' = 'immutable',
    'belgian-inu' = 'belgian-inu',
    'manufactory-2' = 'manufactory-2',
    'hyperverse' = 'hyperverse',
    'cakewswap' = 'cakewswap',
    'decentralfree' = 'decentralfree',
    'kabosu' = 'kabosu',
    'lovelace' = 'lovelace',
    'shiba-inu-pay' = 'shiba-inu-pay',
    'firsthare' = 'firsthare',
    'crazy-lion' = 'crazy-lion',
    'clucoin' = 'clucoin',
    'philcoin' = 'philcoin',
    'olaf-token' = 'olaf-token',
    'spacegrime' = 'spacegrime',
    'yellow-pepe' = 'yellow-pepe',
    'openlive-nft' = 'openlive-nft',
    'bitnautic-token' = 'bitnautic-token',
    'musk-meme' = 'musk-meme',
    'bonk-bnb' = 'bonk-bnb',
    'crypto-ai-bsc' = 'crypto-ai-bsc',
    'dubai-cat' = 'dubai-cat',
    'moomonster' = 'moomonster',
    'bhbd' = 'bhbd',
    'peachy' = 'peachy',
    'tribalisland-game-token' = 'tribalisland-game-token',
    'doge-floki-coin' = 'doge-floki-coin',
    'safegem-finance' = 'safegem-finance',
    'minibnbtiger' = 'minibnbtiger',
    'moox-v2' = 'moox-v2',
    'bnbdragon' = 'bnbdragon',
    'yuse' = 'yuse',
    'hugo-inu' = 'hugo-inu',
    'pepe-dao-finance' = 'pepe-dao-finance',
    'dinosaur-inu' = 'dinosaur-inu',
    'multiverse-token' = 'multiverse-token',
    'pegonetwork' = 'pegonetwork',
    'king-trump' = 'king-trump',
    'happy-pepe-bnb' = 'happy-pepe-bnb',
    'pancake-hunny' = 'pancake-hunny',
    'osl-ai' = 'osl-ai',
    'nftl' = 'nftl',
    'qubit' = 'qubit',
    'navis' = 'navis',
    'useless-new' = 'useless-new',
    'wally' = 'wally',
    'buck' = 'buck',
    'green-climate-world' = 'green-climate-world',
    'frog-bsc' = 'frog-bsc',
    'metafame' = 'metafame',
    'block-galaxy-network' = 'block-galaxy-network',
    'snapex' = 'snapex',
    'atlantis' = 'atlantis',
    'meblox-protocol' = 'meblox-protocol',
    'blocksafu' = 'blocksafu',
    'galaxy-war' = 'galaxy-war',
    'leisurepay' = 'leisurepay',
    'bananacoin-bsc' = 'bananacoin-bsc',
    'meme-etf' = 'meme-etf',
    'whale-exploder' = 'whale-exploder',
    'alphadex' = 'alphadex',
    'pepe-2' = 'pepe-2',
    'bogged-finance' = 'bogged-finance',
    'bored-coin' = 'bored-coin',
    'pink-bnb' = 'pink-bnb',
    'pepemoon-token' = 'pepemoon-token',
    'coinradr' = 'coinradr',
    'burnny-inu' = 'burnny-inu',
    'trollmuskwifhat' = 'trollmuskwifhat',
    'xmas-grok' = 'xmas-grok',
    'pepearmy' = 'pepearmy',
    'benten-inu' = 'benten-inu',
    'surf-live' = 'surf-live',
    'minidoge' = 'minidoge',
    'pepe-classic-bsc' = 'pepe-classic-bsc',
    'catvills-coin' = 'catvills-coin',
    'la-peseta-digital' = 'la-peseta-digital',
    'pepverse' = 'pepverse',
    'little-angry-bunny-v2' = 'little-angry-bunny-v2',
    'kaby-gaming-token' = 'kaby-gaming-token',
    'grokboy' = 'grokboy',
    'insurabler' = 'insurabler',
    'bluebenx' = 'bluebenx',
    'crypto-arc' = 'crypto-arc',
    'milady-coin' = 'milady-coin',
    'xrpcashone' = 'xrpcashone',
    'czfarm' = 'czfarm',
    'moonlift-protocol' = 'moonlift-protocol',
    'oxm-protocol' = 'oxm-protocol',
    'phoenix-dragon' = 'phoenix-dragon',
    'fox-coxswap' = 'fox-coxswap',
    'melody' = 'melody',
    'minions-inu' = 'minions-inu',
    'pyramid-trading' = 'pyramid-trading',
    'golden-paws' = 'golden-paws',
    'evodefi' = 'evodefi',
    'usd-mars' = 'usd-mars',
    'zeppelin-dao' = 'zeppelin-dao',
    'sandwich-network' = 'sandwich-network',
    'grok-heroes' = 'grok-heroes',
    'binance-smart-chain-girl' = 'binance-smart-chain-girl',
    'solcash' = 'solcash',
    'helper-coin' = 'helper-coin',
    'agricoin' = 'agricoin',
    'pepefork-inu' = 'pepefork-inu',
    'joe-yo-coin' = 'joe-yo-coin',
    'genomicdao' = 'genomicdao',
    'czshares' = 'czshares',
    'all-in-ai' = 'all-in-ai',
    'girlfriend' = 'girlfriend',
    'walterinu' = 'walterinu',
    'the-parallel' = 'the-parallel',
    'vegas-inu-token' = 'vegas-inu-token',
    'sishi-finance' = 'sishi-finance',
    'whitex' = 'whitex',
    'gemguardian' = 'gemguardian',
    'hokkaido-inu-' = 'hokkaido-inu-',
    'cred-coin-pay' = 'cred-coin-pay',
    'bogey' = 'bogey',
    'dives-defi' = 'dives-defi',
    'horizondollar' = 'horizondollar',
    'babysweep' = 'babysweep',
    'catceo' = 'catceo',
    'shibking-inu' = 'shibking-inu',
    'lego-pepe-vision' = 'lego-pepe-vision',
    'add-finance' = 'add-finance',
    'united-emirate-decentralized-coin' = 'united-emirate-decentralized-coin',
    'ai-trader' = 'ai-trader',
    'pepa-inu2' = 'pepa-inu2',
    'elontech' = 'elontech',
    'rabbit-inu' = 'rabbit-inu',
    'ordi-2-vip' = 'ordi-2-vip',
    'amazingdoge' = 'amazingdoge',
    'pink-panther-lovers' = 'pink-panther-lovers',
    'shibcat' = 'shibcat',
    'energyfi' = 'energyfi',
    'equilibrium-' = 'equilibrium-',
    'api-inu' = 'api-inu',
    'zoo' = 'zoo',
    'txbit-token' = 'txbit-token',
    'adacash' = 'adacash',
    'green-block-token' = 'green-block-token',
    'pulseai' = 'pulseai',
    'grokky' = 'grokky',
    'king-grok' = 'king-grok',
    'floki-shiba-pepe-ceo' = 'floki-shiba-pepe-ceo',
    '2g-carbon-coin' = '2g-carbon-coin',
    'cheese-swap' = 'cheese-swap',
    'vancat-v2' = 'vancat-v2',
    'snowcrash-token' = 'snowcrash-token',
    'hummingbird-finance' = 'hummingbird-finance',
    'kamaleont' = 'kamaleont',
    'grok-moon' = 'grok-moon',
    'payrue-propel' = 'payrue-propel',
    'dsundao' = 'dsundao',
    'pepeking' = 'pepeking',
    'oxm-protocol-v2' = 'oxm-protocol-v2',
    'autoshark-dex' = 'autoshark-dex',
    'traders-coin' = 'traders-coin',
    'royal-gold' = 'royal-gold',
    'outrace' = 'outrace',
    'posschain' = 'posschain',
    'jade-protocol' = 'jade-protocol',
    'the-crypto-you' = 'the-crypto-you',
    'aquagoat-finance-new' = 'aquagoat-finance-new',
    'gg-token' = 'gg-token',
    'smp-finance' = 'smp-finance',
    'ezzy-game' = 'ezzy-game',
    'ezzy-game-gezy' = 'ezzy-game-gezy',
    'cryptoblades-kingdoms' = 'cryptoblades-kingdoms',
    'pinhub' = 'pinhub',
    'vagabond' = 'vagabond',
    'bitorbit' = 'bitorbit',
    'snaps' = 'snaps',
    'tardigrades-finance-bsc' = 'tardigrades-finance-bsc',
    'frlong-token' = 'frlong-token',
    'story' = 'story',
    'swinca' = 'swinca',
    'seamlessswap' = 'seamlessswap',
    'earnguild' = 'earnguild',
    'wolf-town-wool' = 'wolf-town-wool',
    'babydoge-ceo' = 'babydoge-ceo',
    'mmsc-platform' = 'mmsc-platform',
    'swapcoinz' = 'swapcoinz',
    'globaltrustfund-token' = 'globaltrustfund-token',
    'endless-board-game' = 'endless-board-game',
    'folgoryusd' = 'folgoryusd',
    'nyantereum' = 'nyantereum',
    'kiwi-finance' = 'kiwi-finance',
    'jetfuel-finance' = 'jetfuel-finance',
    'warrior-token-spartan-casino' = 'warrior-token-spartan-casino',
    'daily' = 'daily',
    'berryswap' = 'berryswap',
    'realfinance-network' = 'realfinance-network',
    'swampy' = 'swampy',
    'porta' = 'porta',
    'donkey' = 'donkey',
    'klend' = 'klend',
    'cherryswap' = 'cherryswap',
    'croxswap' = 'croxswap',
    'rasta-finance' = 'rasta-finance',
    'fortress-lending' = 'fortress-lending',
    'dot-finance' = 'dot-finance',
    'frenchie-network' = 'frenchie-network',
    'greenheart-cbd' = 'greenheart-cbd',
    'vanity' = 'vanity',
    'rfox-finance' = 'rfox-finance',
    'black-eye-galaxy' = 'black-eye-galaxy',
    'wshiba' = 'wshiba',
    'koji' = 'koji',
    'coinswap-space' = 'coinswap-space',
    'jetswap-finance' = 'jetswap-finance',
    'neftipedia' = 'neftipedia',
    'yield-parrot' = 'yield-parrot',
    'babybitcoin' = 'babybitcoin',
    'redfeg' = 'redfeg',
    'nobility-new' = 'nobility-new',
    'nuketoken' = 'nuketoken',
    'moonie-nft' = 'moonie-nft',
    'kaiken-shiba' = 'kaiken-shiba',
    'minimals' = 'minimals',
    'starmon-metaverse' = 'starmon-metaverse',
    'sona-network' = 'sona-network',
    'yocoin-yoco' = 'yocoin-yoco',
    'hummingbird-egg-token' = 'hummingbird-egg-token',
    'rugzombie' = 'rugzombie',
    'portify' = 'portify',
    'xtra-token' = 'xtra-token',
    'baby-floki-billionaire' = 'baby-floki-billionaire',
    'starsharks-sea' = 'starsharks-sea',
    'ksm-starter' = 'ksm-starter',
    'ideanet-token' = 'ideanet-token',
    'piggy-finance' = 'piggy-finance',
    'red-floki' = 'red-floki',
    'treat-dao-new' = 'treat-dao-new',
    'graphene' = 'graphene',
    'pappay' = 'pappay',
    'cpos-cloud-payment' = 'cpos-cloud-payment',
    'little-bunny-rocket' = 'little-bunny-rocket',
    'fastswap-bsc' = 'fastswap-bsc',
    'squidgametoken' = 'squidgametoken',
    'cashcow' = 'cashcow',
    'babydogezilla' = 'babydogezilla',
    'shibazillacoin' = 'shibazillacoin',
    'afrostar' = 'afrostar',
    'cockapoo' = 'cockapoo',
    'bunscake' = 'bunscake',
    'santa-coin-2' = 'santa-coin-2',
    'rifi-united' = 'rifi-united',
    'zuna' = 'zuna',
    'arcadenetwork' = 'arcadenetwork',
    'nsights-defi-trader' = 'nsights-defi-trader',
    'metadoge' = 'metadoge',
    'avnrich-token' = 'avnrich-token',
    'fia-protocol' = 'fia-protocol',
    'diamond-dnd' = 'diamond-dnd',
    'ad-token' = 'ad-token',
    'shiba-interstellar' = 'shiba-interstellar',
    'quizdrop' = 'quizdrop',
    'shiba-inu-empire' = 'shiba-inu-empire',
    'dali' = 'dali',
    'happy-land' = 'happy-land',
    'hashland' = 'hashland',
    'sappchat' = 'sappchat',
    'microverse' = 'microverse',
    'busdx' = 'busdx',
    'summit-koda-token-new' = 'summit-koda-token-new',
    'catbonk' = 'catbonk',
    'rps-league' = 'rps-league',
    'autobusd' = 'autobusd',
    'bunnypark-bg' = 'bunnypark-bg',
    'metavice' = 'metavice',
    'baby-lovely-inu' = 'baby-lovely-inu',
    'collectcoin' = 'collectcoin',
    'localtrade' = 'localtrade',
    'multiverse-capital' = 'multiverse-capital',
    'peoplez' = 'peoplez',
    'defi-coin' = 'defi-coin',
    'woopmoney' = 'woopmoney',
    'battlecity-io' = 'battlecity-io',
    'bizverse' = 'bizverse',
    'gforce' = 'gforce',
    'cryptotem' = 'cryptotem',
    'filipcoin' = 'filipcoin',
    'tata-coin' = 'tata-coin',
    'the-wasted-lands' = 'the-wasted-lands',
    'crypto-fight-club' = 'crypto-fight-club',
    'rise-of-defenders' = 'rise-of-defenders',
    'power-cash' = 'power-cash',
    'king-forever' = 'king-forever',
    'travgopv' = 'travgopv',
    'bot-planet' = 'bot-planet',
    'cakeswap' = 'cakeswap',
    'ipulse' = 'ipulse',
    'conjee' = 'conjee',
    'obsidium' = 'obsidium',
    'exobots' = 'exobots',
    'fado-go' = 'fado-go',
    'zatcoin' = 'zatcoin',
    'ouse-token' = 'ouse-token',
    'baha' = 'baha',
    'france-rev-finance' = 'france-rev-finance',
    'shiba-inu-classic' = 'shiba-inu-classic',
    'topmanager' = 'topmanager',
    'gamifi-gg' = 'gamifi-gg',
    'puli-inu' = 'puli-inu',
    'lucrosus-capital' = 'lucrosus-capital',
    'eagle-token' = 'eagle-token',
    'king-dog-inu' = 'king-dog-inu',
    'gunstar-metaverse-currency' = 'gunstar-metaverse-currency',
    'dogecolony' = 'dogecolony',
    'galatic-kitty-fighters' = 'galatic-kitty-fighters',
    'value-finance' = 'value-finance',
    'earthbyt' = 'earthbyt',
    'doge-1-mission-to-the-moon' = 'doge-1-mission-to-the-moon',
    'fenomy' = 'fenomy',
    'robot-warriors' = 'robot-warriors',
    'tourist-shiba-inu' = 'tourist-shiba-inu',
    'appleswap-token' = 'appleswap-token',
    'forus' = 'forus',
    'nft11' = 'nft11',
    'platinx' = 'platinx',
    'metaswap' = 'metaswap',
    'web3-doge' = 'web3-doge',
    'teneo' = 'teneo',
    'metarim' = 'metarim',
    'santa-floki-v2' = 'santa-floki-v2',
    'fantompad' = 'fantompad',
    'covid-cutter' = 'covid-cutter',
    'hare-plus' = 'hare-plus',
    'nekocoin' = 'nekocoin',
    'zoro-inu' = 'zoro-inu',
    'food-bank' = 'food-bank',
    'wolfcoin-wolf' = 'wolfcoin-wolf',
    'arize' = 'arize',
    'dpad-finance' = 'dpad-finance',
    'frz-solar-system' = 'frz-solar-system',
    'metatariff' = 'metatariff',
    'space-corsair-key' = 'space-corsair-key',
    'mooner' = 'mooner',
    'memeverse' = 'memeverse',
    'goldefy' = 'goldefy',
    'calvex' = 'calvex',
    'samurai-legends' = 'samurai-legends',
    'cryptomines-reborn' = 'cryptomines-reborn',
    'derify-protocol' = 'derify-protocol',
    'cylum-finance' = 'cylum-finance',
    'updefi' = 'updefi',
    'two-monkey-juice-bar' = 'two-monkey-juice-bar',
    'mloky' = 'mloky',
    'tiraverse' = 'tiraverse',
    'vulcano' = 'vulcano',
    'catpay' = 'catpay',
    'vitteey' = 'vitteey',
    'metatoll' = 'metatoll',
    'metacontinental' = 'metacontinental',
    'zirve-coin' = 'zirve-coin',
    'kittens-puppies' = 'kittens-puppies',
    'apple-binemon' = 'apple-binemon',
    'web3-game' = 'web3-game',
    'sanji-inu' = 'sanji-inu',
    'zenc-coin' = 'zenc-coin',
    'world-of-masters' = 'world-of-masters',
    'galaxy-heroes-coin-new' = 'galaxy-heroes-coin-new',
    'gem' = 'gem',
    'firework-games' = 'firework-games',
    'pankuku' = 'pankuku',
    'numitor' = 'numitor',
    'belecx-protocol' = 'belecx-protocol',
    'million-dollar-baby' = 'million-dollar-baby',
    'stepg' = 'stepg',
    'ezillion' = 'ezillion',
    'x-metaverse' = 'x-metaverse',
    'shinobiverse' = 'shinobiverse',
    'walk-to-earn' = 'walk-to-earn',
    'sports-2k75' = 'sports-2k75',
    'coin-gabbar' = 'coin-gabbar',
    'goat-gang' = 'goat-gang',
    'vizslaswap' = 'vizslaswap',
    'hyperone' = 'hyperone',
    'oloid' = 'oloid',
    'metagamble' = 'metagamble',
    'yoda-coin-swap' = 'yoda-coin-swap',
    'plant-empires' = 'plant-empires',
    'source-token' = 'source-token',
    'lookscoin' = 'lookscoin',
    'mudra-mdr' = 'mudra-mdr',
    'artificial-intelligence-technology-network' = 'artificial-intelligence-technology-network',
    'wateenswap' = 'wateenswap',
    'optimus-opt' = 'optimus-opt',
    'nbox' = 'nbox',
    'souls-of-meta' = 'souls-of-meta',
    'lunatics' = 'lunatics',
    'lulu-market' = 'lulu-market',
    'ecosmart-gold' = 'ecosmart-gold',
    'metahamster' = 'metahamster',
    'chihuahua-token-19fcd0de-eb4d-4fd7-bc4a-a202247dfdbb' = 'chihuahua-token-19fcd0de-eb4d-4fd7-bc4a-a202247dfdbb',
    'future-of-fintech' = 'future-of-fintech',
    'poochain' = 'poochain',
    'lucky-coin' = 'lucky-coin',
    'telegram-inu' = 'telegram-inu',
    'genesis-universe' = 'genesis-universe',
    'decode-coin' = 'decode-coin',
    'logos' = 'logos',
    'shanum' = 'shanum',
    'babyapefunclub' = 'babyapefunclub',
    'newtowngaming' = 'newtowngaming',
    'stylike-governance' = 'stylike-governance',
    'nskswap' = 'nskswap',
    'smart-world-union' = 'smart-world-union',
    'moonions' = 'moonions',
    'nxd-next' = 'nxd-next',
    'presale-world' = 'presale-world',
    'karbun' = 'karbun',
    'fame-reward-plus' = 'fame-reward-plus',
    'global-innovative-solutions' = 'global-innovative-solutions',
    'soccerinu' = 'soccerinu',
    'eclat' = 'eclat',
    'maximus-coin' = 'maximus-coin',
    'blockrock' = 'blockrock',
    'hayya-qatar' = 'hayya-qatar',
    'winterdog' = 'winterdog',
    'nutgain' = 'nutgain',
    'lucky-roo' = 'lucky-roo',
    'jackpotdoge' = 'jackpotdoge',
    'metasetgo' = 'metasetgo',
    'upload-token' = 'upload-token',
    'zfmcoin' = 'zfmcoin',
    'forever-burn' = 'forever-burn',
    'vetter-skylabs' = 'vetter-skylabs',
    'punk-panda-messenger' = 'punk-panda-messenger',
    'bitratoken' = 'bitratoken',
    'setter-protocol' = 'setter-protocol',
    'mxmboxceus-token' = 'mxmboxceus-token',
    'meta-space-2045' = 'meta-space-2045',
    'homie-wars' = 'homie-wars',
    'etxinfinity' = 'etxinfinity',
    'dyzilla' = 'dyzilla',
    'shopnext-reward-token' = 'shopnext-reward-token',
    'bitcointry-token' = 'bitcointry-token',
    'jimizz' = 'jimizz',
    'defi-connect-new' = 'defi-connect-new',
    'control2xy' = 'control2xy',
    'espl-arena' = 'espl-arena',
    'vege-token' = 'vege-token',
    'metadoge-top' = 'metadoge-top',
    'meta-game-city' = 'meta-game-city',
    'cryptegrity-dao' = 'cryptegrity-dao',
    'bsc-payments' = 'bsc-payments',
    'king-finance' = 'king-finance',
    'floshido-inu' = 'floshido-inu',
    'gliesecoin' = 'gliesecoin',
    'ifortune' = 'ifortune',
    'metapioneers' = 'metapioneers',
    'belifex-new' = 'belifex-new',
    'martik' = 'martik',
    'optimus-inu' = 'optimus-inu',
    'doge-ipa' = 'doge-ipa',
    'alif-coin' = 'alif-coin',
    'libra-protocol' = 'libra-protocol',
    'dogearmy' = 'dogearmy',
    'podfast' = 'podfast',
    'utility-web3shot' = 'utility-web3shot',
    'interactwith' = 'interactwith',
    'frog-ceo' = 'frog-ceo',
    'generative-gpt' = 'generative-gpt',
    'neocortexai' = 'neocortexai',
    'gpt-ai' = 'gpt-ai',
    'cyberharbor' = 'cyberharbor',
    'sincronix' = 'sincronix',
    'ccgds' = 'ccgds',
    'viddli' = 'viddli',
    'stafi-staked-bnb' = 'stafi-staked-bnb',
    'yorkie-doge' = 'yorkie-doge',
    'kang3n' = 'kang3n',
    'cradle-of-sins' = 'cradle-of-sins',
    'real-pepe-ceo' = 'real-pepe-ceo',
    'fathom' = 'fathom',
    'ls-trade' = 'ls-trade',
    'sell-token' = 'sell-token',
    'cremation-coin' = 'cremation-coin',
    'ceo-doge' = 'ceo-doge',
    'wrapped-besc' = 'wrapped-besc',
    'bidz-coin' = 'bidz-coin',
    'meta-fps' = 'meta-fps',
    'truthgpt-bsc' = 'truthgpt-bsc',
    'deri-trade' = 'deri-trade',
    'blockchaingames' = 'blockchaingames',
    'pepito-bsc' = 'pepito-bsc',
    'layer-network' = 'layer-network',
    'red-floki-ceo' = 'red-floki-ceo',
    'have-fun' = 'have-fun',
    'songoku' = 'songoku',
    'pooh-inu' = 'pooh-inu',
    'bearium' = 'bearium',
    'flokipepe' = 'flokipepe',
    'pepebsc' = 'pepebsc',
    'crazy-pepe' = 'crazy-pepe',
    'pepe-chain' = 'pepe-chain',
    'pepezilla' = 'pepezilla',
    'pepelon' = 'pepelon',
    'migmig-swap' = 'migmig-swap',
    'cowrie' = 'cowrie',
    'wombex-wom' = 'wombex-wom',
    'frogtoken' = 'frogtoken',
    'frozy-inu' = 'frozy-inu',
    'hotel-of-secrets' = 'hotel-of-secrets',
    'babypepeentire' = 'babypepeentire',
    'stuart-inu' = 'stuart-inu',
    'automated-income-machine' = 'automated-income-machine',
    'lunasphere' = 'lunasphere',
    'rhinomars' = 'rhinomars',
    'the-wrong-token' = 'the-wrong-token',
    'lindayacc-ceo' = 'lindayacc-ceo',
    'spacexdoge' = 'spacexdoge',
    'diamond-are-forever' = 'diamond-are-forever',
    'metafury' = 'metafury',
    'utb-ai' = 'utb-ai',
    'sylvester-bsc' = 'sylvester-bsc',
    'refund-token' = 'refund-token',
    'odesis' = 'odesis',
    'rollerswap' = 'rollerswap',
    'soigpt' = 'soigpt',
    'puppets-coin' = 'puppets-coin',
    'hajiini' = 'hajiini',
    'jasan-wellness' = 'jasan-wellness',
    'uxos' = 'uxos',
    'quadrillion-coin' = 'quadrillion-coin',
    'pepe-x' = 'pepe-x',
    'pepe-grow' = 'pepe-grow',
    'peppa-token' = 'peppa-token',
    '4realza-coin' = '4realza-coin',
    'pepeburn' = 'pepeburn',
    'onetoken' = 'onetoken',
    'hundred' = 'hundred',
    'pepe-grinch' = 'pepe-grinch',
    'bananace' = 'bananace',
    'cryptomoonshot' = 'cryptomoonshot',
    'etherpos' = 'etherpos',
    'archean' = 'archean',
    'finance-token' = 'finance-token',
    'soma-network' = 'soma-network',
    'selftoken' = 'selftoken',
    'mr-bob-coin' = 'mr-bob-coin',
    'refundtoken' = 'refundtoken',
    'dinowars' = 'dinowars',
    'divewallet-token-v2' = 'divewallet-token-v2',
    'pepe-gem-ai' = 'pepe-gem-ai',
    'this-is-fine' = 'this-is-fine',
    'hongkong-btc-bank' = 'hongkong-btc-bank',
    'snowmantastic' = 'snowmantastic',
    'rob' = 'rob',
    'ferzan' = 'ferzan',
    'garuda-coin' = 'garuda-coin',
    'white-tiger-pixel' = 'white-tiger-pixel',
    'babyama' = 'babyama',
    'babyltc' = 'babyltc',
    'cyrus-exchange' = 'cyrus-exchange',
    'gogu-coin' = 'gogu-coin',
    'tribalisland-token' = 'tribalisland-token',
    'dzd' = 'dzd',
    'jesus-raptor-bsc' = 'jesus-raptor-bsc',
    'what-do-you-meme' = 'what-do-you-meme',
    'x-project' = 'x-project',
    'luigi-inu' = 'luigi-inu',
    'bossbaby' = 'bossbaby',
    'spider-man' = 'spider-man',
    'incorgnito' = 'incorgnito',
    'safari-crush' = 'safari-crush',
    'wario-coin' = 'wario-coin',
    'nekoin' = 'nekoin',
    'bullpepe-token' = 'bullpepe-token',
    'pepe-30' = 'pepe-30',
    'fintradao' = 'fintradao',
    'starterpool' = 'starterpool',
    'bscburn' = 'bscburn',
    'beagle-inu-io' = 'beagle-inu-io',
    'shibazillacoin-new' = 'shibazillacoin-new',
    'tradex-ai' = 'tradex-ai',
    'nexbox' = 'nexbox',
    'sorcery' = 'sorcery',
    'real-smurf-cat' = 'real-smurf-cat',
    'alva' = 'alva',
    'greenenvcoalition' = 'greenenvcoalition',
    'crypto-news-flash-ai' = 'crypto-news-flash-ai',
    'happy-train' = 'happy-train',
    'froggy' = 'froggy',
    'nba-bsc' = 'nba-bsc',
    'weavers-token' = 'weavers-token',
    'doge-1satellite' = 'doge-1satellite',
    'minelab' = 'minelab',
    'artdraw' = 'artdraw',
    'rencom-network' = 'rencom-network',
    'bnb-whales' = 'bnb-whales',
    'pawstars' = 'pawstars',
    'doge-satellite-inu' = 'doge-satellite-inu',
    'grok-2-0' = 'grok-2-0',
    'cakebot-net' = 'cakebot-net',
    'gojo-coin' = 'gojo-coin',
    'diamond-token' = 'diamond-token',
    'froge' = 'froge',
    'grok-x-ai' = 'grok-x-ai',
    'droggy' = 'droggy',
    'santa-grok' = 'santa-grok',
    'gta-vi-bsc' = 'gta-vi-bsc',
    'safegrok' = 'safegrok',
    'smartmeme' = 'smartmeme',
    'day-of-defeat-mini-100x' = 'day-of-defeat-mini-100x',
    'nazar-protocol' = 'nazar-protocol',
    'baby-bob' = 'baby-bob',
    'bonkinu' = 'bonkinu',
    'santa-christmas-inu' = 'santa-christmas-inu',
    'grokolaus' = 'grokolaus',
    'jojobsc' = 'jojobsc',
    'baby-dragon' = 'baby-dragon',
    'gpt-1-allbestico' = 'gpt-1-allbestico',
    'bonk-grok' = 'bonk-grok',
    'pokegrok' = 'pokegrok',
    'elon-doge-token' = 'elon-doge-token',
    'king-bonk' = 'king-bonk',
    'trollheim' = 'trollheim',
    'tyo-ghoul' = 'tyo-ghoul',
    'myrowif' = 'myrowif',
    'baby-musk' = 'baby-musk',
    'mini-myro' = 'mini-myro',
    'johnlennonc0in' = 'johnlennonc0in',
    'grok-allbestico' = 'grok-allbestico',
    'payvertise' = 'payvertise',
    'myro-dragon' = 'myro-dragon',
    'dada' = 'dada',
    'baby-mickey' = 'baby-mickey',
    'baby-long' = 'baby-long',
    'babybnb-baby' = 'babybnb-baby',
    'baby-honk' = 'baby-honk',
    'longfu' = 'longfu',
    'ponke-fork' = 'ponke-fork',
    'xover' = 'xover',
    'dogefork' = 'dogefork',
    'cassie-dragon' = 'cassie-dragon',
    'super-doge' = 'super-doge',
    'bnb-ai-allbestico' = 'bnb-ai-allbestico',
    'spidey-token' = 'spidey-token',
    'dug' = 'dug',
    'honorarium' = 'honorarium',
    'baby-sora' = 'baby-sora',
    'saitamainu' = 'saitamainu',
    'bnb-vegeta' = 'bnb-vegeta',
    'troll-mode' = 'troll-mode',
    'sora-ceo' = 'sora-ceo',
    'pepe-etf' = 'pepe-etf',
    'groksorax' = 'groksorax',
    'flokiwifhat' = 'flokiwifhat',
    'flokimoon' = 'flokimoon',
    'pepe-ai-biz' = 'pepe-ai-biz',
    'sugar-daddy' = 'sugar-daddy',
    'minipepe-coin' = 'minipepe-coin',
    'crude-oil-brent-zedcex' = 'crude-oil-brent-zedcex',
    'litecoin' = 'litecoin',
    'xrp' = 'xrp',
    'dogecoin' = 'dogecoin',
    'ethereum-classic' = 'ethereum-classic',
    'maker' = 'maker',
    'eos' = 'eos',
    'tron' = 'tron',
    'tezos' = 'tezos',
    'filecoin' = 'filecoin',
    'synthetix' = 'synthetix',
    'fetch' = 'fetch',
    'cosmos' = 'cosmos',
    'polygon' = 'polygon',
    'avalanche' = 'avalanche',
    'polkadot-new' = 'polkadot-new',
    'axie-infinity' = 'axie-infinity',
    'injective' = 'injective',
    'aave' = 'aave',
    'aioz-network' = 'aioz-network',
    'floki-inu' = 'floki-inu',
    'toncoin' = 'toncoin',
    'bittorrent-new' = 'bittorrent-new',
    'first-digital-usd' = 'first-digital-usd',
    'onbeam' = 'onbeam',
    'zcash' = 'zcash',
    'basic-attention-token' = 'basic-attention-token',
    'aelf' = 'aelf',
    'zilliqa' = 'zilliqa',
    'ontology' = 'ontology',
    'iotex' = 'iotex',
    'zel' = 'zel',
    'terra-luna' = 'terra-luna',
    'pax-gold' = 'pax-gold',
    'compound' = 'compound',
    'mantra' = 'mantra',
    'dexe' = 'dexe',
    'oasis-network' = 'oasis-network',
    '1inch' = '1inch',
    'raydium' = 'raydium',
    'mask-network' = 'mask-network',
    'apenft' = 'apenft',
    'galxe' = 'galxe',
    'green-metaverse-token' = 'green-metaverse-token',
    'usdd' = 'usdd',
    'space-id' = 'space-id',
    'altlayer' = 'altlayer',
    'celer-network' = 'celer-network',
    'chromia' = 'chromia',
    'beldex' = 'beldex',
    'prom' = 'prom',
    'band-protocol' = 'band-protocol',
    'alchemy-pay' = 'alchemy-pay',
    'baby-doge-coin' = 'baby-doge-coin',
    'coin98' = 'coin98',
    'delysium' = 'delysium',
    'wrapped-beacon-eth' = 'wrapped-beacon-eth',
    'zeebu' = 'zeebu',
    'maga' = 'maga',
    'bluzelle' = 'bluzelle',
    'mvl' = 'mvl',
    'paxos-standard' = 'paxos-standard',
    'wink' = 'wink',
    'insure' = 'insure',
    'smooth-love-potion' = 'smooth-love-potion',
    'terrausd' = 'terrausd',
    'oraichain-token' = 'oraichain-token',
    'dao-maker' = 'dao-maker',
    'zigcoin' = 'zigcoin',
    'gomining-token' = 'gomining-token',
    'hashflow' = 'hashflow',
    'allianceblock-nexera' = 'allianceblock-nexera',
    'cyberconnect' = 'cyberconnect',
    'global-currency-reserve' = 'global-currency-reserve',
    'loom-network' = 'loom-network',
    'arpa-chain' = 'arpa-chain',
    'telos' = 'telos',
    'shentu' = 'shentu',
    'map-protocol' = 'map-protocol',
    'cream-finance' = 'cream-finance',
    'gamercoin' = 'gamercoin',
    'polkastarter' = 'polkastarter',
    'everscale' = 'everscale',
    'ankreth' = 'ankreth',
    'bounce-token' = 'bounce-token',
    'myneighboralice' = 'myneighboralice',
    'trias-token' = 'trias-token',
    'dogelon' = 'dogelon',
    'star-atlas' = 'star-atlas',
    'highstreet' = 'highstreet',
    'radio-caca' = 'radio-caca',
    'mines-of-dalarnia' = 'mines-of-dalarnia',
    'phoenix-global-new' = 'phoenix-global-new',
    'sidus' = 'sidus',
    'maverick-protocol' = 'maverick-protocol',
    'marblex' = 'marblex',
    'komodo' = 'komodo',
    'cannation' = 'cannation',
    'nuls' = 'nuls',
    'streamr' = 'streamr',
    'netrum' = 'netrum',
    'math' = 'math',
    'orion-xyz' = 'orion-xyz',
    'defichain' = 'defichain',
    'dextools' = 'dextools',
    'frontier' = 'frontier',
    'meter-governance' = 'meter-governance',
    'dia' = 'dia',
    'bella-protocol' = 'bella-protocol',
    'clover' = 'clover',
    'alien-worlds' = 'alien-worlds',
    'singularitydao' = 'singularitydao',
    'automata-network' = 'automata-network',
    'polytrade' = 'polytrade',
    'impossible-decentralized-incubator-access' = 'impossible-decentralized-incubator-access',
    'bitrise-token' = 'bitrise-token',
    'beta-finance' = 'beta-finance',
    'numbers-protocol' = 'numbers-protocol',
    'onyxcoin' = 'onyxcoin',
    'limewire' = 'limewire',
    'anchored-coins-aeur' = 'anchored-coins-aeur',
    'circuits-of-value' = 'circuits-of-value',
    'litecoin-ultra' = 'litecoin-ultra',
    'measurable-data-token' = 'measurable-data-token',
    'ceek-vr' = 'ceek-vr',
    'smartofgiving' = 'smartofgiving',
    'thundercore' = 'thundercore',
    'combo-network' = 'combo-network',
    'elysia' = 'elysia',
    'parsiq' = 'parsiq',
    'deapcoin' = 'deapcoin',
    'hackenai' = 'hackenai',
    'aleph-im' = 'aleph-im',
    'mcdex' = 'mcdex',
    'reef' = 'reef',
    'dego-finance' = 'dego-finance',
    'linear' = 'linear',
    'alpha-quark-token' = 'alpha-quark-token',
    'ramp' = 'ramp',
    'paid-network' = 'paid-network',
    'mdex' = 'mdex',
    'xcad-network' = 'xcad-network',
    'cratos' = 'cratos',
    'decimal' = 'decimal',
    'land-of-conquest' = 'land-of-conquest',
    'archloot' = 'archloot',
    'planettoken' = 'planettoken',
    'shrapnel-com' = 'shrapnel-com',
    'adx-net' = 'adx-net',
    'airdao' = 'airdao',
    'ava' = 'ava',
    'vite' = 'vite',
    'velas' = 'velas',
    'zynecoin' = 'zynecoin',
    'proton' = 'proton',
    'suku' = 'suku',
    'wing' = 'wing',
    'unilend' = 'unilend',
    'rocket-vault-rocketx' = 'rocket-vault-rocketx',
    'derace' = 'derace',
    'wrapped-ncg' = 'wrapped-ncg',
    'katana-inu' = 'katana-inu',
    'pstake-finance' = 'pstake-finance',
    'qorpo' = 'qorpo',
    'hello-labs' = 'hello-labs',
    'vidt-dao' = 'vidt-dao',
    'firo' = 'firo',
    'chrono-tech' = 'chrono-tech',
    'swftcoin' = 'swftcoin',
    'abbc-coin' = 'abbc-coin',
    'newscrypto' = 'newscrypto',
    'kardiachain' = 'kardiachain',
    'renbtc' = 'renbtc',
    'trustswap' = 'trustswap',
    'yearn-finance-ii' = 'yearn-finance-ii',
    'stobox-token' = 'stobox-token',
    'dvision-network' = 'dvision-network',
    'ovr' = 'ovr',
    'deeper-network' = 'deeper-network',
    'cellframe' = 'cellframe',
    'ternoa' = 'ternoa',
    'milc-platform' = 'milc-platform',
    'stader' = 'stader',
    'credefi' = 'credefi',
    'gamium' = 'gamium',
    'trvl' = 'trvl',
    'paraswap' = 'paraswap',
    'galeon' = 'galeon',
    'volt-inu-v2' = 'volt-inu-v2',
    'dmail-network' = 'dmail-network',
    'dexcheck' = 'dexcheck',
    'carbon-browser' = 'carbon-browser',
    'inspect' = 'inspect',
    'duel' = 'duel',
    'adshares' = 'adshares',
    'fusion' = 'fusion',
    'phantasma' = 'phantasma',
    'the-force-protocol' = 'the-force-protocol',
    'mixmarvel' = 'mixmarvel',
    'fuse-network' = 'fuse-network',
    'kira-network' = 'kira-network',
    'revv' = 'revv',
    'uncx' = 'uncx',
    'electra-protocol' = 'electra-protocol',
    'hapi-one' = 'hapi-one',
    'saito' = 'saito',
    'epik-prime' = 'epik-prime',
    'gamezone' = 'gamezone',
    'rmrk' = 'rmrk',
    'swash' = 'swash',
    'rangers-protocol' = 'rangers-protocol',
    'vita-inu' = 'vita-inu',
    'nvirworld' = 'nvirworld',
    'saitama-inu-new' = 'saitama-inu-new',
    'brickken' = 'brickken',
    'oho' = 'oho',
    'verified-usd' = 'verified-usd',
    'byte' = 'byte',
    'bigone-token' = 'bigone-token',
    'switcheo' = 'switcheo',
    'tokpie' = 'tokpie',
    'banano' = 'banano',
    'nest-protocol' = 'nest-protocol',
    'serum' = 'serum',
    'torn' = 'torn',
    'prosper' = 'prosper',
    'polkabridge' = 'polkabridge',
    'tower-token' = 'tower-token',
    'gamee' = 'gamee',
    'purefi-protocol' = 'purefi-protocol',
    'izumi-finance' = 'izumi-finance',
    'multichain' = 'multichain',
    'aipad' = 'aipad',
    'minati-coin' = 'minati-coin',
    'obyte' = 'obyte',
    'colossusxt' = 'colossusxt',
    'quadrantprotocol' = 'quadrantprotocol',
    'apm-coin' = 'apm-coin',
    'umbrella-network' = 'umbrella-network',
    'unmarshal' = 'unmarshal',
    'gains-associates' = 'gains-associates',
    'ispolink' = 'ispolink',
    'buying-com' = 'buying-com',
    'lovely-inu' = 'lovely-inu',
    'label-foundation' = 'label-foundation',
    'dappradar' = 'dappradar',
    'hillstone' = 'hillstone',
    'kunci-coin' = 'kunci-coin',
    'chirpley' = 'chirpley',
    'orbofi-ai' = 'orbofi-ai',
    'penpie' = 'penpie',
    'defi-yield-protocol-v2' = 'defi-yield-protocol-v2',
    'effect-ai' = 'effect-ai',
    'newyork-exchange' = 'newyork-exchange',
    'redfox-labs' = 'redfox-labs',
    'clintex-cti' = 'clintex-cti',
    'dehub' = 'dehub',
    'dose' = 'dose',
    'everrise' = 'everrise',
    'grove3' = 'grove3',
    'mysterium' = 'mysterium',
    'nervenetwork' = 'nervenetwork',
    'asta' = 'asta',
    'mettalex' = 'mettalex',
    'unbound' = 'unbound',
    'mirror-protocol' = 'mirror-protocol',
    'bondly' = 'bondly',
    'exeedme' = 'exeedme',
    'unore' = 'unore',
    'etherland' = 'etherland',
    'dotmoovs' = 'dotmoovs',
    'witch-token' = 'witch-token',
    'mars4' = 'mars4',
    'stream-coin' = 'stream-coin',
    'bbs-network' = 'bbs-network',
    'apebond' = 'apebond',
    'de-fi' = 'de-fi',
    'minter-network' = 'minter-network',
    'peakdefi' = 'peakdefi',
    'juggernaut' = 'juggernaut',
    'polkacity' = 'polkacity',
    'urus' = 'urus',
    'chain-guardians' = 'chain-guardians',
    'mogul-productions' = 'mogul-productions',
    'rainicorn' = 'rainicorn',
    'hi-dollar' = 'hi-dollar',
    'deepspace' = 'deepspace',
    'ariva' = 'ariva',
    'pawthereum' = 'pawthereum',
    'walken' = 'walken',
    'free-coin' = 'free-coin',
    'sparkpoint' = 'sparkpoint',
    'aryacoin' = 'aryacoin',
    'tenup' = 'tenup',
    'neutrino-usd' = 'neutrino-usd',
    'idena' = 'idena',
    'bird-money' = 'bird-money',
    'modefi' = 'modefi',
    'insurace' = 'insurace',
    'polkamon' = 'polkamon',
    'nabox' = 'nabox',
    'virtue-poker' = 'virtue-poker',
    'pleasure-coin' = 'pleasure-coin',
    'fear-nfts' = 'fear-nfts',
    'xcarnival' = 'xcarnival',
    'cirus-foundation' = 'cirus-foundation',
    'arix' = 'arix',
    'dingocoin' = 'dingocoin',
    'jumptoken' = 'jumptoken',
    'mongolnft-coin' = 'mongolnft-coin',
    'nanobyte-token' = 'nanobyte-token',
    'wombat-exchange' = 'wombat-exchange',
    'vv-token' = 'vv-token',
    'bitcore' = 'bitcore',
    'zap' = 'zap',
    'suterusu' = 'suterusu',
    'ardcoin' = 'ardcoin',
    'bitgesell' = 'bitgesell',
    'pnetwork' = 'pnetwork',
    'offshift' = 'offshift',
    'hakka-finance' = 'hakka-finance',
    'oin-finance' = 'oin-finance',
    'zeroswap' = 'zeroswap',
    'oddz' = 'oddz',
    'pussy-financial' = 'pussy-financial',
    'hot-cross' = 'hot-cross',
    'bistroo' = 'bistroo',
    'vempire-ddao' = 'vempire-ddao',
    'velaspad' = 'velaspad',
    'blockchain-monster-hunt' = 'blockchain-monster-hunt',
    'rainmaker-games' = 'rainmaker-games',
    'xy-finance' = 'xy-finance',
    'kaizen-finance' = 'kaizen-finance',
    'cheems-inu-new' = 'cheems-inu-new',
    'bismuth' = 'bismuth',
    'garlicoin' = 'garlicoin',
    'atomic-wallet-coin' = 'atomic-wallet-coin',
    'safe-haven' = 'safe-haven',
    'rio-defi' = 'rio-defi',
    'hyve' = 'hyve',
    'ubix-network' = 'ubix-network',
    'glitch' = 'glitch',
    'chronicle' = 'chronicle',
    'woonkly-power' = 'woonkly-power',
    'kattana' = 'kattana',
    'peri-finance' = 'peri-finance',
    'blockbank' = 'blockbank',
    'omchain' = 'omchain',
    'ethpad' = 'ethpad',
    'brokoli-network' = 'brokoli-network',
    'adapad' = 'adapad',
    'internet-of-energy-network' = 'internet-of-energy-network',
    'artwallet' = 'artwallet',
    'avocado-dao-token' = 'avocado-dao-token',
    'alvey-chain' = 'alvey-chain',
    'metrix-coin' = 'metrix-coin',
    'bitcoinz' = 'bitcoinz',
    'callisto-network' = 'callisto-network',
    'etho-protocol' = 'etho-protocol',
    's4fe' = 's4fe',
    'perlin' = 'perlin',
    'tokoin' = 'tokoin',
    'arcona' = 'arcona',
    'gather' = 'gather',
    'freeway-token' = 'freeway-token',
    'cyberfi' = 'cyberfi',
    'unido' = 'unido',
    'don-key' = 'don-key',
    'loser-coin' = 'loser-coin',
    'tiger-king-coin' = 'tiger-king-coin',
    'corite' = 'corite',
    'evrynet' = 'evrynet',
    'pulsepad' = 'pulsepad',
    'nfty-network' = 'nfty-network',
    'trollcoin' = 'trollcoin',
    'cotrader' = 'cotrader',
    'atromg8' = 'atromg8',
    'zelwin' = 'zelwin',
    'tixl-new' = 'tixl-new',
    'satt' = 'satt',
    'coreto' = 'coreto',
    'kangal' = 'kangal',
    'equalizer' = 'equalizer',
    'signata' = 'signata',
    'coldstack' = 'coldstack',
    'trade-race-manager' = 'trade-race-manager',
    'the-husl' = 'the-husl',
    'life-crypto' = 'life-crypto',
    'shirtum' = 'shirtum',
    'nftrade' = 'nftrade',
    'jetcoin' = 'jetcoin',
    'dovu' = 'dovu',
    'daps-coin' = 'daps-coin',
    'duckdaodime' = 'duckdaodime',
    'lead-wallet' = 'lead-wallet',
    'sake-token' = 'sake-token',
    'peanut' = 'peanut',
    'keyfi' = 'keyfi',
    'playcent' = 'playcent',
    'disbalancer' = 'disbalancer',
    '8pay' = '8pay',
    'raze-network' = 'raze-network',
    'impossible-finance' = 'impossible-finance',
    'triall' = 'triall',
    'sport' = 'sport',
    'polis' = 'polis',
    'golff' = 'golff',
    'easyfi' = 'easyfi',
    'acoconut' = 'acoconut',
    'curate' = 'curate',
    'finxflo' = 'finxflo',
    'apyswap' = 'apyswap',
    'shadows' = 'shadows',
    'ares-protocol' = 'ares-protocol',
    'dehive' = 'dehive',
    'genesis-shards' = 'genesis-shards',
    'mochi-market' = 'mochi-market',
    'dinox' = 'dinox',
    'kampay' = 'kampay',
    'wagyuswap' = 'wagyuswap',
    'ekta' = 'ekta',
    'chronobase' = 'chronobase',
    'orion-money' = 'orion-money',
    'idrx' = 'idrx',
    'nevacoin' = 'nevacoin',
    'shard' = 'shard',
    'zum-token' = 'zum-token',
    'all-best-ico' = 'all-best-ico',
    'phoenixdao' = 'phoenixdao',
    'spaceswap' = 'spaceswap',
    'yvs-finance' = 'yvs-finance',
    'duck-dao' = 'duck-dao',
    'prcy-coin' = 'prcy-coin',
    'totemfi' = 'totemfi',
    'rigel-protocol' = 'rigel-protocol',
    'formation-fi' = 'formation-fi',
    'shoefy' = 'shoefy',
    'mrweb-finance-v2' = 'mrweb-finance-v2',
    'sechain' = 'sechain',
    'extradna' = 'extradna',
    'gem-exchange-and-trading' = 'gem-exchange-and-trading',
    'bonfi' = 'bonfi',
    'corionx' = 'corionx',
    'gourmet-galaxy' = 'gourmet-galaxy',
    'landbox' = 'landbox',
    'rage-fan' = 'rage-fan',
    'workquest' = 'workquest',
    'unifarm' = 'unifarm',
    '1millionnfts' = '1millionnfts',
    'knit-finance' = 'knit-finance',
    'kwikswap' = 'kwikswap',
    'zcore' = 'zcore',
    'ecosc' = 'ecosc',
    'wmatic' = 'wmatic',
    'wrapped-solana' = 'wrapped-solana',
    'catcoin' = 'catcoin',
    'cryptogpt' = 'cryptogpt',
    'htx' = 'htx',
    'masa-network' = 'masa-network',
    'polyhedra-network' = 'polyhedra-network',
    'vndc' = 'vndc',
    'defi-yield-protocol' = 'defi-yield-protocol',
    'chex-token' = 'chex-token',
    'golcoin' = 'golcoin',
    'xen-crypto' = 'xen-crypto',
    'netmind-token' = 'netmind-token',
    'wrapped-multiversx-egld' = 'wrapped-multiversx-egld',
    'digital-financial-exchange' = 'digital-financial-exchange',
    'xana' = 'xana',
    'merit-circle' = 'merit-circle',
    'parex' = 'parex',
    'your' = 'your',
    'lif3' = 'lif3',
    'hippo-wallet-token' = 'hippo-wallet-token',
    'work-x' = 'work-x',
    'cat-boy' = 'cat-boy',
    'childrens-aid-foundation' = 'childrens-aid-foundation',
    'trex20' = 'trex20',
    'qanplatform' = 'qanplatform',
    'escointoken' = 'escointoken',
    'boringdao-new' = 'boringdao-new',
    'animal-concerts' = 'animal-concerts',
    'usdz' = 'usdz',
    'rebase-gg' = 'rebase-gg',
    'degen-zoo' = 'degen-zoo',
    'csas-ordinals' = 'csas-ordinals',
    'brz' = 'brz',
    'bitcoiva' = 'bitcoiva',
    'zedxion' = 'zedxion',
    'metagaming-guild' = 'metagaming-guild',
    'bit-store' = 'bit-store',
    'patex' = 'patex',
    'fideum' = 'fideum',
    'six' = 'six',
    'popsicle-finance' = 'popsicle-finance',
    'oobit' = 'oobit',
    'fegtoken-v2' = 'fegtoken-v2',
    'derp-dex' = 'derp-dex',
    'xrgb' = 'xrgb',
    'wadzpay-token' = 'wadzpay-token',
    'privapp-network' = 'privapp-network',
    'enjinstarter' = 'enjinstarter',
    'hypercycle' = 'hypercycle',
    'befe' = 'befe',
    'tribe-one' = 'tribe-one',
    'mcontent' = 'mcontent',
    'melos-studio' = 'melos-studio',
    'saitarealty' = 'saitarealty',
    'acria' = 'acria',
    'luffy-v2' = 'luffy-v2',
    'sophiaverse' = 'sophiaverse',
    'catecoin' = 'catecoin',
    'eqifi' = 'eqifi',
    'alienform' = 'alienform',
    'vera-network' = 'vera-network',
    'domi-online' = 'domi-online',
    'xox-labs' = 'xox-labs',
    'launchpool' = 'launchpool',
    'fbomb' = 'fbomb',
    'freela' = 'freela',
    'gold-fever' = 'gold-fever',
    'bloxmove' = 'bloxmove',
    'gm' = 'gm',
    'meanfi' = 'meanfi',
    'cleardao' = 'cleardao',
    'betswap-gg' = 'betswap-gg',
    'loopnetwork' = 'loopnetwork',
    'historydao' = 'historydao',
    'zeniq' = 'zeniq',
    'zamio' = 'zamio',
    'pancake-games' = 'pancake-games',
    'chihuahua-wtf' = 'chihuahua-wtf',
    'spellfire-re-master-the-magic' = 'spellfire-re-master-the-magic',
    'golden-inu' = 'golden-inu',
    'wrapped-kaspa' = 'wrapped-kaspa',
    'obortech' = 'obortech',
    'talken' = 'talken',
    'realm' = 'realm',
    'electric-vehicle-direct-currency' = 'electric-vehicle-direct-currency',
    'zombie-inu-v2' = 'zombie-inu-v2',
    'truepnl' = 'truepnl',
    'jax-network' = 'jax-network',
    'catcoin-token' = 'catcoin-token',
    'dynamite' = 'dynamite',
    'nexalt' = 'nexalt',
    'occamfi' = 'occamfi',
    'weld-money' = 'weld-money',
    'lunr-token' = 'lunr-token',
    'onus' = 'onus',
    'safemoon-v2' = 'safemoon-v2',
    'idefiyieldprotocol' = 'idefiyieldprotocol',
    'wrapped-rose' = 'wrapped-rose',
    'xodex' = 'xodex',
    'evanesco-network' = 'evanesco-network',
    'core-multichain' = 'core-multichain',
    'kiba-inu' = 'kiba-inu',
    'trace-network-labs' = 'trace-network-labs',
    'qmall-token' = 'qmall-token',
    'dark-energy-crystals' = 'dark-energy-crystals',
    'naos-finance' = 'naos-finance',
    'your-future-exchange' = 'your-future-exchange',
    'proximax' = 'proximax',
    'million' = 'million',
    'moon-rabbit' = 'moon-rabbit',
    'phantom-protocol' = 'phantom-protocol',
    'poken' = 'poken',
    'blockchainspace' = 'blockchainspace',
    'frontrow' = 'frontrow',
    'the-winkyverse' = 'the-winkyverse',
    'nav-coin' = 'nav-coin',
    'blind-boxes' = 'blind-boxes',
    'halfpizza' = 'halfpizza',
    'celestial' = 'celestial',
    'sphynx-bsc' = 'sphynx-bsc',
    'reunit-wallet' = 'reunit-wallet',
    'archethic' = 'archethic',
    'boy-x-highspeed' = 'boy-x-highspeed',
    'envoy-network' = 'envoy-network',
    'dinger-token' = 'dinger-token',
    'spacemine' = 'spacemine',
    'doge-kaki' = 'doge-kaki',
    'chain-games' = 'chain-games',
    'paralink-network' = 'paralink-network',
    'wrapped-one' = 'wrapped-one',
    'asia-coin' = 'asia-coin',
    'nasdex' = 'nasdex',
    'uplift' = 'uplift',
    'deus-finance-2' = 'deus-finance-2',
    'mimir-token' = 'mimir-token',
    'lion-token' = 'lion-token',
    'vent-finance' = 'vent-finance',
    'chainport' = 'chainport',
    'nexum' = 'nexum',
    'xeno-governance' = 'xeno-governance',
    'mazimatic-' = 'mazimatic-',
    'datahighway' = 'datahighway',
    'curio-governance' = 'curio-governance',
    'tosdis' = 'tosdis',
    'microtuber' = 'microtuber',
    'despace-protocol' = 'despace-protocol',
    'pixelverse' = 'pixelverse',
    'ridotto' = 'ridotto',
    'amazingteamdao' = 'amazingteamdao',
    'rentible' = 'rentible',
    'nftlaunch' = 'nftlaunch',
    'tcgcoin-2-0' = 'tcgcoin-2-0',
    'x-protocol' = 'x-protocol',
    'axl-inu' = 'axl-inu',
    '99starz' = '99starz',
    'brewlabs' = 'brewlabs',
    'okuru' = 'okuru',
    'definity' = 'definity',
    'flurry' = 'flurry',
    'allbridge' = 'allbridge',
    'fodl-finance' = 'fodl-finance',
    'govworld' = 'govworld',
    'buyholdearn' = 'buyholdearn',
    'pontoon' = 'pontoon',
    'bytz' = 'bytz',
    'prospective' = 'prospective',
    'tnc-coin' = 'tnc-coin',
    'bamboo-defi' = 'bamboo-defi',
    'sportsicon' = 'sportsicon',
    'resource-protocol' = 'resource-protocol',
    'build' = 'build',
    'genius' = 'genius',
    'wistaverse' = 'wistaverse',
    'babytrump' = 'babytrump',
    'cat-of-meme' = 'cat-of-meme',
    'jupiter' = 'jupiter',
    'bittorrent' = 'bittorrent',
    'piratecash' = 'piratecash',
    'bilira' = 'bilira',
    'dbx' = 'dbx',
    'metavpad' = 'metavpad',
    'linear-finance' = 'linear-finance',
    'osean' = 'osean',
    'telebtc-new' = 'telebtc-new',
    'sinergia' = 'sinergia',
    'creator-platform' = 'creator-platform',
    'playnity' = 'playnity',
    'icecream' = 'icecream',
    'besa-gaming' = 'besa-gaming',
    'cloudbric' = 'cloudbric',
    'lever-token' = 'lever-token',
    'nextype' = 'nextype',
    'galaxygoggle-dao' = 'galaxygoggle-dao',
    'jarvis-synthetic-euro' = 'jarvis-synthetic-euro',
    'shirushi-coin' = 'shirushi-coin',
    'bbc-gold-coin' = 'bbc-gold-coin',
    'tidex-token' = 'tidex-token',
    'zugacoin' = 'zugacoin',
    'metalswap' = 'metalswap',
    'cryptocart-v2' = 'cryptocart-v2',
    'froyo-games' = 'froyo-games',
    'frz-solar-system-coin' = 'frz-solar-system-coin',
    'mn-bridge' = 'mn-bridge',
    'connect-united' = 'connect-united',
    'anyswap' = 'anyswap',
    'luxurious-pro-network-token' = 'luxurious-pro-network-token',
    'quidd' = 'quidd',
    'anime-token' = 'anime-token',
    'shih-tzu' = 'shih-tzu',
    'betero' = 'betero',
    'gyrowin' = 'gyrowin',
    'banksocial' = 'banksocial',
    'crowdswap' = 'crowdswap',
    '8bit-chain' = '8bit-chain',
    'baby-golden-coin' = 'baby-golden-coin',
    'xido-finance' = 'xido-finance',
    'bob' = 'bob',
    'tokenclub' = 'tokenclub',
    '4thpillar-technologies' = '4thpillar-technologies',
    'ethereum-meta' = 'ethereum-meta',
    'barter' = 'barter',
    'liquidus' = 'liquidus',
    'jefe-token' = 'jefe-token',
    'farm-me' = 'farm-me',
    'fqswap' = 'fqswap',
    'ignore-fud' = 'ignore-fud',
    'popecoin' = 'popecoin',
    'zilpepe' = 'zilpepe',
    'zcore-network' = 'zcore-network',
    'swiftcash' = 'swiftcash',
    'bscstarter' = 'bscstarter',
    'ttcoin' = 'ttcoin',
    'goji-crypto' = 'goji-crypto',
    'ore-network' = 'ore-network',
    'yoshi-exchange' = 'yoshi-exchange',
    'centaurify' = 'centaurify',
    'pige-inu' = 'pige-inu',
    'talent-token' = 'talent-token',
    'coinwind' = 'coinwind',
    'blackcoin' = 'blackcoin',
    'tfs-token' = 'tfs-token',
    'bobcoin' = 'bobcoin',
    'enreachdao' = 'enreachdao',
    'halo-network' = 'halo-network',
    'bit2me' = 'bit2me',
    'wakanda-inu' = 'wakanda-inu',
    'paybolt' = 'paybolt',
    'main-community' = 'main-community',
    'ankr-reward-earning-matic' = 'ankr-reward-earning-matic',
    'upbots1' = 'upbots1',
    'gogolcoin' = 'gogolcoin',
    'pirate-coin-games' = 'pirate-coin-games',
    'safle' = 'safle',
    'stronghands-finance' = 'stronghands-finance',
    'nunu-spirits' = 'nunu-spirits',
    'coinspaid' = 'coinspaid',
    'crypto-gaming-united' = 'crypto-gaming-united',
    'hector-dao' = 'hector-dao',
    'primate' = 'primate',
    'stafi-staked-matic' = 'stafi-staked-matic',
    'american-shiba' = 'american-shiba',
    'yel-finance' = 'yel-finance',
    '42-coin' = '42-coin',
    'mainstream-for-the-underground' = 'mainstream-for-the-underground',
    'pop-network-token' = 'pop-network-token',
    'payaccept' = 'payaccept',
    'tozex' = 'tozex',
    'minter-hub' = 'minter-hub',
    'hokkaido-inu' = 'hokkaido-inu',
    'sokuswap' = 'sokuswap',
    'omnisea' = 'omnisea',
    'xv' = 'xv',
    'novacoin' = 'novacoin',
    'nolimitcoin' = 'nolimitcoin',
    'kambria' = 'kambria',
    'switch' = 'switch',
    'bitcoin-pro' = 'bitcoin-pro',
    'itam-games' = 'itam-games',
    'dexkit' = 'dexkit',
    'vidiachange' = 'vidiachange',
    'dohrnii' = 'dohrnii',
    'cloudtx' = 'cloudtx',
    'voltz' = 'voltz',
    'giftedhands' = 'giftedhands',
    'zipmex' = 'zipmex',
    'zoo-token' = 'zoo-token',
    'kingdefi' = 'kingdefi',
    'matrixswap' = 'matrixswap',
    'zenith-coin' = 'zenith-coin',
    'tor' = 'tor',
    'gemlink' = 'gemlink',
    'exohood' = 'exohood',
    'shibaken-finance' = 'shibaken-finance',
    'digitalnote' = 'digitalnote',
    'nft-stars' = 'nft-stars',
    'cogecoin' = 'cogecoin',
    'lunachow' = 'lunachow',
    'shibelon' = 'shibelon',
    'colizeum' = 'colizeum',
    'travel-care-v2' = 'travel-care-v2',
    'lacostoken' = 'lacostoken',
    'gm-holding' = 'gm-holding',
    'duet-protocol' = 'duet-protocol',
    'saudishibainu' = 'saudishibainu',
    'appleswap-ai' = 'appleswap-ai',
    'blazex' = 'blazex',
    'zeedex' = 'zeedex',
    'reflex' = 'reflex',
    'gera-coin' = 'gera-coin',
    'goldex-token' = 'goldex-token',
    'happy-fans' = 'happy-fans',
    'lightcoin-lhc' = 'lightcoin-lhc',
    'elf-wallet' = 'elf-wallet',
    'int-chain' = 'int-chain',
    'credit' = 'credit',
    'homeros' = 'homeros',
    'ijascoin' = 'ijascoin',
    'ttx-games' = 'ttx-games',
    'crystal-stones' = 'crystal-stones',
    'iclick-inu' = 'iclick-inu',
    'crown' = 'crown',
    'scaleswap' = 'scaleswap',
    'raptor-token' = 'raptor-token',
    'waves-ducks' = 'waves-ducks',
    'relay-token' = 'relay-token',
    'delta-theta' = 'delta-theta',
    'cross-chain-bridge-token' = 'cross-chain-bridge-token',
    'shido-inu' = 'shido-inu',
    'micromines' = 'micromines',
    'defiato' = 'defiato',
    'ammyi-coin' = 'ammyi-coin',
    'torg' = 'torg',
    'xave-coin' = 'xave-coin',
    'l3usd' = 'l3usd',
    'rage' = 'rage',
    'ormeus-coin' = 'ormeus-coin',
    'project-merge' = 'project-merge',
    'centurion' = 'centurion',
    'bitvolt' = 'bitvolt',
    'lamden' = 'lamden',
    'fantasygold' = 'fantasygold',
    'kalkulus' = 'kalkulus',
    'noku' = 'noku',
    'yolocash' = 'yolocash',
    'beetle-coin' = 'beetle-coin',
    'escroco-emerald' = 'escroco-emerald',
    'araw' = 'araw',
    'xrhodium' = 'xrhodium',
    'asian-fintech' = 'asian-fintech',
    'levolution' = 'levolution',
    'aircoins' = 'aircoins',
    'cofinex-coin' = 'cofinex-coin',
    'banque-universal' = 'banque-universal',
    'jboxcoin' = 'jboxcoin',
    'medicalveda' = 'medicalveda',
    'action-coin' = 'action-coin',
    'ebox' = 'ebox',
    'umi' = 'umi',
    'upfire' = 'upfire',
    'sensitrust' = 'sensitrust',
    'palgold' = 'palgold',
    'terablock' = 'terablock',
    'identity' = 'identity',
    'hibiki-finance' = 'hibiki-finance',
    'polkafantasy' = 'polkafantasy',
    'crosschain-iotx' = 'crosschain-iotx',
    'nftmall' = 'nftmall',
    'dao-invest' = 'dao-invest',
    'quarashi' = 'quarashi',
    'starchi' = 'starchi',
    'umi-digital' = 'umi-digital',
    'rbx' = 'rbx',
    'maga-coin' = 'maga-coin',
    'adonis' = 'adonis',
    'rice-wallet' = 'rice-wallet',
    'studyum' = 'studyum',
    'huh' = 'huh',
    'multi-chain-capital-new' = 'multi-chain-capital-new',
    'a4' = 'a4',
    'vcgamers' = 'vcgamers',
    'itam-cube' = 'itam-cube',
    'digimetaverse' = 'digimetaverse',
    'elyfi' = 'elyfi',
    'gocryptome' = 'gocryptome',
    'salmonation' = 'salmonation',
    'polysports' = 'polysports',
    'kyuubi' = 'kyuubi',
    'universal-store-of-value' = 'universal-store-of-value',
    'echelon' = 'echelon',
    'spiceusd' = 'spiceusd',
    'lif3-lshare' = 'lif3-lshare',
    'drac-token' = 'drac-token',
    'coin98-dollar' = 'coin98-dollar',
    'shibnobi-v2' = 'shibnobi-v2',
    'kudoe' = 'kudoe',
    'musicai' = 'musicai',
    'aves' = 'aves',
    'kylacoin' = 'kylacoin',
    'entropy-network' = 'entropy-network',
    'ogzclub' = 'ogzclub',
    'youwho' = 'youwho',
    'celo' = 'celo',
    'celo-dollar' = 'celo-dollar',
    'celo-euro' = 'celo-euro',
    'plastiks' = 'plastiks',
    'moola-celo-usd' = 'moola-celo-usd',
    'moola-celo-eur' = 'moola-celo-eur',
    'impactmarket' = 'impactmarket',
    'celo-brazilian-real' = 'celo-brazilian-real',
    'moola-market' = 'moola-market',
    'moola-celo' = 'moola-celo',
    'immortaldao-finance' = 'immortaldao-finance',
    'mobius-money' = 'mobius-money',
    'ari-swap' = 'ari-swap',
    'poof-cash' = 'poof-cash',
    'beefy-finance' = 'beefy-finance',
    'truefeedback' = 'truefeedback',
    'usd-coin-wormhole-from-ethereum' = 'usd-coin-wormhole-from-ethereum',
    'glo-dollar' = 'glo-dollar',
    'moss-carbon-credit' = 'moss-carbon-credit',
    'knoxedge' = 'knoxedge',
    'ethichub' = 'ethichub',
    'gooddollar' = 'gooddollar',
    'symmetric' = 'symmetric',
    'doge-eat-doge' = 'doge-eat-doge',
    'wrapped-wdoge' = 'wrapped-wdoge',
    'yodeswap' = 'yodeswap',
    'dogeshiba' = 'dogeshiba',
    'poo-doge' = 'poo-doge',
    'dogechain' = 'dogechain',
    'dogira-new' = 'dogira-new',
    'duckereum' = 'duckereum',
    'multiversx-egld' = 'multiversx-egld',
    'hatom' = 'hatom',
    'cantina-royale' = 'cantina-royale',
    'onedex' = 'onedex',
    'zoidpay' = 'zoidpay',
    'emorya-finance' = 'emorya-finance',
    'proteo-defi' = 'proteo-defi',
    'ta-da' = 'ta-da',
    'bh-network' = 'bh-network',
    'itheum' = 'itheum',
    'xexchange' = 'xexchange',
    'ashswap' = 'ashswap',
    'cyberpunk-city' = 'cyberpunk-city',
    'sense4fit' = 'sense4fit',
    'qowatt' = 'qowatt',
    'moove-protocol' = 'moove-protocol',
    'superciety' = 'superciety',
    'aerovek-aviation' = 'aerovek-aviation',
    'jexchange' = 'jexchange',
    'checkerchain' = 'checkerchain',
    'gocharge-tech' = 'gocharge-tech',
    'plata-network' = 'plata-network',
    'effort-economy' = 'effort-economy',
    'ofero-network' = 'ofero-network',
    'landboard' = 'landboard',
    'krogan' = 'krogan',
    'vital' = 'vital',
    'beskar' = 'beskar',
    'utrust' = 'utrust',
    'holoride' = 'holoride',
    'shiba-inu' = 'shiba-inu',
    'unus-sed-leo' = 'unus-sed-leo',
    'mantle' = 'mantle',
    'okb' = 'okb',
    'render' = 'render',
    'immutable-x' = 'immutable-x',
    'pepe' = 'pepe',
    'bitget-token-new' = 'bitget-token-new',
    'ethena' = 'ethena',
    'gala' = 'gala',
    'quant' = 'quant',
    'ondo-finance' = 'ondo-finance',
    'the-sandbox' = 'the-sandbox',
    'starknet-token' = 'starknet-token',
    'worldcoin-org' = 'worldcoin-org',
    'jasmy' = 'jasmy',
    'decentraland' = 'decentraland',
    'kucoin-token' = 'kucoin-token',
    'apecoin-ape' = 'apecoin-ape',
    'echelon-prime' = 'echelon-prime',
    'gatetoken' = 'gatetoken',
    'ribbon-finance' = 'ribbon-finance',
    'blur-token' = 'blur-token',
    'dydx-ethdydx' = 'dydx-ethdydx',
    'tether-gold' = 'tether-gold',
    'mx-token' = 'mx-token',
    'ocean-protocol' = 'ocean-protocol',
    'superfarm' = 'superfarm',
    'skale-network' = 'skale-network',
    'ether-fi-ethfi' = 'ether-fi-ethfi',
    'rocket-pool' = 'rocket-pool',
    'ethereum-name-service' = 'ethereum-name-service',
    '0x' = '0x',
    'holo' = 'holo',
    'ssv-network' = 'ssv-network',
    'livepeer' = 'livepeer',
    'origintrail' = 'origintrail',
    'biconomy' = 'biconomy',
    'golem-network-tokens' = 'golem-network-tokens',
    'aragon' = 'aragon',
    'illuvium' = 'illuvium',
    'loopring' = 'loopring',
    'amp' = 'amp',
    'metisdao' = 'metisdao',
    'threshold' = 'threshold',
    'arkham' = 'arkham',
    'meme' = 'meme',
    'zetachain' = 'zetachain',
    'pixels' = 'pixels',
    'yield-guild-games' = 'yield-guild-games',
    'reserve-rights' = 'reserve-rights',
    'steth' = 'steth',
    'wrapped-eeth' = 'wrapped-eeth',
    'rocket-pool-eth' = 'rocket-pool-eth',
    'marinade' = 'marinade',
    'coinbase-wrapped-staked-eth' = 'coinbase-wrapped-staked-eth',
    'orbler' = 'orbler',
    'rollbit-coin' = 'rollbit-coin',
    'paal-ai' = 'paal-ai',
    'hex' = 'hex',
    'propy' = 'propy',
    'telcoin' = 'telcoin',
    'tribe' = 'tribe',
    '0x0-ai-ai-smart-contract' = '0x0-ai-ai-smart-contract',
    'convex-finance' = 'convex-finance',
    'wilder-world' = 'wilder-world',
    'huobi-btc' = 'huobi-btc',
    'airtor-protocol' = 'airtor-protocol',
    'storj' = 'storj',
    'audius' = 'audius',
    'lcx' = 'lcx',
    'uma' = 'uma',
    'arcblock' = 'arcblock',
    'mog-coin' = 'mog-coin',
    'paypal-usd' = 'paypal-usd',
    'swissborg' = 'swissborg',
    'api3' = 'api3',
    'rss3' = 'rss3',
    'guildfi' = 'guildfi',
    'vvs-finance' = 'vvs-finance',
    'vanar' = 'vanar',
    'braintrust' = 'braintrust',
    'aevo' = 'aevo',
    'flex' = 'flex',
    'rlc' = 'rlc',
    'syntropy' = 'syntropy',
    'civic' = 'civic',
    'portal-gaming' = 'portal-gaming',
    'guild-of-guardians' = 'guild-of-guardians',
    'unizen' = 'unizen',
    'covalent' = 'covalent',
    'iq' = 'iq',
    'keep-network' = 'keep-network',
    'numeraire' = 'numeraire',
    'big-time' = 'big-time',
    'power-ledger' = 'power-ledger',
    'pundix-new' = 'pundix-new',
    'status' = 'status',
    'multibit' = 'multibit',
    'node-ai' = 'node-ai',
    'bone-shibaswap' = 'bone-shibaswap',
    'vulcan-forged-pyr' = 'vulcan-forged-pyr',
    'truefi-token' = 'truefi-token',
    'stasis-euro' = 'stasis-euro',
    'entangle' = 'entangle',
    'dent' = 'dent',
    'dione-protocol' = 'dione-protocol',
    'nym' = 'nym',
    'sovryn' = 'sovryn',
    'request' = 'request',
    'victoria-vr' = 'victoria-vr',
    'dkargo' = 'dkargo',
    'constitutiondao' = 'constitutiondao',
    'bitmart-token' = 'bitmart-token',
    'tokamak-network' = 'tokamak-network',
    'liquity-usd' = 'liquity-usd',
    'gemini-dollar' = 'gemini-dollar',
    'aurora-near' = 'aurora-near',
    'myria' = 'myria',
    'goldfinch-protocol' = 'goldfinch-protocol',
    'xyo' = 'xyo',
    'looksrare' = 'looksrare',
    'polygon-ecosystem-token' = 'polygon-ecosystem-token',
    'standard-tokenization-protocol' = 'standard-tokenization-protocol',
    'orchid' = 'orchid',
    'bifrost' = 'bifrost',
    'tectum' = 'tectum',
    'cudos' = 'cudos',
    'htx-token' = 'htx-token',
    'tokenlon-network-token' = 'tokenlon-network-token',
    'hifi-finance-new' = 'hifi-finance-new',
    'metal' = 'metal',
    'ethernity-chain' = 'ethernity-chain',
    'rarible' = 'rarible',
    'liquity' = 'liquity',
    'bancor' = 'bancor',
    'banana-gun' = 'banana-gun',
    'origin-protocol' = 'origin-protocol',
    'aavegotchi' = 'aavegotchi',
    'omg' = 'omg',
    'heroes-of-mavia' = 'heroes-of-mavia',
    'bazaars' = 'bazaars',
    'adventure-gold' = 'adventure-gold',
    'quarkchain' = 'quarkchain',
    'superrare' = 'superrare',
    'wrapped-nxm' = 'wrapped-nxm',
    'hippocrat' = 'hippocrat',
    'clearpool' = 'clearpool',
    'polymath-network' = 'polymath-network',
    'gitcoin' = 'gitcoin',
    'dimitra' = 'dimitra',
    'harrypotterobamasonic10inu-eth' = 'harrypotterobamasonic10inu-eth',
    'maple' = 'maple',
    'alethea-artificial-liquid-intelligence-token' = 'alethea-artificial-liquid-intelligence-token',
    'pepefork' = 'pepefork',
    'stormx' = 'stormx',
    'agoras-tokens' = 'agoras-tokens',
    'lever' = 'lever',
    'perpetual-protocol' = 'perpetual-protocol',
    'vaiot' = 'vaiot',
    'dora-factory-new' = 'dora-factory-new',
    'boson-protocol' = 'boson-protocol',
    'rally' = 'rally',
    'cobak-token' = 'cobak-token',
    'tominet' = 'tominet',
    'function-x' = 'function-x',
    'ultra' = 'ultra',
    'ampleforth-governance-token' = 'ampleforth-governance-token',
    'uquid-coin' = 'uquid-coin',
    'gods-unchained' = 'gods-unchained',
    'ren' = 'ren',
    'district0x' = 'district0x',
    'neopin' = 'neopin',
    'funtoken' = 'funtoken',
    'pandora-coin' = 'pandora-coin',
    'carry' = 'carry',
    'grok-erc' = 'grok-erc',
    'sweat-economy' = 'sweat-economy',
    'palm-ai' = 'palm-ai',
    'propchain' = 'propchain',
    'alchemix' = 'alchemix',
    'defi-pulse-index' = 'defi-pulse-index',
    'enzyme' = 'enzyme',
    'the-root-network' = 'the-root-network',
    'lyra-finance' = 'lyra-finance',
    'zero1-labs' = 'zero1-labs',
    'hunt' = 'hunt',
    'verasity' = 'verasity',
    '5ire' = '5ire',
    'moon-tropica' = 'moon-tropica',
    'ait-protocol' = 'ait-protocol',
    'strike' = 'strike',
    'firmachain' = 'firmachain',
    'playdapp' = 'playdapp',
    'niza-global' = 'niza-global',
    'susd' = 'susd',
    'morpheus-network' = 'morpheus-network',
    'reserve' = 'reserve',
    'whiteheart' = 'whiteheart',
    'idex' = 'idex',
    'euro-coin' = 'euro-coin',
    'assemble-protocol' = 'assemble-protocol',
    'cheqd' = 'cheqd',
    'cere-network' = 'cere-network',
    'doge-killer' = 'doge-killer',
    'league-of-kingdoms' = 'league-of-kingdoms',
    'isiklar-coin' = 'isiklar-coin',
    'kishu-inu' = 'kishu-inu',
    'sentinel-protocol' = 'sentinel-protocol',
    'moss-coin' = 'moss-coin',
    'hopr' = 'hopr',
    'botto' = 'botto',
    'vectorspace-ai' = 'vectorspace-ai',
    'litentry' = 'litentry',
    'radix' = 'radix',
    'paragonsdao' = 'paragonsdao',
    'vegaprotocol' = 'vegaprotocol',
    'tether-eurt' = 'tether-eurt',
    'gelato' = 'gelato',
    'polyswarm' = 'polyswarm',
    'xsgd' = 'xsgd',
    'celsius' = 'celsius',
    'selfkey' = 'selfkey',
    'syncus' = 'syncus',
    'stratos' = 'stratos',
    'avinoc' = 'avinoc',
    'turbo' = 'turbo',
    'bitmax-token' = 'bitmax-token',
    'stronghold-token' = 'stronghold-token',
    'onbuff' = 'onbuff',
    'nft-worlds' = 'nft-worlds',
    'smart-layer-network' = 'smart-layer-network',
    'keep3rv1' = 'keep3rv1',
    'altava' = 'altava',
    'stafi' = 'stafi',
    'qredo' = 'qredo',
    'aura-finance' = 'aura-finance',
    'fox-token' = 'fox-token',
    'across-protocol' = 'across-protocol',
    'crypto-asset-governance-alliance' = 'crypto-asset-governance-alliance',
    'student-coin' = 'student-coin',
    'quickswap' = 'quickswap',
    'pointpay' = 'pointpay',
    'nuco-cloud' = 'nuco-cloud',
    'swarm-markets' = 'swarm-markets',
    'iskra' = 'iskra',
    'kryll' = 'kryll',
    'shido-inu-new' = 'shido-inu-new',
    'dejitaru-tsuka' = 'dejitaru-tsuka',
    'blocksquare-token' = 'blocksquare-token',
    'akropolis' = 'akropolis',
    'get-protocol' = 'get-protocol',
    'ampleforth' = 'ampleforth',
    'rakon' = 'rakon',
    'hard-protocol' = 'hard-protocol',
    'inverse-finance' = 'inverse-finance',
    'usdk' = 'usdk',
    'wagmi-game-2' = 'wagmi-game-2',
    'ufo-gaming' = 'ufo-gaming',
    'shiba-predator' = 'shiba-predator',
    'cryptex-finance' = 'cryptex-finance',
    'ethereum-swarm' = 'ethereum-swarm',
    'airswap' = 'airswap',
    'optimus-ai' = 'optimus-ai',
    'rabbitx' = 'rabbitx',
    'barnbridge' = 'barnbridge',
    'star-link' = 'star-link',
    'kleros' = 'kleros',
    'zkml' = 'zkml',
    'yield-app' = 'yield-app',
    'gameswift' = 'gameswift',
    'castello-coin' = 'castello-coin',
    'asterix-labs' = 'asterix-labs',
    'ichi' = 'ichi',
    'pepe-2-0' = 'pepe-2-0',
    'locus-chain' = 'locus-chain',
    'cosplay-token' = 'cosplay-token',
    'arianee-protocol' = 'arianee-protocol',
    'aventus' = 'aventus',
    'hermez-network' = 'hermez-network',
    'shping' = 'shping',
    'zusd' = 'zusd',
    'biopassport-token' = 'biopassport-token',
    'dragonchain' = 'dragonchain',
    'moby' = 'moby',
    'viberate' = 'viberate',
    'bubblefong' = 'bubblefong',
    'polaris-share' = 'polaris-share',
    'volumint' = 'volumint',
    'prisma-mkusd' = 'prisma-mkusd',
    'coinweb' = 'coinweb',
    'quiztok' = 'quiztok',
    'metacade' = 'metacade',
    'spectre-ai' = 'spectre-ai',
    'oax' = 'oax',
    'voyager-token' = 'voyager-token',
    'bad-idea-ai' = 'bad-idea-ai',
    'cult-dao' = 'cult-dao',
    'crustnetwork' = 'crustnetwork',
    'spookyswap' = 'spookyswap',
    'thrupenny' = 'thrupenny',
    'fei-usd' = 'fei-usd',
    'atlas-navi' = 'atlas-navi',
    'deversifi' = 'deversifi',
    'solve' = 'solve',
    'leisuremeta' = 'leisuremeta',
    'image-generation-ai' = 'image-generation-ai',
    'bitrock' = 'bitrock',
    'ferro' = 'ferro',
    'gyen' = 'gyen',
    'hegic' = 'hegic',
    'epns' = 'epns',
    'unibot-eth' = 'unibot-eth',
    'latoken' = 'latoken',
    'everest' = 'everest',
    'powerpool' = 'powerpool',
    'prometheum-prodigy' = 'prometheum-prodigy',
    'befi-labs' = 'befi-labs',
    'akita-inu' = 'akita-inu',
    'unicly-cryptopunks-collection' = 'unicly-cryptopunks-collection',
    'somnium-space-cubes' = 'somnium-space-cubes',
    'jesus-toys' = 'jesus-toys',
    'senate' = 'senate',
    'unibright' = 'unibright',
    'realio-network' = 'realio-network',
    'robonomics-network' = 'robonomics-network',
    'nestree' = 'nestree',
    'big-data-protocol' = 'big-data-protocol',
    'pibble' = 'pibble',
    'landx-finance' = 'landx-finance',
    'winnerz' = 'winnerz',
    'ai-analysis-token' = 'ai-analysis-token',
    'ooki-protocol' = 'ooki-protocol',
    'konnect' = 'konnect',
    'choise' = 'choise',
    'bepro-network' = 'bepro-network',
    'lambda' = 'lambda',
    'husd' = 'husd',
    'lossless' = 'lossless',
    'angola' = 'angola',
    'origin-dollar-governance' = 'origin-dollar-governance',
    'rai-finance-sofi' = 'rai-finance-sofi',
    'arkitech' = 'arkitech',
    'props' = 'props',
    'nftx' = 'nftx',
    'project-with' = 'project-with',
    'eml-protocol' = 'eml-protocol',
    'decentral-games' = 'decentral-games',
    'augur' = 'augur',
    'hoge-finance' = 'hoge-finance',
    'babb' = 'babb',
    'zeon' = 'zeon',
    'valor-token' = 'valor-token',
    'dacxi' = 'dacxi',
    'polkafoundry' = 'polkafoundry',
    'stfx' = 'stfx',
    'linker-coin' = 'linker-coin',
    'xdefi-wallet' = 'xdefi-wallet',
    'orbcity' = 'orbcity',
    'gameswap' = 'gameswap',
    'bitkan' = 'bitkan',
    'origin-dollar' = 'origin-dollar',
    'mongcoin' = 'mongcoin',
    'efinity' = 'efinity',
    'minted' = 'minted',
    'pluton' = 'pluton',
    'lithium' = 'lithium',
    'geeq' = 'geeq',
    'bitforex-token' = 'bitforex-token',
    'era-swap' = 'era-swap',
    'mancium' = 'mancium',
    'shina-inu' = 'shina-inu',
    'wombat-web-3-gaming-platform' = 'wombat-web-3-gaming-platform',
    'lush-ai' = 'lush-ai',
    'havah' = 'havah',
    'masq' = 'masq',
    'receive-access-ecosystem' = 'receive-access-ecosystem',
    'muse' = 'muse',
    'unisocks' = 'unisocks',
    'vixco' = 'vixco',
    'hourglass' = 'hourglass',
    'gensokishis-metaverse' = 'gensokishis-metaverse',
    'blockchain-brawlers' = 'blockchain-brawlers',
    'beoble' = 'beoble',
    'smartkey' = 'smartkey',
    'pintu-token' = 'pintu-token',
    'breederdao' = 'breederdao',
    'adappter-token' = 'adappter-token',
    'dhedge-dao' = 'dhedge-dao',
    'king-dag' = 'king-dag',
    'veloce-vext' = 'veloce-vext',
    'shopx-token' = 'shopx-token',
    'netvrk' = 'netvrk',
    'presearch' = 'presearch',
    'whale' = 'whale',
    'dappstore' = 'dappstore',
    'tamadoge' = 'tamadoge',
    'darwinia-network' = 'darwinia-network',
    'mileverse' = 'mileverse',
    'lootbot' = 'lootbot',
    'aethergames' = 'aethergames',
    'pawswap' = 'pawswap',
    'zkbase' = 'zkbase',
    'emirex-token' = 'emirex-token',
    'bitrue-coin' = 'bitrue-coin',
    'real-smurf-cat-eth' = 'real-smurf-cat-eth',
    'bag-coin' = 'bag-coin',
    'xels' = 'xels',
    'dad' = 'dad',
    'pikachu' = 'pikachu',
    'tomtomcoin' = 'tomtomcoin',
    'orbit-chain' = 'orbit-chain',
    'etherisc' = 'etherisc',
    'sylo' = 'sylo',
    'colawork' = 'colawork',
    'govi' = 'govi',
    'coupon-assets' = 'coupon-assets',
    'stat' = 'stat',
    'revain' = 'revain',
    'rari-governance-token' = 'rari-governance-token',
    'wom-protocol' = 'wom-protocol',
    'chainbing' = 'chainbing',
    'blocery' = 'blocery',
    'polkadex' = 'polkadex',
    'perion' = 'perion',
    'prime-numbers' = 'prime-numbers',
    'impt' = 'impt',
    'onooks' = 'onooks',
    'senso' = 'senso',
    'b-cube-ai' = 'b-cube-ai',
    'rai' = 'rai',
    'dust-protocol' = 'dust-protocol',
    'playfuel' = 'playfuel',
    'shiryo-inu' = 'shiryo-inu',
    'bware-labs' = 'bware-labs',
    'suncontract' = 'suncontract',
    'wefi' = 'wefi',
    'super-trump-io' = 'super-trump-io',
    'verse-token' = 'verse-token',
    'unification' = 'unification',
    'strips-finance' = 'strips-finance',
    'amond' = 'amond',
    'moneytoken' = 'moneytoken',
    'gny' = 'gny',
    'spheroid-universe' = 'spheroid-universe',
    'vesper' = 'vesper',
    'crpt' = 'crpt',
    'vabble' = 'vabble',
    'axis-token' = 'axis-token',
    'opendao' = 'opendao',
    'kiboshib' = 'kiboshib',
    'electric-vehicle-zone' = 'electric-vehicle-zone',
    'morpheus-labs' = 'morpheus-labs',
    'meme-ai-token' = 'meme-ai-token',
    'sheb' = 'sheb',
    'neighbourhoods' = 'neighbourhoods',
    'acent' = 'acent',
    'mindai' = 'mindai',
    'spx6900' = 'spx6900',
    'efforce' = 'efforce',
    'bend-dao' = 'bend-dao',
    'unix-gaming' = 'unix-gaming',
    'dfyn-network' = 'dfyn-network',
    'baasid' = 'baasid',
    'kine' = 'kine',
    'gorilla-token' = 'gorilla-token',
    'lattice-token' = 'lattice-token',
    'triumphx' = 'triumphx',
    'dafi-protocol' = 'dafi-protocol',
    'dxchain-token' = 'dxchain-token',
    'kylin' = 'kylin',
    'exnetwork-token' = 'exnetwork-token',
    'wecan-group' = 'wecan-group',
    'defrogs' = 'defrogs',
    'yaxis' = 'yaxis',
    'pickle-finance' = 'pickle-finance',
    'saffron-finance' = 'saffron-finance',
    'cropbytes' = 'cropbytes',
    'bolt' = 'bolt',
    'polkamarkets' = 'polkamarkets',
    'lympo' = 'lympo',
    'maga-vp' = 'maga-vp',
    'standard-protocol' = 'standard-protocol',
    'jobchain' = 'jobchain',
    'niftyx-protocol' = 'niftyx-protocol',
    'btse' = 'btse',
    'meta' = 'meta',
    'vestchain' = 'vestchain',
    'upcx' = 'upcx',
    'antimatter' = 'antimatter',
    'vidya' = 'vidya',
    'castle-of-blackwater' = 'castle-of-blackwater',
    'unilayer' = 'unilayer',
    'civilization' = 'civilization',
    'lgcy-network' = 'lgcy-network',
    'baanx' = 'baanx',
    'vnx-euro' = 'vnx-euro',
    'green-satoshi-token-eth' = 'green-satoshi-token-eth',
    'spacechain' = 'spacechain',
    'populous' = 'populous',
    'drep-new' = 'drep-new',
    'devai' = 'devai',
    'idle' = 'idle',
    'launchblock' = 'launchblock',
    'bifi' = 'bifi',
    'ziktalk' = 'ziktalk',
    'aurusx' = 'aurusx',
    'tenx' = 'tenx',
    'razor-network' = 'razor-network',
    'my-crypto-heroes' = 'my-crypto-heroes',
    'damex-token' = 'damex-token',
    'zelix' = 'zelix',
    'k21' = 'k21',
    'tap' = 'tap',
    'salt' = 'salt',
    'max-exchange-token' = 'max-exchange-token',
    'grape' = 'grape',
    'derivadao' = 'derivadao',
    'ally' = 'ally',
    'iq-protocol' = 'iq-protocol',
    'vnx-swiss-franc' = 'vnx-swiss-franc',
    'fenerbahce-token' = 'fenerbahce-token',
    'kek' = 'kek',
    'attila' = 'attila',
    'skeb-coin' = 'skeb-coin',
    'alvara-protocol' = 'alvara-protocol',
    'konomi-network' = 'konomi-network',
    'yam' = 'yam',
    'integral' = 'integral',
    'monavale' = 'monavale',
    'elmoerc' = 'elmoerc',
    'crust-shadow' = 'crust-shadow',
    'humaniq' = 'humaniq',
    'xdai' = 'xdai',
    'convergence' = 'convergence',
    'top' = 'top',
    'hipenguins' = 'hipenguins',
    'blox-pro' = 'blox-pro',
    'base-protocol' = 'base-protocol',
    'pumlx' = 'pumlx',
    'thorstarter' = 'thorstarter',
    'neutrino-system-base-token' = 'neutrino-system-base-token',
    'breezecoin' = 'breezecoin',
    'leverj-gluon' = 'leverj-gluon',
    'pillar' = 'pillar',
    'hypr-network' = 'hypr-network',
    'eden-network' = 'eden-network',
    'idavoll-network' = 'idavoll-network',
    'unistake' = 'unistake',
    'xmon' = 'xmon',
    'fringe-finance' = 'fringe-finance',
    'contracoin' = 'contracoin',
    'geojam-token' = 'geojam-token',
    'nvidia-inu' = 'nvidia-inu',
    'all-in' = 'all-in',
    'eminer' = 'eminer',
    'nord-finance' = 'nord-finance',
    'dodreamchain' = 'dodreamchain',
    'safemoon-inu' = 'safemoon-inu',
    'keeperdao' = 'keeperdao',
    'shiba-saga' = 'shiba-saga',
    '3space-art' = '3space-art',
    'aurigami' = 'aurigami',
    'reflexer-ungovernance-token' = 'reflexer-ungovernance-token',
    'waltonchain' = 'waltonchain',
    'elitium' = 'elitium',
    'maps' = 'maps',
    'exmo-coin' = 'exmo-coin',
    'throne' = 'throne',
    'fidex-token' = 'fidex-token',
    'dentacoin' = 'dentacoin',
    'monetha' = 'monetha',
    '0xbtc' = '0xbtc',
    'hidoodles' = 'hidoodles',
    'polkapets' = 'polkapets',
    'palette' = 'palette',
    'dragonball-z-tribute' = 'dragonball-z-tribute',
    'decentr' = 'decentr',
    'abyss' = 'abyss',
    'unfederalreserve' = 'unfederalreserve',
    'sync-network' = 'sync-network',
    'vnx-gold' = 'vnx-gold',
    'locgame' = 'locgame',
    'alphabet-erc404' = 'alphabet-erc404',
    'tranche-finance' = 'tranche-finance',
    'meme-alliance' = 'meme-alliance',
    'cat-token' = 'cat-token',
    'te-food' = 'te-food',
    'acquire-fi' = 'acquire-fi',
    'c20' = 'c20',
    'salad' = 'salad',
    'avatly-new' = 'avatly-new',
    'dexgame' = 'dexgame',
    'databroker' = 'databroker',
    'strong' = 'strong',
    'klubcoin' = 'klubcoin',
    'bonuscloud' = 'bonuscloud',
    'oggy-inu-eth' = 'oggy-inu-eth',
    'aurora' = 'aurora',
    'ojamu' = 'ojamu',
    'aga' = 'aga',
    'fsw-token' = 'fsw-token',
    'genaro-network' = 'genaro-network',
    'gamecredits' = 'gamecredits',
    'dawn-protocol' = 'dawn-protocol',
    'olyseum' = 'olyseum',
    'king' = 'king',
    'metamonkeyai' = 'metamonkeyai',
    'himfers' = 'himfers',
    'furucombo' = 'furucombo',
    'sora' = 'sora',
    'finblox' = 'finblox',
    'earn-network' = 'earn-network',
    'plotx' = 'plotx',
    'reapchain' = 'reapchain',
    'opium' = 'opium',
    'blank-wallet' = 'blank-wallet',
    'shib-original-vision' = 'shib-original-vision',
    'trustverse' = 'trustverse',
    'refereum' = 'refereum',
    'hiens3' = 'hiens3',
    'xerc20-pro' = 'xerc20-pro',
    'keystone-of-opportunity-knowledge' = 'keystone-of-opportunity-knowledge',
    'handy' = 'handy',
    'oxygen' = 'oxygen',
    'the-gold-token' = 'the-gold-token',
    'voxel-x-network' = 'voxel-x-network',
    'bridge-mutual' = 'bridge-mutual',
    'linka' = 'linka',
    'waifuai' = 'waifuai',
    'digg' = 'digg',
    '404aliens' = '404aliens',
    'alpha-token' = 'alpha-token',
    'parachute' = 'parachute',
    'burency' = 'burency',
    'public-mint' = 'public-mint',
    'hollygold' = 'hollygold',
    'hanchain' = 'hanchain',
    'ubd-network' = 'ubd-network',
    'domraider' = 'domraider',
    'bobs-repair' = 'bobs-repair',
    'murall' = 'murall',
    'rapidz' = 'rapidz',
    'papi' = 'papi',
    'hifidenza' = 'hifidenza',
    'swerve' = 'swerve',
    'inex-project' = 'inex-project',
    'harrypotterobamapacman8inu' = 'harrypotterobamapacman8inu',
    'octofi' = 'octofi',
    'hipunks' = 'hipunks',
    'titanswap' = 'titanswap',
    'dexa-coin' = 'dexa-coin',
    'ruff' = 'ruff',
    'cybervein' = 'cybervein',
    'define' = 'define',
    'balpha' = 'balpha',
    'float-protocol' = 'float-protocol',
    'cook-protocol' = 'cook-protocol',
    'fxdx-exchange' = 'fxdx-exchange',
    'the-qwan' = 'the-qwan',
    'hifluf-fractionalized-nft-kucoin' = 'hifluf-fractionalized-nft-kucoin',
    'swingby' = 'swingby',
    'duckies-the-canary-network-for-yellow' = 'duckies-the-canary-network-for-yellow',
    'zoracles' = 'zoracles',
    'hisquiggle' = 'hisquiggle',
    'fantomgo' = 'fantomgo',
    'nerd-bot' = 'nerd-bot',
    'hiazuki' = 'hiazuki',
    '88mph' = '88mph',
    'xeno-token' = 'xeno-token',
    'digital-reserve-currency' = 'digital-reserve-currency',
    'hibayc' = 'hibayc',
    'ubex' = 'ubex',
    'empty-set-dollar' = 'empty-set-dollar',
    'powertrade-fuel' = 'powertrade-fuel',
    'shopping-io-token' = 'shopping-io-token',
    'beyond-protocol' = 'beyond-protocol',
    'polkalokr' = 'polkalokr',
    'himayc' = 'himayc',
    'herocoin' = 'herocoin',
    'hicoolcats-fractionalized-nft-kucoin' = 'hicoolcats-fractionalized-nft-kucoin',
    'darwinia-commitment-token' = 'darwinia-commitment-token',
    'basis-cash' = 'basis-cash',
    'cryptosoul' = 'cryptosoul',
    'tidal-finance' = 'tidal-finance',
    'internxt' = 'internxt',
    'traxx' = 'traxx',
    'gemie' = 'gemie',
    'blockzerolabs' = 'blockzerolabs',
    'seigniorage-shares' = 'seigniorage-shares',
    'stake-dao' = 'stake-dao',
    'sohotrn' = 'sohotrn',
    'rocki' = 'rocki',
    'ig-gold' = 'ig-gold',
    'axis-defi' = 'axis-defi',
    'himeebits' = 'himeebits',
    'higazers' = 'higazers',
    'adreward' = 'adreward',
    'conun' = 'conun',
    'temdao' = 'temdao',
    'sora-validator-token' = 'sora-validator-token',
    'fractal' = 'fractal',
    'snowswap' = 'snowswap',
    'jarvis-network' = 'jarvis-network',
    'bitball' = 'bitball',
    'hedget' = 'hedget',
    'e-money-coin' = 'e-money-coin',
    'apy-finance' = 'apy-finance',
    'yfdai-finance' = 'yfdai-finance',
    'hifriends' = 'hifriends',
    'hiundead' = 'hiundead',
    'cyclub' = 'cyclub',
    'hirenga' = 'hirenga',
    'definer' = 'definer',
    'governor-dao' = 'governor-dao',
    'earthfund' = 'earthfund',
    'pawtocol' = 'pawtocol',
    'rush-coin' = 'rush-coin',
    'busy' = 'busy',
    'vodra' = 'vodra',
    '404-bakery' = '404-bakery',
    'celeum' = 'celeum',
    'hibeanz' = 'hibeanz',
    'tokenbot' = 'tokenbot',
    'hisand33' = 'hisand33',
    'orao-network' = 'orao-network',
    'polinate' = 'polinate',
    'hashgard' = 'hashgard',
    'hiclonex' = 'hiclonex',
    'spheresxs' = 'spheresxs',
    'hivalhalla-fractionalized-nft-kucoin' = 'hivalhalla-fractionalized-nft-kucoin',
    'pymedao' = 'pymedao',
    'bidipass' = 'bidipass',
    'hiseals' = 'hiseals',
    'xceltoken-plus' = 'xceltoken-plus',
    'misbloc' = 'misbloc',
    'geodb' = 'geodb',
    'drops' = 'drops',
    'dogeswap' = 'dogeswap',
    'phoenix-token' = 'phoenix-token',
    'creaticles' = 'creaticles',
    'castweet' = 'castweet',
    'himoonbirds' = 'himoonbirds',
    'hiens4' = 'hiens4',
    'bitball-treasure' = 'bitball-treasure',
    'etha-lend' = 'etha-lend',
    'coinsbit-token' = 'coinsbit-token',
    'sentinel-chain' = 'sentinel-chain',
    'blockchain-cuties-universe' = 'blockchain-cuties-universe',
    'hiod' = 'hiod',
    'hiodbs' = 'hiodbs',
    'everycoin' = 'everycoin',
    'hairyplotterftx' = 'hairyplotterftx',
    'blockburn' = 'blockburn',
    'rublix' = 'rublix',
    'yield-protocol' = 'yield-protocol',
    'liquid-apps' = 'liquid-apps',
    'opacity' = 'opacity',
    'flixxo' = 'flixxo',
    'bitspawn-protocol' = 'bitspawn-protocol',
    'beyond-finance' = 'beyond-finance',
    'tokenbox' = 'tokenbox',
    'synchrobitcoin' = 'synchrobitcoin',
    'ormeus-cash' = 'ormeus-cash',
    'spume' = 'spume',
    'nurifootball' = 'nurifootball',
    'polkarare' = 'polkarare',
    'red-token' = 'red-token',
    'jigstack' = 'jigstack',
    'boutspro' = 'boutspro',
    'nsure-network' = 'nsure-network',
    'uptrennd' = 'uptrennd',
    'tapmydata' = 'tapmydata',
    'pyro-network' = 'pyro-network',
    'azuki' = 'azuki',
    'alphr-finance' = 'alphr-finance',
    'melalie' = 'melalie',
    'unicorn-token' = 'unicorn-token',
    'archimedes-finance' = 'archimedes-finance',
    'fracton-protocol-hibakc' = 'fracton-protocol-hibakc',
    'skyrim-finance' = 'skyrim-finance',
    'innovative-bioresearch-coin' = 'innovative-bioresearch-coin',
    'chimpion' = 'chimpion',
    'niftify' = 'niftify',
    'guider' = 'guider',
    'rewardiqa' = 'rewardiqa',
    'indexed-finance' = 'indexed-finance',
    'connectome' = 'connectome',
    'sharedstake' = 'sharedstake',
    'fuze-token' = 'fuze-token',
    'kcash' = 'kcash',
    'hxacoin' = 'hxacoin',
    'carecoinwellness' = 'carecoinwellness',
    'kuverit' = 'kuverit',
    'elementeum' = 'elementeum',
    'finance-blocks' = 'finance-blocks',
    'crdt' = 'crdt',
    'mesefa' = 'mesefa',
    'crystal-token' = 'crystal-token',
    'askobar-network' = 'askobar-network',
    'prema' = 'prema',
    's-finance' = 's-finance',
    'halodao' = 'halodao',
    'lua-token' = 'lua-token',
    'dracula-token' = 'dracula-token',
    'blackpearl-chain' = 'blackpearl-chain',
    'troll-new' = 'troll-new',
    'whitebit-token' = 'whitebit-token',
    'kelp-dao-restaked-eth' = 'kelp-dao-restaked-eth',
    'bitcoin-cats' = 'bitcoin-cats',
    'airbloc' = 'airbloc',
    'boysclubandy' = 'boysclubandy',
    'hugewin' = 'hugewin',
    'apeiron' = 'apeiron',
    'ordiswap' = 'ordiswap',
    'tooly-i-am-king' = 'tooly-i-am-king',
    'pufeth' = 'pufeth',
    'dogwifsaudihat' = 'dogwifsaudihat',
    'truflation' = 'truflation',
    'normie' = 'normie',
    'smiley-eth' = 'smiley-eth',
    'altered-state-token' = 'altered-state-token',
    'ipverse-eth' = 'ipverse-eth',
    'gala-music' = 'gala-music',
    'mantle-staked-ether' = 'mantle-staked-ether',
    'savings-dai' = 'savings-dai',
    'zuzalu-inu' = 'zuzalu-inu',
    'apu-apustaja' = 'apu-apustaja',
    'joseon-mun' = 'joseon-mun',
    'pepecoin' = 'pepecoin',
    'edelcoin' = 'edelcoin',
    'sora-ai' = 'sora-ai',
    'enterbutton' = 'enterbutton',
    'prisma-finance' = 'prisma-finance',
    'restaked-swell-ethereum' = 'restaked-swell-ethereum',
    'angle-protocol' = 'angle-protocol',
    'eesee' = 'eesee',
    'vitalik-smart-gas' = 'vitalik-smart-gas',
    'satoshivm' = 'satoshivm',
    'bob1' = 'bob1',
    'connext' = 'connext',
    'turtsat' = 'turtsat',
    'usdp' = 'usdp',
    'virtual-protocol' = 'virtual-protocol',
    'bobo-coin' = 'bobo-coin',
    'fluence-network' = 'fluence-network',
    'bitstable' = 'bitstable',
    'blockgames' = 'blockgames',
    'the-next-gem' = 'the-next-gem',
    'arcana' = 'arcana',
    'basedai' = 'basedai',
    'p2p-solutions-foundation' = 'p2p-solutions-foundation',
    'lbk' = 'lbk',
    'blendr-network' = 'blendr-network',
    'autonolas' = 'autonolas',
    'pdx-coin' = 'pdx-coin',
    'dgi-game' = 'dgi-game',
    'chainflip' = 'chainflip',
    'gearbox-protocol' = 'gearbox-protocol',
    'everyworld' = 'everyworld',
    'republik' = 'republik',
    'railgun' = 'railgun',
    'reality-metaverse' = 'reality-metaverse',
    'wrapped-tao' = 'wrapped-tao',
    'structure-finance' = 'structure-finance',
    'love-hate-inu' = 'love-hate-inu',
    'xtcom-token' = 'xtcom-token',
    'planet-mojo' = 'planet-mojo',
    'brett-on-eth' = 'brett-on-eth',
    'taproot-exchange' = 'taproot-exchange',
    'flooring-lab-credit' = 'flooring-lab-credit',
    'tipcoineth' = 'tipcoineth',
    'chain-swap' = 'chain-swap',
    'massive-protocol' = 'massive-protocol',
    'phemex' = 'phemex',
    'pooh' = 'pooh',
    'hashai' = 'hashai',
    'metababy' = 'metababy',
    'spectra-chain' = 'spectra-chain',
    'opsec' = 'opsec',
    'nettensor' = 'nettensor',
    'destra-network' = 'destra-network',
    'astropepex' = 'astropepex',
    'wojak' = 'wojak',
    'fellaz' = 'fellaz',
    'signin' = 'signin',
    'yoracle-ai' = 'yoracle-ai',
    'staked-eth' = 'staked-eth',
    'avav' = 'avav',
    'fanc' = 'fanc',
    'ai-x' = 'ai-x',
    'curve-fi-frax-usdc' = 'curve-fi-frax-usdc',
    'australian-dollar-token' = 'australian-dollar-token',
    'vps-ai' = 'vps-ai',
    'caw' = 'caw',
    'subquery-network' = 'subquery-network',
    'convex-crv' = 'convex-crv',
    'i-love-puppies' = 'i-love-puppies',
    'shuffle' = 'shuffle',
    'apf-coin' = 'apf-coin',
    'rivusdao' = 'rivusdao',
    'refund' = 'refund',
    'euler-finance' = 'euler-finance',
    'rogin-ai' = 'rogin-ai',
    'perro-dinero' = 'perro-dinero',
    'aigentx' = 'aigentx',
    'linqai' = 'linqai',
    'usk' = 'usk',
    'zyncoin' = 'zyncoin',
    'stader-ethx' = 'stader-ethx',
    'terrausd-wormhole' = 'terrausd-wormhole',
    'akt-io' = 'akt-io',
    'velar' = 'velar',
    'inscribe' = 'inscribe',
    'lock-token' = 'lock-token',
    'gho' = 'gho',
    'lobstex-new' = 'lobstex-new',
    'plastichero' = 'plastichero',
    'peapods-finance' = 'peapods-finance',
    'switch-reward-card' = 'switch-reward-card',
    '1ex-trading-board' = '1ex-trading-board',
    'arbius' = 'arbius',
    'vector-reserve' = 'vector-reserve',
    'ix-swap' = 'ix-swap',
    'gaga-pepe' = 'gaga-pepe',
    'luckys-leprecoin' = 'luckys-leprecoin',
    'dav-coin' = 'dav-coin',
    'ethxy' = 'ethxy',
    'shadow-node' = 'shadow-node',
    'scotty-the-ai' = 'scotty-the-ai',
    'devve' = 'devve',
    'bitdao' = 'bitdao',
    'not-financial-advice' = 'not-financial-advice',
    'nitefeeder' = 'nitefeeder',
    'amino' = 'amino',
    'bitscrunch' = 'bitscrunch',
    'charged-particles' = 'charged-particles',
    'ferc' = 'ferc',
    'ordibank' = 'ordibank',
    'swell-sweth' = 'swell-sweth',
    'sudoswap' = 'sudoswap',
    'sommelier' = 'sommelier',
    'scalia-infrastructure' = 'scalia-infrastructure',
    'ten-best-coins' = 'ten-best-coins',
    'chickencoin' = 'chickencoin',
    'runesbridge' = 'runesbridge',
    'humans-ai' = 'humans-ai',
    'futurexcrypto' = 'futurexcrypto',
    'bobacat' = 'bobacat',
    'gg-metagame' = 'gg-metagame',
    'k9-finance' = 'k9-finance',
    'apes-go-bananas' = 'apes-go-bananas',
    'genius-token-nft-collections' = 'genius-token-nft-collections',
    'cajutel' = 'cajutel',
    'the-kermit' = 'the-kermit',
    'spacepi-eth' = 'spacepi-eth',
    'flappymoonbird' = 'flappymoonbird',
    '00-token' = '00-token',
    'nirvana' = 'nirvana',
    'ethlas' = 'ethlas',
    'stader-maticx' = 'stader-maticx',
    'arcade-2-earn' = 'arcade-2-earn',
    'origin-ether' = 'origin-ether',
    'chainminer' = 'chainminer',
    'the-emerald-company' = 'the-emerald-company',
    'octopus-network' = 'octopus-network',
    'alkimi' = 'alkimi',
    'wall-street-bet' = 'wall-street-bet',
    'tg-casino' = 'tg-casino',
    'gorilla-in-a-coupe' = 'gorilla-in-a-coupe',
    'arc' = 'arc',
    'medifakt' = 'medifakt',
    'worldwide-usd' = 'worldwide-usd',
    'teq-network' = 'teq-network',
    'dork-lord' = 'dork-lord',
    'defactor' = 'defactor',
    'fanstime' = 'fanstime',
    'bmax' = 'bmax',
    'musk-dao' = 'musk-dao',
    'azure-wallet' = 'azure-wallet',
    'dogwifhat-eth' = 'dogwifhat-eth',
    'smart-reward-token' = 'smart-reward-token',
    'infrax' = 'infrax',
    'pando' = 'pando',
    'x2y2' = 'x2y2',
    'landwolf-eth' = 'landwolf-eth',
    'zklend' = 'zklend',
    'deepfakeai' = 'deepfakeai',
    'olympus' = 'olympus',
    'kelp-earned-points' = 'kelp-earned-points',
    'lmeow-coin-eth' = 'lmeow-coin-eth',
    'wasder' = 'wasder',
    'ness-lab' = 'ness-lab',
    'orchai' = 'orchai',
    'fileshare-platform' = 'fileshare-platform',
    'oracle-ai' = 'oracle-ai',
    'pepe-community' = 'pepe-community',
    'pond0x' = 'pond0x',
    'aegis-ai' = 'aegis-ai',
    'wrapped-minima' = 'wrapped-minima',
    'advanced-united-continent-project' = 'advanced-united-continent-project',
    'kaijuno8' = 'kaijuno8',
    'zed-run-token' = 'zed-run-token',
    'axondao-governance-token' = 'axondao-governance-token',
    'inu-inu' = 'inu-inu',
    'resistor-ai' = 'resistor-ai',
    'lp-3pool-curve' = 'lp-3pool-curve',
    'juicebot-app' = 'juicebot-app',
    'mazze' = 'mazze',
    'unleashclub' = 'unleashclub',
    'wrapped-dogecoin' = 'wrapped-dogecoin',
    'forgotten-playland' = 'forgotten-playland',
    'vitadao' = 'vitadao',
    'snackboxai' = 'snackboxai',
    'vestate' = 'vestate',
    'pastel' = 'pastel',
    'kinka' = 'kinka',
    'fofar' = 'fofar',
    'woofwork' = 'woofwork',
    'ecomi-new' = 'ecomi-new',
    'value-interlocking-exchange' = 'value-interlocking-exchange',
    'vow' = 'vow',
    'suzuverse' = 'suzuverse',
    'landwolf-erc' = 'landwolf-erc',
    'aped' = 'aped',
    'soonswap' = 'soonswap',
    'tatsu' = 'tatsu',
    'soul-society' = 'soul-society',
    'verge-eth' = 'verge-eth',
    'pawzone' = 'pawzone',
    'jc-coin' = 'jc-coin',
    'ecoterra' = 'ecoterra',
    'karate-combat' = 'karate-combat',
    'brc-app' = 'brc-app',
    'noisegpt' = 'noisegpt',
    'calcium' = 'calcium',
    'ryoshi-research' = 'ryoshi-research',
    'lenx-finance' = 'lenx-finance',
    'metazero' = 'metazero',
    'ether-fi' = 'ether-fi',
    'hytopia' = 'hytopia',
    'berry' = 'berry',
    'lends' = 'lends',
    'shib20' = 'shib20',
    'digifinextoken' = 'digifinextoken',
    'creso' = 'creso',
    'wagie-bot' = 'wagie-bot',
    'wrapped-pocket-network' = 'wrapped-pocket-network',
    'gravita-protocol' = 'gravita-protocol',
    'ring-ai' = 'ring-ai',
    'researchcoin' = 'researchcoin',
    'defy' = 'defy',
    'instadapp' = 'instadapp',
    'viridis-network' = 'viridis-network',
    'satoshisync' = 'satoshisync',
    'index-cooperative' = 'index-cooperative',
    'faith-tribe' = 'faith-tribe',
    'cosmic-network' = 'cosmic-network',
    'pullix' = 'pullix',
    'commune-ai' = 'commune-ai',
    'green-bitcoin' = 'green-bitcoin',
    'data-lake' = 'data-lake',
    'decentralized-cloud-infrastructure' = 'decentralized-cloud-infrastructure',
    'enigma' = 'enigma',
    'mythos' = 'mythos',
    'tao-ceti' = 'tao-ceti',
    'angle' = 'angle',
    'truebit' = 'truebit',
    'chimpzee' = 'chimpzee',
    'weave6' = 'weave6',
    'the-epiko' = 'the-epiko',
    'gptplus' = 'gptplus',
    'ugold-inc' = 'ugold-inc',
    'panther-protocol' = 'panther-protocol',
    'epik-protocol' = 'epik-protocol',
    'dodo-coin' = 'dodo-coin',
    'revest-finance' = 'revest-finance',
    'gekko-hq' = 'gekko-hq',
    'divergence' = 'divergence',
    'soulsaver' = 'soulsaver',
    'electronic-usd' = 'electronic-usd',
    'zklock' = 'zklock',
    'edum' = 'edum',
    'galileo-protocol' = 'galileo-protocol',
    'jinbi-token' = 'jinbi-token',
    'non-playable-coin' = 'non-playable-coin',
    'ai-network' = 'ai-network',
    'lido-staked-matic' = 'lido-staked-matic',
    'doge-2-0-token' = 'doge-2-0-token',
    'sensay' = 'sensay',
    'devour-token-v2' = 'devour-token-v2',
    'lootex' = 'lootex',
    'human' = 'human',
    'shiva-inu' = 'shiva-inu',
    'contentbox' = 'contentbox',
    'arcs' = 'arcs',
    'tidalflats' = 'tidalflats',
    'el-hippo' = 'el-hippo',
    'imvu' = 'imvu',
    'typeai' = 'typeai',
    'brainers' = 'brainers',
    'lords' = 'lords',
    'fourcoin' = 'fourcoin',
    'cramer-coin' = 'cramer-coin',
    'oraidex' = 'oraidex',
    'pikamoon-pika' = 'pikamoon-pika',
    'fottiecoin' = 'fottiecoin',
    'medicle' = 'medicle',
    'angelblock' = 'angelblock',
    'luna-inu-' = 'luna-inu-',
    'titanx' = 'titanx',
    'fame-ai' = 'fame-ai',
    'addams-ai' = 'addams-ai',
    'bank' = 'bank',
    'hemule' = 'hemule',
    'wrapped-centrifuge' = 'wrapped-centrifuge',
    'janus-interactive' = 'janus-interactive',
    'xers' = 'xers',
    '4-chan-token' = '4-chan-token',
    'trump' = 'trump',
    'nova-ubi' = 'nova-ubi',
    'harrypotterobamainu' = 'harrypotterobamainu',
    'tensorspace' = 'tensorspace',
    'united-states-property-coin' = 'united-states-property-coin',
    'apwine-finance' = 'apwine-finance',
    'icy' = 'icy',
    'detensor' = 'detensor',
    'crypto-gpt' = 'crypto-gpt',
    'trump-meme' = 'trump-meme',
    'ecoreal-estate' = 'ecoreal-estate',
    'wibx' = 'wibx',
    'go-fu-k-yourself' = 'go-fu-k-yourself',
    'cappasity' = 'cappasity',
    'aviator' = 'aviator',
    'renq-finance' = 'renq-finance',
    'metal-blockchain' = 'metal-blockchain',
    'blackhole-protocol' = 'blackhole-protocol',
    'sectbot' = 'sectbot',
    'balance-ai' = 'balance-ai',
    'tao-inu' = 'tao-inu',
    'the-nemesis' = 'the-nemesis',
    'ben-v2' = 'ben-v2',
    'vaultcraft' = 'vaultcraft',
    'financie-token' = 'financie-token',
    'mind' = 'mind',
    'ondo-us-dollar-yield' = 'ondo-us-dollar-yield',
    'dawg-coin' = 'dawg-coin',
    'paragon-network' = 'paragon-network',
    'mute' = 'mute',
    'maga-trump' = 'maga-trump',
    'degate' = 'degate',
    'morpheus-labs-v2' = 'morpheus-labs-v2',
    'kondux' = 'kondux',
    'redacted' = 'redacted',
    'magical-blocks' = 'magical-blocks',
    'flokifork' = 'flokifork',
    'socrates' = 'socrates',
    'storagechain' = 'storagechain',
    'space-iz' = 'space-iz',
    'nexus-chain' = 'nexus-chain',
    'shibadoge' = 'shibadoge',
    'mumu' = 'mumu',
    'peepo' = 'peepo',
    'symverse' = 'symverse',
    'greenworld' = 'greenworld',
    'metahorse-unity' = 'metahorse-unity',
    'ben' = 'ben',
    'kusunoki-samurai' = 'kusunoki-samurai',
    'scamfari-token' = 'scamfari-token',
    'joe-biden-meme' = 'joe-biden-meme',
    'harrypotterobamawallstreetbets10inu' = 'harrypotterobamawallstreetbets10inu',
    'crazy-frog-love' = 'crazy-frog-love',
    'hasaki' = 'hasaki',
    'plumpydragons' = 'plumpydragons',
    'lgb' = 'lgb',
    'turbochain' = 'turbochain',
    'rake-coin' = 'rake-coin',
    'mazuri-gamefi' = 'mazuri-gamefi',
    'pepy' = 'pepy',
    'thorswap' = 'thorswap',
    'jfin' = 'jfin',
    'zkhive' = 'zkhive',
    'toto' = 'toto',
    'gpt-protocol' = 'gpt-protocol',
    'linkeye' = 'linkeye',
    'defender-bot' = 'defender-bot',
    'lillian-token' = 'lillian-token',
    'manifold-finance' = 'manifold-finance',
    'xfloki' = 'xfloki',
    'spurdo' = 'spurdo',
    'changex' = 'changex',
    'gradient' = 'gradient',
    'c-cash' = 'c-cash',
    'harambe-official' = 'harambe-official',
    'starkmeta' = 'starkmeta',
    'meowchain' = 'meowchain',
    'lovepowercoin' = 'lovepowercoin',
    'ggdapp' = 'ggdapp',
    'cryn' = 'cryn',
    'convex-fxs' = 'convex-fxs',
    'aurix' = 'aurix',
    'multidex-ai' = 'multidex-ai',
    'zigzag' = 'zigzag',
    'dinolfg' = 'dinolfg',
    'taopad' = 'taopad',
    'cpucoin' = 'cpucoin',
    'cadence-protocol' = 'cadence-protocol',
    'neuralai' = 'neuralai',
    'toad-token' = 'toad-token',
    'liquidlayer' = 'liquidlayer',
    'stake-dao-crv' = 'stake-dao-crv',
    'monerium' = 'monerium',
    'dongo-ai' = 'dongo-ai',
    'kabosu-inu' = 'kabosu-inu',
    'assetmantle' = 'assetmantle',
    'golden-token' = 'golden-token',
    'fracton-protocol' = 'fracton-protocol',
    'reaktor' = 'reaktor',
    'super-best-friends' = 'super-best-friends',
    'lynkey' = 'lynkey',
    'hera-finance' = 'hera-finance',
    'metanept' = 'metanept',
    'caduceus-foundation' = 'caduceus-foundation',
    'multiverse' = 'multiverse',
    'mickey-meme' = 'mickey-meme',
    'buildai' = 'buildai',
    'mfers' = 'mfers',
    'super-athletes' = 'super-athletes',
    'quantixai' = 'quantixai',
    'feels-good-man' = 'feels-good-man',
    'relation-labs' = 'relation-labs',
    'bigshortbets' = 'bigshortbets',
    'blockdefend-ai' = 'blockdefend-ai',
    'bebe-eth' = 'bebe-eth',
    'solareum-token' = 'solareum-token',
    'cloak-ai' = 'cloak-ai',
    'sheboshis' = 'sheboshis',
    'the-balkan-dwarf' = 'the-balkan-dwarf',
    'byteai' = 'byteai',
    'eurocoin-token' = 'eurocoin-token',
    'landwolf-io' = 'landwolf-io',
    'mars-battle' = 'mars-battle',
    'aqtis' = 'aqtis',
    'exen-coin' = 'exen-coin',
    'blockchain-bets' = 'blockchain-bets',
    'codex-' = 'codex-',
    'flovi-inu' = 'flovi-inu',
    'neurahub' = 'neurahub',
    'stima' = 'stima',
    'cryptoai' = 'cryptoai',
    'spot' = 'spot',
    'greelance' = 'greelance',
    'reach' = 'reach',
    'wattton' = 'wattton',
    'bazed-games' = 'bazed-games',
    'agg' = 'agg',
    'zypto' = 'zypto',
    'smartmesh' = 'smartmesh',
    'boxbet' = 'boxbet',
    'joe-coin' = 'joe-coin',
    'maxity' = 'maxity',
    'root-protocol' = 'root-protocol',
    'open-exchange-token' = 'open-exchange-token',
    'purple-ai' = 'purple-ai',
    'montage-token' = 'montage-token',
    'hop-exchange' = 'hop-exchange',
    'choppy' = 'choppy',
    'trestle' = 'trestle',
    'metavisa-protocol' = 'metavisa-protocol',
    'memevengers' = 'memevengers',
    'smart-game-finance' = 'smart-game-finance',
    'spatialcomputing' = 'spatialcomputing',
    'cybonk' = 'cybonk',
    'wrapped-ampleforth' = 'wrapped-ampleforth',
    'morra' = 'morra',
    'fine-erc' = 'fine-erc',
    'esg' = 'esg',
    'bobo' = 'bobo',
    'independence-token' = 'independence-token',
    'papa' = 'papa',
    'whirl' = 'whirl',
    'silent-notary' = 'silent-notary',
    'nahmii' = 'nahmii',
    'decentracard' = 'decentracard',
    'snkrz-frc' = 'snkrz-frc',
    'dbxen' = 'dbxen',
    'trump-financial' = 'trump-financial',
    'digital-asset-rights-token' = 'digital-asset-rights-token',
    'empyreal-exchange' = 'empyreal-exchange',
    'sowa-ai' = 'sowa-ai',
    'xrootai' = 'xrootai',
    'ugas' = 'ugas',
    'the-protocol' = 'the-protocol',
    'uwu-lend' = 'uwu-lend',
    'dolz' = 'dolz',
    'reddit-rddt' = 'reddit-rddt',
    'open-platform' = 'open-platform',
    'save-america' = 'save-america',
    'don-t-buy-inu' = 'don-t-buy-inu',
    'utilies-cryptocurrency-exchange' = 'utilies-cryptocurrency-exchange',
    'nobleblocks' = 'nobleblocks',
    'zeni' = 'zeni',
    'nightverse-game' = 'nightverse-game',
    'ordbridge' = 'ordbridge',
    'restake-finance' = 'restake-finance',
    'cow-protocol' = 'cow-protocol',
    'giveth' = 'giveth',
    'burn' = 'burn',
    'dua-token' = 'dua-token',
    'zktao' = 'zktao',
    'dash-trade' = 'dash-trade',
    'kizuna' = 'kizuna',
    'dreamverse' = 'dreamverse',
    'dfund' = 'dfund',
    'roko' = 'roko',
    'ecox' = 'ecox',
    'day-by-day' = 'day-by-day',
    'tehbag' = 'tehbag',
    'exorde' = 'exorde',
    'lanify' = 'lanify',
    'bharat-smart-chain-project' = 'bharat-smart-chain-project',
    'artizen' = 'artizen',
    'dextf-protocol' = 'dextf-protocol',
    'trillant' = 'trillant',
    'paladinai' = 'paladinai',
    'ai-pin' = 'ai-pin',
    'decentralized-vulnerability-platform' = 'decentralized-vulnerability-platform',
    'metano-foundations-token' = 'metano-foundations-token',
    'mindverse' = 'mindverse',
    'invest-club-global' = 'invest-club-global',
    'brepe' = 'brepe',
    'clipper' = 'clipper',
    'crypto-real-estate' = 'crypto-real-estate',
    'mandox-token' = 'mandox-token',
    'jungle-labz' = 'jungle-labz',
    'tokensight' = 'tokensight',
    'hypercomic' = 'hypercomic',
    '0xscans' = '0xscans',
    'qlindo' = 'qlindo',
    'syn-city' = 'syn-city',
    'modex' = 'modex',
    'x-com-erc' = 'x-com-erc',
    'i-will-poop-it-nft' = 'i-will-poop-it-nft',
    'globe-derivative-exchange' = 'globe-derivative-exchange',
    '0-knowledge-network' = '0-knowledge-network',
    'buddha' = 'buddha',
    'dubbz' = 'dubbz',
    'inovai' = 'inovai',
    'moonapp' = 'moonapp',
    'taxa-token' = 'taxa-token',
    'tpro-network' = 'tpro-network',
    'cybria' = 'cybria',
    'arro-social' = 'arro-social',
    'ryoshi-inu' = 'ryoshi-inu',
    'aureus-nummus-gold' = 'aureus-nummus-gold',
    'autonio' = 'autonio',
    'scarab-tools' = 'scarab-tools',
    'mystic-land-coin' = 'mystic-land-coin',
    'collateral-network' = 'collateral-network',
    'hotkeyswap' = 'hotkeyswap',
    'bitmex-token' = 'bitmex-token',
    'punk-vault-nftx' = 'punk-vault-nftx',
    '0xgpu-ai' = '0xgpu-ai',
    'mememe' = 'mememe',
    'element-black' = 'element-black',
    'cindrum' = 'cindrum',
    '0xvpn-org' = '0xvpn-org',
    'paradox-metaverse' = 'paradox-metaverse',
    'eth-2x-flexible-leverage-index' = 'eth-2x-flexible-leverage-index',
    'cfx-quantum' = 'cfx-quantum',
    'anontech' = 'anontech',
    'the-gamehub' = 'the-gamehub',
    'regen-network' = 'regen-network',
    'akita-dao' = 'akita-dao',
    'neo-tokyo' = 'neo-tokyo',
    'lukso' = 'lukso',
    'ash' = 'ash',
    'donald-trump-2024' = 'donald-trump-2024',
    'odyssey' = 'odyssey',
    'bluesparrow-token-new' = 'bluesparrow-token-new',
    'sideshift-token' = 'sideshift-token',
    'titanium22' = 'titanium22',
    'cat-in-a-box-ether' = 'cat-in-a-box-ether',
    'tontoken' = 'tontoken',
    'maidsafecoin-' = 'maidsafecoin-',
    'naga' = 'naga',
    'centurion-invest' = 'centurion-invest',
    'meow-coin' = 'meow-coin',
    'rent-ai' = 'rent-ai',
    'alice-ai' = 'alice-ai',
    'meshwave' = 'meshwave',
    'joe-biden' = 'joe-biden',
    'cat-ai-ethereum' = 'cat-ai-ethereum',
    'book-of-pepe' = 'book-of-pepe',
    'notional-finance' = 'notional-finance',
    'voxto-amplify' = 'voxto-amplify',
    'nexusai' = 'nexusai',
    'materium' = 'materium',
    'shezmu' = 'shezmu',
    '0chain' = '0chain',
    'plt-token' = 'plt-token',
    'metronome' = 'metronome',
    'aardvark' = 'aardvark',
    'aimbot' = 'aimbot',
    'multiplanetary-inus' = 'multiplanetary-inus',
    'l' = 'l',
    'steamboat-willie' = 'steamboat-willie',
    'clips-token' = 'clips-token',
    'lsdx-finance' = 'lsdx-finance',
    'coinw-token' = 'coinw-token',
    'doggensnout-skeptic' = 'doggensnout-skeptic',
    'crown-token' = 'crown-token',
    'chainback' = 'chainback',
    'habibi' = 'habibi',
    'jpeg-d' = 'jpeg-d',
    'future1coin' = 'future1coin',
    'sturdy' = 'sturdy',
    'opyn-squeeth' = 'opyn-squeeth',
    'feisty-doge-nft' = 'feisty-doge-nft',
    'quant-ai' = 'quant-ai',
    'xmax' = 'xmax',
    'conic-finance' = 'conic-finance',
    '0x-leverage' = '0x-leverage',
    'futuresai' = 'futuresai',
    'scryinfo' = 'scryinfo',
    'pacific' = 'pacific',
    'wasd-studios' = 'wasd-studios',
    'edgeswap' = 'edgeswap',
    'verox' = 'verox',
    'jeff-world' = 'jeff-world',
    'memedao' = 'memedao',
    'wicked-bet' = 'wicked-bet',
    'so-col' = 'so-col',
    'metazoomee' = 'metazoomee',
    'poundtoken' = 'poundtoken',
    'crafting-finance' = 'crafting-finance',
    'doge-payment' = 'doge-payment',
    'ishi' = 'ishi',
    'every-game' = 'every-game',
    'neos-credits' = 'neos-credits',
    'lunchdao' = 'lunchdao',
    'meme-kombat' = 'meme-kombat',
    'carbify' = 'carbify',
    'otsea' = 'otsea',
    'drc-mobility' = 'drc-mobility',
    'aag-ventures' = 'aag-ventures',
    'smartmoney' = 'smartmoney',
    'assangedao' = 'assangedao',
    'sifu-vision' = 'sifu-vision',
    'duckdao' = 'duckdao',
    'precipitate-ai' = 'precipitate-ai',
    'hnb-protocol' = 'hnb-protocol',
    'banana-cyberkongz' = 'banana-cyberkongz',
    'veil-exchange' = 'veil-exchange',
    'solav-token' = 'solav-token',
    'web-x-ai' = 'web-x-ai',
    'meta-masters-guild-games' = 'meta-masters-guild-games',
    'quantfury-token' = 'quantfury-token',
    'moe' = 'moe',
    'calicoin' = 'calicoin',
    'zurrency' = 'zurrency',
    'empire-token' = 'empire-token',
    'toad-killer' = 'toad-killer',
    'mixtrust' = 'mixtrust',
    'veraone' = 'veraone',
    'casinu-inu' = 'casinu-inu',
    'kitty-inu' = 'kitty-inu',
    'aigang' = 'aigang',
    'paypolitan-token' = 'paypolitan-token',
    'invitoken' = 'invitoken',
    'morpher' = 'morpher',
    'theopetra' = 'theopetra',
    'gas-dao' = 'gas-dao',
    'exosama-network' = 'exosama-network',
    'chuck' = 'chuck',
    'mooney' = 'mooney',
    'freerossdao' = 'freerossdao',
    'global-digital-content' = 'global-digital-content',
    'xrun' = 'xrun',
    'bitcoin-2' = 'bitcoin-2',
    'on-chain-dynamics' = 'on-chain-dynamics',
    'trumpcat' = 'trumpcat',
    'moros-net' = 'moros-net',
    'pandadao' = 'pandadao',
    'astraai' = 'astraai',
    'cardiocoin' = 'cardiocoin',
    'tigra' = 'tigra',
    'magnum-trading' = 'magnum-trading',
    'grokcoin-co' = 'grokcoin-co',
    'prophetbots' = 'prophetbots',
    'rake-finance' = 'rake-finance',
    'mmx' = 'mmx',
    'fire-protocol' = 'fire-protocol',
    'codegenie' = 'codegenie',
    'port-ai' = 'port-ai',
    'cuminu' = 'cuminu',
    'plgnet' = 'plgnet',
    'hitchain' = 'hitchain',
    'taylor-swift' = 'taylor-swift',
    'hxro' = 'hxro',
    'medishares' = 'medishares',
    'matrixetf' = 'matrixetf',
    'xpayments' = 'xpayments',
    'baby-x' = 'baby-x',
    'biaocoin' = 'biaocoin',
    'sashimi' = 'sashimi',
    'naka-bodhi-token' = 'naka-bodhi-token',
    'proxi' = 'proxi',
    'infinitybit-token' = 'infinitybit-token',
    'onx-finance' = 'onx-finance',
    'dacat' = 'dacat',
    'turbopepe' = 'turbopepe',
    'coinfi' = 'coinfi',
    'dogegf' = 'dogegf',
    'hoppy-token' = 'hoppy-token',
    'alpaca-city' = 'alpaca-city',
    'shiba-girlfriend' = 'shiba-girlfriend',
    'ordigen' = 'ordigen',
    'lien' = 'lien',
    'rebaked' = 'rebaked',
    'bincentive' = 'bincentive',
    'vader-protocol' = 'vader-protocol',
    'idexo-token' = 'idexo-token',
    'fx1sports' = 'fx1sports',
    '1sol' = '1sol',
    'essentia' = 'essentia',
    'cryptoart-ai' = 'cryptoart-ai',
    'beep-coin' = 'beep-coin',
    'satellite-doge-1-mission' = 'satellite-doge-1-mission',
    'vault-tech' = 'vault-tech',
    'timeless' = 'timeless',
    'dope-wars-paper' = 'dope-wars-paper',
    'popo' = 'popo',
    'bounty0x' = 'bounty0x',
    'crypto-villains' = 'crypto-villains',
    'xception' = 'xception',
    'fort-block-games' = 'fort-block-games',
    'nftmart-token' = 'nftmart-token',
    'npick-block' = 'npick-block',
    'frogswap' = 'frogswap',
    'wooooo-coin' = 'wooooo-coin',
    'nitro-league' = 'nitro-league',
    'capybara-capy' = 'capybara-capy',
    'bacondao' = 'bacondao',
    'blockcdn' = 'blockcdn',
    'moda-dao' = 'moda-dao',
    'myownitem-v2' = 'myownitem-v2',
    'lemochain' = 'lemochain',
    'hitmakr' = 'hitmakr',
    'marvin-inu' = 'marvin-inu',
    'vectorchat-ai' = 'vectorchat-ai',
    'delphy' = 'delphy',
    'tmnt' = 'tmnt',
    'maga-2024' = 'maga-2024',
    'nkcl-classic' = 'nkcl-classic',
    'lemond' = 'lemond',
    'inspireai' = 'inspireai',
    'bogdanoff' = 'bogdanoff',
    'friends-with-benefits-pro' = 'friends-with-benefits-pro',
    'iht-real-estate-protocol' = 'iht-real-estate-protocol',
    'the-infinite-garden' = 'the-infinite-garden',
    'kuma-inu' = 'kuma-inu',
    'callhub' = 'callhub',
    'jaypeggers' = 'jaypeggers',
    'website-ai' = 'website-ai',
    'crypto-rangers' = 'crypto-rangers',
    'tempus' = 'tempus',
    'eveai' = 'eveai',
    'wanna-bot' = 'wanna-bot',
    'bear-inu' = 'bear-inu',
    'xtremeverse' = 'xtremeverse',
    'eve-exchange' = 'eve-exchange',
    'deesse' = 'deesse',
    'champignons-of-arborethia' = 'champignons-of-arborethia',
    'unipoly' = 'unipoly',
    'crypto-holding-frank-token' = 'crypto-holding-frank-token',
    'hypersign-identity' = 'hypersign-identity',
    'ajna-protocol' = 'ajna-protocol',
    'blocksport' = 'blocksport',
    'solidlydex' = 'solidlydex',
    'ehash' = 'ehash',
    'homer' = 'homer',
    '0xgasless' = '0xgasless',
    'kittenfinance' = 'kittenfinance',
    'foxe' = 'foxe',
    'emiswap' = 'emiswap',
    'akropolis-delphi' = 'akropolis-delphi',
    'miaomiaomiaoerc20' = 'miaomiaomiaoerc20',
    'stakewise' = 'stakewise',
    'skrumble-network' = 'skrumble-network',
    'gsenetwork' = 'gsenetwork',
    'one-cash' = 'one-cash',
    'panoverse' = 'panoverse',
    'stox' = 'stox',
    'gold-plus' = 'gold-plus',
    'r4re-token' = 'r4re-token',
    'vuzz-mind' = 'vuzz-mind',
    'libra-credit' = 'libra-credit',
    'gigaswap' = 'gigaswap',
    'merchdao' = 'merchdao',
    'aladdindao' = 'aladdindao',
    'nyan-meme-coin' = 'nyan-meme-coin',
    'jpy-coin' = 'jpy-coin',
    'energreen' = 'energreen',
    'filecoin-standard-hashrate-token' = 'filecoin-standard-hashrate-token',
    'vidy' = 'vidy',
    'cydotori' = 'cydotori',
    '0xnumber' = '0xnumber',
    'woozoo-music' = 'woozoo-music',
    'vetme' = 'vetme',
    'miniswap' = 'miniswap',
    'sienna-erc20' = 'sienna-erc20',
    'onchain-ai' = 'onchain-ai',
    'theos' = 'theos',
    'dos-network' = 'dos-network',
    'defil' = 'defil',
    'bnktothefuture' = 'bnktothefuture',
    'marnotaur' = 'marnotaur',
    'probit-token' = 'probit-token',
    'haycoin' = 'haycoin',
    'game' = 'game',
    'pear-swap' = 'pear-swap',
    'sipher' = 'sipher',
    'trump-inu-coin' = 'trump-inu-coin',
    'artem-coin' = 'artem-coin',
    'soai' = 'soai',
    'bigtownchef' = 'bigtownchef',
    'unit-protocol-duck' = 'unit-protocol-duck',
    'wwf' = 'wwf',
    'douglas-adams' = 'douglas-adams',
    'unidexai' = 'unidexai',
    'mobius-finance' = 'mobius-finance',
    'noa-play' = 'noa-play',
    'protectorate-protocol' = 'protectorate-protocol',
    'tensorscan-ai' = 'tensorscan-ai',
    'cofix' = 'cofix',
    'chicken' = 'chicken',
    'pine' = 'pine',
    'hoichi' = 'hoichi',
    'krypton-galaxy-coin' = 'krypton-galaxy-coin',
    'smartset-token' = 'smartset-token',
    'autumn' = 'autumn',
    'powblocks' = 'powblocks',
    'ogsminem' = 'ogsminem',
    'tbtc' = 'tbtc',
    'galvan' = 'galvan',
    'kreaitor' = 'kreaitor',
    'yesports' = 'yesports',
    'messier' = 'messier',
    'snetwork' = 'snetwork',
    'hokkaidu-inu' = 'hokkaidu-inu',
    'electrifyasia' = 'electrifyasia',
    'lndry' = 'lndry',
    'neural-tensor-dynamics' = 'neural-tensor-dynamics',
    'the-dare' = 'the-dare',
    'unodex' = 'unodex',
    'guardai' = 'guardai',
    'gamma-strategies' = 'gamma-strategies',
    'bitopro-exchange-token' = 'bitopro-exchange-token',
    'satoshi-token' = 'satoshi-token',
    'daosquare' = 'daosquare',
    'p-pizza' = 'p-pizza',
    'calvaria' = 'calvaria',
    'nekoverse-city-of-greed-anima-spirit-gem' = 'nekoverse-city-of-greed-anima-spirit-gem',
    'pooltogether' = 'pooltogether',
    'funfi' = 'funfi',
    'blockgpt-bgpt' = 'blockgpt-bgpt',
    'csr' = 'csr',
    'parallel-protocol' = 'parallel-protocol',
    'animalia' = 'animalia',
    'the9' = 'the9',
    'got-guaranteed' = 'got-guaranteed',
    'wise' = 'wise',
    'pbtc35a' = 'pbtc35a',
    'swype-eth' = 'swype-eth',
    'grok-1-5' = 'grok-1-5',
    'ethermon' = 'ethermon',
    'miner-ercx' = 'miner-ercx',
    'hilo' = 'hilo',
    'stats' = 'stats',
    'remme' = 'remme',
    'parallel' = 'parallel',
    'pepinu' = 'pepinu',
    'lucky-block-v2' = 'lucky-block-v2',
    'seth' = 'seth',
    'qrolli' = 'qrolli',
    'discoverfeed' = 'discoverfeed',
    'benji-bananas' = 'benji-bananas',
    'everex' = 'everex',
    'amaterasu-omikami' = 'amaterasu-omikami',
    'miraqle' = 'miraqle',
    'laika' = 'laika',
    'red' = 'red',
    'sensor-protocol' = 'sensor-protocol',
    'tucker-carlson' = 'tucker-carlson',
    'ripio-credit-network' = 'ripio-credit-network',
    'nchart' = 'nchart',
    'shill-guard-token' = 'shill-guard-token',
    'decentralized-etf' = 'decentralized-etf',
    'pepe-coin' = 'pepe-coin',
    'firstneuralink' = 'firstneuralink',
    'kleekai' = 'kleekai',
    'vone' = 'vone',
    'raiden-network-token' = 'raiden-network-token',
    'kanagawa-nami' = 'kanagawa-nami',
    'socialgood' = 'socialgood',
    'robo-inu-finance' = 'robo-inu-finance',
    'streeth' = 'streeth',
    'yup-token' = 'yup-token',
    'apu-token' = 'apu-token',
    'hommies' = 'hommies',
    'virtuswap' = 'virtuswap',
    'kompete' = 'kompete',
    'medical-chain' = 'medical-chain',
    'cyber-crowd-chain' = 'cyber-crowd-chain',
    'ost' = 'ost',
    'dumbmoney' = 'dumbmoney',
    'couponbay' = 'couponbay',
    'gec' = 'gec',
    'grok-2' = 'grok-2',
    'sekuritance' = 'sekuritance',
    'eosdac' = 'eosdac',
    'side-xyz' = 'side-xyz',
    'artificial-idiot' = 'artificial-idiot',
    'gambex' = 'gambex',
    'qash' = 'qash',
    'mobi-finance' = 'mobi-finance',
    'dope-coin' = 'dope-coin',
    '0xs' = '0xs',
    'voidz' = 'voidz',
    'etherparty' = 'etherparty',
    '2dai-io' = '2dai-io',
    'evermoon-token' = 'evermoon-token',
    'ribbit-meme' = 'ribbit-meme',
    'dogeclub' = 'dogeclub',
    'sanin-inu' = 'sanin-inu',
    'milei-token' = 'milei-token',
    'fognet' = 'fognet',
    'dinerobet' = 'dinerobet',
    'sec' = 'sec',
    'ducker' = 'ducker',
    'starshiperc-vip' = 'starshiperc-vip',
    'pickle' = 'pickle',
    'sora-ai-bot' = 'sora-ai-bot',
    'exenpay-token' = 'exenpay-token',
    'btour-chain' = 'btour-chain',
    'squid-game1' = 'squid-game1',
    'saudi-bonk' = 'saudi-bonk',
    'eventchain' = 'eventchain',
    'big-eyes' = 'big-eyes',
    'yes-3' = 'yes-3',
    'xrp20' = 'xrp20',
    'zeusshield' = 'zeusshield',
    'everybody-holds' = 'everybody-holds',
    'covenant-child' = 'covenant-child',
    'xreators' = 'xreators',
    'dedprz-entertainment' = 'dedprz-entertainment',
    'escrowed-illuvium-2' = 'escrowed-illuvium-2',
    'jeff' = 'jeff',
    'blastai' = 'blastai',
    'shardus' = 'shardus',
    'raft' = 'raft',
    'k-tune' = 'k-tune',
    'phuture' = 'phuture',
    'tia' = 'tia',
    'sopay' = 'sopay',
    'sponge' = 'sponge',
    'luckytoad' = 'luckytoad',
    'mixin' = 'mixin',
    'aidi-finance-new' = 'aidi-finance-new',
    'nodesynapse' = 'nodesynapse',
    'linq' = 'linq',
    'usdebt-meme' = 'usdebt-meme',
    'niifi' = 'niifi',
    'nova-dao' = 'nova-dao',
    'coti-governance-token' = 'coti-governance-token',
    'cardstack' = 'cardstack',
    'sentinel-bot-ai' = 'sentinel-bot-ai',
    'bitcoin-inu' = 'bitcoin-inu',
    'evany' = 'evany',
    'treat-token' = 'treat-token',
    'sendpicks' = 'sendpicks',
    'wassie' = 'wassie',
    'metaunit' = 'metaunit',
    'terrier' = 'terrier',
    'winter' = 'winter',
    'millix' = 'millix',
    'veritaseum' = 'veritaseum',
    'olea-token' = 'olea-token',
    'trump-cat' = 'trump-cat',
    'eternal-ai' = 'eternal-ai',
    'summer' = 'summer',
    'alphakek-ai' = 'alphakek-ai',
    'value-defi' = 'value-defi',
    'lyve-finance' = 'lyve-finance',
    'xsushi' = 'xsushi',
    'etherpets' = 'etherpets',
    'hashcoin' = 'hashcoin',
    'decentralized-eternal-virtual-traveller' = 'decentralized-eternal-virtual-traveller',
    'dog-in-cats-world' = 'dog-in-cats-world',
    'neuroni-ai' = 'neuroni-ai',
    'bonsai3' = 'bonsai3',
    'zenex' = 'zenex',
    'ordinex' = 'ordinex',
    'index-coop' = 'index-coop',
    'host-ai' = 'host-ai',
    'liquid-staking-derivatives' = 'liquid-staking-derivatives',
    'wolf-game-wool' = 'wolf-game-wool',
    'sportx' = 'sportx',
    'bezoge-earth' = 'bezoge-earth',
    'tate' = 'tate',
    'freetrump' = 'freetrump',
    'dollarsqueeze' = 'dollarsqueeze',
    'parastate' = 'parastate',
    'inofi' = 'inofi',
    'mixtoearn' = 'mixtoearn',
    'spring' = 'spring',
    'futureswap' = 'futureswap',
    'cocktail-bar' = 'cocktail-bar',
    'childhoods-end' = 'childhoods-end',
    'i-love-snoopy' = 'i-love-snoopy',
    'seth2' = 'seth2',
    'melania-trump' = 'melania-trump',
    'dede' = 'dede',
    'gems-protocol' = 'gems-protocol',
    'stone' = 'stone',
    'bitcastle' = 'bitcastle',
    'trump-shiba' = 'trump-shiba',
    'undead-blocks' = 'undead-blocks',
    'jerome-powell' = 'jerome-powell',
    'zarp-stablecoin' = 'zarp-stablecoin',
    'fufu-token' = 'fufu-token',
    'elyssa-ar' = 'elyssa-ar',
    'metafinance-1' = 'metafinance-1',
    'decentramind' = 'decentramind',
    'bambi' = 'bambi',
    'sandclock' = 'sandclock',
    'mistral-ai' = 'mistral-ai',
    'convergence-fi' = 'convergence-fi',
    'olympus-v1' = 'olympus-v1',
    'metarare' = 'metarare',
    'dprating' = 'dprating',
    'alpha-gardeners' = 'alpha-gardeners',
    'ethereum-express' = 'ethereum-express',
    'mineshield' = 'mineshield',
    'baby-grok' = 'baby-grok',
    'primas' = 'primas',
    'xi-token' = 'xi-token',
    'hzm-coin' = 'hzm-coin',
    'neonai' = 'neonai',
    'mumu-the-bull-erc' = 'mumu-the-bull-erc',
    'kitty-token' = 'kitty-token',
    'love' = 'love',
    'lunar-token' = 'lunar-token',
    'new-world-order' = 'new-world-order',
    'mithril-share' = 'mithril-share',
    'edge' = 'edge',
    'die-protocol' = 'die-protocol',
    'tiusd' = 'tiusd',
    'liza' = 'liza',
    'linkcoin-token' = 'linkcoin-token',
    'legacy-ichi' = 'legacy-ichi',
    'hachi' = 'hachi',
    'shiba-v-pepe' = 'shiba-v-pepe',
    'xeno' = 'xeno',
    'betacarbon' = 'betacarbon',
    'ultra-prisma-finance' = 'ultra-prisma-finance',
    'paw-token' = 'paw-token',
    'proof-of-pepe' = 'proof-of-pepe',
    'hedron' = 'hedron',
    'grokcoin' = 'grokcoin',
    'greg' = 'greg',
    'daisy-launch-pad' = 'daisy-launch-pad',
    'darussafaka-sports-club-token' = 'darussafaka-sports-club-token',
    'merchant-token' = 'merchant-token',
    'nothing-cash' = 'nothing-cash',
    'sonictothemoon' = 'sonictothemoon',
    'nihao' = 'nihao',
    'd-drops' = 'd-drops',
    'hermes-dao' = 'hermes-dao',
    'open-proprietary-protocol' = 'open-proprietary-protocol',
    'aidoge' = 'aidoge',
    'when-token' = 'when-token',
    'bugs-bunny-coin' = 'bugs-bunny-coin',
    'arcadefi' = 'arcadefi',
    'one-share' = 'one-share',
    'aibabydoge' = 'aibabydoge',
    'mainframe' = 'mainframe',
    'tribal-token' = 'tribal-token',
    'chow-chow-token' = 'chow-chow-token',
    'dexioprotocol-v2' = 'dexioprotocol-v2',
    'g-revolution' = 'g-revolution',
    'metabet' = 'metabet',
    'darkmatter-token' = 'darkmatter-token',
    'ethereans' = 'ethereans',
    'rebelsatoshi' = 'rebelsatoshi',
    'biden-2024' = 'biden-2024',
    'humandao' = 'humandao',
    'crypto-cat' = 'crypto-cat',
    'shanghai-inu' = 'shanghai-inu',
    'osmo-bot' = 'osmo-bot',
    'cindicator' = 'cindicator',
    'magic-internet-cash' = 'magic-internet-cash',
    'beholder' = 'beholder',
    'juicebox' = 'juicebox',
    'photochromic' = 'photochromic',
    'z-cubed' = 'z-cubed',
    'hobbes' = 'hobbes',
    'shiba-20' = 'shiba-20',
    'ryoshis-vision' = 'ryoshis-vision',
    'sparko' = 'sparko',
    'etherscape' = 'etherscape',
    'venom-vnm' = 'venom-vnm',
    'valleydao' = 'valleydao',
    'block-browser' = 'block-browser',
    'babypepe' = 'babypepe',
    'snailbrook' = 'snailbrook',
    'spyro' = 'spyro',
    'wagmi-' = 'wagmi-',
    'mt-pelerin' = 'mt-pelerin',
    'kirby-ceo' = 'kirby-ceo',
    'pokemon' = 'pokemon',
    'gambit-game' = 'gambit-game',
    'muzzletoken' = 'muzzletoken',
    'dork' = 'dork',
    '4artechnologies' = '4artechnologies',
    'shib2' = 'shib2',
    'baby-doge-2-0' = 'baby-doge-2-0',
    'inheritance-art' = 'inheritance-art',
    'baby-shiba-inu-erc' = 'baby-shiba-inu-erc',
    'pika' = 'pika',
    'mononoke-inu' = 'mononoke-inu',
    'matching-game' = 'matching-game',
    'wrapped-luna-token' = 'wrapped-luna-token',
    'wooforacle' = 'wooforacle',
    'neton' = 'neton',
    'super-grok' = 'super-grok',
    'betbuinu-crypto' = 'betbuinu-crypto',
    'fx-protocol-leveraged-eth' = 'fx-protocol-leveraged-eth',
    'changer' = 'changer',
    'tgold' = 'tgold',
    'adana-demirspor-token' = 'adana-demirspor-token',
    'coinbot' = 'coinbot',
    'novacore' = 'novacore',
    'openai-erc' = 'openai-erc',
    'cosmo-baby' = 'cosmo-baby',
    'pepe-girl' = 'pepe-girl',
    'tether-mxnt' = 'tether-mxnt',
    'kento-coin' = 'kento-coin',
    'capybara-bara' = 'capybara-bara',
    'metaverse-index' = 'metaverse-index',
    'maga-trump2024' = 'maga-trump2024',
    'blockv' = 'blockv',
    'liquid-staked-eth' = 'liquid-staked-eth',
    'interns' = 'interns',
    'hola-token' = 'hola-token',
    'pepefork2-0' = 'pepefork2-0',
    'sonata-network' = 'sonata-network',
    'tweety' = 'tweety',
    'atma' = 'atma',
    'ei-risitas' = 'ei-risitas',
    'bomb' = 'bomb',
    'shibarium-perpetuals' = 'shibarium-perpetuals',
    'jackbot' = 'jackbot',
    'terraai' = 'terraai',
    'hamster-coin' = 'hamster-coin',
    'monkeys' = 'monkeys',
    'newsly' = 'newsly',
    'agnus-ai' = 'agnus-ai',
    'dark-brandon' = 'dark-brandon',
    'derp-coin' = 'derp-coin',
    'bananatok' = 'bananatok',
    'tsilver' = 'tsilver',
    'bens-finale' = 'bens-finale',
    'mcfinance' = 'mcfinance',
    'hikari-protocol' = 'hikari-protocol',
    'dummy' = 'dummy',
    'nucypher' = 'nucypher',
    'chainex' = 'chainex',
    'cuckadoodledoo' = 'cuckadoodledoo',
    'clown-pepe' = 'clown-pepe',
    'eth-2-vip' = 'eth-2-vip',
    'gigantix-wallet-token' = 'gigantix-wallet-token',
    'momo-v2' = 'momo-v2',
    'gains-farm-v2' = 'gains-farm-v2',
    'generaitiv' = 'generaitiv',
    'preme-token' = 'preme-token',
    'the-blu-arctic-water-company' = 'the-blu-arctic-water-company',
    'coinmerge-io' = 'coinmerge-io',
    'tcgc' = 'tcgc',
    'eth3-0' = 'eth3-0',
    'bread' = 'bread',
    'jim' = 'jim',
    'sheesha-finance-erc20' = 'sheesha-finance-erc20',
    'metaoctagon' = 'metaoctagon',
    'varc' = 'varc',
    'lotty' = 'lotty',
    'diablo-iv' = 'diablo-iv',
    'bidao' = 'bidao',
    'tether-cnh' = 'tether-cnh',
    'fungify' = 'fungify',
    'decentranode' = 'decentranode',
    'warped-games' = 'warped-games',
    'union-protocol-governance-token' = 'union-protocol-governance-token',
    'kounotori' = 'kounotori',
    'moonbot' = 'moonbot',
    'kevin-token' = 'kevin-token',
    'page' = 'page',
    'iykyk-' = 'iykyk-',
    'toshi-tools' = 'toshi-tools',
    'earn-defi' = 'earn-defi',
    'minds' = 'minds',
    'spillways' = 'spillways',
    'xai-corp' = 'xai-corp',
    'degenstogether' = 'degenstogether',
    'shikoku' = 'shikoku',
    'coinhound' = 'coinhound',
    'chartai' = 'chartai',
    'gooch' = 'gooch',
    'buck-hath-coin' = 'buck-hath-coin',
    '#0x0' = '0x0',
    'diyarbekirspor-token' = 'diyarbekirspor-token',
    'starship-erc20' = 'starship-erc20',
    'tonka-finance' = 'tonka-finance',
    'liquid-protocol' = 'liquid-protocol',
    '50x-com' = '50x-com',
    'fuck-pepe' = 'fuck-pepe',
    'bitcoin-etf-token' = 'bitcoin-etf-token',
    'barbiecrashbandicootrfk888inu' = 'barbiecrashbandicootrfk888inu',
    'cometh' = 'cometh',
    'blackswan-ai' = 'blackswan-ai',
    'element' = 'element',
    'stronger' = 'stronger',
    'azure' = 'azure',
    'linework-coin' = 'linework-coin',
    'pax-unitas' = 'pax-unitas',
    'd-community' = 'd-community',
    'psyop' = 'psyop',
    'cryptofranc' = 'cryptofranc',
    'lambo-coin' = 'lambo-coin',
    'sora-synthetic-usd' = 'sora-synthetic-usd',
    'president-donald-trump' = 'president-donald-trump',
    'x-gpt' = 'x-gpt',
    'dogey' = 'dogey',
    'monox-protocol-token' = 'monox-protocol-token',
    'blockster' = 'blockster',
    'evil-pepe' = 'evil-pepe',
    'basis-share' = 'basis-share',
    'voldemorttrumprobotnik-10neko' = 'voldemorttrumprobotnik-10neko',
    'chad-coin' = 'chad-coin',
    'maximus-deci' = 'maximus-deci',
    'plebtoken' = 'plebtoken',
    'kstarcoin' = 'kstarcoin',
    'arbismart' = 'arbismart',
    'fisco' = 'fisco',
    'bankless-dao' = 'bankless-dao',
    'oasisplatform' = 'oasisplatform',
    'coinmetro-token' = 'coinmetro-token',
    'smart-marketing-token' = 'smart-marketing-token',
    'mineable' = 'mineable',
    'trump2024' = 'trump2024',
    'hbdc' = 'hbdc',
    'mechachain' = 'mechachain',
    'dam-finance' = 'dam-finance',
    'xdoge' = 'xdoge',
    'everflow' = 'everflow',
    'sivasspor-token' = 'sivasspor-token',
    'etf' = 'etf',
    'rawblock' = 'rawblock',
    'duzce-token' = 'duzce-token',
    'safereum' = 'safereum',
    'good-gensler' = 'good-gensler',
    'meetple' = 'meetple',
    'rug-radio' = 'rug-radio',
    'harrypottertrumphomersimpson777inu' = 'harrypottertrumphomersimpson777inu',
    'wctrades' = 'wctrades',
    'espresso-bot' = 'espresso-bot',
    'aniverse' = 'aniverse',
    'monarch-game' = 'monarch-game',
    'bitpanda-ecosystem-token' = 'bitpanda-ecosystem-token',
    'bancor-governance-token' = 'bancor-governance-token',
    'hatayspor-token' = 'hatayspor-token',
    'projectdojo' = 'projectdojo',
    'gmfam' = 'gmfam',
    'bankera-v2' = 'bankera-v2',
    'elon-musk-eth' = 'elon-musk-eth',
    'agility-lsd' = 'agility-lsd',
    'vector-space-biosciences-inc' = 'vector-space-biosciences-inc',
    'tryc' = 'tryc',
    'zksync-labs' = 'zksync-labs',
    'kermit' = 'kermit',
    'wexo' = 'wexo',
    'amlt' = 'amlt',
    'baby-doge-2-token' = 'baby-doge-2-token',
    'swim-spread-wisdom' = 'swim-spread-wisdom',
    'hitbtc-token' = 'hitbtc-token',
    'mybit' = 'mybit',
    'sakaryaspor-token' = 'sakaryaspor-token',
    'shita-kiri-suzume' = 'shita-kiri-suzume',
    'madworld' = 'madworld',
    'pora-ai' = 'pora-ai',
    'mine-ai' = 'mine-ai',
    'baby-shiva' = 'baby-shiva',
    'print-mining' = 'print-mining',
    'tuzlaspor-token' = 'tuzlaspor-token',
    '0xcoco' = '0xcoco',
    'kayserispor-token' = 'kayserispor-token',
    'ouchi' = 'ouchi',
    'socialai' = 'socialai',
    'ruletka' = 'ruletka',
    'fable-of-the-dragon' = 'fable-of-the-dragon',
    'baby-trump' = 'baby-trump',
    'blocktools' = 'blocktools',
    'x8x-token' = 'x8x-token',
    'transactra-finance' = 'transactra-finance',
    'icel-idman-yurdu-token' = 'icel-idman-yurdu-token',
    'xaielonmusk-xyz' = 'xaielonmusk-xyz',
    'floor-cheese-burger' = 'floor-cheese-burger',
    'elon-vitalik-pigs' = 'elon-vitalik-pigs',
    'nifty-league' = 'nifty-league',
    'chaintools' = 'chaintools',
    'pollchain' = 'pollchain',
    'vanspor-token' = 'vanspor-token',
    'tidebit-token' = 'tidebit-token',
    'xrpchain' = 'xrpchain',
    'teh-fund' = 'teh-fund',
    'koipond' = 'koipond',
    'the-blox-project' = 'the-blox-project',
    'trump-tech' = 'trump-tech',
    'koyo' = 'koyo',
    'yobit-token' = 'yobit-token',
    'elon-xmas' = 'elon-xmas',
    'open-mind-network' = 'open-mind-network',
    'trumpmemetoken' = 'trumpmemetoken',
    'ketaicoin' = 'ketaicoin',
    'algory-project' = 'algory-project',
    'rizespor-token' = 'rizespor-token',
    'swot-ai' = 'swot-ai',
    'bok-choy' = 'bok-choy',
    'blob' = 'blob',
    'decentral-games-governance-xdg' = 'decentral-games-governance-xdg',
    'blacksmith-token' = 'blacksmith-token',
    'crocbot' = 'crocbot',
    'happy-birthday-coin' = 'happy-birthday-coin',
    'balikesirspor-token' = 'balikesirspor-token',
    'search' = 'search',
    'god-coin' = 'god-coin',
    'grokx' = 'grokx',
    'defi-stoa' = 'defi-stoa',
    'elon-musk' = 'elon-musk',
    'luna-2-0' = 'luna-2-0',
    'somdej' = 'somdej',
    'generational-wealth' = 'generational-wealth',
    'blocks' = 'blocks',
    'year-of-the-dragon' = 'year-of-the-dragon',
    'boop' = 'boop',
    'buildup' = 'buildup',
    'idea-chain-coin' = 'idea-chain-coin',
    'shiba-' = 'shiba-',
    'neural-radiance-field' = 'neural-radiance-field',
    'mondo-community-coin' = 'mondo-community-coin',
    'mountain-protocol' = 'mountain-protocol',
    'harry' = 'harry',
    'anonify' = 'anonify',
    'icommunity-blockchain-solutions-ibs' = 'icommunity-blockchain-solutions-ibs',
    'rabbitgame' = 'rabbitgame',
    'vnlnk' = 'vnlnk',
    'archangel-token' = 'archangel-token',
    'experty-wisdom-token' = 'experty-wisdom-token',
    'flash-3-0' = 'flash-3-0',
    'mars-network' = 'mars-network',
    'print-the-pepe' = 'print-the-pepe',
    'defi-franc-token' = 'defi-franc-token',
    'eyes-protocol' = 'eyes-protocol',
    'cryptomeda' = 'cryptomeda',
    'shiba-3-0' = 'shiba-3-0',
    'da-pinchi' = 'da-pinchi',
    'catcoin-eth' = 'catcoin-eth',
    'kumadex-token' = 'kumadex-token',
    'trump2024-finance' = 'trump2024-finance',
    'long-eth' = 'long-eth',
    'cnns' = 'cnns',
    'wen-token' = 'wen-token',
    'xai-eth' = 'xai-eth',
    'dogebonk-io' = 'dogebonk-io',
    'the-tribe' = 'the-tribe',
    'marswap' = 'marswap',
    'donald-trump-2-0' = 'donald-trump-2-0',
    'dubx' = 'dubx',
    'salamander' = 'salamander',
    'big-floppa' = 'big-floppa',
    'whaleroom' = 'whaleroom',
    'deepcave' = 'deepcave',
    'xrp-2' = 'xrp-2',
    'zuckpepe' = 'zuckpepe',
    'export-mortos-platform' = 'export-mortos-platform',
    'egg-eth' = 'egg-eth',
    'eterland' = 'eterland',
    'ethscan-ai' = 'ethscan-ai',
    'baby-memecoin' = 'baby-memecoin',
    'vkenaf' = 'vkenaf',
    'encrypgen' = 'encrypgen',
    'dejitaru-shirudo' = 'dejitaru-shirudo',
    'quasa' = 'quasa',
    'kryxivia' = 'kryxivia',
    'wolverinu-new' = 'wolverinu-new',
    'gysr' = 'gysr',
    'mezz-token' = 'mezz-token',
    'riot-racers' = 'riot-racers',
    'floki-vs-pepe' = 'floki-vs-pepe',
    'wrapped-accumulate' = 'wrapped-accumulate',
    'a2a-50x-com' = 'a2a-50x-com',
    'meow-meme' = 'meow-meme',
    'open-trade-exchange' = 'open-trade-exchange',
    'alpha-shards' = 'alpha-shards',
    'tipcoin' = 'tipcoin',
    'antalyaspor-token' = 'antalyaspor-token',
    'dongcoin' = 'dongcoin',
    'solidify' = 'solidify',
    'linkpool' = 'linkpool',
    'cashcab' = 'cashcab',
    'uhive' = 'uhive',
    'copecoin' = 'copecoin',
    'gallerycoin' = 'gallerycoin',
    'waxe' = 'waxe',
    'apollo-ftw' = 'apollo-ftw',
    'lezgi-token' = 'lezgi-token',
    'torex' = 'torex',
    'ccore' = 'ccore',
    'met-a-meta-metameme' = 'met-a-meta-metameme',
    'reddit' = 'reddit',
    'trumpjr' = 'trumpjr',
    'dingo-token-new' = 'dingo-token-new',
    'giresunspor-token' = 'giresunspor-token',
    'solareum-eth' = 'solareum-eth',
    'tokenize-xchange' = 'tokenize-xchange',
    'bermuda' = 'bermuda',
    'hyper-blockchain' = 'hyper-blockchain',
    'daystarter' = 'daystarter',
    'memecoin-2-0' = 'memecoin-2-0',
    'elis' = 'elis',
    'moxy-io' = 'moxy-io',
    'grom' = 'grom',
    'decentraweb' = 'decentraweb',
    'etherempires' = 'etherempires',
    'xrise' = 'xrise',
    'apy-vision' = 'apy-vision',
    'beforecoinmarketcap' = 'beforecoinmarketcap',
    'the-rug-game' = 'the-rug-game',
    'potion-404' = 'potion-404',
    'smartcredit-token' = 'smartcredit-token',
    'hachikoinu' = 'hachikoinu',
    'echo-bot' = 'echo-bot',
    'new-order' = 'new-order',
    'urfaspor-token' = 'urfaspor-token',
    'sanshu-inu' = 'sanshu-inu',
    'ethereum-2' = 'ethereum-2',
    'versace-eth' = 'versace-eth',
    'immopet-token' = 'immopet-token',
    'looted-network' = 'looted-network',
    'ordinalsfi' = 'ordinalsfi',
    'beg' = 'beg',
    'auctus' = 'auctus',
    'bull-market' = 'bull-market',
    'erzurumspor-token' = 'erzurumspor-token',
    'mandala-exchange-token' = 'mandala-exchange-token',
    'soraeth-ai' = 'soraeth-ai',
    'haram' = 'haram',
    'alchemist' = 'alchemist',
    'hummingbot' = 'hummingbot',
    'freechat' = 'freechat',
    'renzec' = 'renzec',
    'gtavi' = 'gtavi',
    'decloud' = 'decloud',
    'modai' = 'modai',
    'huobi-pool-token' = 'huobi-pool-token',
    'kirby-inu' = 'kirby-inu',
    'athenas-ai' = 'athenas-ai',
    'spindle' = 'spindle',
    'yama-inu' = 'yama-inu',
    'satoshis-vision' = 'satoshis-vision',
    'freight-trust-clearing-network' = 'freight-trust-clearing-network',
    'jenny-metaverse-dao-token' = 'jenny-metaverse-dao-token',
    'etf-ethereum' = 'etf-ethereum',
    'skillchain' = 'skillchain',
    'shila-inu' = 'shila-inu',
    'aloha' = 'aloha',
    'the-hustle-app' = 'the-hustle-app',
    'tronipay' = 'tronipay',
    'half-shiba-inu' = 'half-shiba-inu',
    'monnos' = 'monnos',
    'dextoken' = 'dextoken',
    'magacoin-net' = 'magacoin-net',
    'pepe3-0' = 'pepe3-0',
    'waste-coin' = 'waste-coin',
    'tr3zor' = 'tr3zor',
    'yeni-malatyaspor-token' = 'yeni-malatyaspor-token',
    'demeter-chain' = 'demeter-chain',
    'global-gaming' = 'global-gaming',
    'flama' = 'flama',
    'jpycoin' = 'jpycoin',
    'longtotem' = 'longtotem',
    'doge-protocol' = 'doge-protocol',
    'arbidex' = 'arbidex',
    'dogpad-finance' = 'dogpad-finance',
    'scanetchain' = 'scanetchain',
    'axia-protocol' = 'axia-protocol',
    'generation' = 'generation',
    'tail' = 'tail',
    'memefi-toybox-404' = 'memefi-toybox-404',
    'continuum-world' = 'continuum-world',
    'x-tech' = 'x-tech',
    'elon-mars-x' = 'elon-mars-x',
    'ncop' = 'ncop',
    'xrpmoonpepeinuhomersimpsondeveloper' = 'xrpmoonpepeinuhomersimpsondeveloper',
    'guise' = 'guise',
    'camp' = 'camp',
    'limestone-network' = 'limestone-network',
    'vinu-network' = 'vinu-network',
    'citios' = 'citios',
    'bibox-token' = 'bibox-token',
    'degenx' = 'degenx',
    'xshib' = 'xshib',
    'moeda-loyalty-points' = 'moeda-loyalty-points',
    'befy-protocol' = 'befy-protocol',
    'mdsquare' = 'mdsquare',
    'ezystayz' = 'ezystayz',
    'mission-helios' = 'mission-helios',
    'pac-man' = 'pac-man',
    'vendit' = 'vendit',
    'qawalla' = 'qawalla',
    'pablo-defi' = 'pablo-defi',
    'unium' = 'unium',
    'mmaon' = 'mmaon',
    'nothing-token' = 'nothing-token',
    'libfx' = 'libfx',
    'tilwiki' = 'tilwiki',
    'buzzshow' = 'buzzshow',
    'xank' = 'xank',
    'greentek' = 'greentek',
    'intexcoin' = 'intexcoin',
    'goldenugget' = 'goldenugget',
    'titi-protocol' = 'titi-protocol',
    'usd-bancor' = 'usd-bancor',
    'bestay' = 'bestay',
    'pre-retogeum' = 'pre-retogeum',
    'digex' = 'digex',
    'pundi-x' = 'pundi-x',
    'answer-governance' = 'answer-governance',
    'hedgetrade' = 'hedgetrade',
    'satopay-network' = 'satopay-network',
    'dynex-gpu' = 'dynex-gpu',
    'bdid' = 'bdid',
    'grok-xai' = 'grok-xai',
    'prostarter' = 'prostarter',
    'bnsd-finance' = 'bnsd-finance',
    'colibri' = 'colibri',
    'fancy-games' = 'fancy-games',
    'new-crypto-village-accelerator' = 'new-crypto-village-accelerator',
    'seele' = 'seele',
    'uttoken' = 'uttoken',
    'meme-coin' = 'meme-coin',
    'baby-maga-2' = 'baby-maga-2',
    'rugame' = 'rugame',
    'substratum' = 'substratum',
    'epanus' = 'epanus',
    'bright-union' = 'bright-union',
    'u-network' = 'u-network',
    'hiveterminal-token' = 'hiveterminal-token',
    'cdbio' = 'cdbio',
    'cryption-network' = 'cryption-network',
    'metropoly' = 'metropoly',
    'mindol' = 'mindol',
    'ballswap' = 'ballswap',
    'grn' = 'grn',
    'belacoin' = 'belacoin',
    'xaurum' = 'xaurum',
    'bitcrystals' = 'bitcrystals',
    'digixdao' = 'digixdao',
    'wings' = 'wings',
    'swarm-city' = 'swarm-city',
    'edgeless' = 'edgeless',
    'trust' = 'trust',
    'speedcash' = 'speedcash',
    'lunyr' = 'lunyr',
    'monolith' = 'monolith',
    'patientory' = 'patientory',
    'polybius' = 'polybius',
    'santiment' = 'santiment',
    'skincoin' = 'skincoin',
    'investfeed' = 'investfeed',
    'poet' = 'poet',
    'agrello-delta' = 'agrello-delta',
    'indorse-token' = 'indorse-token',
    'xpa' = 'xpa',
    'kyber-network-crystal-legacy' = 'kyber-network-crystal-legacy',
    'vibe' = 'vibe',
    'rivetz' = 'rivetz',
    'cobinhood' = 'cobinhood',
    'ethereum-blue' = 'ethereum-blue',
    'exrnchain' = 'exrnchain',
    'iethereum' = 'iethereum',
    'flypme' = 'flypme',
    'ibtc' = 'ibtc',
    'atlant' = 'atlant',
    'eltcoin' = 'eltcoin',
    'upfiring' = 'upfiring',
    'privatix' = 'privatix',
    'paypex' = 'paypex',
    'eroscoin' = 'eroscoin',
    'bonpay' = 'bonpay',
    'playkey' = 'playkey',
    'micromoney' = 'micromoney',
    'lockchain' = 'lockchain',
    'worldcore' = 'worldcore',
    'starbase' = 'starbase',
    'sirin-labs-token' = 'sirin-labs-token',
    'neumark' = 'neumark',
    'appcoins' = 'appcoins',
    'aichain' = 'aichain',
    'arbitragect' = 'arbitragect',
    'insurepal' = 'insurepal',
    'hydro-protocol' = 'hydro-protocol',
    'indahash' = 'indahash',
    'aidcoin' = 'aidcoin',
    'axpr-token' = 'axpr-token',
    'dmarket' = 'dmarket',
    'wepower' = 'wepower',
    'neurotoken' = 'neurotoken',
    'startercoin' = 'startercoin',
    'tokenomy' = 'tokenomy',
    'nanjcoin' = 'nanjcoin',
    'blitzpredict' = 'blitzpredict',
    'friends' = 'friends',
    'rentberry' = 'rentberry',
    'smart-mfg' = 'smart-mfg',
    'fintrux-network' = 'fintrux-network',
    'daex' = 'daex',
    'transcodium' = 'transcodium',
    'amon' = 'amon',
    'flip' = 'flip',
    'mytoken' = 'mytoken',
    'zippie' = 'zippie',
    'daostack' = 'daostack',
    'global-social-chain' = 'global-social-chain',
    'digix-gold-token' = 'digix-gold-token',
    'sakura-bloom' = 'sakura-bloom',
    'joint-ventures' = 'joint-ventures',
    'datarius-credit' = 'datarius-credit',
    'smartshare' = 'smartshare',
    'zinc' = 'zinc',
    'kanadecoin' = 'kanadecoin',
    'otcbtc-token' = 'otcbtc-token',
    'karatgold-coin' = 'karatgold-coin',
    'tourist-token' = 'tourist-token',
    'vikkytoken' = 'vikkytoken',
    'wabnetwork' = 'wabnetwork',
    'thore-cash' = 'thore-cash',
    'xovbank' = 'xovbank',
    'pkg-token' = 'pkg-token',
    'igtoken' = 'igtoken',
    'siacashcoin' = 'siacashcoin',
    'blockpass' = 'blockpass',
    'netkoin' = 'netkoin',
    'obitan-chain' = 'obitan-chain',
    'pumapay' = 'pumapay',
    'heartbout' = 'heartbout',
    'showhand' = 'showhand',
    'kingxchain' = 'kingxchain',
    'veridocglobal' = 'veridocglobal',
    'yuki' = 'yuki',
    'futurax' = 'futurax',
    'abcc-token' = 'abcc-token',
    'fire-lotto' = 'fire-lotto',
    'cybermusic' = 'cybermusic',
    'bitether' = 'bitether',
    'evencoin' = 'evencoin',
    'havy' = 'havy',
    'realtract' = 'realtract',
    'abulaba' = 'abulaba',
    'mobilinktoken' = 'mobilinktoken',
    'cryptrust' = 'cryptrust',
    'robotina' = 'robotina',
    'cmitcoin' = 'cmitcoin',
    'qchi' = 'qchi',
    'tronclassic' = 'tronclassic',
    'knekted' = 'knekted',
    'neural-protocol' = 'neural-protocol',
    'wixlar' = 'wixlar',
    'zenswap-network-token' = 'zenswap-network-token',
    'decentralized-asset-trading-platform' = 'decentralized-asset-trading-platform',
    'truedeck' = 'truedeck',
    'model-x-coin' = 'model-x-coin',
    'waletoken' = 'waletoken',
    'alpha-coin' = 'alpha-coin',
    'playgame' = 'playgame',
    'travelnote' = 'travelnote',
    'shivers' = 'shivers',
    'herbalist-token' = 'herbalist-token',
    'quinads' = 'quinads',
    'the-currency-analytics' = 'the-currency-analytics',
    'playchip' = 'playchip',
    'btu-protocol' = 'btu-protocol',
    'decentralized-crypto-token' = 'decentralized-crypto-token',
    'save-environment-token' = 'save-environment-token',
    'customcontractnetwork' = 'customcontractnetwork',
    'boat-pilot-token' = 'boat-pilot-token',
    'litecoin-token' = 'litecoin-token',
    'ethereum-gold-project' = 'ethereum-gold-project',
    'botxcoin' = 'botxcoin',
    'tratok' = 'tratok',
    'ethplode' = 'ethplode',
    'maya-preferred' = 'maya-preferred',
    'eos-trust' = 'eos-trust',
    'globex' = 'globex',
    'bitcoinregular' = 'bitcoinregular',
    '12ships' = '12ships',
    'bitcoinsov' = 'bitcoinsov',
    'finexboxtoken' = 'finexboxtoken',
    'aerotoken' = 'aerotoken',
    'apple-network' = 'apple-network',
    'afrodex' = 'afrodex',
    'compound-dai' = 'compound-dai',
    'goaltime-n' = 'goaltime-n',
    'b-one-payment' = 'b-one-payment',
    'coinloan' = 'coinloan',
    'bali-coin' = 'bali-coin',
    'jack-token' = 'jack-token',
    'rebit' = 'rebit',
    'litecoin-sv' = 'litecoin-sv',
    'dxdao' = 'dxdao',
    'cryptobet' = 'cryptobet',
    'dmm-governance' = 'dmm-governance',
    'stpay' = 'stpay',
    'coinzoom' = 'coinzoom',
    'digimax-dgmt' = 'digimax-dgmt',
    'defipie' = 'defipie',
    'jubi-token' = 'jubi-token',
    'stable-asset' = 'stable-asset',
    'upper-euro' = 'upper-euro',
    'upper-pound' = 'upper-pound',
    'upper-dollar' = 'upper-dollar',
    'betherchip' = 'betherchip',
    'crypto-accept' = 'crypto-accept',
    'cvault-finance' = 'cvault-finance',
    'help-coin' = 'help-coin',
    'auric-network' = 'auric-network',
    'defidollar-dao' = 'defidollar-dao',
    'libartysharetoken' = 'libartysharetoken',
    'goldfinx' = 'goldfinx',
    'folder-protocol' = 'folder-protocol',
    'unicap-finance' = 'unicap-finance',
    'dgpayment' = 'dgpayment',
    'guarded-ether' = 'guarded-ether',
    'alpha5' = 'alpha5',
    'hub-human-trust-protocol' = 'hub-human-trust-protocol',
    'spiderdao' = 'spiderdao',
    'unique-one' = 'unique-one',
    'bao-finance' = 'bao-finance',
    'potentiam' = 'potentiam',
    'marginswap' = 'marginswap',
    'luxochain' = 'luxochain',
    'bt-finance' = 'bt-finance',
    'advertise-coin' = 'advertise-coin',
    'cad-coin' = 'cad-coin',
    'eco' = 'eco',
    'eddaswap' = 'eddaswap',
    'impulseven' = 'impulseven',
    'stater' = 'stater',
    'delta-finance' = 'delta-finance',
    'daohaus' = 'daohaus',
    'stone-token' = 'stone-token',
    'xfai' = 'xfai',
    'frogex' = 'frogex',
    'community-business-token' = 'community-business-token',
    'wrapped-statera' = 'wrapped-statera',
    'unicly' = 'unicly',
    'xiglute-coin' = 'xiglute-coin',
    'curio-stable-coin' = 'curio-stable-coin',
    'liti-capital' = 'liti-capital',
    'yieldly' = 'yieldly',
    'boost-coin' = 'boost-coin',
    'unreal-finance' = 'unreal-finance',
    'cardwallet' = 'cardwallet',
    'crypto-carbon-energy' = 'crypto-carbon-energy',
    'raid-token' = 'raid-token',
    'bright-token' = 'bright-token',
    'shibaverse' = 'shibaverse',
    'falcon-9' = 'falcon-9',
    'dog-collar' = 'dog-collar',
    'wrapped-curio-ferrari-f12tdf' = 'wrapped-curio-ferrari-f12tdf',
    'universal-basic-income' = 'universal-basic-income',
    'piccolo-inu' = 'piccolo-inu',
    'btrips' = 'btrips',
    'chilliswap' = 'chilliswap',
    'jacy' = 'jacy',
    'follow-token' = 'follow-token',
    'upper-swiss-franc' = 'upper-swiss-franc',
    'keys-token' = 'keys-token',
    'straitsx-indonesia-rupiah' = 'straitsx-indonesia-rupiah',
    'firulais' = 'firulais',
    'metabrands' = 'metabrands',
    'kryza-exchange' = 'kryza-exchange',
    'lit' = 'lit',
    'empower' = 'empower',
    'mongoose' = 'mongoose',
    'devita-global' = 'devita-global',
    'x' = 'x',
    'elon-goat' = 'elon-goat',
    'metahero-universe-pow' = 'metahero-universe-pow',
    'londoncoingold' = 'londoncoingold',
    'dream' = 'dream',
    'apollo-inu' = 'apollo-inu',
    'musk-gold' = 'musk-gold',
    'meme-kong' = 'meme-kong',
    'envision' = 'envision',
    'bitazza' = 'bitazza',
    'lyfe-silver' = 'lyfe-silver',
    'floordao' = 'floordao',
    'stakewise-reth2' = 'stakewise-reth2',
    'telefy' = 'telefy',
    'crypviser' = 'crypviser',
    'auditchain' = 'auditchain',
    'lyfe-gold' = 'lyfe-gold',
    'edenloop' = 'edenloop',
    'blockblend' = 'blockblend',
    'viktama' = 'viktama',
    'vehicle-mining-system' = 'vehicle-mining-system',
    'maximus-token' = 'maximus-token',
    'omniaverse' = 'omniaverse',
    'talent' = 'talent',
    'hitop' = 'hitop',
    'coinghost' = 'coinghost',
    'saltmarble' = 'saltmarble',
    'usd-freedom' = 'usd-freedom',
    'microchains-gov-token' = 'microchains-gov-token',
    'allpaycoin' = 'allpaycoin',
    'steed' = 'steed',
    'concentrator-token' = 'concentrator-token',
    'jpgold-coin' = 'jpgold-coin',
    'muu' = 'muu',
    'beauty-bakery-linked-operation-transaction-technology' = 'beauty-bakery-linked-operation-transaction-technology',
    'icosa' = 'icosa',
    'clever-token' = 'clever-token',
    'x7dao' = 'x7dao',
    'x7r' = 'x7r',
    'serum-ser' = 'serum-ser',
    'cold-chain' = 'cold-chain',
    'ushi' = 'ushi',
    'zudgezury' = 'zudgezury',
    'spider-tanks' = 'spider-tanks',
    'stake-link' = 'stake-link',
    'advantis' = 'advantis',
    'protocol-zero' = 'protocol-zero',
    'lyfe-v2' = 'lyfe-v2',
    'pulsebitcoin' = 'pulsebitcoin',
    'archive-ai' = 'archive-ai',
    'stablz' = 'stablz',
    'eggs-care' = 'eggs-care',
    'zktsunami' = 'zktsunami',
    'goerli-eth' = 'goerli-eth',
    'tradix' = 'tradix',
    'doke-inu' = 'doke-inu',
    'tuf-token' = 'tuf-token',
    'formula-inu' = 'formula-inu',
    'sigil-finance' = 'sigil-finance',
    'shibarium-name-service' = 'shibarium-name-service',
    'zero-liquid' = 'zero-liquid',
    'hairdao' = 'hairdao',
    'catgirl-optimus' = 'catgirl-optimus',
    'meeds' = 'meeds',
    'crypto-sdg' = 'crypto-sdg',
    'truthgpt' = 'truthgpt',
    'laelaps' = 'laelaps',
    'deelance' = 'deelance',
    'pepechain' = 'pepechain',
    'wsb-coin' = 'wsb-coin',
    'wallstreetbets-classic' = 'wallstreetbets-classic',
    'pepeai' = 'pepeai',
    'smol-su' = 'smol-su',
    'blaze-token' = 'blaze-token',
    'smudge-lord' = 'smudge-lord',
    'larry-coin' = 'larry-coin',
    'wewe' = 'wewe',
    'ngmi-coin' = 'ngmi-coin',
    'masya' = 'masya',
    'frens' = 'frens',
    'yukky' = 'yukky',
    'all-your-base' = 'all-your-base',
    'a-fund-baby' = 'a-fund-baby',
    'gatsbyinu' = 'gatsbyinu',
    'president-ron-desantis' = 'president-ron-desantis',
    'kiko' = 'kiko',
    'trace-ai' = 'trace-ai',
    'geniecoin' = 'geniecoin',
    'rin-tin-tin' = 'rin-tin-tin',
    'arweave-name-service' = 'arweave-name-service',
    'florky' = 'florky',
    'fat-cat-killer1' = 'fat-cat-killer1',
    'bart-simpson' = 'bart-simpson',
    'farmer-friends' = 'farmer-friends',
    'davecoin-io' = 'davecoin-io',
    'lisa-simpson' = 'lisa-simpson',
    'jesus-raptor' = 'jesus-raptor',
    'saitabit' = 'saitabit',
    'alien-milady-fumo' = 'alien-milady-fumo',
    'gemach' = 'gemach',
    '9gag' = '9gag',
    'sheesh' = 'sheesh',
    'nexai' = 'nexai',
    'ethane' = 'ethane',
    'shib2-0' = 'shib2-0',
    'harrypotterobamasonic10inu2-0' = 'harrypotterobamasonic10inu2-0',
    'shibadogepepe' = 'shibadogepepe',
    'jared-from-subway' = 'jared-from-subway',
    'genieswap' = 'genieswap',
    'bob-2-0' = 'bob-2-0',
    'bobo-2-0' = 'bobo-2-0',
    'truthgpt-eth' = 'truthgpt-eth',
    'dogelon-mars-2-0' = 'dogelon-mars-2-0',
    'wrapped-pepe' = 'wrapped-pepe',
    'victory-impact-coin' = 'victory-impact-coin',
    'thug-life' = 'thug-life',
    'hamsters' = 'hamsters',
    'pepe-chain-2' = 'pepe-chain-2',
    'xrp2' = 'xrp2',
    'xdoge-vip' = 'xdoge-vip',
    'snek-on-eth' = 'snek-on-eth',
    'x-com' = 'x-com',
    'chatter-shield' = 'chatter-shield',
    'test' = 'test',
    'strongx' = 'strongx',
    'none-trading' = 'none-trading',
    'beautiful-princess-disorder' = 'beautiful-princess-disorder',
    'shares-finance' = 'shares-finance',
    'mixaverse' = 'mixaverse',
    'ultimate-tipbot' = 'ultimate-tipbot',
    'niccagewaluigielmo42069inu' = 'niccagewaluigielmo42069inu',
    'dejitaru-hoshi' = 'dejitaru-hoshi',
    'barbiecrashbandicootrfk777inu' = 'barbiecrashbandicootrfk777inu',
    'wojak-269' = 'wojak-269',
    'bookiebot' = 'bookiebot',
    'curve-inu' = 'curve-inu',
    'magnesium' = 'magnesium',
    'jrvgcupvsc' = 'jrvgcupvsc',
    'hydra-coin' = 'hydra-coin',
    'viralsniper' = 'viralsniper',
    'wall-street-bets-wsb' = 'wall-street-bets-wsb',
    'joebiden2024' = 'joebiden2024',
    'emoticoin-new' = 'emoticoin-new',
    'silver-eth' = 'silver-eth',
    'robin-hood' = 'robin-hood',
    'bugatti-coin' = 'bugatti-coin',
    'gucci-eth' = 'gucci-eth',
    'eth-2-0-token' = 'eth-2-0-token',
    'xdoge-eth' = 'xdoge-eth',
    'xinu-eth' = 'xinu-eth',
    'reserve-eth' = 'reserve-eth',
    'r-dee-protocol' = 'r-dee-protocol',
    'gravitas' = 'gravitas',
    'btex' = 'btex',
    'fido-erc' = 'fido-erc',
    'erc20rock' = 'erc20rock',
    'dogeboy' = 'dogeboy',
    'peipeicn' = 'peipeicn',
    'simplehub' = 'simplehub',
    'javelin' = 'javelin',
    'stoicdao' = 'stoicdao',
    'golden-zen-token' = 'golden-zen-token',
    'x-com-2023' = 'x-com-2023',
    'panda' = 'panda',
    'deflect-harbor-ai' = 'deflect-harbor-ai',
    'milei' = 'milei',
    'rottycoin' = 'rottycoin',
    'uni-terminal' = 'uni-terminal',
    'green' = 'green',
    'wolf-of-wall-street-eth' = 'wolf-of-wall-street-eth',
    'foox' = 'foox',
    'lemon-terminal' = 'lemon-terminal',
    'fluid-token' = 'fluid-token',
    'two-lands' = 'two-lands',
    'wisdom-bank-network' = 'wisdom-bank-network',
    'molly-coin' = 'molly-coin',
    'silly-dragon-co' = 'silly-dragon-co',
    'bitbullbot' = 'bitbullbot',
    'bundl-tools' = 'bundl-tools',
    'siphon-life-spell' = 'siphon-life-spell',
    'luna28' = 'luna28',
    'xrealm-ai' = 'xrealm-ai',
    'xpayment' = 'xpayment',
    'etherrock404' = 'etherrock404',
    'rugged-art' = 'rugged-art',
    'avatar-404' = 'avatar-404',
    'pepeye-2-0' = 'pepeye-2-0',
    'wrapped-cellmates' = 'wrapped-cellmates',
    'sora-porn' = 'sora-porn',
    'virtumate' = 'virtumate',
    'paradox' = 'paradox',
    'error404-meme' = 'error404-meme',
    'maga-2-0' = 'maga-2-0',
    'criminal-flamingo' = 'criminal-flamingo',
    'polybet' = 'polybet',
    'maga-trump-me' = 'maga-trump-me',
    'diamond-coin' = 'diamond-coin',
    'aimee' = 'aimee',
    'shin-chan' = 'shin-chan',
    'x-ratio-ai' = 'x-ratio-ai',
    'grow' = 'grow',
    'bomb-shelter-inu' = 'bomb-shelter-inu',
    'iryde-coin' = 'iryde-coin',
    'dibbles' = 'dibbles',
    'trump-doge' = 'trump-doge',
    'donald-the-trump' = 'donald-the-trump',
    'caesars-arena' = 'caesars-arena',
    'ionic-pocket-token' = 'ionic-pocket-token',
    'cronos' = 'cronos',
    'flow' = 'flow',
    'osmosis' = 'osmosis',
    'wax' = 'wax',
    'creditcoin' = 'creditcoin',
    'alephium' = 'alephium',
    'fasttoken' = 'fasttoken',
    'phala-network' = 'phala-network',
    'radworks' = 'radworks',
    'forta' = 'forta',
    'smog' = 'smog',
    'elastos' = 'elastos',
    'metadium' = 'metadium',
    'wirex-token' = 'wirex-token',
    'boba-network' = 'boba-network',
    'astrafer' = 'astrafer',
    'dimo' = 'dimo',
    'irisnet' = 'irisnet',
    'quickswap-new' = 'quickswap-new',
    'sentinel' = 'sentinel',
    'coinex-token' = 'coinex-token',
    'graphlinq-protocol' = 'graphlinq-protocol',
    'mintlayer' = 'mintlayer',
    'peercoin' = 'peercoin',
    'bytom' = 'bytom',
    'tokemak' = 'tokemak',
    'pip' = 'pip',
    'ambire-wallet' = 'ambire-wallet',
    'meverse' = 'meverse',
    'energi' = 'energi',
    'divi' = 'divi',
    'zenon' = 'zenon',
    'shyft-network' = 'shyft-network',
    'amulet' = 'amulet',
    'nash' = 'nash',
    'bosagora' = 'bosagora',
    'anchor-protocol' = 'anchor-protocol',
    'forest-knight' = 'forest-knight',
    'publish' = 'publish',
    'x42-protocol' = 'x42-protocol',
    'centaur' = 'centaur',
    'sifchain' = 'sifchain',
    'sator' = 'sator',
    'aquariuscoin' = 'aquariuscoin',
    'gaj-finance' = 'gaj-finance',
    'moneta-digital' = 'moneta-digital',
    'seamless' = 'seamless',
    'wrapped-canto' = 'wrapped-canto',
    'payprotocol' = 'payprotocol',
    'tonup' = 'tonup',
    'evmos' = 'evmos',
    'tenet-protocol' = 'tenet-protocol',
    'bitkub-coin' = 'bitkub-coin',
    'corgiai' = 'corgiai',
    'koi-finance' = 'koi-finance',
    'rond' = 'rond',
    'lfg' = 'lfg',
    'puff-the-dragon' = 'puff-the-dragon',
    'xx-network' = 'xx-network',
    'juno' = 'juno',
    'raini-studios-token' = 'raini-studios-token',
    'mineplex' = 'mineplex',
    'btc-proxy' = 'btc-proxy',
    'power' = 'power',
    'xfund' = 'xfund',
    'ion' = 'ion',
    'polkaswap' = 'polkaswap',
    'flux-dao' = 'flux-dao',
    'jd-coin' = 'jd-coin',
    'bitcanna' = 'bitcanna',
    'e-money' = 'e-money',
    'polygen' = 'polygen',
    'sovi-finance' = 'sovi-finance',
    'gnft' = 'gnft',
    'etherlite' = 'etherlite',
    'aelin' = 'aelin',
    'staked-usdt' = 'staked-usdt',
    'manna' = 'manna',
    'goldmint' = 'goldmint',
    'poa' = 'poa',
    'charg-coin' = 'charg-coin',
    'sonocoin' = 'sonocoin',
    'baby-jesus' = 'baby-jesus',
    'wrapped-everscale' = 'wrapped-everscale',
    'octus-bridge' = 'octus-bridge',
    'flatqube' = 'flatqube',
    'everstart' = 'everstart',
    'kaspa' = 'kaspa',
    'klaytn' = 'klaytn',
    'webuy' = 'webuy',
    'defi-kingdoms' = 'defi-kingdoms',
    'klayswap-protocol' = 'klayswap-protocol',
    'superwalk' = 'superwalk',
    'observer' = 'observer',
    'temco' = 'temco',
    'azit' = 'azit',
    'seloplus' = 'seloplus',
    'klaydice' = 'klaydice',
    'kleva-protocol' = 'kleva-protocol',
    'galaxia' = 'galaxia',
    'somesing' = 'somesing',
    'drawshop-kingdom-reverse' = 'drawshop-kingdom-reverse',
    'hiblocks' = 'hiblocks',
    'gemhub' = 'gemhub',
    'psub' = 'psub',
    'conun-io' = 'conun-io',
    'meta-dance' = 'meta-dance',
    'favor' = 'favor',
    'mineral' = 'mineral',
    'lemon' = 'lemon',
    'metamafia' = 'metamafia',
    'musicn' = 'musicn',
    'gametree' = 'gametree',
    'round-x' = 'round-x',
    '3-kingdoms-multiverse' = '3-kingdoms-multiverse',
    'another-world' = 'another-world',
    'orbit-bridge-klaytn-usdc' = 'orbit-bridge-klaytn-usdc',
    'tari-world' = 'tari-world',
    'orbit-bridge-klaytn-orbit-chain' = 'orbit-bridge-klaytn-orbit-chain',
    'asan-verse' = 'asan-verse',
    'walk-token' = 'walk-token',
    'd-shop' = 'd-shop',
    'box' = 'box',
    'klaymeta' = 'klaymeta',
    'monstock' = 'monstock',
    'claimswap' = 'claimswap',
    'perproject' = 'perproject',
    'aurora-klay' = 'aurora-klay',
    'dsc-mix' = 'dsc-mix',
    'meta-kongz' = 'meta-kongz',
    'lemonchain' = 'lemonchain',
    'hello-art' = 'hello-art',
    'avatara' = 'avatara',
    'ducato-protocol-token' = 'ducato-protocol-token',
    'cojam' = 'cojam',
    'blue-baikal' = 'blue-baikal',
    'klaymore-stakehouse' = 'klaymore-stakehouse',
    'nikplace' = 'nikplace',
    'centercoin' = 'centercoin',
    'klayfi-finance' = 'klayfi-finance',
    'krome-shares' = 'krome-shares',
    'cryptokki' = 'cryptokki',
    'herbee' = 'herbee',
    'orbit-bridge-klaytn-handy' = 'orbit-bridge-klaytn-handy',
    'defi-kingdoms-jade' = 'defi-kingdoms-jade',
    'swapscanner' = 'swapscanner',
    'bora' = 'bora',
    'amo-coin' = 'amo-coin',
    'treecle' = 'treecle',
    'wrapped-klaytn' = 'wrapped-klaytn',
    'angry-bulls-club' = 'angry-bulls-club',
    'nakamoto-games' = 'nakamoto-games',
    'art-de-finance' = 'art-de-finance',
    'voxies' = 'voxies',
    'vicicoin' = 'vicicoin',
    'farcana' = 'farcana',
    'drunk-robots' = 'drunk-robots',
    'wifi-map' = 'wifi-map',
    'carrieverse' = 'carrieverse',
    'klimadao' = 'klimadao',
    'kasta' = 'kasta',
    'bim' = 'bim',
    'affyn' = 'affyn',
    'medieval-empires' = 'medieval-empires',
    'swisscheese' = 'swisscheese',
    'uponly' = 'uponly',
    'matr1x-fire' = 'matr1x-fire',
    'rain-coin' = 'rain-coin',
    'soil' = 'soil',
    'metabeat' = 'metabeat',
    'permission-coin' = 'permission-coin',
    'rainbowtoken' = 'rainbowtoken',
    'captain-tsubasa' = 'captain-tsubasa',
    'algebra' = 'algebra',
    'cipher-v2' = 'cipher-v2',
    'digital-fitness' = 'digital-fitness',
    'rebel-bots' = 'rebel-bots',
    'dogami' = 'dogami',
    'cleo-tech' = 'cleo-tech',
    'battle-world' = 'battle-world',
    'metatrace' = 'metatrace',
    'aimedis-new' = 'aimedis-new',
    'ideaology' = 'ideaology',
    'bitcone' = 'bitcone',
    'polylastic' = 'polylastic',
    'onerare' = 'onerare',
    'the-unfettered' = 'the-unfettered',
    'aki-network' = 'aki-network',
    'mars-token' = 'mars-token',
    'catheon-gaming' = 'catheon-gaming',
    'playermon' = 'playermon',
    'islamicoin' = 'islamicoin',
    'frontfanz-fanx' = 'frontfanz-fanx',
    'revolt-2-earn' = 'revolt-2-earn',
    'polker' = 'polker',
    'gravity-finance' = 'gravity-finance',
    'metasoccer' = 'metasoccer',
    'galaxy-fight-club' = 'galaxy-fight-club',
    'dinoswap' = 'dinoswap',
    'decentral-games-ice' = 'decentral-games-ice',
    'grabcoinclub' = 'grabcoinclub',
    'skyplay' = 'skyplay',
    'airtnt' = 'airtnt',
    'lithosphere' = 'lithosphere',
    'crypto-sports' = 'crypto-sports',
    'genesis-worlds' = 'genesis-worlds',
    'pigcoin' = 'pigcoin',
    'uzx' = 'uzx',
    'websea' = 'websea',
    'clfi' = 'clfi',
    'meta-plus-token' = 'meta-plus-token',
    'ai-pepe-king' = 'ai-pepe-king',
    'seamans-token' = 'seamans-token',
    '300fit-network' = '300fit-network',
    'smell-token' = 'smell-token',
    'polyalpha-finance' = 'polyalpha-finance',
    'surfboard-finance' = 'surfboard-finance',
    'gone' = 'gone',
    'icon-x-world' = 'icon-x-world',
    'enegra-egx' = 'enegra-egx',
    'creta-world' = 'creta-world',
    'geodnet' = 'geodnet',
    'miracle-play' = 'miracle-play',
    'firestarter' = 'firestarter',
    'candy-pocket' = 'candy-pocket',
    'africarare-ubuntu' = 'africarare-ubuntu',
    'game-meteor-coin' = 'game-meteor-coin',
    'ethernity-cloud' = 'ethernity-cloud',
    'metavault-trade' = 'metavault-trade',
    'ipmb' = 'ipmb',
    'oshi-token' = 'oshi-token',
    'bounty-temple' = 'bounty-temple',
    'polydoge' = 'polydoge',
    'envida' = 'envida',
    'crepe-coin' = 'crepe-coin',
    'leandro-lopes' = 'leandro-lopes',
    'decentrawood' = 'decentrawood',
    'versus-x' = 'versus-x',
    'digital-swis-franc' = 'digital-swis-franc',
    'carnomaly-new' = 'carnomaly-new',
    'proxy' = 'proxy',
    'bullieverse' = 'bullieverse',
    'fidira' = 'fidira',
    'sheertopia' = 'sheertopia',
    'pixelworldcoin' = 'pixelworldcoin',
    'filmio' = 'filmio',
    'tanpin' = 'tanpin',
    'ruufcoin' = 'ruufcoin',
    'floyx' = 'floyx',
    'turnup' = 'turnup',
    'aeternus' = 'aeternus',
    'culo' = 'culo',
    'vendetta' = 'vendetta',
    'geniux' = 'geniux',
    'floxypay' = 'floxypay',
    'luxy' = 'luxy',
    'bombcrypto-coin' = 'bombcrypto-coin',
    'xactrewards' = 'xactrewards',
    'attack-wagon' = 'attack-wagon',
    'uforika' = 'uforika',
    'nodewaves' = 'nodewaves',
    'goldpesa' = 'goldpesa',
    'kridafans' = 'kridafans',
    'sunflower-land' = 'sunflower-land',
    'ix-token' = 'ix-token',
    'tetu' = 'tetu',
    'jennyco' = 'jennyco',
    'iron-titanium-token' = 'iron-titanium-token',
    'aventis-metaverse' = 'aventis-metaverse',
    'aree-shards' = 'aree-shards',
    'tmn-global' = 'tmn-global',
    'mt-tower' = 'mt-tower',
    'farmsent' = 'farmsent',
    'umma-token' = 'umma-token',
    'cryptotradingfund' = 'cryptotradingfund',
    'nft-champions' = 'nft-champions',
    'croatian-ff-fan-token' = 'croatian-ff-fan-token',
    'pivn' = 'pivn',
    'torah-network' = 'torah-network',
    'giddy' = 'giddy',
    'toucan-protocol-base-carbon-tonne' = 'toucan-protocol-base-carbon-tonne',
    'artmeta' = 'artmeta',
    'nftbooks' = 'nftbooks',
    'derby-stars' = 'derby-stars',
    'karmaverse' = 'karmaverse',
    'inftspace' = 'inftspace',
    'popkon' = 'popkon',
    'unicorn-milk-token' = 'unicorn-milk-token',
    'tracer' = 'tracer',
    'o-mee' = 'o-mee',
    'magic-shoes' = 'magic-shoes',
    'itsbloc' = 'itsbloc',
    'firebottoken' = 'firebottoken',
    'snook' = 'snook',
    'lucidao' = 'lucidao',
    'ribus' = 'ribus',
    'pegaxy' = 'pegaxy',
    'black-stallion' = 'black-stallion',
    'mammothai' = 'mammothai',
    'monsoon-finance' = 'monsoon-finance',
    'sway-social-protocol' = 'sway-social-protocol',
    'nurcoin' = 'nurcoin',
    'map-node' = 'map-node',
    'milo-project' = 'milo-project',
    'mm-finance-polygon' = 'mm-finance-polygon',
    'moonedge' = 'moonedge',
    'homeety' = 'homeety',
    'bundles-v2' = 'bundles-v2',
    'sponge-token' = 'sponge-token',
    'phantom-of-the-kill' = 'phantom-of-the-kill',
    'jugni' = 'jugni',
    'nextearth' = 'nextearth',
    'letsgobrandoncoin' = 'letsgobrandoncoin',
    'equals9' = 'equals9',
    'pay-it-now' = 'pay-it-now',
    'infinity-skies' = 'infinity-skies',
    'wlitidao' = 'wlitidao',
    'sphere-finance' = 'sphere-finance',
    'nexus-dubai' = 'nexus-dubai',
    'defi-hunters-dao' = 'defi-hunters-dao',
    'msquare-global' = 'msquare-global',
    'pearl-exchange' = 'pearl-exchange',
    'time-raiders' = 'time-raiders',
    'tangible' = 'tangible',
    'ethical-finance' = 'ethical-finance',
    'a51-finance' = 'a51-finance',
    'dragonmaster-dmt' = 'dragonmaster-dmt',
    'gib' = 'gib',
    'amaurot' = 'amaurot',
    'bonkcon' = 'bonkcon',
    'vigorus' = 'vigorus',
    'cri3x' = 'cri3x',
    'web3games' = 'web3games',
    'indu-4-0' = 'indu-4-0',
    'dragonmaster' = 'dragonmaster',
    'gamestation' = 'gamestation',
    'dragon-soul-token' = 'dragon-soul-token',
    'flare-token' = 'flare-token',
    'sheesha-finance-polygon' = 'sheesha-finance-polygon',
    'axion' = 'axion',
    'polycat-finance' = 'polycat-finance',
    'cool-cats-milk' = 'cool-cats-milk',
    'aavegotchi-fomo' = 'aavegotchi-fomo',
    'dux' = 'dux',
    'poodl-exchange-token' = 'poodl-exchange-token',
    'aavegotchi-kek' = 'aavegotchi-kek',
    'ovalpixel' = 'ovalpixel',
    'fitmint' = 'fitmint',
    'captain-tsubasa-rivals-tsubasaut' = 'captain-tsubasa-rivals-tsubasaut',
    'qidao' = 'qidao',
    'aavegotchi-fud' = 'aavegotchi-fud',
    'aavegotchi-alpha' = 'aavegotchi-alpha',
    'ommniverse' = 'ommniverse',
    'waultswap-polygon' = 'waultswap-polygon',
    'cryptomus' = 'cryptomus',
    'timeleap' = 'timeleap',
    'versagames' = 'versagames',
    'dominium' = 'dominium',
    'geopoly' = 'geopoly',
    'mm-optimizer-polygon' = 'mm-optimizer-polygon',
    'anito-legends' = 'anito-legends',
    'coincollect' = 'coincollect',
    'adamant-finance' = 'adamant-finance',
    'hibiki-run' = 'hibiki-run',
    'xion-finance' = 'xion-finance',
    'polygonfarm-finance' = 'polygonfarm-finance',
    'dragoma' = 'dragoma',
    'tutellus' = 'tutellus',
    'karmaverse-zombie' = 'karmaverse-zombie',
    'quantum-tech' = 'quantum-tech',
    'coinpoker' = 'coinpoker',
    'kogecoin' = 'kogecoin',
    'energy8' = 'energy8',
    'polybeta-finance' = 'polybeta-finance',
    'kpapa' = 'kpapa',
    'saiyan-pepe' = 'saiyan-pepe',
    'rise-of-elves' = 'rise-of-elves',
    'strongnode-edge' = 'strongnode-edge',
    'verity-one-ltd' = 'verity-one-ltd',
    'moonwolf' = 'moonwolf',
    'parrotly' = 'parrotly',
    'pearzap' = 'pearzap',
    'ocavu-network-token' = 'ocavu-network-token',
    'the-world-state' = 'the-world-state',
    'polydragon' = 'polydragon',
    'virtu' = 'virtu',
    'polypup-finance' = 'polypup-finance',
    'penrose-finance' = 'penrose-finance',
    'daily-cop' = 'daily-cop',
    'dpex' = 'dpex',
    'foodchain-global' = 'foodchain-global',
    'polycub' = 'polycub',
    'space-rebase-xusd' = 'space-rebase-xusd',
    'gooeys' = 'gooeys',
    'honey-hive-investments' = 'honey-hive-investments',
    'medieus' = 'medieus',
    'polycrystal-finance' = 'polycrystal-finance',
    'polydefi' = 'polydefi',
    'stasis-network' = 'stasis-network',
    'jetswap-pwings' = 'jetswap-pwings',
    'dovish-finance' = 'dovish-finance',
    'safeswap' = 'safeswap',
    'todays-crypto' = 'todays-crypto',
    'amber-soul-stone' = 'amber-soul-stone',
    'exchange-genesis-ethlas-medium' = 'exchange-genesis-ethlas-medium',
    'kitsumon' = 'kitsumon',
    'dystopia' = 'dystopia',
    'sunflower-farm' = 'sunflower-farm',
    'albino' = 'albino',
    'supremex' = 'supremex',
    'singularfarm' = 'singularfarm',
    'firebird-finance' = 'firebird-finance',
    'sportzchain' = 'sportzchain',
    'okletsplay' = 'okletsplay',
    'cresio' = 'cresio',
    'vault-hill-city' = 'vault-hill-city',
    'greenhouse' = 'greenhouse',
    'alpha-petto-shells' = 'alpha-petto-shells',
    'lunafi' = 'lunafi',
    'meta-utopia' = 'meta-utopia',
    'pddollar' = 'pddollar',
    'polygon-hbd' = 'polygon-hbd',
    'cyber-city' = 'cyber-city',
    'power-of-deep-ocean' = 'power-of-deep-ocean',
    'dude' = 'dude',
    'branaverse' = 'branaverse',
    'abcmeta' = 'abcmeta',
    'maxx-finance' = 'maxx-finance',
    'cookies-protocol' = 'cookies-protocol',
    'runy' = 'runy',
    'reel-token' = 'reel-token',
    'monolend' = 'monolend',
    'c3' = 'c3',
    'bitsniper' = 'bitsniper',
    'climbers' = 'climbers',
    'retreeb' = 'retreeb',
    'ghost' = 'ghost',
    'x-cash' = 'x-cash',
    'taki' = 'taki',
    'bitcoinx-bxc' = 'bitcoinx-bxc',
    'kassandra' = 'kassandra',
    'crypto-royale' = 'crypto-royale',
    'potcoin' = 'potcoin',
    'minerva-wallet' = 'minerva-wallet',
    'intdestcoin' = 'intdestcoin',
    'optimism-ethereum' = 'optimism-ethereum',
    'kwenta' = 'kwenta',
    'dextoro' = 'dextoro',
    'velodrome-finance' = 'velodrome-finance',
    'extra-finance' = 'extra-finance',
    'sonne-finance' = 'sonne-finance',
    'ethos-reserve-note' = 'ethos-reserve-note',
    'lernitas' = 'lernitas',
    'blue-norva' = 'blue-norva',
    'open-swap' = 'open-swap',
    'pika-protocol' = 'pika-protocol',
    'exactly-protocol' = 'exactly-protocol',
    'opx-finance' = 'opx-finance',
    'openx-locked-velo' = 'openx-locked-velo',
    'harmony' = 'harmony',
    'tranquil-staked-one' = 'tranquil-staked-one',
    'speed-star-joc' = 'speed-star-joc',
    'artemis-protocol' = 'artemis-protocol',
    'speed-star-star' = 'speed-star-star',
    'defira' = 'defira',
    'viper-protocol' = 'viper-protocol',
    'knights-peasants' = 'knights-peasants',
    'moon-robots' = 'moon-robots',
    'marscolony' = 'marscolony',
    'farmers-only' = 'farmers-only',
    'tranquil-finance' = 'tranquil-finance',
    'speed-star-speed' = 'speed-star-speed',
    'solana' = 'solana',
    'dogwifhat' = 'dogwifhat',
    'jupiter-ag' = 'jupiter-ag',
    'wormhole' = 'wormhole',
    'bonk1' = 'bonk1',
    'pyth-network' = 'pyth-network',
    'mew' = 'mew',
    'book-of-meme' = 'book-of-meme',
    'jito' = 'jito',
    'slerf' = 'slerf',
    'helium-mobile' = 'helium-mobile',
    'wen' = 'wen',
    'myro' = 'myro',
    'zebec-network' = 'zebec-network',
    'hivemapper' = 'hivemapper',
    'orca' = 'orca',
    'popcat-sol' = 'popcat-sol',
    'tensor' = 'tensor',
    'crown-by-third-time-games' = 'crown-by-third-time-games',
    'access-protocol' = 'access-protocol',
    'staika' = 'staika',
    'star-atlas-polis' = 'star-atlas-polis',
    'neon' = 'neon',
    'artrade' = 'artrade',
    'metaplex' = 'metaplex',
    'solchat' = 'solchat',
    'ponke' = 'ponke',
    'green-satoshi-token' = 'green-satoshi-token',
    'bonfida' = 'bonfida',
    'solend' = 'solend',
    'aurory' = 'aurory',
    'only1' = 'only1',
    'samoyedcoin' = 'samoyedcoin',
    'mango-markets' = 'mango-markets',
    'synesis-one' = 'synesis-one',
    'helium-iot' = 'helium-iot',
    'solanium' = 'solanium',
    'silly-dragon' = 'silly-dragon',
    'octo-gaming' = 'octo-gaming',
    'decimated' = 'decimated',
    'step-finance' = 'step-finance',
    'roa-core' = 'roa-core',
    'solama' = 'solama',
    'defi-land' = 'defi-land',
    'duko' = 'duko',
    'gari' = 'gari',
    'neversol' = 'neversol',
    'cheems' = 'cheems',
    'ben-the-dog' = 'ben-the-dog',
    'analos' = 'analos',
    'spacefalcon' = 'spacefalcon',
    'honk' = 'honk',
    'cryowar' = 'cryowar',
    'pandaswapsol' = 'pandaswapsol',
    'unkjd' = 'unkjd',
    'the-first-youtube-cat' = 'the-first-youtube-cat',
    'crowny' = 'crowny',
    'bozo' = 'bozo',
    'prism' = 'prism',
    'xhashtag' = 'xhashtag',
    'all-art-protocol' = 'all-art-protocol',
    'eggdog' = 'eggdog',
    'woof' = 'woof',
    'hawksight' = 'hawksight',
    'midas-mds' = 'midas-mds',
    'catman' = 'catman',
    'solfarm' = 'solfarm',
    'solzilla' = 'solzilla',
    'frakt-token' = 'frakt-token',
    'dexlab' = 'dexlab',
    'cropperfinance' = 'cropperfinance',
    'racefi' = 'racefi',
    'exgoland' = 'exgoland',
    'sypool' = 'sypool',
    'solchicks-token' = 'solchicks-token',
    'synthetify' = 'synthetify',
    'soldex' = 'soldex',
    'jito-staked-sol' = 'jito-staked-sol',
    'jupiter-perps-lp' = 'jupiter-perps-lp',
    'blazestake-staked-sol' = 'blazestake-staked-sol',
    'jeo-boden' = 'jeo-boden',
    'zeus-network' = 'zeus-network',
    'sharky' = 'sharky',
    'shark-cat' = 'shark-cat',
    'teh-epik-duck' = 'teh-epik-duck',
    'socean-staked-sol' = 'socean-staked-sol',
    'solana-kit' = 'solana-kit',
    'nubcat' = 'nubcat',
    'legends-of-elumia' = 'legends-of-elumia',
    'meowgangs' = 'meowgangs',
    'ansems-cat' = 'ansems-cat',
    'whales-market' = 'whales-market',
    'smolecoin' = 'smolecoin',
    'pundu' = 'pundu',
    'monku' = 'monku',
    'doland-tremp' = 'doland-tremp',
    'peng-sol' = 'peng-sol',
    'iq50' = 'iq50',
    'genesysgo-shadow' = 'genesysgo-shadow',
    'justanegg-sol' = 'justanegg-sol',
    'saros' = 'saros',
    'sols-spl20-io' = 'sols-spl20-io',
    'mumu-ing' = 'mumu-ing',
    'nosana' = 'nosana',
    'costco-hot-dog' = 'costco-hot-dog',
    'kittenwifhat' = 'kittenwifhat',
    'ore' = 'ore',
    'analysoor' = 'analysoor',
    'andy-on-sol' = 'andy-on-sol',
    'moutai' = 'moutai',
    'bork-token' = 'bork-token',
    'book-of-meow' = 'book-of-meow',
    'catwifbag' = 'catwifbag',
    'lido-for-solana' = 'lido-for-solana',
    'metatoken' = 'metatoken',
    'loopy' = 'loopy',
    'parrot-usd' = 'parrot-usd',
    'catwifhatsolana' = 'catwifhatsolana',
    'baby-sol' = 'baby-sol',
    'jizzlord' = 'jizzlord',
    'lessfngas' = 'lessfngas',
    'anita-max-wynn' = 'anita-max-wynn',
    'hump' = 'hump',
    'gme' = 'gme',
    'shork' = 'shork',
    'harambe-on-solana' = 'harambe-on-solana',
    'chonky' = 'chonky',
    'ligma-node' = 'ligma-node',
    'guufy' = 'guufy',
    'snap-sol' = 'snap-sol',
    'sonic-the-goat' = 'sonic-the-goat',
    'magic-internet-money-solana' = 'magic-internet-money-solana',
    'bonk-2' = 'bonk-2',
    'powsche' = 'powsche',
    'baby-of-bomeow' = 'baby-of-bomeow',
    'book-of-solana' = 'book-of-solana',
    'uxd-stablecoin' = 'uxd-stablecoin',
    'candle-cat' = 'candle-cat',
    'wolf-wif' = 'wolf-wif',
    'mnde' = 'mnde',
    'chinu-sol' = 'chinu-sol',
    'pepe-wif-hat-world' = 'pepe-wif-hat-world',
    'catwiftoken-org' = 'catwiftoken-org',
    'tongue-cat' = 'tongue-cat',
    'hund-meme-coin' = 'hund-meme-coin',
    'harold-the-duck' = 'harold-the-duck',
    'guac' = 'guac',
    'media-network' = 'media-network',
    'abble' = 'abble',
    'hubble-protocol' = 'hubble-protocol',
    'burek-obema' = 'burek-obema',
    'mahabibi-bin-solman' = 'mahabibi-bin-solman',
    'bul' = 'bul',
    'uranus-sol' = 'uranus-sol',
    'brett-token' = 'brett-token',
    'dither-ai' = 'dither-ai',
    'koala-ai' = 'koala-ai',
    'monkey-sol' = 'monkey-sol',
    'bscbet-online' = 'bscbet-online',
    'shroomates' = 'shroomates',
    'solcasino-token' = 'solcasino-token',
    'cats-of-sol' = 'cats-of-sol',
    'red-the-mal' = 'red-the-mal',
    'honeyland' = 'honeyland',
    'success-kid-sol' = 'success-kid-sol',
    'solcard' = 'solcard',
    'multi-ai-' = 'multi-ai-',
    'bunny-mev-bot' = 'bunny-mev-bot',
    'orbitt-token' = 'orbitt-token',
    'momo-2' = 'momo-2',
    'punkai' = 'punkai',
    'milady-wif-hat' = 'milady-wif-hat',
    'apewifhat' = 'apewifhat',
    'gigachad-meme' = 'gigachad-meme',
    'fluxbot' = 'fluxbot',
    'ai-dragon' = 'ai-dragon',
    'solmedia' = 'solmedia',
    'print-protocol' = 'print-protocol',
    'bitbrawl' = 'bitbrawl',
    'alickshundra-occasional-cortex' = 'alickshundra-occasional-cortex',
    'solbank' = 'solbank',
    'solana-crash' = 'solana-crash',
    'solar-swap' = 'solar-swap',
    'sonicwifhat' = 'sonicwifhat',
    'hami' = 'hami',
    'vibing-cat' = 'vibing-cat',
    'a-gently-used-2001-honda-civic' = 'a-gently-used-2001-honda-civic',
    'wally-the-whale' = 'wally-the-whale',
    'wolf-of-solana' = 'wolf-of-solana',
    'epep' = 'epep',
    'ninja-protocol' = 'ninja-protocol',
    'rosewifhat' = 'rosewifhat',
    'mochicat' = 'mochicat',
    'sealwifhat' = 'sealwifhat',
    'shibwifhat' = 'shibwifhat',
    'port-finance' = 'port-finance',
    'atlas-dex' = 'atlas-dex',
    'wc-token' = 'wc-token',
    'daumenfrosch' = 'daumenfrosch',
    'mini-bitcoin' = 'mini-bitcoin',
    'wall-street-baby' = 'wall-street-baby',
    'bookofbullrun' = 'bookofbullrun',
    'bozohybrid' = 'bozohybrid',
    'gatsby' = 'gatsby',
    'pepesol' = 'pepesol',
    'dogecola' = 'dogecola',
    'czolana' = 'czolana',
    'you-looked' = 'you-looked',
    'rex' = 'rex',
    'memeolorgy' = 'memeolorgy',
    'moggo' = 'moggo',
    'banx-gg' = 'banx-gg',
    'elizabath-whoren' = 'elizabath-whoren',
    'not-a-lion-a' = 'not-a-lion-a',
    'family-token' = 'family-token',
    'pussy-in-bio' = 'pussy-in-bio',
    'wiflama' = 'wiflama',
    'atpay' = 'atpay',
    'solgoat' = 'solgoat',
    'loaf-cat' = 'loaf-cat',
    'kermit-frog' = 'kermit-frog',
    'queen-of-engrand' = 'queen-of-engrand',
    'joram-poowel' = 'joram-poowel',
    'gecko-coin' = 'gecko-coin',
    'jizzus-christ' = 'jizzus-christ',
    'the-simpsons' = 'the-simpsons',
    'jet-protocol' = 'jet-protocol',
    'silly-goose' = 'silly-goose',
    'kurobi' = 'kurobi',
    'zebu' = 'zebu',
    'bird-dog' = 'bird-dog',
    'mixmob' = 'mixmob',
    'baobaosol' = 'baobaosol',
    'dark-boden' = 'dark-boden',
    'byat' = 'byat',
    'babydogwifhat' = 'babydogwifhat',
    'mbapepe' = 'mbapepe',
    'hunter-biden-laptop' = 'hunter-biden-laptop',
    'your-open-metaverse' = 'your-open-metaverse',
    'solstream' = 'solstream',
    'realy' = 'realy',
    'upsidedowncat' = 'upsidedowncat',
    'yikes-dog' = 'yikes-dog',
    'ceiling-cat' = 'ceiling-cat',
    'whalebert' = 'whalebert',
    'buftoad' = 'buftoad',
    'jake-the-dog' = 'jake-the-dog',
    'novadex' = 'novadex',
    'polycaps' = 'polycaps',
    'real-smurf-cat-sol' = 'real-smurf-cat-sol',
    'larix' = 'larix',
    'lifinity' = 'lifinity',
    'rod-ai' = 'rod-ai',
    'solara' = 'solara',
    'spiderswap' = 'spiderswap',
    'myra' = 'myra',
    'ada' = 'ada',
    'solbook' = 'solbook',
    'rocky' = 'rocky',
    'doggo' = 'doggo',
    'bonkwifhat' = 'bonkwifhat',
    'solpets' = 'solpets',
    'cat-of-elon' = 'cat-of-elon',
    'nova-finance' = 'nova-finance',
    'solordi' = 'solordi',
    'hadeswap' = 'hadeswap',
    'indian-call-center' = 'indian-call-center',
    'i-dont-know' = 'i-dont-know',
    'megapix' = 'megapix',
    'whole-earth-coin' = 'whole-earth-coin',
    'goosefx' = 'goosefx',
    'deplan' = 'deplan',
    'solspend' = 'solspend',
    'blue-whale' = 'blue-whale',
    'korra' = 'korra',
    'doginhood' = 'doginhood',
    'dawkoins' = 'dawkoins',
    'sol-x' = 'sol-x',
    'baby-solana' = 'baby-solana',
    'solex-launchpad' = 'solex-launchpad',
    'kingdom-of-ants' = 'kingdom-of-ants',
    'efk-token' = 'efk-token',
    'bonk-of-america' = 'bonk-of-america',
    'pepewifhat' = 'pepewifhat',
    'safemoon-solana' = 'safemoon-solana',
    'andrea-von-speed' = 'andrea-von-speed',
    'whats-updog' = 'whats-updog',
    'sobit' = 'sobit',
    'solnic' = 'solnic',
    'enterthedojo' = 'enterthedojo',
    'alan-the-alien' = 'alan-the-alien',
    'baby-slerf' = 'baby-slerf',
    'solabrador-xyz' = 'solabrador-xyz',
    'shibainu-on-solana' = 'shibainu-on-solana',
    'sunny-aggregator' = 'sunny-aggregator',
    'pou' = 'pou',
    'sonarwatch' = 'sonarwatch',
    'neversurrenderones' = 'neversurrenderones',
    'taylor-swift-s-cat-benji' = 'taylor-swift-s-cat-benji',
    'egg' = 'egg',
    'soltradingbot' = 'soltradingbot',
    'hmm' = 'hmm',
    'snoopy' = 'snoopy',
    'baba' = 'baba',
    'el-gato' = 'el-gato',
    'lickgoat' = 'lickgoat',
    'bobo-bear' = 'bobo-bear',
    'solice' = 'solice',
    'churro-the-jupiter-dog' = 'churro-the-jupiter-dog',
    'shibonkcoin' = 'shibonkcoin',
    'palmy' = 'palmy',
    'richrabbit' = 'richrabbit',
    '21x' = '21x',
    'the-gm-machine' = 'the-gm-machine',
    'catscoin-sol' = 'catscoin-sol',
    'bpinky' = 'bpinky',
    'gary-banking' = 'gary-banking',
    'kineko1' = 'kineko1',
    'canwifhat' = 'canwifhat',
    'dino' = 'dino',
    'memecoindao' = 'memecoindao',
    'prnt' = 'prnt',
    'catwifhat' = 'catwifhat',
    'solpaka' = 'solpaka',
    'hairypotheadtrempsanic69inu' = 'hairypotheadtrempsanic69inu',
    'pepe-neko' = 'pepe-neko',
    'cyclos' = 'cyclos',
    'solana-shib' = 'solana-shib',
    'ai' = 'ai',
    'spoofify' = 'spoofify',
    'balin-bank' = 'balin-bank',
    'lowq-frends' = 'lowq-frends',
    'edgevana-staked-sol' = 'edgevana-staked-sol',
    'dogwifscarf' = 'dogwifscarf',
    'trump-pepe-solana' = 'trump-pepe-solana',
    'nobiko-coin' = 'nobiko-coin',
    'black-coqinu' = 'black-coqinu',
    'marvin-2' = 'marvin-2',
    'parrot-protocol' = 'parrot-protocol',
    'cat-wif-hat' = 'cat-wif-hat',
    'crustieno-renaldo' = 'crustieno-renaldo',
    'fomosolana' = 'fomosolana',
    'corestarter' = 'corestarter',
    'gpu-inu' = 'gpu-inu',
    'waggle-network' = 'waggle-network',
    'tabtrader-token' = 'tabtrader-token',
    'gameboy' = 'gameboy',
    'war-of-meme' = 'war-of-meme',
    'dragoncoin' = 'dragoncoin',
    'swifties-space' = 'swifties-space',
    'psy-options' = 'psy-options',
    'stray' = 'stray',
    'puff' = 'puff',
    'siu' = 'siu',
    'graphite-protocol' = 'graphite-protocol',
    'coolcat' = 'coolcat',
    'blocksmith-labs-forge' = 'blocksmith-labs-forge',
    'magaiba' = 'magaiba',
    'wall-street-bets-solana' = 'wall-street-bets-solana',
    'cat-doge' = 'cat-doge',
    'starlaunch' = 'starlaunch',
    'solana-beach' = 'solana-beach',
    'fubao' = 'fubao',
    'uxd-protocol' = 'uxd-protocol',
    'solfiles' = 'solfiles',
    'book-of-whales' = 'book-of-whales',
    'tholana' = 'tholana',
    'andy-sol' = 'andy-sol',
    'crazy-pepe-sol' = 'crazy-pepe-sol',
    'trump-sol' = 'trump-sol',
    'pug-ai' = 'pug-ai',
    'hotdogsolana' = 'hotdogsolana',
    'yaoyaos-cat' = 'yaoyaos-cat',
    'liq-protocol' = 'liq-protocol',
    'unqclub' = 'unqclub',
    'ratio-finance' = 'ratio-finance',
    'peepo-sol' = 'peepo-sol',
    'catfish-so' = 'catfish-so',
    'sdoge' = 'sdoge',
    'fronk' = 'fronk',
    'knob' = 'knob',
    'block' = 'block',
    'silly-duck' = 'silly-duck',
    'world-record-banana' = 'world-record-banana',
    'degen-sol' = 'degen-sol',
    'yaku' = 'yaku',
    'deltafi' = 'deltafi',
    'flowmatic' = 'flowmatic',
    'tmrw-coin' = 'tmrw-coin',
    'bomk' = 'bomk',
    'angry-girlfriend' = 'angry-girlfriend',
    'solrazr' = 'solrazr',
    'simbawifhat' = 'simbawifhat',
    'aldrin' = 'aldrin',
    'wenwifhat' = 'wenwifhat',
    'bitcoin-cat' = 'bitcoin-cat',
    'solidefi' = 'solidefi',
    'solpad-finance' = 'solpad-finance',
    'bible-of-memes' = 'bible-of-memes',
    'pepecoin-on-sol' = 'pepecoin-on-sol',
    'solanajeet' = 'solanajeet',
    'dogelana-com' = 'dogelana-com',
    'kabosu-sol' = 'kabosu-sol',
    'dynasty-wars' = 'dynasty-wars',
    'sentre-protocol' = 'sentre-protocol',
    'famous-fox-federation' = 'famous-fox-federation',
    'just-the-tip' = 'just-the-tip',
    'bonk-earn' = 'bonk-earn',
    'krypdraw' = 'krypdraw',
    'tanox' = 'tanox',
    'arb-protocol' = 'arb-protocol',
    'nana-token' = 'nana-token',
    'monkedao' = 'monkedao',
    'yuge-coin' = 'yuge-coin',
    'capybara-sol' = 'capybara-sol',
    'hashbit-blockchain' = 'hashbit-blockchain',
    'frogonsol' = 'frogonsol',
    'garfield-wif-hat' = 'garfield-wif-hat',
    'sols' = 'sols',
    'cato' = 'cato',
    'bong-bonks-brother' = 'bong-bonks-brother',
    'cat-king' = 'cat-king',
    'corn-dog' = 'corn-dog',
    'nexmillionaires-app' = 'nexmillionaires-app',
    'sillycat' = 'sillycat',
    'soldoge' = 'soldoge',
    'honey-finance' = 'honey-finance',
    'ewon-mucks' = 'ewon-mucks',
    'monkeywifhat' = 'monkeywifhat',
    'solamander' = 'solamander',
    'yotoshi' = 'yotoshi',
    'chili' = 'chili',
    'sbf-in-jail' = 'sbf-in-jail',
    'grimreaper-coin' = 'grimreaper-coin',
    'burrrd' = 'burrrd',
    'vizion-protocol' = 'vizion-protocol',
    'phauntem' = 'phauntem',
    'nikola-tesla-token' = 'nikola-tesla-token',
    'plerf' = 'plerf',
    'libra-finance' = 'libra-finance',
    'sam-bankmeme-fried' = 'sam-bankmeme-fried',
    'pre' = 'pre',
    'kitty-coin-solana' = 'kitty-coin-solana',
    'chuchu' = 'chuchu',
    'plutonians-tech' = 'plutonians-tech',
    'jpool' = 'jpool',
    'pepe-sora-ai' = 'pepe-sora-ai',
    'grape-network' = 'grape-network',
    'badluckbaby' = 'badluckbaby',
    'wifstash-coin' = 'wifstash-coin',
    'crashboys' = 'crashboys',
    'pigeon-in-yellow-boots' = 'pigeon-in-yellow-boots',
    'the-mvp-society' = 'the-mvp-society',
    'dogemon-go' = 'dogemon-go',
    'neko-coin' = 'neko-coin',
    'pepe-by-matt-furie' = 'pepe-by-matt-furie',
    'doge-1-solana' = 'doge-1-solana',
    'dogswaghat' = 'dogswaghat',
    'safemuun' = 'safemuun',
    'gary' = 'gary',
    'homer-simpson-solana' = 'homer-simpson-solana',
    'rich-of-memes' = 'rich-of-memes',
    'snoopybabe' = 'snoopybabe',
    'basis-markets' = 'basis-markets',
    'leancoin' = 'leancoin',
    'goku-sol' = 'goku-sol',
    'solamb' = 'solamb',
    'boring-protocol' = 'boring-protocol',
    'donald-trump' = 'donald-trump',
    'community-of-meme' = 'community-of-meme',
    'sol-cat-warrior' = 'sol-cat-warrior',
    'walli' = 'walli',
    'solberg' = 'solberg',
    'meow-of-meme' = 'meow-of-meme',
    'astronaut-aron' = 'astronaut-aron',
    'homer-of-meme' = 'homer-of-meme',
    'sekai-dao' = 'sekai-dao',
    'decentracloud' = 'decentracloud',
    'idolinu' = 'idolinu',
    'solapefinance' = 'solapefinance',
    'trendingtool-io' = 'trendingtool-io',
    'kitty-ai' = 'kitty-ai',
    'pepe-the-pepe' = 'pepe-the-pepe',
    'style' = 'style',
    'king-of-cats' = 'king-of-cats',
    'lambda-markets' = 'lambda-markets',
    'cat-hero' = 'cat-hero',
    'compendium-finance' = 'compendium-finance',
    'genopets-ki' = 'genopets-ki',
    'homer-sol' = 'homer-sol',
    'solfarm-token' = 'solfarm-token',
    'book-of-nothing' = 'book-of-nothing',
    'solpeng' = 'solpeng',
    'snakes-on-a-nft-game' = 'snakes-on-a-nft-game',
    'voodoo-token' = 'voodoo-token',
    'harrypotterwifhatmyrowynn10inu' = 'harrypotterwifhatmyrowynn10inu',
    'dawg' = 'dawg',
    'myro-dog' = 'myro-dog',
    'kitti-token' = 'kitti-token',
    'copewithus' = 'copewithus',
    'swole-doge' = 'swole-doge',
    'chikincoin' = 'chikincoin',
    'penisgrow' = 'penisgrow',
    'slerffork' = 'slerffork',
    'genz-token' = 'genz-token',
    'slork' = 'slork',
    'robin-of-da-hood' = 'robin-of-da-hood',
    'evermoon-sol' = 'evermoon-sol',
    'chinese-communist-dragon' = 'chinese-communist-dragon',
    'crew-inu' = 'crew-inu',
    'duge' = 'duge',
    'holygrails-io' = 'holygrails-io',
    'aping' = 'aping',
    'viral-inu' = 'viral-inu',
    'dogald-trump' = 'dogald-trump',
    'heavenland' = 'heavenland',
    'book-of-killer' = 'book-of-killer',
    'crypto-cavemen-club' = 'crypto-cavemen-club',
    'pepe-v2' = 'pepe-v2',
    'cirque-du-sol' = 'cirque-du-sol',
    'boku' = 'boku',
    'crazy-bunny-top' = 'crazy-bunny-top',
    'avocadocoin' = 'avocadocoin',
    'larrywifhat' = 'larrywifhat',
    'sandy-sol' = 'sandy-sol',
    'sussy-baka-impostor' = 'sussy-baka-impostor',
    'book-of-meme-2-0' = 'book-of-meme-2-0',
    'mad-bears-club' = 'mad-bears-club',
    'panda-coin' = 'panda-coin',
    'welsh-corgi' = 'welsh-corgi',
    'yolonolo' = 'yolonolo',
    'solsponge' = 'solsponge',
    'fone' = 'fone',
    'nioctib' = 'nioctib',
    'dragy' = 'dragy',
    'dogwiffork' = 'dogwiffork',
    'investin' = 'investin',
    'gamefork' = 'gamefork',
    'johm-lemmon' = 'johm-lemmon',
    'worried' = 'worried',
    'solareum-wallet' = 'solareum-wallet',
    'potato' = 'potato',
    'solanapepe' = 'solanapepe',
    'astradex' = 'astradex',
    'plutonians' = 'plutonians',
    'solxdex' = 'solxdex',
    'akita-inu-token' = 'akita-inu-token',
    'jerry-on-sol' = 'jerry-on-sol',
    'saudi-pepe' = 'saudi-pepe',
    'solcial' = 'solcial',
    'pepewifhat-sol' = 'pepewifhat-sol',
    'watergate' = 'watergate',
    'oogi' = 'oogi',
    'lunar-new-year' = 'lunar-new-year',
    'moonlana' = 'moonlana',
    'bitcat' = 'bitcat',
    'lolasol' = 'lolasol',
    'pepe-floki-solana' = 'pepe-floki-solana',
    'wifcat-coin' = 'wifcat-coin',
    'chief-troll-officer' = 'chief-troll-officer',
    'danol-tremp' = 'danol-tremp',
    'sam' = 'sam',
    'bamboo-coin' = 'bamboo-coin',
    'cronk-sol' = 'cronk-sol',
    'scrap' = 'scrap',
    'kitty-solana' = 'kitty-solana',
    'woop' = 'woop',
    'puma-sol' = 'puma-sol',
    'noka-solana-ai' = 'noka-solana-ai',
    'solana-meme-token' = 'solana-meme-token',
    'magasolana' = 'magasolana',
    'ethrix' = 'ethrix',
    'trump2024solana' = 'trump2024solana',
    'selfie-cat' = 'selfie-cat',
    'apexit-finance' = 'apexit-finance',
    'capybara' = 'capybara',
    'tremp' = 'tremp',
    'pouwifhat' = 'pouwifhat',
    'maga-sol' = 'maga-sol',
    'memes-street' = 'memes-street',
    'book-of-meme-2' = 'book-of-meme-2',
    'pepetrumptoken' = 'pepetrumptoken',
    'sunny-side-up' = 'sunny-side-up',
    'gemini-inu' = 'gemini-inu',
    'apricot' = 'apricot',
    'croco' = 'croco',
    'sonicobamalockheedmartin69inu' = 'sonicobamalockheedmartin69inu',
    'rope-token' = 'rope-token',
    'ghostkiddao' = 'ghostkiddao',
    'bruv' = 'bruv',
    'mege' = 'mege',
    'simpson-family' = 'simpson-family',
    'gaysler' = 'gaysler',
    'venture-coin-2' = 'venture-coin-2',
    'floof' = 'floof',
    'galaxy-dao' = 'galaxy-dao',
    'lyra-sol' = 'lyra-sol',
    'chuck-norris' = 'chuck-norris',
    'doge-of-grok-ai' = 'doge-of-grok-ai',
    'courage-the-cowardly-dog' = 'courage-the-cowardly-dog',
    'babytrump-net' = 'babytrump-net',
    'longsol-vip' = 'longsol-vip',
    'just-a-cat' = 'just-a-cat',
    'the-og-cheems-inu' = 'the-og-cheems-inu',
    'bang-hub' = 'bang-hub',
    'wrapped-algo' = 'wrapped-algo',
    'metamall' = 'metamall',
    'moonkize' = 'moonkize',
    'thesolandao' = 'thesolandao',
    'etfsol2024' = 'etfsol2024',
    'laddercaster' = 'laddercaster',
    'solanaprime' = 'solanaprime',
    'trump-inu-solana' = 'trump-inu-solana',
    'nfteyez' = 'nfteyez',
    'save-elon-coin' = 'save-elon-coin',
    'book-of-memes' = 'book-of-memes',
    'yawww' = 'yawww',
    'solanasail' = 'solanasail',
    'king-slerf' = 'king-slerf',
    'nirvana-nirv' = 'nirvana-nirv',
    'babyslerf' = 'babyslerf',
    'trump-solana' = 'trump-solana',
    'defi-land-gold' = 'defi-land-gold',
    'perpetual-motion-machine' = 'perpetual-motion-machine',
    'dragoncoin-sol' = 'dragoncoin-sol',
    'doginwotah' = 'doginwotah',
    'elusk-mon' = 'elusk-mon',
    'sweets' = 'sweets',
    'charactbit' = 'charactbit',
    'elumia-krystals-legends-of-elumia' = 'elumia-krystals-legends-of-elumia',
    'doginphire' = 'doginphire',
    'slerf-2' = 'slerf-2',
    'cricket-star-manager' = 'cricket-star-manager',
    'secret-skellies-society' = 'secret-skellies-society',
    'unclemine' = 'unclemine',
    'book-of-derp' = 'book-of-derp',
    'learning-star' = 'learning-star',
    'soulocoin' = 'soulocoin',
    'tiny-colony' = 'tiny-colony',
    'glitter-finance' = 'glitter-finance',
    'solchicks-shards' = 'solchicks-shards',
    'boombaby-io' = 'boombaby-io',
    'baby-samo-coin' = 'baby-samo-coin',
    'solanacash' = 'solanacash',
    'theca' = 'theca',
    'mindfolk-wood' = 'mindfolk-wood',
    'solvent' = 'solvent',
    'rally-solana' = 'rally-solana',
    'enrex' = 'enrex',
    'abc-floor-index' = 'abc-floor-index',
    'jelly-esports' = 'jelly-esports',
    'aevum' = 'aevum',
    'leia' = 'leia',
    '2024-token' = '2024-token',
    'socola-inu' = 'socola-inu',
    'solanaape' = 'solanaape',
    'elon-dragon' = 'elon-dragon',
    'randall' = 'randall',
    'muskdragon' = 'muskdragon',
    'wc-donalds' = 'wc-donalds',
    'meow-meow-coin' = 'meow-meow-coin',
    'funassyi' = 'funassyi',
    'maga-trump-solana' = 'maga-trump-solana',
    'citi-fediverse' = 'citi-fediverse',
    'bome-2-bio' = 'bome-2-bio',
    'figurewifhat' = 'figurewifhat',
    'atlaz' = 'atlaz',
    'helium' = 'helium',
    'usdh' = 'usdh',
    'saber' = 'saber',
    'bonklana' = 'bonklana',
    'hedge-usd' = 'hedge-usd',
    'definder-capital' = 'definder-capital',
    'jetton' = 'jetton',
    'resistance-dog' = 'resistance-dog',
    'ton-bridged-usdt' = 'ton-bridged-usdt',
    'gram' = 'gram',
    'ston-fi' = 'ston-fi',
    'tg20-tgram' = 'tg20-tgram',
    'tony-the-duck' = 'tony-the-duck',
    'tonk-inu' = 'tonk-inu',
    'ton-raffles' = 'ton-raffles',
    'kingyton' = 'kingyton',
    'tonnel-network' = 'tonnel-network',
    'paper-plane' = 'paper-plane',
    'ton-inu' = 'ton-inu',
    'arbuz' = 'arbuz',
    'fanzee' = 'fanzee',
    'scaleton' = 'scaleton',
    'duckcoin' = 'duckcoin',
    'megaton-finance' = 'megaton-finance',
    'gemston' = 'gemston',
    'glint-coin' = 'glint-coin',
    'punk-city' = 'punk-city',
    'lavandos' = 'lavandos',
    'find-check' = 'find-check',
    'tongochi' = 'tongochi',
    'safemoonton' = 'safemoonton',
    'bemo-staked-ton' = 'bemo-staked-ton',
    'huebel-bolt' = 'huebel-bolt',
    'margaritis' = 'margaritis',
    'ton-bridged-usdc' = 'ton-bridged-usdc',
    'tonsniper' = 'tonsniper',
    'ambra' = 'ambra',
    'tonex' = 'tonex',
    'just' = 'just',
    'usdj' = 'usdj',
    'sun-token' = 'sun-token',
    'coconut-chicken' = 'coconut-chicken',
    'wstusdt' = 'wstusdt',
    'rock-dao' = 'rock-dao',
    'bitcoin-trc20' = 'bitcoin-trc20',
    'paynet-coin' = 'paynet-coin',
    'fimarkcoin' = 'fimarkcoin',
    'toman-coin' = 'toman-coin',
    'kyrrex' = 'kyrrex',
    'fuji' = 'fuji',
    'metaworldcc' = 'metaworldcc',
    'guarantee' = 'guarantee',
    'companion-pet-coin' = 'companion-pet-coin',
    'carrot' = 'carrot',
    'corn' = 'corn',
    'global-trading-xenocurrency' = 'global-trading-xenocurrency',
    'salmonswap' = 'salmonswap',
    'tai' = 'tai',
    'fix00' = 'fix00',
    'bemchain' = 'bemchain',
    'upstabletoken' = 'upstabletoken',
    'staked-trx' = 'staked-trx',
    'ushark' = 'ushark',
    'greenzonex' = 'greenzonex',
    'degree-crypto-token' = 'degree-crypto-token',
    'tourism-industry-metaverse' = 'tourism-industry-metaverse',
    'x7-coin' = 'x7-coin',
    'bull-tron' = 'bull-tron',
    'osk' = 'osk',
    'lumi-credits' = 'lumi-credits',
    'pearl' = 'pearl',
    'litecoin-trc20' = 'litecoin-trc20',
    'jackpool-finance' = 'jackpool-finance',
    'muse-ent-nft' = 'muse-ent-nft',
    'cornatto' = 'cornatto',
    'healium' = 'healium',
    'cryptobharatcoin' = 'cryptobharatcoin',
    'socialswap' = 'socialswap',
    'mcobit' = 'mcobit',
    'cryptoneur-network-foundation' = 'cryptoneur-network-foundation',
    'jot-art' = 'jot-art',
    'tamakitty' = 'tamakitty',
    'chainpay' = 'chainpay',
    'bizzcoin' = 'bizzcoin',
    '888tron' = '888tron',
    'tronbetlive' = 'tronbetlive',
    'mmscash' = 'mmscash',
    'ruby-currency' = 'ruby-currency',
    'extraterrestrial-token' = 'extraterrestrial-token',
    'truecnh' = 'truecnh',
    'itc' = 'itc',
    'wrapped-tron' = 'wrapped-tron',
    'metagamz' = 'metagamz',
    'venom' = 'venom',
    'vechain' = 'vechain',
    'vethor-token' = 'vethor-token',
    'oceanex-token' = 'oceanex-token',
    'jur' = 'jur',
    'xdc-network' = 'xdc-network',
    'comtech-gold' = 'comtech-gold',
    'plugin' = 'plugin',
    'storx-network' = 'storx-network',
    'fathom-protocol' = 'fathom-protocol',
    'globiance-exchange-token' = 'globiance-exchange-token',
    'law-blocks' = 'law-blocks',
    'xswap-protocol' = 'xswap-protocol',
    'fathom-protocol-token' = 'fathom-protocol-token',
    'xswap-treasure' = 'xswap-treasure',
    'ecoin-2' = 'ecoin-2',
    'fluent-finance' = 'fluent-finance',
    'wrapped-xdc-network' = 'wrapped-xdc-network',
    'stellar' = 'stellar',
    'mobius' = 'mobius',
    'rabet' = 'rabet',
    'lumenswap' = 'lumenswap',
    'edgecoin' = 'edgecoin',
    'scopuly-coin' = 'scopuly-coin',
    'thefutbolcoin' = 'thefutbolcoin',
    'afreum' = 'afreum',
    'xrp-healthcare' = 'xrp-healthcare',
    'galaxycoin' = 'galaxycoin',
    'xrpaynet' = 'xrpaynet',
    'eq' = 'eq',
    'xrp-classic-token-new' = 'xrp-classic-token-new',
    'xspectar' = 'xspectar',
    'xgold-coin' = 'xgold-coin',
    'elysian-els' = 'elysian-els',
    'smartnft' = 'smartnft',
    'xlist' = 'xlist',
    'smartlox' = 'smartlox',
    'the-reaper' = 'the-reaper',
}
